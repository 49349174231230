import React from 'react';

import { ServicingRoutes } from '../../../../../../routes';
import { BannerNotificationsProps } from '../../types';

import { ActiveFinancialSituationReview } from './ActiveFinancialSituationReview';

type SettlementOfferAcceptanceProps = Pick<BannerNotificationsProps, 'settlementOfferExpirationDate' | 'uuid'>;
export const SettlementOfferAcceptance: React.FC<SettlementOfferAcceptanceProps> = ({ settlementOfferExpirationDate, uuid}) => (
  <ActiveFinancialSituationReview
    uuid={uuid}
    bannerText='Your settlement offer expires on {0}'
    bannerLink='View Offer'
    expirationDate={settlementOfferExpirationDate || ''}
    workflowRoute={ServicingRoutes.settlementEnrollment}
  />
);
