import * as React from 'react';
import {
  Headline,
  SelectInput,
  SelectOption,
} from '@amount/frontend-components';
import { FragmentSelectionOnWorkflowSelectInput, IInputProps } from '@amount/workflow-js';
import camelCase from 'lodash-es/camelCase';

import { WorkflowInputMaxWidthWrapper } from './common';

interface IWorkflowSelectInputProps extends IInputProps {
  input: FragmentSelectionOnWorkflowSelectInput;
}

export const WorkflowSelectInput: React.FunctionComponent<IWorkflowSelectInputProps> = ({
  input,
  updateInput,
  value,
}) => (
  <>
    { input.placeholder && (
      <Headline scale='small' as='label' htmlFor={camelCase(input.placeholder || undefined)}>
        {input.placeholder}
      </Headline>
    )}
    <WorkflowInputMaxWidthWrapper>
      <SelectInput
        onChange={e => updateInput(e.currentTarget.value)}
        defaultValue={value}
        required={!!input.required}
        placeholder={input.placeholder || undefined}
        data-event={camelCase(`${input.placeholder || ''}SelectInput`)}
        id={camelCase(input.placeholder || undefined)}
      >
        {input.values
          .map(opt => (
            <SelectOption
              key={opt.value}
              value={opt.value}
            >
              {opt.displayValue}
            </SelectOption>
          ))
        }
      </SelectInput>
    </WorkflowInputMaxWidthWrapper>
  </>
);
