import React from 'react';
import Styled from 'styled-components';
import { Button  } from '@amount/frontend-components';
import { Link } from 'react-router-dom';

import { FragmentSelectionOnLoan } from '../queries/GetCustomerHomeInformation.graphql';
import { ApplicationBaseRoutes } from '../../../routes';
import RepaymentCircularProgress from '../../Common/RepaymentCircularProgress';
import { ButtonWrapper, Divider, HalfContainer, ProductInfoWrapper, StyledViewArrowSVG } from '../common';
import { ConditionalRenderWrapper, FlexColumn } from '../../Common/CommonStyles';
import DatapointLabelWrapper from '../../Common/DatapointLabelWrapper';

import NextPaymentLoanCard from './NextPaymentLoanCard';

const RepaymentContainer = Styled(FlexColumn)`
  flex: 1 1 100%;
  justify-content: flex-start;
`;

const HeadlineWrapper = Styled.div<{ padding: string }>`
  padding: ${props => props.padding};
`;

interface ILoanContentProps {
  loan: FragmentSelectionOnLoan;
  inactive: boolean;
}

export const LoanContent: React.FunctionComponent<ILoanContentProps> = ({ loan, inactive }) => {
  if (!loan) { return null; }

  const hideNextPayment: boolean = !!(inactive || loan.wasRefinanced);

  return (
    <>
      <ProductInfoWrapper>
        {hideNextPayment ? (
          <RepaymentContainer>
            {!inactive && (
              <>
                <HeadlineWrapper as='h4' padding='0 0 0.625em'>
                  <DatapointLabelWrapper>Repayment Progress</DatapointLabelWrapper>
                </HeadlineWrapper>
                <RepaymentCircularProgress {...loan} />
              </>
            )}
          </RepaymentContainer>
        ) : (
          <>
            <HalfContainer>
              <HeadlineWrapper as='h4' padding='0 0 0.625em'>
                <DatapointLabelWrapper>Repayment Progress</DatapointLabelWrapper>
              </HeadlineWrapper>
              <RepaymentCircularProgress {...loan} />
            </HalfContainer>
            <Divider />
            <HalfContainer>
              <HeadlineWrapper as='h4' padding='0 0 1.125em'>
                <DatapointLabelWrapper>
                  {loan.isLate && loan.pastDueAmount && loan.pastDueAmount.cents > 0 ? 'Past Due Amount' : 'Next Amount Due'}
                </DatapointLabelWrapper>
              </HeadlineWrapper>
              <NextPaymentLoanCard {...loan} />
            </HalfContainer>
          </>
        )}
      </ProductInfoWrapper>
      {AvantConfig.TenantConfig.hasLoanServicing && loan.isFunded && (
        <ConditionalRenderWrapper hiddenOnMobile={false}>
          <ButtonWrapper>
            <Link data-event='viewLoan' tabIndex={-1} to={`${ApplicationBaseRoutes.loanServicing}/${loan.uuid}`}>
              <Button buttonStyle='secondary' inline={true}>
                <strong>View Loan</strong>
                <StyledViewArrowSVG icon='forward-arrow' iconStyle='link' />
              </Button>
            </Link>
          </ButtonWrapper>
        </ConditionalRenderWrapper>
      )}
    </>
  );
};
