import { ICustomerDashTheme } from '../customTypings/tenantTheme';


const creditTypeTextProps = {
  backgroundColor: 'green10',
  padding: {
    horizontal: '1x',
    vertical: '0.625x'
  }
};

export const BaseTenantTheme: ICustomerDashTheme = {
  boxes: {
    RootContainer: {},
    backgroundProvider: {
      display: 'block',
    },
    contentContainer: {
      direction: 'column',
    },
    contentPaddingContainer: {
      direction: 'column',
      width: '100%',
      padding: {
        base: { vertical: '4x', horizontal: '5%' },
        xlarge: { top: '6x', right: '0', bottom: '4x' },
      },
    },
    innerContainer: {
      padding: {
        left: '2x',
        right: '2x',
      },
    },
    maxWidthContainer: {
      width: { max: '1200px' },
      margin: '0 auto',
    },
    productDetails: {
      direction: {
        base: 'column',
        medium: 'row',
      },
      justifyContent: 'space-between',
      margin: { bottom: '3x' },
    },
    outerContainer: {
      backgroundColor: 'transparent',
      justifyContent: 'center',
      padding: {
        base: { top: '6x' },
        medium: { top: '8x' },
        large: { top: '10x' },
      },
      width: '100%',
    },
    verContainer: {
      padding: {
        base: { top: '6x' },
        medium: { top: '8x' },
        large: { top: '10x' }
      }
    },
    introHeader: {
      margin: {
        base: { bottom: '3x' },
        medium: { bottom: '4x' },
      },
    },
    subHead: {
      margin: {
        base: { bottom: '3x' },
        medium: { bottom: '4x' },
      },
    },
    verInnerContainer: {
      display: 'block',
      padding: {
        left: '2x',
        right: '2x',
      },
      width: '66.25em',
    },
    verOuterContainer: {
      backgroundColor: 'transparent',
      justifyContent: 'center',
      padding: {
        base: { top: '6x' },
        medium: { top: '8x' },
        large: { top: '10x' },
      },
      width: '100%',
    },
  },
  typography: {},
  mitek: {
    gif: {
      margin: '0.75em auto',
    },
  },
  datapointLabel: {
    size: '1.6rem',
  },
  destructiveButtonType: 'primary',
  checkImageContents: {
    accountBackground: '#dadee6',
    textAlign: 'center',
    depositTypePadding: '0.25em',
    fontSize: '2em',
    fontFamily: `'micr_encodingregular', Helvetica, Arial, 'Lucida Grande', sans-serif`,
  },
  inputs: {
    datePicker: {
      width: '100%'
    }
  },
  allSetPage: {
    marqetaJs: {
      card: {
        fontFamily: `'optimist', Helvetica, Arial, 'Lucida Grande', sans-serif`,
        fontSize: {
          desktop: {
            cardNumber: '28px',
            expCvvNumber: '22px'
          },
          mobile: {
            cardNumber: '16px',
            expCvvNumber: '14px'
          }
        }
      },
      modal: {
        fontFamily: `'optimist_semibold', Helvetica, Arial, 'Lucida Grande', sans-serif`,
        fontWeight: '600',
        fontSize: {
          desktop: {
            cardNumber: '24px',
            expCvvNumber: '24px'
          },
          mobile: {
            cardNumber: '20px',
            expCvvNumber: '20px'
          }
        }
      }
    },
    fontColor: '#255F82'
  },
  errors: {
    errorHeader: {
      margin: { base: { horizontal: '3x' } },
      align: 'center'
    },
    serverErrorText: {
      size: 'heroSmall',
      align: 'center',
      lineHeight: '1.875',
    },
    notFoundText: {
      size: 'body',
      align: 'center',
      lineHeight: '1.5',
    }
  },
  autopayModal: {
    cardWidth: { max: '42em' },
    heading: {
      size: 'heroLarge',
      lineHeight: '1.25',
      color: 'black',
      family: 'optimist_light',
      padding: { bottom: '2x' },
    }
  },
  paymentTable: {
    status: {
      completed: {
        icon: {
          icon: 'done',
          color: 'success',
          size: '3x',
        },
        iconOffset: {
          top: '1em',
          left: '-0.25em'
        }
      },
      returned: {
        icon: {
          icon: 'cancel',
          color: 'error',
          size: '2.5x'
        },
        iconOffset: {
          top: '1em',
          left: '-0.25em'
        }
      },
      cancelled: {
        icon: {
          icon: 'cancel',
          color: 'error',
          size: '2.5x'
        },
        iconOffset: {
          top: '1em',
          left: '-0.25em'
        }
      },
      autopay: {
        icon: {
          icon: 'autopay',
          color: 'success',
          size: '2.5x'
        },
        iconOffset: {
          top: '1em',
          left: '-0.25em'
        }
      },
      created: {
        icon: {
          icon: 'calendar-date',
          color: 'primary',
          size: '2.75x'
        },
        iconOffset: {
          top: '1em',
          left: '-0.25em'
        }
      },
      notScheduled: {
      },
      isPastDue: {
        icon: {
          icon: 'service-incident-outline',
          color: 'error',
          size: '2.5x'
        },
        iconOffset: {
          top: '1em',
          left: '-0.25em'
        }
      },
      onHold: {
        icon: {
          icon: 'loading-hourglass',
          color: 'disabledText',
          size: '2.5x'
        },
        iconOffset: {
          top: '1em',
          left: '-0.25em'
        }
      },
    },
    type: {
      payoff: {
        text: {
          backgroundColor: 'yellow10',
          padding: {
            horizontal: '1x',
            vertical: '0.625x'
          }
        },
        mobileIconPadding: '0.625x'
      },
      oneTimePayment: {
        text: {
          backgroundColor: 'yellow10',
          padding: {
            horizontal: '1x',
            vertical: '0.625x'
          }
        },
        mobileIconPadding: '0.625x'
      },
      oneTimePaymentReturned: {
        text: {
          backgroundColor: 'red10',
          padding: {
            horizontal: '1x',
            vertical: '0.625x'
          }
        },
      },
      installment: {
        icon: {
          icon: 'status-outline',
          color: 'gray400',
          size: '1.5x',
          iconTitle: 'unpaid',
          'aria-hidden': false,
        },
        text: {
          padding: {
            vertical: '0.625x'
          }
        },
        iconOffset: {
          top: '.8em',
          left: '0.25em'
        },
        mobileIconPadding: '0.625x'
      },
      installmentPaid: {
        icon: {
          icon: 'status-circle',
          color: 'success',
          size: '1.5x',
          iconTitle: 'paid',
          'aria-hidden': false,
        },
        text: {
          padding: {
            vertical: '0.625x'
          }
        },
        iconOffset: {
          top: '.8em',
          left: '0.25em'
        },
        mobileIconPadding: '0.625x'
      },
      installmentPastDue: {
        icon: {
          icon: 'status-outline',
          color: 'error',
          size: '1.5x',
          iconTitle: 'past due',
          'aria-hidden': false,
        },
        text: {
          padding: {
            vertical: '0.625x'
          }
        },
        iconOffset: {
          top: '.8em',
          left: '0.25em'
        },
        mobileIconPadding: '0.625x'
      },
      installmentInactive: {
        icon: {
          icon: 'status-circle',
          color: 'gray400',
          size: '1.5x',
          iconTitle: 'inactive',
          'aria-hidden': false,
        },
        text: {
          padding: {
            vertical: '0.625x'
          }
        },
        iconOffset: {
          top: '.8em',
          left: '0.25em'
        },
        mobileIconPadding: '0.625x'
      },
      credit: {
        text: creditTypeTextProps,
      },
      refund: {
        text: creditTypeTextProps,
      },
      orderAdjustment: {
        text: creditTypeTextProps,
      },
      paperCheckRefund: {
        text: creditTypeTextProps,
      }
    },
    inactiveRow: {
      opacity: '75%'
    },
  },
  banners: {
    verificationsConfirm: {
      style: 'success'
    },
    bannerTextAlignment: 'center',
  },
};
