import { ProductStatuses } from "@Constants"

export const validProductStatusToViewDashboard = [
  ProductStatuses.ChargedOff,
  ProductStatuses.Current,
  ProductStatuses.Late,
  ProductStatuses.PaidOff,
];

export function canAccessServicingDashboard ({
  isVCUsed, productStatus
}: {
  isVCUsed: boolean;
  productStatus: string;
}) {
  return (
    isVCUsed &&
    validProductStatusToViewDashboard.includes(productStatus as ProductStatuses)
  );
}
