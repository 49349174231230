import Styled from 'styled-components';

export const Close = Styled.button`
  margin-left: 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  svg {
    fill: ${({ theme: { banner: { inactive }}}) => inactive.colorText};
    width: 0.875em;
    height: 0.875em;
  }
`;
