import React from 'react';
import {
  Text,
  Box,
  Heading,
} from '@amount/frontend-components';

import { CustomerHomeCardsWrapper } from './styledComponents';
import { NoActivePlansCard } from './NoActivePlansCard';
import { BackgroundProviderBox, MaxWidthContainerBox } from 'src/components/Common/Box';
import { FullBannerProps } from 'src/g2/servicing/components/_shared/BannerNotifications/LoanBanner/types';

import {
  SelectionOnAccountManagement,
  SelectionOnIdentity__Customer
} from '../queries/GetCustomerHomeG2.graphql';
import { LoanProductCard } from './LoanProductCard';
import {
  hasUnresolvedPaymentProtectionDispute,
} from 'src/g2/servicing/components/ServicingTasks/utils/hasUnresolvedPaymentProtectionDispute';
import { getSortedCustomerHomeProductCards } from './customerHomeProductCardSorter';
import { withWarningBanner } from 'src/modules/helpers/withWarningBanner';

const CustomerHomePresentational: React.FC<{
  servicingAccounts: SelectionOnAccountManagement[],
  identity: SelectionOnIdentity__Customer
}> = ({ servicingAccounts, identity }) => {
  const { get_customer_information: { first_name: firstName } } = identity;
  const sortedServicingAccounts = getSortedCustomerHomeProductCards(servicingAccounts)

  return (
    <BackgroundProviderBox>
      <MaxWidthContainerBox>
        <Box
          direction='column'
          padding={{
            base: { top: '6x', horizontal: '0', bottom: '9x' },
            medium: { top: '9x', horizontal: '0', bottom: '9x' }
          }}
        >
          <Box margin={{ base: { left: '2x' }, medium: { left: '0' } }}>
            <Heading.h1>
              Hello, {firstName}.
            </Heading.h1>
          </Box>
            <CustomerHomeCardsWrapper>
              <Text
                forwardedAs="h2"
                margin={{
                  base: { top: '3x', bottom: '2x', left: '2x' },
                  medium: { top: '5x', bottom: '2.5x', left: '0'},
                }}
                lineHeight="1.5"
                size="heroSmall"
              >
                Payment Plans
              </Text>
              {sortedServicingAccounts.length ? (
                sortedServicingAccounts.map((account, idx) => (
                  <LoanProductCard
                    hasUnresolvedPaymentProtectionDispute={hasUnresolvedPaymentProtectionDispute(account.disputes)}
                    product={account.product}
                    repaymentInformation={account.repayment_information}
                    servicingAccountUUID={account.uuid}
                    virtualCard={account.virtual_card}
                    payoffInfo={account.payoff_information}
                    bannerProps={{ identity, servicingAccount: account } as FullBannerProps}
                    futurePayments={account.all_future_payments}
                    key={`card-${idx}`}
                  />
                ))
              ) : (
                <NoActivePlansCard />
              )}
          </CustomerHomeCardsWrapper>
        </Box>
      </MaxWidthContainerBox>
    </BackgroundProviderBox>
  );
};

export const CustomerHome = withWarningBanner(CustomerHomePresentational, 'home');
