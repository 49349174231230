import React, { useState } from 'react';

import { BannerNotificationsProps, PointOfSaleProps } from '../../types';
import { LoanBanner } from '..';
import { generateLocalStorageKeyWithId } from '../../../../../../util';
import { accountCredit } from '../../bannerStyles';

const dismissedAdjustedAmountKey: string = 'dismissedAdjustedAmount';
type AccountCreditProps = Pick<PointOfSaleProps, 'adjustedLoanAmount'> & Pick<BannerNotificationsProps, 'isDismissable' | 'uuid'>;

const dismissAdjustedAmountKey: (uuid: string) => string = uuid =>
   generateLocalStorageKeyWithId(dismissedAdjustedAmountKey, uuid);

const lastDismissedAdjustedAmount: (uuid: string) => string | null = uuid => {
  const key = dismissAdjustedAmountKey(uuid);

  return localStorage.getItem(key);
};

const setDismissedAdjustedAmount: (uuid: string, value: string) => void = (uuid, value) => {
  const key = dismissAdjustedAmountKey(uuid);
  localStorage.setItem(key, value);
};

export const hasDismissedAdjustedLoanAmount: (uuid: string, amount: string) => boolean = (uuid, amount) =>
  lastDismissedAdjustedAmount(uuid) === amount;

export const AccountCreditBanner: React.FC<AccountCreditProps> = ({ uuid, isDismissable, adjustedLoanAmount: { formatted } }) => {
  const [hide, setHide] = useState(hasDismissedAdjustedLoanAmount(uuid, formatted));

  if (hide) {
    return null;
  }

  const onDismiss: () => void = () => {
    setDismissedAdjustedAmount(uuid, formatted);
    setHide(true);
  };

  return (
    <LoanBanner style={accountCredit} onDismiss={isDismissable ? onDismiss : undefined}>
      <span>
        Your loan balance has been adjusted based on a credit to your account.
        {' '}Your adjusted loan balance is {formatted}.
        {' '}Your monthly installment payment amount and scheduled payment dates have not changed.
      </span>
    </LoanBanner>
  );
};
