import React from 'react';
import { css } from 'styled-components';
import {
  Box,
  BreakPointsMap,
  CollapsibleSubNavInnerContainer,
  CollapsibleSubNavLinks,
} from '@amount/frontend-components';
import { Route } from 'react-router-dom';
import { IServicingRoute } from './types';
import { CollapsibleSubNavLink } from './CollapsibleSubNavLink';

interface ISubNav {
  pathname: string;
  tabsConfig: IServicingRoute[];
  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export const SubNavTabs: React.FC<ISubNav> = ({ tabsConfig, onClick, pathname }) => {
  const removeLinkTextOnMobile = tabsConfig.length > 2;

  return (
    <CollapsibleSubNavResponsiveWrapper removeLinkTextOnMobile={removeLinkTextOnMobile}>
      <CollapsibleSubNavInnerContainer>
        <CollapsibleSubNavLinks>
          <Route
            exact={true}
            path={tabsConfig.map(tab => tab.route)}
            render={() => (
              <>
                {tabsConfig.map(tab => (
                  // FEC - need to dynamically set aria props for mobile, need to figure out how to do this
                  <CollapsibleSubNavLink
                    key={tab.route}
                    icon={tab.icon}
                    name={tab.name}
                    data-event={tab.dataEvent}
                    onClick={onClick}
                    active={pathname === tab.route}
                    data-route={tab.route}
                    aria-current={pathname === tab.route ? 'page' : undefined} />
                ))}
              </>
            )} />
        </CollapsibleSubNavLinks>
      </CollapsibleSubNavInnerContainer>
    </CollapsibleSubNavResponsiveWrapper>
  );
};

interface CollapsibleSubNavResponsiveWrapperProps {
  removeLinkTextOnMobile: boolean;
}

const CollapsibleSubNavResponsiveWrapper: React.FC<CollapsibleSubNavResponsiveWrapperProps> = props => {

  const { children, removeLinkTextOnMobile } = props;

  const responsiveStyles = removeLinkTextOnMobile ? {
    childrenStyles: css`
      @media (max-width: ${BreakPointsMap.medium}em) {
        button span { display:none; }
      }
    `
  } : {};

  return (
    <Box
      direction="row"
      flexBasis="100%"
      flexGrow="1"
      flexShrink="1"
      {...responsiveStyles}>
      {children}
    </Box>
  );
};
