import { formatString } from '@Util/string';
import React from 'react';

import { LoanBanner } from '.';
import { BannerNotificationsProps } from '../index';
import { FullBannerProps } from '../types';

const {
  autopayOptOutCurrentBankruptcy,
  autopayOptOutPastDueBankruptcy,
} = AvantConfig.TenantConfig.accountManagementDash.bannerMessages

export const BankruptcyAutopayOptOutBanner: React.FC<BannerNotificationsProps> = ({ variant, bannerProps }) => {
  const { servicingAccount: { product } } = bannerProps as FullBannerProps;
  const isPastDue = (
    product.is_past_due
    && product.past_due_amount
    && product.past_due_amount?.cents > 0
  );
  const bannerText = isPastDue
    ? autopayOptOutPastDueBankruptcy
    : autopayOptOutCurrentBankruptcy;
  return (
    <LoanBanner variant={variant}>
      <span>{formatString(bannerText, undefined, product.past_due_amount?.formatted)}</span>
    </LoanBanner>
  );
};
