import { cssDeclaration } from "@amount/frontend-components";
import { css } from "styled-components";

export const containerStyles = {
  childrenStyles: css`
    svg: {
      ${({ theme: { themeTwo } }) => cssDeclaration(themeTwo, 'width', { base: '100%', max: '16rem' })};
      display: 'inline-block',
      vertical-align: 'middle',
    }
  `
};
