/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable padding-line-between-statements */
/* eslint-disable arrow-body-style */
import React from 'react';

import {
  LoanBannerTypes,
  IdentityLoanBannerTypes,
  ServicingAccountLoanBannerTypes,
  FullBannerProps,
  IdentityBannerProps,
  ConsumerProductDispute
} from './types';
import { loanBannerConditions } from './loanBannerConditions';
import { BankruptcyAutopayOptOutBanner } from './Banners/BankruptcyAutopayOptOut';
import { BankruptcyDischargedBanner } from './Banners/BankruptcyDischarged';
import { PaidOffBanner } from './Banners/PaidOff';
import { ChargeOffBanner } from './Banners/ChargeOff';
import { PayoffQuoteBanner } from './Banners/PayoffQuote';
import { AccountCreditBanner } from './Banners/POS/AccountCredit';
import { RefundedBanner } from './Banners/POS/Refunded';
import { ExpiredSplitPayVCBanner } from './Banners/VirtualCard/ExpiredVirtualCard/ExpiredSplitPayVC';
import { ExpiredNotSplitPayVCBanner } from './Banners/VirtualCard/ExpiredVirtualCard/ExpiredNotSplitPayVC';
import { NotUsedNotSplitPayVCBanner } from './Banners/VirtualCard/NotUsedVirtualCard/NotSplitPayVC';
import { NotUsedSplitPayVCBanner } from './Banners/VirtualCard/NotUsedVirtualCard/SplitPayVC';
import { ActivePaymentProtectedDisputeBanner } from './Banners/POS/Dispute/Active'
import { ReturnedPastDueBanner } from './Banners/PastDue/ReturnedPastDue';
import { PastDueBanner } from './Banners/PastDue/PastDue'
import { AutoPayOffBanner } from './Banners/AutoPayOff';
import { AutoPayOnBanner } from './Banners/AutoPayOn';
import {
  filterPaymentProtectedDisputes,
  filterPaymentProtectedMerchantFavorDisputes,
  ResolvedPaymentProtectedDisputeBanner,
  ResolvedPaymentProtectedDisputeMerchantFavorBanner,
  getLatestDisputeByResolutionDate
} from './Banners/POS/Dispute/Resolved'
import { OutstandingBalanceBanner } from './Banners/PastDue/OutstandingBalance'
import { NoPaymentMethodsBanner } from './Banners/NoPaymentMethods';
import { BadPaymentMethodBanner } from './Banners/BadPaymentMethod'
import { ReturnedPaymentBanner } from './Banners/ReturnedPayment';
import { BadPersonalInformationBanner } from './Banners/BadPersonalInformation'
import { VALID_BANNER_TYPES } from '@amount/frontend-components';
import { getBannerPriorityLogic } from './utils/bannerPriorityLogic'

export interface BannerDataProps {
  bannerProps: FullBannerProps | IdentityBannerProps;
}
export interface BannerBaseProps {
  variant: VALID_BANNER_TYPES;
  servicingAccountUUID?: string;
}

export type BannerNotificationsProps =  BannerDataProps & BannerBaseProps

const { bannerIcons } = AvantConfig.TenantConfig.accountManagementDash;

export const LoanBanners: React.FC<Omit<BannerNotificationsProps, 'variant'>> = props  => {
  const hasServicingAccount = 'servicingAccount' in props.bannerProps

  const fullBannerProps = props.bannerProps as FullBannerProps

  const IdentityBanners: IdentityBannerPropType = {
    [LoanBannerTypes.noPaymentMethods]: variant => <NoPaymentMethodsBanner variant={variant} />,
    [LoanBannerTypes.hasBadPaymentMethod]: variant => <BadPaymentMethodBanner variant={variant} />,
    [LoanBannerTypes.badPersonalInformation]: variant => <BadPersonalInformationBanner variant={variant} />,
  }

  const ServicingAccountBanners: ServicingAccountBannersPropType = {
    [LoanBannerTypes.bankruptcyAutopayOptOut]: variant => <BankruptcyAutopayOptOutBanner bannerProps={fullBannerProps} variant={variant} />,
    [LoanBannerTypes.bankruptcyDischarged]: variant => <BankruptcyDischargedBanner variant={variant} />,
    [LoanBannerTypes.paidOff]: variant => <PaidOffBanner variant={variant} />,
    [LoanBannerTypes.chargeOff]: variant =>
      <ChargeOffBanner bannerProps={fullBannerProps} servicingAccountUUID={props.servicingAccountUUID} variant={variant} />,
    [LoanBannerTypes.refunded]: variant => <RefundedBanner variant={variant} />,
    [LoanBannerTypes.expiredVirtualCardSplitPay]: variant => <ExpiredSplitPayVCBanner variant={variant} />,
    [LoanBannerTypes.expiredVirtualCardNotSplitPay]: variant => <ExpiredNotSplitPayVCBanner variant={variant} />,
    [LoanBannerTypes.notUsedVirtualCardSplitPay]: variant => <NotUsedSplitPayVCBanner bannerProps={fullBannerProps} variant={variant} />,
    [LoanBannerTypes.notUsedVirtualCardNotSplitPay]: variant =>
      <NotUsedNotSplitPayVCBanner bannerProps={fullBannerProps} variant={variant} />,
    [LoanBannerTypes.activePaymentProtectedDispute]: variant => <ActivePaymentProtectedDisputeBanner variant={variant} />,
    [LoanBannerTypes.returnedPaymentPastDue]: variant =>
      <ReturnedPastDueBanner bannerProps={fullBannerProps} servicingAccountUUID={props.servicingAccountUUID} variant={variant} />,
    [LoanBannerTypes.pastDue]: variant =>
      <PastDueBanner bannerProps={fullBannerProps} servicingAccountUUID={props.servicingAccountUUID} variant={variant}  />,
    [LoanBannerTypes.payoffQuote]: variant => <PayoffQuoteBanner bannerProps={fullBannerProps} variant={variant} />,
    [LoanBannerTypes.autoPayOff]: variant =>
      <AutoPayOffBanner bannerProps={fullBannerProps} servicingAccountUUID={props.servicingAccountUUID} variant={variant} />,
    [LoanBannerTypes.autoPayOn]: variant => <AutoPayOnBanner bannerProps={fullBannerProps} variant={variant} />,
    [LoanBannerTypes.resolvedPaymentProtectedDispute]: variant => (
      <ResolvedPaymentProtectedDisputeBanner
        refundAmount={fullBannerProps.servicingAccount.point_of_sale_information.refund_information?.amount}
        dispute={getLatestDisputeByResolutionDate(
          filterPaymentProtectedDisputes(fullBannerProps.servicingAccount.disputes)
          ) as ConsumerProductDispute}
        variant={variant}
      />
    ),
    [LoanBannerTypes.resolvedPaymentProtectedMerchantFavor]: variant => (
      <ResolvedPaymentProtectedDisputeMerchantFavorBanner
        // eslint-disable-next-line max-len
        dispute={getLatestDisputeByResolutionDate(filterPaymentProtectedMerchantFavorDisputes(fullBannerProps.servicingAccount.disputes)) as ConsumerProductDispute}
        variant={variant}
      />
    ),
    [LoanBannerTypes.accountCredit]: variant => fullBannerProps.servicingAccount.point_of_sale_information &&
      <AccountCreditBanner  bannerProps={fullBannerProps} variant={variant} />,
    [LoanBannerTypes.outstandingAmount]: variant =>
      <OutstandingBalanceBanner bannerProps={fullBannerProps} servicingAccountUUID={props.servicingAccountUUID} variant={variant} />,
    [LoanBannerTypes.returnedPayment]: variant => <ReturnedPaymentBanner variant={variant}  />,
  }

  const Banners = hasServicingAccount ? {...IdentityBanners, ...ServicingAccountBanners} : IdentityBanners

  const bannerPrioritySelections = getBannerPriorityLogic();

  const bannerToDisplay = Object.values(bannerPrioritySelections).find(bannerSelection => {
    return !!loanBannerConditions[bannerSelection.type]?.(props);
  });

  return !!bannerToDisplay ? Banners[bannerToDisplay.type](bannerToDisplay.variant, bannerIcons[bannerToDisplay.variant]) : null;
};

type IdentityBannerPropType = {
  [key in IdentityLoanBannerTypes]: (variant: VALID_BANNER_TYPES) => React.ReactElement | null;
}

type ServicingAccountBannersPropType = {
  [key in ServicingAccountLoanBannerTypes]: (variant: VALID_BANNER_TYPES) => React.ReactElement | null;
}
