import React from 'react';
import { Link } from 'react-router-dom';
import { BannerLink } from '@amount/frontend-components';

import {
  AccountRoutes,
  ApplicationBaseRoutes,
  createPathWithUUID
} from '../../../../../../routes';
import { LinkAndTextWrapper } from '../../../common';
import { LoanBanner } from '../';
import { PointOfSaleProps } from '../../types';
import { paymentPreference } from '../../bannerStyles';

type PaymentPreferencesProps = Pick<PointOfSaleProps, 'merchantName'> & { uuid: string };
export const PaymentPreferencesBanner: React.FC<PaymentPreferencesProps> = ({ merchantName, uuid }) => (
  <LoanBanner style={paymentPreference}>
    <LinkAndTextWrapper>
      <span>
        Please set up your payment preferences for your {AvantConfig.TenantConfig.product.loan.name} with {merchantName}.
      </span>
      <BannerLink>
        <Link
          data-event='bannerPOSLoanUpdatePaymentPreferences'
          to={createPathWithUUID({ base: ApplicationBaseRoutes.account, uuid, route: AccountRoutes.paymentPreference })}
        >
          Set up your payment preferences
        </Link>
      </BannerLink>
    </LinkAndTextWrapper>
  </LoanBanner>
);
