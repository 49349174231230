import * as React from 'react';
import {
  EmailInput,
  Headline,
} from '@amount/frontend-components';
import { FragmentSelectionOnWorkflowEmailInput, IInputProps } from '@amount/workflow-js';
import camelCase from 'lodash-es/camelCase';

import { WorkflowInputMaxWidthWrapper } from './common';

interface IWorkflowEmailInputProps extends IInputProps {
  input: FragmentSelectionOnWorkflowEmailInput;
}

export const WorkflowEmailInput: React.FunctionComponent<IWorkflowEmailInputProps> = ({
  input,
  updateInput,
  value,
}) => (
  <>
    { input.placeholder && (
      <Headline scale='small' as='label' htmlFor={camelCase(input.placeholder || undefined)}>
        {input.placeholder}
      </Headline>
    )}
    <WorkflowInputMaxWidthWrapper>
      <EmailInput
        id={camelCase(input.placeholder || undefined)}
        placeholder={input.placeholder || undefined}
        defaultValue={value || ''}
        required={!!input.required}
        onChange={e => updateInput(e.currentTarget.value)}
        data-event={camelCase(`${input.placeholder || ''}EmailInput`)}
      />
    </WorkflowInputMaxWidthWrapper>
  </>
);
