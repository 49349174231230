/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { ThemeProvider } from 'styled-components';
import {
  GlobalStyleWrap, theme,
} from '@amount/frontend-components';
import Cookies from 'universal-cookie';

import './analytics';
import './index.css';
import App from './App';
import { getQueryStringParams, GetQueryStringReturnType, TOKEN_COOKIE } from './util';
import SessionManager from './util/sessionManager';
import Boundary from './components/Common/Error/Boundary';
import { client } from './apolloClient';
import { FeatureFlagWrapper } from './util/featureFlag';
import { mergeWithCopyArrays } from '../config/utils';
import { ITenantTheme } from './customTypings/tenantTheme';
import { TenantTheme } from './config/tenantTheme';

const combinedTheme: ITenantTheme = mergeWithCopyArrays(
  theme, { ...TenantTheme }
);

const Index: React.FC = () => (
  <Boundary>
    <ThemeProvider theme={combinedTheme}>
      <>
        <GlobalStyleWrap {...combinedTheme} />
        {/* Need both providers until we can update react-apollo to ^3.0.0 */}
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <FeatureFlagWrapper>
                <App />
            </FeatureFlagWrapper>
          </ApolloHooksProvider>
        </ApolloProvider>
      </>
    </ThemeProvider>
  </Boundary>
);

const qsParams: { [key: string]: string } = getQueryStringParams(window.location.search);
const cookies = new Cookies();

if (qsParams.jwtToken || cookies.get(TOKEN_COOKIE)) {
  SessionManager.setToken(qsParams.jwtToken || cookies.get(TOKEN_COOKIE));
  const redirectPage = cookies.get('redirectAfterAuth');
  cookies.remove('redirectAfterAuth');
  SessionManager.removeTokenCookie();

  if (redirectPage) {
    window.location.replace(redirectPage);

  } else if ( qsParams.jwtToken ) {
    window.history.replaceState({}, document.title, getUrlWithoutJWTParam(qsParams));
  }
}


ReactDOM.render(
  <Index />,
  document.getElementById('root') as HTMLElement
);

/* eslint-disable @typescript-eslint/no-explicit-any */
if ((module as any).hot) {
  (module as any).hot.accept('./App', () => {
    ReactDOM.render(
      <Index />,
      document.getElementById('root')
    );
  });
}
/* eslint-enable */
// investigate re-enabling:
// https://github.com/facebook/create-react-app/pull/3817
// https://github.com/facebook/create-react-app/issues/2554
// registerServiceWorker();
// unregisterServiceWorker();

function getUrlWithoutJWTParam (qsParams: GetQueryStringReturnType) {
  const url = window.location.toString();
  const urlWithoutParams = url.substring(0, url.indexOf('?'));
  const { jwtToken, ...rest } = qsParams;
  const cleanedParams = Object.entries(rest)
    .map(param => param.join('='))
    .join('&');

  const possibleParamString = cleanedParams ? `?${cleanedParams}` : '';

  return `${urlWithoutParams}${possibleParamString}`;
}
