import { SelectionOnCustomerApplication } from '../../queries/GetAccountHistoryData.graphql';
import { HistoryViewModel, HistoryViewModes } from '../types';

export const isOpenApplication = (app: SelectionOnCustomerApplication) => app.isOpen && !app.closedAt;
export const isDeclinedApplication = (app: SelectionOnCustomerApplication) => app.declined;
export const isCanceledApplication = (app: SelectionOnCustomerApplication) => app.canceled || app.expired;

const applicationToHistoryViewModel: (application: SelectionOnCustomerApplication) => HistoryViewModel | null = application => {
  if (!application.merchantName) {
    return null;
  }

  const baseModel = {
    sortDate: application.createdAt || '',
    applicationDate: application.createdAt || '',
    merchant: application.merchantName,
    applicationID: application.id,
    noaaLink: application.declineScenario?.noticeUrl ?? undefined,
    displayDate: application.closedAt || '',
  };

  if (isDeclinedApplication(application)) {
    return ({
      type: HistoryViewModes.Declined,
      ...baseModel,
    });
  } else if (isCanceledApplication(application)) {
    return ({
      type: HistoryViewModes.Canceled,
      ...baseModel,
    });
  } else {
    return ({
      type: HistoryViewModes.Closed,
      ...baseModel,
    });
  }
};

export default applicationToHistoryViewModel;
