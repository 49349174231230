import React from 'react';
import { HeaderLink, Link as FECLink, VALID_HEADER_LINKS } from '@amount/frontend-components';
import { Link, LinkProps } from 'react-router-dom';

type SiteHeaderLinkProps = React.PropsWithChildren<LinkProps & {
  propsType?: 'SiteHeaderLinkProps';
  variant: VALID_HEADER_LINKS;
}>;

type InlineLinkProps = React.PropsWithChildren<LinkProps> & {
  propsType?: 'InlineLinkProps';
};

type ButtonLinkProps = React.PropsWithChildren<LinkProps & {
  propsType?: 'ButtonLinkProps';
  variant: 'primary' | 'secondary';
}>;

interface FECButtonLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  propsType?: 'FECButtonLinkProps';
  variant: 'primary' | 'secondary';
  href: string;
}

type LinkPropVariants = InlineLinkProps | ButtonLinkProps | SiteHeaderLinkProps;

// We need to wrap FEC link because <Link> exposes a `navigate` function to its component
// that needs to be called in order to route without a page refresh. Without calling this
// method on click, it will fall back to a full page refresh. Click handler behavior is
// adapted from the <Link> component source.
// from https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/modules/Link.js
function isModifiedEvent (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const WrappedFECLink = (
  {
    navigate,
    ...rest
  }: (LinkPropVariants) & { navigate(): void; }
) => {
  const { target, onClick } = rest;

  const wrappedOnClick: React.MouseEventHandler<HTMLAnchorElement> = event => {
    try {
      if (onClick) {
        onClick(event);
      }
    } catch (ex) {
      event.preventDefault();
      throw ex;
    }

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      (!target || target === "_self") && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      navigate();
    }
  };

  const props = {
    ...rest,
    onClick: wrappedOnClick
  };

  switch (props.propsType) {
    case 'SiteHeaderLinkProps':
      return <HeaderLink {...props} />;
    case 'ButtonLinkProps':
      return <FECLink.button {...props as FECButtonLinkProps} />;
    default:
      return <FECLink.inline {...props as InlineLinkProps} />;
  }
};

export const InlineLink = ({ children, ...rest }: InlineLinkProps) => (
  <Link component={WrappedFECLink} {...rest}>{children}</Link>
);

export const ButtonLink = ({ children, ...rest }: ButtonLinkProps) => (
  <Link component={WrappedFECLink} {...rest}>{children}</Link >
);

export const SiteHeaderLink = ({ children, ...rest }: SiteHeaderLinkProps) => (
  <Link component={WrappedFECLink} {...rest}>{children}</Link >
);

export const RouterLink = {
  button: (props: ButtonLinkProps) => ButtonLink({ propsType: 'ButtonLinkProps', ...props }),
  inline: (props: InlineLinkProps) => InlineLink({ propsType: 'InlineLinkProps', ...props }),
  siteHeader: (props: SiteHeaderLinkProps) => SiteHeaderLink({ propsType: 'SiteHeaderLinkProps', ...props }),
}
