/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Box, CircleProgress } from '@amount/frontend-components';

import { RepaymentContent, IRepaymentContentProps } from './RepaymentContent';

const { paidLabel } = AvantConfig.TenantConfig.repaymentDetails;

type IRepaymentCircularProgressProps = IRepaymentContentProps & {
  adjusted_amount_progress: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styleOverride?: any;
}

export const RepaymentCircularProgress: React.FC<IRepaymentCircularProgressProps> = (props: IRepaymentCircularProgressProps) => {
  const {
    adjusted_amount_progress,
    formattedPrincipalOutstandingAmount,
    formattedPaidPrincipal,
    styleOverride
  } = props;

  const repaymentContentProps = { formattedPrincipalOutstandingAmount, formattedPaidPrincipal }

  return (
    <Box
      margin={{
        base: { bottom: '2x' },
        medium: { bottom: '0.625x' },
      }}
      {...styleOverride}
    >
      <Box
        direction="column"
        alignSelf="center"
        flexBasis="14rem"
      >
        <CircleProgress
          label={paidLabel}
          percentage={adjusted_amount_progress}
          animate={true}
        />
      </Box>
      <RepaymentContent {...repaymentContentProps} />
    </Box>
  );
};

export default RepaymentCircularProgress;
