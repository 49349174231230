import * as React from 'react';
import {
  Checkbox,
  FieldSet,
} from '@amount/frontend-components';
import { FragmentSelectionOnWorkflowCheckboxInput, IInputProps } from '@amount/workflow-js';
import camelCase from 'lodash-es/camelCase';

import { WorkflowInputWrapper } from './common';

const TRUE_STRING_LITERAL = true.toString();

interface IWorkflowCheckboxInputProps extends IInputProps {
  input: FragmentSelectionOnWorkflowCheckboxInput;
}

export const WorkflowCheckboxInput: React.FunctionComponent<IWorkflowCheckboxInputProps> = ({
  input,
  updateInput,
  value,
}) => (
  <FieldSet>
    <WorkflowInputWrapper>
      <Checkbox
        checked={value === TRUE_STRING_LITERAL}
        required={!!input.required}
        onChange={e => updateInput(e.currentTarget.checked.toString())}
        data-event={camelCase(`${input.placeholder || ''}CheckboxInput`)}
      >
        {input.placeholder}
      </Checkbox>
    </WorkflowInputWrapper>
  </FieldSet>
);
