import { useLocation } from 'react-router-dom';
import { paymentMethodsPath, personalInformationPath } from '../../../../../../../routes'

const { paymentMethodsPageBannerSelections,
  personalInformationPageBannerSelections, bannerSelections } = AvantConfig.TenantConfig.accountManagementDash;

export const getBannerPriorityLogic = () => {
  const location = useLocation();
  const { pathname } = location;

  const isPaymentMethodsPage = pathname === paymentMethodsPath;
  const isPersonalInformationPage = pathname === personalInformationPath;

  let bannerPrioritySelections = bannerSelections

  if (isPaymentMethodsPage) {
    bannerPrioritySelections = paymentMethodsPageBannerSelections;
  } else if (isPersonalInformationPage) {
    bannerPrioritySelections = personalInformationPageBannerSelections;
  }

  return bannerPrioritySelections;
};
