import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Loading } from '../../components/Common/Spinner/LoadingHOC';

import GetFeatureFlagsQuery, { GetFeatureFlags } from './queries/GetFeatureFlags.graphql';

export const enum AvailableFeatureFlag {
  CreditLineIncrease = 'web_line_increase_workflow',
  WarningBanners = 'warning_banners',
  ManageCardLockWorkflow = 'manage_card_lock_workflow',
}

class FeatureFlag {
  private _featureFlags: AvailableFeatureFlag[] = [];

  public set featureFlags (flags: string[]) {
    this._featureFlags = flags.map(flag => flag as AvailableFeatureFlag);
  }

  public get isCreditLineIncreaseEnabled (): boolean {
    return this.isEnabled(AvailableFeatureFlag.CreditLineIncrease);
  }

  public get isWarningBannersEnabled (): boolean {
    return this.isEnabled(AvailableFeatureFlag.WarningBanners);
  }

  public readonly isEnabled: (flag: AvailableFeatureFlag) => boolean = flag => this._featureFlags.includes(flag);
}

export const FeatureFlagHelper = new FeatureFlag();

export const FeatureFlagWrapper: React.FC = ({ children }) => {
  const { loading, data } = useQuery<GetFeatureFlags>(
    GetFeatureFlagsQuery
  );

  if (!!data) {
    FeatureFlagHelper.featureFlags = data.featureFlags;
  }

  return (
    <Loading loading={loading}>
      {children}
    </Loading>
  );
};
