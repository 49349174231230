import { IConsumerBankAccount, IConsumerDebitCard, IIdentityBankAccount, IIdentityDebitCard } from "src/gql";

export const hasNoValidPaymentMethods = (
  bankAccounts: Array<Partial<IConsumerBankAccount> | Partial<IIdentityBankAccount>>,
  debitCards: Array<Partial<IConsumerDebitCard> | Partial<IIdentityDebitCard>>,
) => {
  return (
    (!bankAccounts && !debitCards) ||
    (bankAccounts.every(bankAccount => !bankAccount.active) && debitCards.every(card => !card.active))
  );
};
