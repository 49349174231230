/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { BannerNotificationsProps, LoanBannerTypes, PointOfSaleProps } from './types';
import { hasDismissedAdjustedLoanAmount } from './Banners/POS/AccountCredit';
import { showsPaymentPreferences } from '../../../../util/pointOfSale';

type ChargedOffProps = Pick<BannerNotificationsProps, 'chargeOffInformation' | 'isChargedOff'>;
const showsChargedOff: (props: ChargedOffProps) => boolean = ({ chargeOffInformation, isChargedOff }) =>
  (isChargedOff || chargeOffInformation?.operationallyChargedOff) || false;


type BadBankProps = Pick<BannerNotificationsProps, 'badAccount' | 'canUpdateBankAccount'>;
const hasBadBank: (props: BadBankProps) => boolean = ({ badAccount, canUpdateBankAccount }) =>
  !!badAccount && canUpdateBankAccount;


type EligibleFinancialSituationReviewProps = Pick<BannerNotificationsProps,
  'financialSituationReviewEligible' | 'financialSituationReviewV2Eligible'>;
export const isEligibleForFinancialSituationReview: (props: EligibleFinancialSituationReviewProps) => boolean = ({
  financialSituationReviewEligible,
  financialSituationReviewV2Eligible
}) => financialSituationReviewEligible || (financialSituationReviewV2Eligible  && AvantConfig.TenantConfig.banners.fsrV2Enabled);


type FinancialSituationReviewProps = Pick<BannerNotificationsProps,
  'financialSituationReviewEligible'
  | 'financialSituationReviewV2Eligible'
  | 'lossMitigationPlanAcceptanceEligible'
  | 'settlementAcceptanceEligible'>;
// G2TODO - this is used in the banner component, but it looks like a redundant check
// should be able to remove the check and not export this, double check when rebuilding G2 Layouts
export const isFinancialSituationReview: (props: FinancialSituationReviewProps) => boolean = props => {
  const { lossMitigationPlanAcceptanceEligible, settlementAcceptanceEligible } = props;
  const { lossMitigationEnabled, settlementEnabled } = AvantConfig.TenantConfig.banners;
  return isEligibleForFinancialSituationReview(props)
    || lossMitigationPlanAcceptanceEligible && lossMitigationEnabled
    || settlementAcceptanceEligible && settlementEnabled;
};


type RemotelyCreatedCheckProps = Pick<BannerNotificationsProps, 'isOptedIntoRcc' | 'badAccount'>;
const isOptedIntoRemotelyCreatedCheck: (props: RemotelyCreatedCheckProps) => boolean = ({ isOptedIntoRcc, badAccount }): boolean =>
  isOptedIntoRcc && !!AvantConfig.TenantConfig.banners.rccDeprecationBanner && !badAccount;


type PastDueProps = Pick<BannerNotificationsProps, 'isLate' | 'pastDueAmount' | 'hasOutstandingBalance'>;
const isPastDue: (props: PastDueProps) => boolean = ({ isLate, pastDueAmount, hasOutstandingBalance }): boolean =>
  (isLate && (pastDueAmount?.cents || 0) > 0) || hasOutstandingBalance;


type DashboardSetupProps = Pick<BannerNotificationsProps, 'pointOfSaleInformation' | 'isFunded'>;
const isDashboardSetup: (props: DashboardSetupProps) => boolean = ({ pointOfSaleInformation, isFunded }) =>
  !!pointOfSaleInformation && !isFunded;


type POSFullRefundProps = Pick<PointOfSaleProps, 'refund' | 'dispute'>;
const isPOSFullRefund: (props: POSFullRefundProps) => boolean = ({ refund, dispute }) =>
  !!refund?.isFullRefund || !!dispute?.resolution?.refund?.isFullRefund;


type POSAccountCredit = Pick<PointOfSaleProps, 'adjustedLoanAmount'> & Pick<BannerNotificationsProps, 'amount'>;
const isPOSAccountCredit: (props: POSAccountCredit) => boolean = ({ adjustedLoanAmount, amount }) =>
  adjustedLoanAmount.cents < (amount?.cents || 0);

type LoanBannerConditionsType = {
  [key in LoanBannerTypes]: (props: BannerNotificationsProps) => boolean | null;
}

export const loanBannerConditions: LoanBannerConditionsType = {
  [LoanBannerTypes.soldOff]: props => props.isSoldOff,
  [LoanBannerTypes.settled]: ({ settlementInformation }) => !!settlementInformation?.isSettled,
  [LoanBannerTypes.bankruptcyDischarged]: props => props.isBankruptcyDischarged,
  [LoanBannerTypes.refinanced]: props => props.wasRefinanced,
  [LoanBannerTypes.paidOff]: props => props.isPaidOff,
  [LoanBannerTypes.incompleteSettlement]: props => props.onActiveOrLockedInSettlement,
  [LoanBannerTypes.chargeOff]: showsChargedOff,
  [LoanBannerTypes.refunded]: ({ pointOfSaleInformation }) => pointOfSaleInformation && isPOSFullRefund(pointOfSaleInformation),
  [LoanBannerTypes.dispute]: ({ pointOfSaleInformation }) => !!pointOfSaleInformation?.dispute,
  [LoanBannerTypes.payoffQuote]: ({ payoffInformation }) => !!payoffInformation?.scheduledQuote,
  [LoanBannerTypes.hasPaymentPlanAndBadBank]: props => props.paymentPlan && hasBadBank(props),
  [LoanBannerTypes.paymentPlan]: props => props.paymentPlan && !hasBadBank(props),
  [LoanBannerTypes.accountCredit]: ({ pointOfSaleInformation, uuid, amount }) => pointOfSaleInformation
    && isPOSAccountCredit({...pointOfSaleInformation, amount})
    && !hasDismissedAdjustedLoanAmount(uuid, pointOfSaleInformation.adjustedLoanAmount.formatted),
  [LoanBannerTypes.financialSituationReview]: isFinancialSituationReview,
  [LoanBannerTypes.paymentPreferences]: props => props.pointOfSaleInformation && showsPaymentPreferences(props),
  [LoanBannerTypes.hasNoPaymentPlanAndBadBank]: hasBadBank,
  [LoanBannerTypes.remotelyCreatedCheck]: isOptedIntoRemotelyCreatedCheck,
  [LoanBannerTypes.pastDute]: isPastDue,
  [LoanBannerTypes.dashboardSetup]: isDashboardSetup
};

