import React from 'react';

interface CardImageProps {
  cardImageUrl: string | null;
  cardImageName: string | null;
}

export const CardImage: React.FC<CardImageProps> = ({cardImageUrl, cardImageName}) => (
  // cardImageUrl and cardImageName will be null for non-co-branded cards
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  <img src={cardImageUrl || require('./creditcard-default.png')} alt={cardImageName || 'Credit Card Image'} />
);
