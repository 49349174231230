import React from 'react';
import Styled from 'styled-components';
import { Datapoint, DatapointLabel } from '@amount/frontend-components';

import { FlexColumn } from '../CommonStyles';

export interface IRepaymentDataPointProps {
  title: string;
  content: string;
  contentHighlight?: boolean;
  extraPadding: boolean;
  applyPaddingTop?: boolean;
}

const InnerColumnWrapper = Styled(FlexColumn)<{ padding: string }>`
  justify-content: flex-start;
  padding: ${props => props.padding};
`;

const RepaymentDataPoint = Styled(Datapoint)<{ isPrincipal?: boolean }>`
  ${props => props.isPrincipal && `color: ${props.theme.brand.colorSecondary}`}
`;

const RepaymentDataDisplay: React.FC<IRepaymentDataPointProps> = data => {
  const topPadding: string = data.extraPadding ? '0.5em 0 0 0' : '0.5em 0 0 1.75em';
  const bottomPadding: string = data.extraPadding ? '0 0 0.5em 0' : '0 0 0.5em 1.75em';

  return (
    <InnerColumnWrapper padding={data.applyPaddingTop ? topPadding : bottomPadding}>
      <DatapointLabel as='dt'>{data.title}</DatapointLabel>
      <RepaymentDataPoint as='dd' size='small' isPrincipal={data.contentHighlight || false}>
        {data.content}
      </RepaymentDataPoint>
    </InnerColumnWrapper>
  );
};

export default RepaymentDataDisplay;
