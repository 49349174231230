import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Loading } from '../components/Common/Spinner/LoadingHOC';
import GetCustomerHomeInformationQuery, {
  GetCustomerHomeInformation,
} from './queries/GetCustomerHomeInformation.graphql';
import { AccountDash } from './Presentational/AccountDash';

export const LiftedAccountDash: React.FunctionComponent<RouteComponentProps> = props => (
  <Query<GetCustomerHomeInformation>
    query={GetCustomerHomeInformationQuery}
    fetchPolicy='cache-and-network'
  >
    {({ data, loading }) => (
      <Loading loading={loading} data={data}>
        <AccountDash
          {...props}
          data={data}
        />
      </Loading>
    )}
  </Query>
);
