import React from 'react';
import { usePaymentMethodsLink } from '../utils/usePaymentMethodsLink';
import { LoanBanner } from '.';
import { BannerLink } from '../../../../../../../_shared/ThemeTwo/Link/BannerLink';
import { BannerLinkAndTextWrapper } from '../../common';
import { BannerBaseProps } from '../index';

const { bannerMessages: { noPaymentMethod }, bannerLinks: { paymentMethods } }
  = AvantConfig.TenantConfig.accountManagementDash;

export const NoPaymentMethodsBanner: React.FC<BannerBaseProps> = ({ variant }) => {
  const { isPaymentMethodsPage, paymentMethodsPath } = usePaymentMethodsLink();

  return (
    <LoanBanner variant={variant}>
      <BannerLinkAndTextWrapper>
        <span>{noPaymentMethod}</span>

        {!isPaymentMethodsPage &&
          <BannerLink
            event="paymentMethods"
            to={paymentMethodsPath}
          >
            {paymentMethods}
          </BannerLink>
        }
      </BannerLinkAndTextWrapper>
    </LoanBanner>
  );
};
