import React from 'react';

const templateSplit = /{(\d)}/g;
const isNumber = /^\d+$/;

/**
 * Inserts variables into a string and formats them based on
 * formattingFunction.
 *
 * @param str input string
 * @param formattingFunction JSX applied to each variable
 * @param values list of values to replace in string
 * @returns JSX formatted string with variables inserted and formatted
 * based on formattingFunction
 *
 * Examples:
 *
 * const str = '{0} test {1} test';
 * formatString(str, value => <b>{value}</b>, value1, value2);
 *
 * Output: <b>value1</b> test <b>value2</b> test
 *
 */
export const formatString: (
  str: string,
  formattingFunction?: (value: number | string, index: number) => React.ReactNode,
  ...values: Array<number | string | React.ReactNode>
) => React.ReactNode = (
  str,
  formattingFunction,
  ...values
) => {
    const splitText = str.split(templateSplit);

    return splitText.map((sentence: string, idx) => {
      if (isNumber.test(sentence)) {
        const value = values[Number(sentence)];
        const templateIdx = parseInt(sentence, 10);

        return (
          <React.Fragment key={idx}>
            {formattingFunction && (typeof (value) === 'string' || typeof (value) === 'number')
              ? formattingFunction(value, templateIdx)
              : value}
          </React.Fragment>
        )
      }

      return (
        <React.Fragment key={idx}>
          {sentence}
        </React.Fragment>
      )
    });
  };

export function tenantConfigToString(
  str: string,
  values: Array<number | string | null | undefined>
) {
  const splitText = str.split(templateSplit);

  return splitText.map(text => {
    if (isNumber.test(text)) {
      const value = values[Number(text)];

      return value;
    }

    return text;
  }).join('');
}
