"use strict";

/**
 * this is an extremely simplified version of https://github.com/willsp/polyfill-Number.toLocaleString-with-Locales
 *
 * specific revision:
 * https://github.com/willsp/polyfill-Number.toLocaleString-with-Locales/blob/2e3ad8c7e03a9b9bea3831f17184d4d7a093bcb0/polyfill.number.toLocaleString.js
 *
 * Differences include:
 *  - only supports USD/GBP currencies
 *  - adds support for percentages
 *  - basic support maximumFractionDigits on percentages
 */
(function () {
  'use strict';

  function toLocaleStringSupportsLocales() {
    var number = 0;
    try {
      number.toLocaleString("i");
    } catch (e) {
      return e.name === "RangeError";
    }
    return false;
  }

  function replaceSeparators(sNum, separators) {
    var sNumParts = sNum.split('.');
    if (separators && separators.thousands) {
      sNumParts[0] = sNumParts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + separators.thousands);
    }
    sNum = sNumParts.join(separators.decimal);

    return sNum;
  };

  function wrapper(num, options) {
    if (!options) {
      return num.toString();
    }

    if (options.style === 'currency') {
      var currencySymbol = '$';
      if (options.currency === 'GBP') {
        currencySymbol = '£';
      }
      return "" + currencySymbol + num.toFixed(options.minimumFractionDigits === undefined ? 2 : options.minimumFractionDigits);
    } else if (options.style === 'percent') {
      return (num.valueOf() * 100).toFixed(options.maximumFractionDigits === undefined ? 2 : options.maximumFractionDigits).toString() + "%";
    }

    return num.valueOf().toString();
  }

  if (!toLocaleStringSupportsLocales()) {
    Number.prototype.toLocaleString = function (locales, options) {
      return replaceSeparators(wrapper(this, options), { thousands: ',', decimal: '.' });
    };
  }
})();
