import React from 'react';
import Styled, { css } from 'styled-components';
import {
  Datapoint,
  DatapointLabel,
  SVGIcon,
} from '@amount/frontend-components';

import { FragmentSelectionOnLoan } from '../queries/GetCustomerHomeInformation.graphql';
import { formatDate } from '../../../util/date';
import { NA, ZeroAmountFormatted } from '../../../constants';
import { AutopayWrapper, DataWrapper, FlexColumn } from '../../Common/CommonStyles';
import { isUnresolvedPaymentProtectPointOfSale } from '../../../util';

const ExpandedDataWrapper = Styled(DataWrapper)`
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const AmountDueWrapper = Styled(FlexColumn)<{ padding?: string }>`
  justify-content: space-between;
  ${props => props.padding && css`padding: ${props.padding};`}
`;

const RedTextDiv = Styled(FlexColumn)`
  color: ${({ theme: { brand } }) => brand.colorAlert};
`;

type NextPaymentLoanCard = Pick<
  FragmentSelectionOnLoan,
  'isLate' | 'nextInstallmentPayment' | 'isAutopay' | 'pastDueAmount' | 'pointOfSaleInformation'
>;

const PastDuePaymentCard: React.FunctionComponent<NextPaymentLoanCard> = ({ pastDueAmount }) => (
  <>
    <ExpandedDataWrapper>
      <AmountDueWrapper>
        <DatapointLabel>Total Past Due Amount</DatapointLabel>
        <RedTextDiv>
          <Datapoint size='small'>
            {pastDueAmount?.formatted || NA}
          </Datapoint>
        </RedTextDiv>
      </AmountDueWrapper>
    </ExpandedDataWrapper>
  </>
);

const dueDateValue: (isUnresolvedPaymentProtectedDispute: boolean, date?: string | null) => string = (isUnresolved, date) =>
  isUnresolved ? 'On Hold' : date ? formatDate(date) : NA;

const amountDueValue: (isUnresolvedPaymentProtectedDispute: boolean, amount?: string | null) => string = (isUnresolved, amount) =>
  isUnresolved ? ZeroAmountFormatted : amount || NA;

const autopayValue: (isUnresolvedPaymentProtectedDispute: boolean) => string = isUnresolved =>
  isUnresolved ? 'Autopay is disabled during dispute window' : 'Autopay is on for this loan';

const FuturePaymentCard: React.FunctionComponent<NextPaymentLoanCard> = props => {
  const { nextInstallmentPayment, isAutopay, pointOfSaleInformation } = props;
  const isActiveDispute = isUnresolvedPaymentProtectPointOfSale(pointOfSaleInformation);

  return (
    <>
      <ExpandedDataWrapper as='dl'>
        <FlexColumn>
          <DatapointLabel as='dt'>Due Date</DatapointLabel>
          <Datapoint as='dd' size='small'>
            {dueDateValue(isActiveDispute, nextInstallmentPayment?.effDate)}
          </Datapoint>
          {
            isAutopay && (
              <AutopayWrapper as='dd'>
                <SVGIcon icon='sync' iconStyle='brand' />
                  {autopayValue(isActiveDispute)}
              </AutopayWrapper>
            )
          }
        </FlexColumn>
        <AmountDueWrapper padding='1em 0'>
          <DatapointLabel as='dt'>Amount Due</DatapointLabel>
          <Datapoint as='dd' size='small'>
            {amountDueValue(isActiveDispute, nextInstallmentPayment?.amount?.formatted)}
          </Datapoint>
        </AmountDueWrapper>
      </ExpandedDataWrapper>
    </>
  );
};

const NextPaymentLoanCard: React.FunctionComponent<NextPaymentLoanCard> = props => {
  const { isLate, pastDueAmount } = props;

  if (isLate && pastDueAmount && pastDueAmount.cents > 0) {
    return PastDuePaymentCard(props);
  }

  return FuturePaymentCard(props);
};

export default NextPaymentLoanCard;
