import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router';

import { IIdentityCustomer } from 'src/gql';
import { LoadableAccountManagementModule, LoadableCustomerManagementModule } from '../../servicing/modules/PreloadableModules';
import { LoadableVerDash } from 'src/modules/PreloadableDashboards';
import GetCustomerHomeEligibilityQuery, {
  GetCustomerHomeEligibility
} from './queries/GetCustomerHomeEligibility.graphql';
import PageTitle from 'src/components/Common/PageTitle';
import SessionManager from 'src/util/sessionManager';
import { Loading } from 'src/components/Common/Spinner/LoadingHOC';
import { DashWrapper } from 'src/components/Common/DashWrapper';
import { Content } from 'src/components/Common/SkipLink';
import { LiftedCustomerHomeDashG2 } from '../components/CustomerHomeDashG2';
import { SessionG2 } from './SessionG2';

function preloadDashboards () {
  LoadableAccountManagementModule.preload();
  LoadableCustomerManagementModule.preload();
  LoadableVerDash.preload();
}

export const LiftedCustomerHomeModuleG2: React.FC = () => {
  const { data, loading } = useQuery<GetCustomerHomeEligibility>(GetCustomerHomeEligibilityQuery);
  const history = useHistory();

  useEffect(() => {
    if (!loading && !data?.identity) {
      history.replace(SessionManager.urlRouter.loggedOut);
    }
    window.addEventListener('load', preloadDashboards);

    return function () {
      window.removeEventListener('load', preloadDashboards);
    }
  }, []);

  return (
    <Loading loading={loading}>
      <PageTitle pageTitle={AvantConfig.TenantConfig.pageTitles.customerHome} />
      <SessionG2 identity={data?.identity as IIdentityCustomer}>
        <DashWrapper>
          <Content>
            <LiftedCustomerHomeDashG2 />
          </Content>
        </DashWrapper>
      </SessionG2>
    </Loading>
  );
};
