import React from 'react';

import { FileDownloader } from '../FileDownloader';
import sessionManager from '../../../util/sessionManager';
import { FormError } from '../Form';

interface INoaaProps {
  appUuid: string;
  noaaUuid: string;
}

interface INoaaState {
  error: boolean;
}

export class NoaaDownloader extends React.PureComponent<INoaaProps, INoaaState> {
  public readonly state: INoaaState = {
    error: false
  };

  public render () {
    return (
      <>
        <FileDownloader
          endpoint={sessionManager.urlRouter.noaaFileDownload}
          params={{ applicationUuid: this.props.appUuid, adverseActionNoticeId: this.props.noaaUuid }}
          updateError={this.updateError}
          downloadName='Notice_of_Adverse_Action.pdf'
          text='Print your Notice of Adverse Action letter'
          icon='download'
          data-event='applicationNoaaDownload'
        />
        {!!this.state.error && <FormError>Error downloading file</FormError>}
      </>
    );
  }

  private readonly updateError: (error: boolean) => void = error => this.setState({ error });
}
