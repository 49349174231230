import React from 'react';
import isEmpty from 'lodash-es/isEmpty';

import Spinner from './';

interface IBaseProps {
  data?: {
    loading: boolean;
  };
}

export function loader<T extends IBaseProps> (
  Component: React.FunctionComponent<T> | React.ComponentClass<T>
): React.ComponentClass<T> {
  return class LoadingComponent extends React.PureComponent<T> {
    public render () {
      if (this.props.data && this.props.data.loading) {
        return <Spinner data-test-id='loading-spinner' />;
      }

      return <Component {...this.props} />;
    }
  };
}

export const Loading: React.FunctionComponent<{
  loading: boolean;
  data?: object;
  margin?: string;
}> = ({ loading, data, margin, children }) =>
  loading && isEmpty(data) ? (
    <Spinner margin={margin} data-testid='loading-spinner' />
  ) : (
    <>{children}</>
  );
