import React from 'react';
import {
  Box,
  Heading,
  Text,
  Link,
  SVGIcon
} from '@amount/frontend-components';

import { AccountHistoryProps } from '../common';
import { MaxWidthContainerBox } from '../../../../../components/Common/Box';
import { formatDate } from '../../../../../util/date';
import {
  historyActionsTheme,
  historyBoxTheme,
  historyItemBorderTheme,
  historyItemBoxTheme,
  historyTagTheme
} from './theme';
import {
  HistoryViewModes,
  AccountHistoryListProps,
  TagTypes
} from './types';
import { formatString } from '../../../../../util/string';
import processHistoryIntoViewModels from './util/processHistoryIntoViewModels';
import { RouterLink } from '../../../../../_shared/ThemeTwo/Link/router-link.component';

const historyCopy = AvantConfig.TenantConfig.accountHistory;

const AccountHistoryList: React.FC<AccountHistoryListProps> = ({
  applications = [],
  accounts = [],
}) => {
  const visibleItems = processHistoryIntoViewModels({ applications, accounts });

  if (!visibleItems.length) {
    return (
      <Box
        margin={{
          base: { horizontal: '2x' },
          medium: { horizontal: '0' }
        }}
      >
        <Text>{historyCopy.emptyStateMessage}</Text>
      </Box>
    )
  }

  return (
    <Box {...historyBoxTheme}>
      {...visibleItems.map((item, idx) => (
        <Box {...historyItemBoxTheme} key={idx} border={idx !== visibleItems.length - 1 ? historyItemBorderTheme : undefined}>
          <Box direction="column">
            <Box flexWrap="wrap" direction={{ base: 'column', medium: 'row' }}>
              <Text lineHeight="1.5">
                {item.purchaseAmount ? (
                  formatString(historyCopy.purchaseWithAmount, undefined, item.purchaseAmount, item.merchant)
                ) : (
                  formatString(historyCopy.purchaseWithoutAmount, undefined, item.merchant, formatDate(item.applicationDate))
                )}
              </Text>
              {item.tag === TagTypes.Active && (
                <Text {...historyTagTheme} color="green70">{historyCopy.tags.active}</Text>
              )}
              {item.tag === TagTypes.PaidOff && (
                <Text {...historyTagTheme} color="gray400">{historyCopy.tags.paidOff}</Text>
              )}
              {item.tag === TagTypes.Canceled && (
                <Text {...historyTagTheme} color="gray400">{historyCopy.tags.canceled}</Text>
              )}
            </Box>
            {item.type === HistoryViewModes.Account ? (
              <>
                <Text lineHeight="1.5">
                  {item.tag === TagTypes.PaidOff && (
                    item.isRefunded ? historyCopy.refunded : historyCopy.paidOff
                  )}
                  {formatDate(item.displayDate)}
                  <Box display="inline" margin={{ horizontal: '1x' }}>|</Box>
                  {historyCopy.loanId} #{item.productID}
                </Text>
                <Text lineHeight="1.5">{formatString(historyCopy.applicationOnDate, undefined, formatDate(item.applicationDate))}</Text>
              </>
            ) : (
              <>
                <Text lineHeight="1.5">Application ID #{item.applicationID}</Text>
                {item.type === HistoryViewModes.Canceled && (
                  <Text lineHeight="1.5">{historyCopy.statusLabels.canceled} {formatDate(item.displayDate)}</Text>
                )}
                {item.type === HistoryViewModes.Declined && (
                  <Text lineHeight="1.5">{historyCopy.statusLabels.declined} {formatDate(item.displayDate)}</Text>
                )}
                {item.type === HistoryViewModes.Closed && (
                  <Text lineHeight="1.5">{historyCopy.statusLabels.closed}</Text>
                )}
              </>
            )}
          </Box>
          {item.type === HistoryViewModes.Account && (
            <Box {...historyActionsTheme}>
              {item.accountLink && (
                <RouterLink.inline
                  to={item.accountLink}>
                  {historyCopy.dashboardLink}
                  <SVGIcon icon="open-in-new" size="2.25x" color="primary" />
                </RouterLink.inline>
              )}
              {item.contractLink && (
                <Link.inline
                  target={AvantConfig.TenantConfig.documentLinkTarget}
                  href={item.contractLink}
                >
                  {historyCopy.contractLink}
                  <SVGIcon icon="download" size="2.25x" color="primary" />
                </Link.inline>
              )}
            </Box>
          )}
          {!!item.noaaLink && (
            <Box {...historyActionsTheme}>
              <Link.inline
                target={AvantConfig.TenantConfig.documentLinkTarget}
                href={item.noaaLink}
              >
                {historyCopy.declinedNoticeLink}
                <SVGIcon icon="download" size="2.25x" color="primary" />
              </Link.inline>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export const AccountHistory: React.FC<AccountHistoryProps> = ({ identity }) => (
  <Box justifyContent="center">
    <MaxWidthContainerBox width={{ max: 'full' }}>
      <Box width={{
        base: 'full',
        medium: 'wide',
        max: '100%'
      }} padding={{
        base: { top: '4x', horizontal: '0', bottom: '9x' },
        medium: { top: '9x', horizontal: '2x' }
      }} direction="column">
        <Box
          margin={{
            base: { left: '2x' },
            medium: { left: '0' }
          }}
        >
          <Heading.h1 margin={{ base: { bottom: '2x' }, medium: { bottom: '3x' } }}>
            {AvantConfig.TenantConfig.pageTitles.accountHistory}
          </Heading.h1>
        </Box>
        <AccountHistoryList accounts={identity.servicing_accounts} applications={identity.applications} />
      </Box>
    </MaxWidthContainerBox>
  </Box>
);
