import React from "react";
import { HeaderLink } from "@amount/frontend-components";

import { HeaderLinkProps } from "../constants";

export const Support: React.FC<HeaderLinkProps> = ({ variant, linkLabel, externalUrl, onClickCallback }) => (
  <HeaderLink
    href={externalUrl}
    target={externalUrl ? '_blank' : undefined}
    variant={variant}
    hideOnMobile={variant === 'default'}
    hideOnDesktop={variant === 'dropdown'}
    onClick={onClickCallback}
  >
    {linkLabel}
  </HeaderLink>
);
