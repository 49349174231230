import React from 'react';
import { BannerLink } from '@amount/frontend-components';
import { Link } from 'react-router-dom';

import { BannerNotificationsProps } from '../../types';
import { LinkAndTextWrapper } from '../../../common';
import { ApplicationBaseRoutes, PaymentRoutes, ServicingRoutes } from '../../../../../../routes';

type ActiveChargeOffLinkProps = Pick<BannerNotificationsProps, 'uuid'>;
const CancelPayoffLink: React.FC<ActiveChargeOffLinkProps> = ({ uuid }) => (
  <Link
    data-event='bannerLoanChargeOffCancelPayoff'
    to={`${ApplicationBaseRoutes.loanServicing}/${uuid}${ServicingRoutes.cancelQuote}`}
  >
    Cancel Payoff for Your Remaining Balance
  </Link>
);

const SchedulePayoffLink: React.FC<ActiveChargeOffLinkProps> = ({ uuid }) => (
  <Link
    data-event='bannerLoanChargeOffPayoff'
    to={`${ApplicationBaseRoutes.loanServicing}/${uuid}${ServicingRoutes.payments}${PaymentRoutes.schedule}`}
  >
    Schedule a Payment
  </Link>
);

type ActiveChargeOffProps = Pick<BannerNotificationsProps, 'uuid' | 'payoffInformation'>;
export const ActiveChargeOff: React.FC<ActiveChargeOffProps> = ({ uuid, payoffInformation }) => (
  <LinkAndTextWrapper>
    <span>
      This loan has been charged off. If you make a payment for any amount we will share your new unpaid balance with the credit bureaus.
    </span>
    <BannerLink bannerStyle='inactive'>
      {payoffInformation?.scheduledQuote ? <CancelPayoffLink uuid={uuid} /> : <SchedulePayoffLink uuid={uuid} />}
    </BannerLink>
  </LinkAndTextWrapper>
);
