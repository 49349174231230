import React from 'react';

import { LoanBanner } from '../../';
import { PointOfSaleProps } from '../../../types';

type ResolvedDisputeBannerProps = Pick<PointOfSaleProps, 'dispute' | 'adjustedLoanAmount'>;
type AdjustedAmountProps = Pick<ResolvedDisputeBannerProps, 'adjustedLoanAmount'>;

const ResolvedPaymentProtectedDisputeBanner: React.FC<AdjustedAmountProps> = ({ adjustedLoanAmount: { formatted }}) => (
  <LoanBanner>
    <span>
      Your loan balance has been adjusted based on the resolution of your recent dispute.
      {' '}Your adjusted loan balance is {formatted}.
      {' '}Your monthly installment payment amount and scheduled payment dates have not changed.
      {' '}However, your next installment payment will include any payments that may have been missed during the dispute window.
    </span>
  </LoanBanner>
);

const ResolvedNonPaymentProtectedDisputeBanner: React.FC<AdjustedAmountProps> = ({ adjustedLoanAmount: { formatted } }) => (
  <LoanBanner>
    <span>
      Your loan balance has been adjusted based on the resolution of your recent dispute.
      {' '}Your adjusted loan balance is {formatted}.
      {' '}Your monthly installment payment amount and scheduled payment dates have not changed.
    </span>
  </LoanBanner>
);

export const ResolvedDisputeBanner: React.FC<ResolvedDisputeBannerProps> = props =>
  props.dispute?.paymentProtected
    ? <ResolvedPaymentProtectedDisputeBanner {...props} />
    : <ResolvedNonPaymentProtectedDisputeBanner {...props} />;
