import React from 'react';

import { ICreditCardProps, LinkContainer } from '../common';

import { CreditCardDashLink } from './CreditCardDashLink';

export const CreditCardActions: React.FC<ICreditCardProps> = props => (
  <LinkContainer>
    <CreditCardDashLink {...props} />
  </LinkContainer>
);
