import React from "react";
import { Box, Heading, Text, Link } from "@amount/frontend-components";
import { PageTemplate } from "../../../components/Common/Box";
import { BaseTenantTheme } from "../../../config/tenantTheme.base";
import { RouterLink } from "../Link/router-link.component";

export const ThemeTwoNotFound: React.FunctionComponent = () => {

  const { notFoundText, errorHeader } = BaseTenantTheme.errors;
  const { headline, text, homepageLinkText, faqLinkText, externalFaqUrl } = AvantConfig.TenantConfig.errorMessages.themeTwoNotFound;

  return (
    <PageTemplate
      width='narrow-2'
      slots={{
        header: (
          <Heading.h1 {...errorHeader}>
            {headline}
          </Heading.h1>
        ),
        content: (
          <>
            <Box padding={{ bottom: '3x' }}>
              <Text {...notFoundText}>
                {text}
              </Text>
            </Box>
            <Text>
              <RouterLink.inline to='/'>{homepageLinkText}</RouterLink.inline>
              &nbsp; | &nbsp;
              <Link.inline href={externalFaqUrl}>{faqLinkText}</Link.inline>
            </Text>
          </>
        )
      }}
    />
  );
};
