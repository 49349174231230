import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { IIdentityCustomer } from "src/gql";
import { AccountRoutes, ApplicationBaseRoutes } from 'src/routes';
import SessionManager from 'src/util/sessionManager';
import { withWarningBanner } from 'src/modules/helpers/withWarningBanner';
import NotFound from 'src/components/Common/Error/NotFound';
import { Loading } from 'src/components/Common/Spinner/LoadingHOC';
import ServerError from 'src/components/Common/Error/ThemeOneServerError';
import { DashWrapper } from 'src/components/Common/DashWrapper';
import { Content } from 'src/components/Common/SkipLink';
import G2AccountHistory from 'src/g2/servicing/components/AccountHistory';

import GetCustomerHomeEligibilityQuery, {
  GetCustomerHomeEligibility
} from 'src/g2/_shared/modules/queries/GetCustomerHomeEligibility.graphql';
import { SessionG2 } from 'src/g2/_shared/modules/SessionG2';

export const LiftedAccountHistoryModule: React.FunctionComponent<RouteComponentProps> = props => {
  const { data, loading } = useQuery<GetCustomerHomeEligibility>(GetCustomerHomeEligibilityQuery, {
    fetchPolicy: 'cache-and-network'
  });

  if (loading) {
    return <Loading loading={loading} />;
  }

  if (!data) {
    return <ServerError />;
  }

  if (!data.identity) {
    props.history.replace(SessionManager.urlRouter.loggedOut);
    return null;
  }

  return (
    <AccountHistoryServicingWrapper data={data} />
  );
};

interface AccountHistoryServicingWrapperProps {
  data: GetCustomerHomeEligibility;
}

const ContentWithBanner = withWarningBanner(Content, 'servicing');

const AccountHistoryServicingWrapper: React.FC<AccountHistoryServicingWrapperProps> = props => {
  const { data } = props;

  return (
    <SessionG2 identity={data.identity as IIdentityCustomer}>
      <DashWrapper>
        <ContentWithBanner>
          <Switch>
            <Route
              exact={true}
              path={`${ApplicationBaseRoutes.account}${AccountRoutes.history}`}
              component={G2AccountHistory}
            />
            <Route component={NotFound} />
          </Switch>
        </ContentWithBanner>
      </DashWrapper>
    </SessionG2>
  );
}
