import { AbstractAnalyticsLogger, IEventDisabled } from '../analyticsLogger';
import {
  IEventInfo,
  ITagInfo,
} from '../../customTypings/avant-config/tenantConfig';
import { client } from '../../apolloClient';

import GetDataLayerValuesQuery, {
  GetDataLayerValues,
  GetDataLayerValuesInput,
} from './queries/GetDataLayerValues.graphql';

interface Window {
  // eslint-disable-next-line @typescript-eslint/array-type, @typescript-eslint/no-explicit-any
  adobeDataLayer: Array<any>;
}

const typedWindow = (window as unknown) as Window;

export enum AdobeLaunchEventNames {
  Alert = 'alert',
  Error = 'error',
  FieldInteraction = 'field_interaction',
  OptionSelected = 'option_selected',
  PageContinue = 'page_continue',
  PageView = 'page_view',
  TimeOut = 'time_out',
}

export enum SITE_SECTIONS {
  Application = 'application',
  Header = 'header',
  Footer = 'footer',
}

export class AdobeLaunch extends AbstractAnalyticsLogger {
  public constructor (applicationId?: string) {
    super();
    typedWindow.adobeDataLayer = typedWindow.adobeDataLayer || [];
    this.loadBeaconScript();
    this.addBrowserDataLayerVariables();
  }

  public view (tags?: IEventInfo, eventDisabled?: IEventDisabled): void {
    this.addBrowserDataLayerVariables();
    const data = { event: 'page_view', ...tags };
    typedWindow.adobeDataLayer.push(data);
  }

  public createPageInfo (siteSection: SITE_SECTIONS, pageName: string): ITagInfo {
    return {
      siteSection,
      pageName,
    }
  }

  public createFieldEventInfo (siteSection: SITE_SECTIONS, identifier: string, type: string) {
    return {
      eventId: `${siteSection}.field_interaction`,
      fieldIdentifier: identifier,
      fieldInteractionType: type,
    };
  }

  public sendEvent (
    tags?: IEventInfo,
    eventName?: string,
    eventDisabled?: IEventDisabled
  ): void {
    this.addBrowserDataLayerVariables();
    const data = { event: eventName, ...tags };
    typedWindow.adobeDataLayer.push(data);
  }

  public sendClickEvent (
    linkLabel: string,
    siteSection: SITE_SECTIONS,
    pageName: string,
  ): void {
    const tags = {
      pageInfo: this.createPageInfo(siteSection, pageName),
      eventInfo: this.createFieldEventInfo(siteSection, `link.${linkLabel}`, 'click'),
    };

    this.sendEvent(
      tags,
      AdobeLaunchEventNames.FieldInteraction,
    );
  }

  public set applicationUuid (applicationUuid: string | undefined) {
    super.applicationUuid = applicationUuid;
    this.populateDataLayerFromQuery();
  }

  // Adobe Launch uses two scripts. This is the environment-specific script that
  // will report events to Adobe, according to beacons configured by the
  // partner. The other script (@adobe/adobe-client-data-layer) will not vary
  // by environment and is included in the build.
  private loadBeaconScript (): void {
    const beaconScriptUrl = process.env.REACT_APP_ADOBE_LAUNCH_BEACON_SCRIPT_URL;
    if (!beaconScriptUrl) {
      return;
    }
    const beaconScript = document.createElement('script');
    beaconScript.type = 'text/javascript';
    beaconScript.src = beaconScriptUrl;
    beaconScript.async = true;
    document.head.appendChild(beaconScript);
  }

  private addBrowserDataLayerVariables (): void {
    typedWindow.adobeDataLayer.push(this.browserDataLayerVariables());
  }

  private browserDataLayerVariables (): ITagInfo {
    return {
      domDomain: window.location.host,
      domHash: window.location.hash,
      domPathname: window.location.pathname,
      domQueryString: window.location.search,
      domReferrer: document.referrer,
      domTitle: document.title,
      domUrl: window.location.href,
      domViewportHeight: window.outerHeight,
      domViewportWidth: window.outerWidth,
      userAgent: navigator.userAgent,
      // visitorId will need to be added when we have clear specs for how to
      // retrieve it
      visitorId: 'not implemented',
    };
  }

  private readonly getApplicationData: () => Promise<GetDataLayerValues | null> =
    async () => {
      const appUuid = super.applicationUuid;
      if (!appUuid) {
        return null;
      }

      const { data } = await client.query<
        GetDataLayerValues,
        GetDataLayerValuesInput
      >({
        query: GetDataLayerValuesQuery,
        variables: {
          uuid: appUuid,
        },
        fetchPolicy: 'cache-first',
      });

      return data;
    };

  private readonly populateDataLayerFromQuery: () => void = async () => {
    const data = await this.getApplicationData();

    const formattedDataLayerValues = {
      applicationId: data?.me.getApplication?.id,
      customerId: data?.me.id,
      merchant: data?.me.getApplication?.merchantName,
      merchantIdentifier: data?.me.getApplication?.merchantIdentifier,
      productType: data?.me.getApplication?.productType,
      productSubtype: data?.me.getApplication?.productSubtype,
    };

    typedWindow.adobeDataLayer.push(formattedDataLayerValues);
  };
}
