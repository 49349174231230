import React, { useState } from 'react';
import { Text, Box, Heading, Button_t2_ } from '@amount/frontend-components';
import { SelectionOnVirtualCardToken } from 'src/modules/queries/GetAccountManagement.graphql';
import { formatDate } from '@Util/date';
import { formatString } from '@Util/string';
import { VCNModalBox } from 'src/components/Common/Box';
import { LiftedVcnModal } from 'src/g2/servicing/components/AllSetVCNCard/LiftedVcnModal';

const {
  cardExpiredLabel,
  description,
  flexPayId,
  viewCardLabel
} = AvantConfig.TenantConfig.product.summary;

export interface IProductSummaryProps {
  contractSignedDate: string | null;
  formattedAmount: string;
  productId: string;
  accountUuid: string;
  merchantName: string | null;
  virtual_card: SelectionOnVirtualCardToken | null;
  headingLevel?: 'h1' | 'h2';
}

export const ProductSummary: React.FunctionComponent<IProductSummaryProps> = ({
  contractSignedDate,
  formattedAmount,
  productId,
  accountUuid,
  merchantName,
  virtual_card,
  headingLevel,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const hasVcnCard = virtual_card !== null;
  const isCardExpired = !!virtual_card?.is_expired;
  const isVCUsed = !!virtual_card?.is_used;
  const isVCExpiredAndUnused = hasVcnCard && !isVCUsed && isCardExpired;

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const HeadingComponent = headingLevel === 'h2' ? Heading.h2 : Heading.h1

  return (
    <>
      <Box display='inline'>
        <HeadingComponent>
          {formatString(description, undefined, formattedAmount, merchantName)}
        </HeadingComponent>
        <Text
          family='optimist'
          display='block'
          size={{ base: 'bodyMedium', medium: 'body' }}
          margin={{ top: '0.5x' }}
        >
          {contractSignedDate &&
            <>
              <Text display="inline" data-testid='product_contract_signed_date_span'>
                {formatDate(contractSignedDate)}
              </Text>
              <DataSeparator />
            </>
          }

          <Text display="inline" data-testid='product_id_span'>
            {formatString(flexPayId, undefined, productId)}
          </Text>

          {hasVcnCard && (
            <>
              <DataSeparator />
              {isVCExpiredAndUnused ? (
                <Text color='error' display="inline">{cardExpiredLabel}</Text>
              ) : (
                <Button_t2_
                  variant="link"
                  onClick={handleToggleModal}
                  style={{ width: 'auto', display: 'inline-block', minWidth: '0' }}
                >
                  {viewCardLabel}
                </Button_t2_>
              )}
            </>
          )}
        </Text>
      </Box>
      {!isVCExpiredAndUnused && showModal && (
        <VCNModalBox>
          <LiftedVcnModal accountUUID={accountUuid} handleModalClose={handleToggleModal} isShown={showModal} />
        </VCNModalBox>
      )}
    </>
  );
}

const DataSeparator: React.FC = () => (
  <>&nbsp;&nbsp;|&nbsp;&nbsp;</>
);
