import React from 'react';
import { SVGIcon } from '@amount/frontend-components';
import camelCase from 'lodash-es/camelCase';
import upperFirst from 'lodash-es/upperFirst';
import Styled from 'styled-components';

import { SelectionOnCustomerApplication1 } from '../queries/GetCustomerHomeInformation.graphql';
import { ApplicationProductType, APPLICATION_STAGE } from '../../../constants';
import { ApplicationBaseRoutes } from '../../../routes';
import sessionManager from '../../../util/sessionManager';
import { ConditionalRenderWrapper } from '../../Common/CommonStyles';
import { isPointOfSaleProduct } from '../../../util';

import {
  ApplicationCardContainer,
  getFormattedProductName,
  IApplicationCardContentProps,
  IApplicationCardProps,
  IBaseStageProps,
} from './common';
import { DeclinedCard } from './DeclinedCard';
import { CreditFreezeCard } from './CreditFreezeCard';
import ApplicationCardContent from './ApplicationCardContent';

const IconWrapper = Styled.div`
  padding-right: 1.25em;

  svg {
    width: 2.5em;
    height: 2.5em;
  }
`;

interface ICardIconInfo extends IBaseStageProps {
  productType: SelectionOnCustomerApplication1['productType'];
}

const getCardIcon: (props: ICardIconInfo) => React.ReactNode = ({ stage, productType }) => {
  const isCC: boolean = productType === ApplicationProductType.CreditCard;

  if (stage === APPLICATION_STAGE.Completed) {
    return <SVGIcon icon={isCC ? 'review-card' : 'review-loan'} iconStyle='secondary' />;
  }

  return <SVGIcon icon={isCC ? 'continue-card' : 'continue-loan'} iconStyle='secondary' />;
};

interface IApplicationContentProps {
  productName: string;
  id: string;
  uuid: string;
  stage: string;
  isContractReSign: boolean | null;
}

const baseApplicationContent: (data: IApplicationContentProps) => IApplicationCardContentProps = ({ uuid, productName, id, stage } ) => ({
  cardHeadline: `Continue your ${AvantConfig.TenantConfig.companyName} ${productName} application process`,
  dataEvent: `applicationCard${upperFirst(camelCase(stage))}`,
  cardLink: {
    text: 'Continue',
    onClick: () => sessionManager.redirectToApplicationWithSession({ uuid, id })
  },
  currentStage: stage,
});

const bankAccountStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...baseApplicationContent(data),
  cardHeadline: 'Provide your bank information.',
});

const additionalInformationStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...baseApplicationContent(data),
  cardHeadline: `Provide initial information for your personal ${data.productName} application process`,
});

const ratesTermsStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...baseApplicationContent(data),
  cardHeadline: `View and select your ${data.productName} options.`,
});

const contractStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...baseApplicationContent(data),
  cardHeadline: `Sign ${data.productName} contract to continue to remainder of application process`,
});

const personalStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...baseApplicationContent(data),
  cardHeadline: `Provide initial information for your personal ${data.productName} application process`,
});

const verificationStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...baseApplicationContent(data),
  cardHeadline: `Verify your personal ${data.productName} application information`,
  cardLink: {
    typeName: 'IApplicationReactRouterLink',
    text: 'View',
    route: `${ApplicationBaseRoutes.verify}/${data.uuid}`,
  }
});

const completedStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...baseApplicationContent(data),
  cardHeadline: `Your ${AvantConfig.TenantConfig.companyName} ${data.productName} application is now in review`,
  cardLink: null,
});

const basePointOfStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...baseApplicationContent(data),
  cardHeadline: `Continue your ${data.productName} application process`,
});

const pointOfSaleContractStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...basePointOfStage(data),
  cardHeadline: `We have an updated contract for your ${data.productName}`,
});

const pointOfSaleCompletedStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...basePointOfStage(data),
  cardHeadline: `Your ${data.productName} application is now in review`,
  cardLink: null,
});

const pointOfSaleVerificationStage: (data: IApplicationContentProps) => IApplicationCardContentProps = data => ({
  ...basePointOfStage(data),
  cardLink: {
    typeName: 'IApplicationReactRouterLink',
    text: 'View',
    route: `${ApplicationBaseRoutes.verify}/${data.uuid}`,
  }
});

const pointOfSaleLoanApplication: (data: IApplicationContentProps) => IApplicationCardContentProps = data => {
  const { stage, isContractReSign, } = data;

  switch (stage) {
    case APPLICATION_STAGE.Contract:
      if (isContractReSign) {
        return pointOfSaleContractStage(data);
      }

      return basePointOfStage(data);
    case APPLICATION_STAGE.Completed:
      return pointOfSaleCompletedStage(data);
    case APPLICATION_STAGE.Verification:
      return pointOfSaleVerificationStage(data);
    default:
      return basePointOfStage(data);
  }
};

const installmentLoanCreditCardApplication: (data: IApplicationContentProps) => IApplicationCardContentProps = data => {
  switch (data.stage) {
    case APPLICATION_STAGE.AdditionalInformation:
      return additionalInformationStage(data);
    case APPLICATION_STAGE.BankAccount:
      return bankAccountStage(data);
    case APPLICATION_STAGE.Completed:
      return completedStage(data);
    case APPLICATION_STAGE.Contract:
      return contractStage(data);
    case APPLICATION_STAGE.Personal:
      return personalStage(data);
    case APPLICATION_STAGE.RatesTerms:
      return ratesTermsStage(data);
    case APPLICATION_STAGE.Verification:
      return verificationStage(data);
    default:
      return baseApplicationContent(data);
  }
};

const contentForApplication: (data: IApplicationCardProps) => IApplicationCardContentProps = data => {
  const { productType, stage, merchantName, isContractReSign, } = data.application;
  const productName = getFormattedProductName(productType, merchantName);
  const currentStage = stage?.toLowerCase() || '';
  const props = { ...data.application, productName, stage: currentStage, isContractReSign };

  if (isPointOfSaleProduct(productType)) {
    return pointOfSaleLoanApplication(props);
  }

  return installmentLoanCreditCardApplication(props);
};

export const ApplicationCard: React.FunctionComponent<IApplicationCardProps> = props => {
  const { productType, declined, hasCreditFreeze } = props.application;

  // Order here matters, as a credit freeze is a decline type
  if (AvantConfig.TenantConfig.customerHome.creditFreezePageEnabled && hasCreditFreeze) {
    return <CreditFreezeCard {...props} />;
  } else if (declined) {
    return <DeclinedCard {...props} />;
  }

  const stage: string | null = props.application.stage?.toLowerCase() || null;
  const cardIcon: React.ReactNode = getCardIcon({ stage, productType });

  return (
    <ApplicationCardContainer>
      {AvantConfig.TenantConfig.customerHome.showApplicationImage && cardIcon && (
        <ConditionalRenderWrapper hiddenOnMobile={true}>
          <IconWrapper>
            {cardIcon}
          </IconWrapper>
        </ConditionalRenderWrapper>
      )}
      <ApplicationCardContent
        {...contentForApplication(props)}
      />
    </ApplicationCardContainer>
  );
};
