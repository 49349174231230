import Styled from 'styled-components';
import { media, SVGIcon, Box, BreakPointsMap } from '@amount/frontend-components';

export const FlexColumn = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const HalfContainer = Styled(FlexColumn)`
  flex: 1 1 50%;
`;

export const Divider = Styled.div`
  background-color: ${props => props.theme.colorSlate10};
  margin: 1.5em 0;
  height: 1px;
  width: 100%;

  ${media.medium`
    height: auto;
    margin: 0em 1.5em;
    width: 1px;
  `}
`;

export const ProductCardWrapper = Styled(Box)`
  margin-bottom: 1.5em;
  align-items: stretch;
  background-color: white;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);

  ${media.small`
    border-radius: 0;
  `}

  @media (min-width: ${BreakPointsMap.medium}em) {
    border-radius: 4px;
    & :first-child {
      border-top-left-radius: ${({ theme }) => theme.themeTwo.global.edgeSize.quadruple};
      border-top-right-radius: ${({ theme }) => theme.themeTwo.global.edgeSize.quadruple};
    }
  }
`;

export const StyledViewArrowSVG = Styled(SVGIcon)`
  svg {
    display: flex;
    height: 1.2em;
    margin-left: 0.25em;
    margin-right: 0em;
    width: 0.8125em;
  }
`;

export const CustomerHomeCardsWrapper = Styled.div`
  ${media.medium`
    ${!AvantConfig.TenantConfig.customerHome.crossSellOffers && 'width: 675px;'} /* SHORT TERM SOLUTION */
  `}
`;
