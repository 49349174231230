import { AbstractAnalyticsLogger, IEventDisabled } from "@Util/analyticsLogger";
import { ITagInfo } from "../../customTypings/avant-config/tenantConfig";

// Analytics event calls are baked into the app all over, so this class acts as a fallback consumer for all
// of those, which just does absolutely nothing
export class NoAnalytics extends AbstractAnalyticsLogger {
  public constructor (applicationId?: string) {
    super();
    return;
  }

  public view (pageSpecificTags?: ITagInfo, eventDisabled?: IEventDisabled): void {
    return;
  }

  public sendEvent (
    eventTags?: ITagInfo, eventName?: string, eventDisabled?: IEventDisabled
  ): void {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public sendClickEvent (): void {}
}
