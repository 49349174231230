export enum ApplicationBaseRoutes {
  baseRoute = '/',
  account = '/account',
  cardServicing = '/servicing/card',
  loanServicing = '/servicing/loan',
  creditFreeze = '/creditFreeze',
  verify = '/verify',
  accountManagement = '/accountManagement',
  customerManagement = '/customerManagement',
  vcnCard = '/allSet'
}

export enum AccountRoutes {
  history = '/history',
  bank = '/updateBank',
  manageDebitCard = '/manageDebitCard',
  paymentPreference = '/updatePaymentPreference',
  incomeAndHousing = '/incomeAndHousing',
}

export enum ServicingRoutes {
  dashboard = '/',
  payments = '/payments',
  quote = '/quote',
  cancelQuote = '/cancelQuote',
  fsr = '/fsr',
  autopay = '/autopay',
  achOptIn = '/achOptIn',
  statements = '/statements',
  rewards = '/rewardDetails',
  settings = '/settings',
  paymentPlanEnrollment = '/paymentPlanEnrollment',
  settlementEnrollment = '/settlementEnrollment',
  onboarding = '/onboarding',
  activation = '/activation',
}

export enum CustomerManagementRoutes {
  personalInformation = '/personalInformation',
  paymentMethods = '/paymentMethods',
}

export enum CardSettingsRoutes {
  autopayEnroll = '/autopayEnroll',
  autopayUnenroll = '/autopayUnenroll',
  bankAccount = '/bankAccount',
  paperless = '/paperless',
  cardmemberAgreement = '/cardmemberAgreement',
  manageCardLock = '/manageCardLock',
  manageDebitCard = '/manageDebitCard',
  requestCreditLineIncrease = '/requestCreditLineIncrease',
  travelNotification = '/travelNotification',
}

export enum PaymentRoutes {
  schedule = '/schedule',
  cancel = '/cancel',
  edit = '/edit',
}

export enum PaymentWorkflows {
  schedule = 'schedule',
  cancel = 'cancel',
  edit = 'edit',
}

export const createVerPathWithUUID: (uuid: string, route: string) => string = (uuid, route) =>
  `${ApplicationBaseRoutes.verify}/${uuid}${route}`;

export const createPathWithUUID: (props: { base: string; uuid: string; route: string }) => string = ({ base, uuid, route }) =>
  `${base}${route}/${uuid}`;

export const createCustomerManagementPath: (route: string) => string = route =>
  `${ApplicationBaseRoutes.customerManagement}${route}`;

export const createAccountManagementPath: (accountUUID: string, taskRoute?: string) => string = (accountUUID, taskRoute) =>
  `${ApplicationBaseRoutes.accountManagement}/${accountUUID}${taskRoute || ''}`;

export const personalInformationPath = `${ApplicationBaseRoutes.customerManagement}${CustomerManagementRoutes.personalInformation}`;

export const paymentMethodsPath = `${ApplicationBaseRoutes.customerManagement}${CustomerManagementRoutes.paymentMethods}`;

export const paymentIDRoute: string = '/:paymentID';

export const paymentActionRoute = '/:action';
