import React from 'react';

import { LoanBanner } from '.';
import { formatDateWithFullMonthName } from '@Util/date';
import { BannerNotificationsProps } from '../index';
import { FullBannerProps } from '../types';
import { formatString } from '@Util/string';

const { payoffQuote } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages

export const PayoffQuoteBanner: React.FC<BannerNotificationsProps> = ({  bannerProps , variant }) => {
  const fullBannerProps = bannerProps as FullBannerProps
  const payoffBalance = fullBannerProps.servicingAccount.payoff_information.payoff_balance.formatted

  if ( !fullBannerProps.servicingAccount.payoff_information.payment_eff_date ) {return null; }

  return(
  <LoanBanner variant={variant}>
    <span>
      {formatString(payoffQuote, undefined, payoffBalance,
        formatDateWithFullMonthName(fullBannerProps.servicingAccount.payoff_information.payment_eff_date))}
    </span>
  </LoanBanner>
)};
