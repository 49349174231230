import React from 'react';
import Styled from 'styled-components';
import {
  Button,
  Headline,
  HorizontalDivider,
  NavButton,
  ScreenReaderOnly,
  Spinner,
} from '@amount/frontend-components';
import camelCase from 'lodash-es/camelCase';
import {
  IActionProps,
  IInputProps,
  IRenderProps,
  IScriptProps,
  IWorkflowStepProps,
  SelectionOnWorkflowOnExit,
  StartWorkflowInput,
  WorkflowViewer
} from '@amount/workflow-js';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Loading } from '../Spinner/LoadingHOC';
import { IUUIDRouteProps, IWorkflow } from '../../../constants';
import heapHelper from '../../../util/heapHelper';

import { WorkflowTextAreaInput } from './WorkflowInputs/TextAreaInput';
import { WorkflowDatePickerInput } from './WorkflowInputs/DatePicker';
import { WorkflowSelectInput } from './WorkflowInputs/SelectInput';
import { WorkflowRadioInput } from './WorkflowInputs/RadioInput';
import { WorkflowTextInput } from './WorkflowInputs/TextInput';
import { WorkflowCheckboxInput } from './WorkflowInputs/CheckboxInput';
import { WorkflowNumberInput } from './WorkflowInputs/NumberInput';
import { WorkflowEmailInput } from './WorkflowInputs/EmailInput';
import { ActionContainer, ErrorDiv, WorkflowContainer } from './common';
import { CustomPrompt } from './CustomPrompt';

type WorkflowInputs = Pick<StartWorkflowInput, 'productUUID' | 'productType' | 'params'>;

interface IWorkflowProps extends WorkflowInputs, RouteComponentProps<IUUIDRouteProps> {
  url?: string; // Finish url
  workflow: IWorkflow;
  onComplete? (): void; // Fired on the finish step
}

interface IWorkflowState {
  showPrompt: boolean;
}

interface IActionOnClick {
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  isFinish: boolean;
  onClick: IActionProps['onClick'];
}

const CodeDiv = Styled.div`
  code {
    font-family: inherit;
    font-weight: bold;
  }
`;

interface IWorkflowState {
  showPrompt: boolean;
  onExit: SelectionOnWorkflowOnExit | null;
}
class StyledWorkflowViewer extends React.PureComponent<IWorkflowProps, IWorkflowState> {
  public state: IWorkflowState = {
    showPrompt: false, // Prompt is not shown until data is changed
    onExit: null
  };

  public componentWillMount () {
    heapHelper.addEventProperties({ workflowName: camelCase(this.props.workflow.name) });
    const pageHeading = document.querySelector('h1');
    pageHeading?.focus();
  }

  public componentWillUnmount = () => {
    heapHelper.clearEventProperties();
  }

  public render () {
    const { workflow, productType, productUUID, params, history } = this.props;

    const navigate = (path: string) => history.push(path);

    return (
      <WorkflowContainer>
        <CustomPrompt
          navigate={navigate}
          when={this.state.showPrompt}
          onExit={this.state.onExit}
        />
        <WorkflowViewer
          renderScript={this.renderScript}
          renderAction={this.renderAction}
          renderInput={this.renderInput}
          renderStep={this.renderStep}
          variables={{
            workflowName: workflow.name,
            productType,
            productUUID,
            params
          }}
          onStepDidMount={this.setOnExit}
        >
          {this.renderWorkflowContent}
        </WorkflowViewer>
      </WorkflowContainer>
    );
  }

  private readonly setOnExit: (step: IWorkflowStepProps) => void = step => {
    this.setState({ onExit: step.onExit });
  }

  private readonly renderWorkflowContent: IRenderProps['children'] = ({ loading, steps }) => (
    <Loading loading={loading} margin='0'>
      <Headline scale='large' as='h1' tabIndex={-1}>
        {this.props.workflow.formattedName}
      </Headline>
      {steps}
    </Loading>
  )

  // Enables exit prompt once data has been changed.
  private readonly updateInputWrapper: (updateInput: (value: string) => void) => (value: string) => void = updateInput =>
    (value: string) => {
      updateInput(value);
      this.setState({
        showPrompt: true
      });
    }

  private readonly renderInput: React.FunctionComponent<IInputProps> = ({
    input,
    updateInput,
    value,
    innerInputs
  }) => {
    // eslint-disable-next-line default-case
    switch (input.__typename) {
      case 'WorkflowCheckboxInput':
        return <WorkflowCheckboxInput input={input} value={value} updateInput={this.updateInputWrapper(updateInput)} />;
      case 'WorkflowNumberInput':
        return <WorkflowNumberInput input={input} value={value} updateInput={this.updateInputWrapper(updateInput)} />;
      case 'WorkflowRadioInput':
        return (
          <WorkflowRadioInput
            input={input}
            value={value}
            innerInputs={innerInputs}
            updateInput={this.updateInputWrapper(updateInput)}
          />
        );
      case 'WorkflowSelectInput':
        return <WorkflowSelectInput input={input} value={value} updateInput={this.updateInputWrapper(updateInput)} />;
      case 'WorkflowDateInput':
        return <WorkflowDatePickerInput input={input} value={value} updateInput={this.updateInputWrapper(updateInput)} />;
      case 'WorkflowTextInput':
        return <WorkflowTextInput input={input} value={value} updateInput={this.updateInputWrapper(updateInput)} />;
      case 'WorkflowEmailInput':
        return <WorkflowEmailInput input={input} value={value} updateInput={this.updateInputWrapper(updateInput)} />;
      case 'WorkflowTextAreaInput':
        return <WorkflowTextAreaInput input={input} value={value} updateInput={this.updateInputWrapper(updateInput)} />;
      default:
        return null;
    }
  }

  private readonly actionOnClick: (props: IActionOnClick) => void = ({ event, isFinish, onClick }) => {
    event.preventDefault();
    if (isFinish) {
      this.handleFinish(event);
    } else {
      this.setState({ showPrompt: true }, onClick);
    }
  }

  private readonly renderAction: React.FunctionComponent<IActionProps> = ({
    action: { displayName, name, isFinish, skipValidation, secondary },
    onClick,
    submitted
  }) => {
    const actionProps = {
      onClick: (event:  React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.actionOnClick({ event, isFinish, onClick }),
      disabled: submitted,
      formNoValidate: skipValidation,
      inline: true,
    };

    return secondary ? (
      <NavButton
        {...actionProps}
        data-action={name}
        data-event={camelCase(`${displayName}Action`)}
      >
        {displayName}
      </NavButton>
    ) : (
        <Button
          {...actionProps}
          data-action={name}
          data-event={camelCase(`${displayName}Action`)}
        >
          <ScreenReaderOnly>Activating this element will cause content on the page to be updated.</ScreenReaderOnly>
          {displayName}
        </Button >
      );
  }

  private readonly handleFinish: (event: React.MouseEvent<HTMLButtonElement>) => void = event => {
    this.setState(
      {
        showPrompt: false,
      },
      () => {
        if (!!this.props.onComplete) {
          this.props.onComplete();
        }
        if (!!this.props.url) {
          this.props.history.push(this.props.url);
        }
      }
    );
  }

  private readonly renderScript: React.FunctionComponent<IScriptProps> = ({ script }) =>
    (
      <>
        {!!script &&
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: script }} />
        }
      </>
    )

  private readonly renderStep: React.FunctionComponent<IWorkflowStepProps<HTMLFormElement>> = ({
    id,
    name,
    script,
    inputs,
    actions,
    formRef,
    inputRef,
    error,
    submitted
  }) => {
    heapHelper.addEventProperties({ stepName: camelCase(name) });
    const showErrorsOnBottom = AvantConfig.TenantConfig.workflows.showErrorsOnBottom;

    return (
      <form ref={formRef} key={id}>
        <CodeDiv>
          {error && !showErrorsOnBottom &&
            <ErrorDiv dangerouslySetInnerHTML={{ __html: error }} role='alert' />
          }
          <div>
            {script}
          </div>
          <div ref={inputRef} tabIndex={-1}>
            {inputs}
          </div>
          {error && showErrorsOnBottom &&
            <ErrorDiv dangerouslySetInnerHTML={{ __html: error }} role='alert' />
          }
          <HorizontalDivider margin='1em 0' />
          <ActionContainer>
            {actions}
            {submitted && <Spinner small={true} />}
          </ActionContainer>
        </CodeDiv>
      </form>
    );
  }
}

export default withRouter(StyledWorkflowViewer);
