import React from 'react';
import {
  HeaderDesktopOnlyLinkContainer,
  NavButton,
  SVGIcon,
  Text,
  VALID_BREAKPOINTS,
} from '@amount/frontend-components';
import styled from 'styled-components';
import { IPointOfSaleInterface } from '.';

const BackButton = styled(NavButton)`
  text-decoration: none;
  align-items: center;
`;

const StyledSVGIcon = styled(SVGIcon)`
  height: 2rem;
  padding-right: 1rem;

  svg {
    height: 2rem;
  }
`;

export interface IReturnToMerchantProps extends IPointOfSaleInterface {
  linksBreakPoint?: VALID_BREAKPOINTS;
  sendClickEventCallback?(pageName: string): void;
  handleReturnToMerchant?(): void;
}

const ReturnToMerchantLink: React.FC<IReturnToMerchantProps> = ({
  linksBreakPoint,
  merchantName,
  sendClickEventCallback,
  handleReturnToMerchant
}) => (
  <HeaderDesktopOnlyLinkContainer breakPoint={linksBreakPoint}>
    <BackButton
      data-event="return-to-merchant"
      data-testid="return-to-merchant"
      tabIndex={0}
      onClick={() => {
        sendClickEventCallback ? sendClickEventCallback('return_to_merchant') : undefined;
        handleReturnToMerchant ? handleReturnToMerchant() : undefined;
      }}
    >
      <StyledSVGIcon icon="return-arrow" iconStyle="link" />
      <Text color="primary" size="body" lineHeight="1.2" decoration="none">
        Return to {merchantName}
      </Text>
    </BackButton>
  </HeaderDesktopOnlyLinkContainer>
);

export default ReturnToMerchantLink;
