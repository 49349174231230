import * as React from 'react';
import { FragmentSelectionOnWorkflowDateInput, IInputProps } from '@amount/workflow-js';
import { DatePickerInput, Headline, ReactDayPicker, ReactDayPickerInput } from '@amount/frontend-components';
import { format, parse } from 'date-fns';
import camelCase from 'lodash-es/camelCase';

import { WorkflowInputMaxWidthWrapper } from '../common';

interface IWorkflowDatePickerInputProps extends IInputProps {
  input: FragmentSelectionOnWorkflowDateInput;
}

interface IWorkFlowDatePickerState {
  selectedDate: string | null;
}

const dateFormat: string = 'YYYY-MM-DD';

export class WorkflowDatePickerInput extends React.PureComponent<IWorkflowDatePickerInputProps, IWorkFlowDatePickerState> {
  public state = {
    selectedDate: this.props.input.value,
  };

  public render () {
    const {
      input,
    } = this.props;

    const disabledDays: ReactDayPicker.Modifier[] = [
      !!input.min && { before: parse(input.min) } || undefined,
      !!input.max && { after: parse(input.max) } || undefined,
    ];

    if (input.blacklisted) {
      input.blacklisted.forEach(day => {
        disabledDays.push(parse(day));
      });
    }

    return (
      <>
        { input.placeholder && (
          <Headline scale='small' as='label' htmlFor={camelCase(input.placeholder || undefined)}>
            {input.placeholder}
          </Headline>
        )}
        <WorkflowInputMaxWidthWrapper>
          <DatePickerInput
            onDayChange={this.handleDayClick}
            formatDate={this.formatDate}
            format={dateFormat}
            placeholder={dateFormat}
            value={this.state.selectedDate || undefined}
            inputProps={{
              readOnly: input.readonly,
              required: input.required,
              'data-event': camelCase(`${input.placeholder || ''}DatePicker`),
              id: camelCase(input.placeholder || undefined)
            }}
            dayPickerProps={{
              disabledDays
            }}
          />
        </WorkflowInputMaxWidthWrapper>
      </>
    );
  }

  private readonly formatDate: (date: Date, format: string, locale: string) => string = (date, formatString, _locale) =>
    format(date, formatString)

  private readonly handleDayClick: (
    day: Date,
    dayModifiers: ReactDayPicker.DayModifiers,
    dayPickerInput: ReactDayPickerInput
  ) => void = (
    _day,
    _dayModifiers,
    dayPickerInput
  ) => {
    const dateValue: string | undefined | null = dayPickerInput.getInput().value;

    if (!dateValue) {
      return;
    }

    this.setState({ selectedDate: dateValue });
    this.props.updateInput(dateValue);
  }
}
