import loadableWrapper from 'src/Loadable';

export const LoadableAccountManagementModule = loadableWrapper(
  async () => {
    const { default: component } = await import(
      /* webpackChunkName: "AccountManagementDash" */ './AccountManagement'
    );

    return component;
  }
);

export const LoadableCustomerManagementModule = loadableWrapper(
  async () => {
    const { default: component } = await import(
      /* webpackChunkName: "CustomerManagementDash" */ './CustomerManagement'
    );

    return component;
  }
);
