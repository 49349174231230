import { theme, Divider, VALID_EDGE_STYLE_DATA } from "@amount/frontend-components";
import React from "react";

export interface IStandardHorizontalDividerProps {
  margin: VALID_EDGE_STYLE_DATA;
}

export const StandardHorizontalDivider: React.FC<IStandardHorizontalDividerProps> = ({
  margin,
}) => {
  return (
    <Divider
      color={theme.colorSlate10}
      width='100%'
      height='1px'
      margin={margin}
      alignSelf='center'
    />
  );
}
