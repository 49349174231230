import React, { useContext } from 'react';
import Styled, { css, ThemeContext } from 'styled-components';

import { Box, cssDeclaration, IBoxProps } from '@amount/frontend-components';

export const RootContainer: React.FC = props => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box
      {...themeContext?.boxes.RootContainer}
      height={{ min: '100vh' }}
      direction="column"
      width="100%"
    >
      {props.children}
    </Box>
  );
};
/**
 * BoxForm is not a real box but when added inside a box it will inherit
 * styles from its parent which will make styling the page easier.
 *
 * ex.
 * <Box direction="column" >
 *   <BoxForm>
 *    <Box>Row1</Box>
 *    <Box>Row2</Box>
 *   </BoxForm>
 * </Box>
 */
export const BoxForm = Styled.form`
  width: 100%;

  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
`;

export const ContentContainerBox: React.FC = props => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box
      {...themeContext?.boxes.contentContainer}
      alignItems={
        AvantConfig.TenantConfig.boxTheme.shared.formContainerAlignment
      }
    >
      {props.children}
    </Box>
  );
};

export const SettingsLayoutBox: React.FC = props => {
  const themeContext = useContext(ThemeContext);

  return <Box {...themeContext?.boxes.settingsLayout}>{props.children}</Box>;
};

export const ApplicationCardBox: React.FC = props => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box {...themeContext?.boxes.applicationCardContainer}>
      {props.children}
    </Box>
  );
};

export const CustomerHomeCardBox: React.FC = props => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box {...themeContext?.boxes.customerHomeDashboardContainer}>
      {props.children}
    </Box>
  );
};

export const PaymentMethodsCardBox: React.FC = props => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box {...themeContext?.boxes.paymentMethodsCardContainer}>
      {props.children}
    </Box>
  );
};

export const ApplicationCardWrapperBox: React.FC = props => {
  const themeContext = useContext(ThemeContext);

  return <Box {...themeContext?.boxes.appCardWrapper}>{props.children}</Box>;
};

export const BackgroundProviderBox: React.FC = props => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box {...themeContext?.boxes.backgroundProvider}>{props.children}</Box>
  );
};

export const ContentPaddingContainerBox: React.FC = props => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box {...themeContext?.boxes.contentPaddingContainer}>{props.children}</Box>
  );
};

export const InnerContainerBox: React.FC<{ width?: string }> = ({
  width,
  children,
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box
      {...themeContext?.boxes.innerContainer}
      width={width || themeContext?.boxes.innerContainer?.width || ''}
    >
      {children}
    </Box>
  );
};

export const MaxWidthContainerBox: React.FC<IBoxProps> = ({ children, ...boxProps }) => {
  const themeContext = useContext(ThemeContext);

  return <Box {...themeContext?.boxes.maxWidthContainer} {...boxProps}>{children}</Box>;
};

export const SettingsOuterContainerBox: React.FC = props => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box {...themeContext?.boxes.settingsOuterContainer}>{props.children}</Box>
  );
};

const ProductDetailsBoxBase = Styled(Box)`
  button {
    display: flex;
    justify-content: center;
    min-width: 7em;
  }
`;

export const ProductDetailsBox: React.FC<{ inactive?: boolean }> = props => {
  const themeContext = useContext(ThemeContext);

  const margin = props.inactive
    ? '0'
    : themeContext?.boxes.productDetails?.margin;

  return (
    <ProductDetailsBoxBase
      {...themeContext?.boxes.productDetails}
      margin={margin}
    >
      {props.children}
    </ProductDetailsBoxBase>
  );
};

export const OuterContainerBox: React.FC<{
  direction?: IBoxProps['direction'];
  padding?: IBoxProps['padding'];
}> = ({ direction, padding, children }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box
      {...themeContext?.boxes.outerContainer}
      direction={direction || themeContext?.boxes.outerContainer?.direction}
      padding={padding || themeContext?.boxes.outerContainer?.padding}
    >
      {children}
    </Box>
  );
};

export const VerContainerBox: React.FC<{
  padding?: IBoxProps['padding'];
}> = ({ padding, children }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Box
      {...themeContext?.boxes.verContainer}
      padding={padding || themeContext?.boxes.verContainer?.padding}
    >
      {children}
    </Box>
  );
};

const SubHeadBoxBase = Styled(Box)`
  p {

    margin-bottom: 0;
    text-align: ${AvantConfig.TenantConfig.boxTheme.shared.headlineTextAlign};
  }
`;

export const SubHeadBox: React.FC<IBoxProps> = ({ children, ...rest }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <SubHeadBoxBase {...themeContext?.boxes.subHead} {...rest}>
      {children}
    </SubHeadBoxBase>
  );
};

export const VerMastHeadIntroExtender = Styled.div`
  flex-grow:1;

  h1, p {
    text-align: ${AvantConfig.TenantConfig.boxTheme.shared.headlineTextAlign};
  }
`;

// can remove justifyContent after the Heading component is extended to receive alignment props
export interface PageTemplateProps {
  width?: string;
  alignment?: string;
  outerContainerPadding?: IBoxProps['padding'];
  slots: {
    header?: React.ReactNode;
    banner?: React.ReactNode;
    content?: React.ReactNode;
    footer?: React.ReactNode;
  };
}

export const PageTemplate: React.FC<PageTemplateProps> = ({
  width,
  alignment = 'center',
  outerContainerPadding,
  slots: { header, banner, content, footer },
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <OuterContainerBox padding={outerContainerPadding}>
      <InnerContainerBox width={width}>
        <Box direction="column" flexGrow="1">
          <Box
            {...themeContext?.boxes.introHeader}
            direction="column"
            alignItems={alignment}
          >
            {header}
          </Box>
          {banner && <Box direction="column" padding={{ bottom: "3x" }}>{banner}</Box>}
          <ContentContainerBox>{content}</ContentContainerBox>
          {footer}
        </Box>
      </InnerContainerBox>
    </OuterContainerBox>
  );
};

PageTemplate.displayName = 'Page Template';

export const FormRowBox: React.FC = ({ children }) => (
  <Box margin={{ bottom: '3x' }} width="100%">
    {children}
  </Box>
);

export const MediumButtonBox: React.FC = ({ children }) => (
  <Box
    padding={{ top: '4x' }}
    // remove width & margin when flex-basis is added to Button, and add it to
    // the button that's inside
    width={{ mediumLarge: '50%' }}
    margin="auto"
  >
    {children}
  </Box>
);

export const LinkButtonBox: React.FC = ({ children }) => (
  <Box padding={{ top: '3x' }} direction="column">
    {children}
  </Box>
);

export const ModalHeaderBox: React.FC<{ id?: string }> = ({ id, children }) => (
  <Box
    id={id}
    padding={{
      base: { horizontal: '3x', top: '7x', bottom: '2x' },
      medium: { horizontal: '4x', top: '6x', bottom: '3x' },
    }}
  >
    {children}
  </Box>
);

export const ModalBodyBox: React.FC = ({ children }) => (
  <Box
    padding={{
      base: { horizontal: '3x', bottom: '3x' },
      medium: { horizontal: '4x', bottom: '4x' },
    }}
    direction="column"
  >
    {children}
  </Box>
);

export const ModalLinksBox: React.FC = ({ children }) => (
  <Box direction="column">{children}</Box>
);

export const VerInnerContainer: React.FC = ({ children }) => {
  const themeContext = useContext(ThemeContext);

  return <Box {...themeContext?.boxes.verInnerContainer}>{children}</Box>;
};

export const VerOuterContainer: React.FC = ({ children }) => {
  const themeContext = useContext(ThemeContext);

  return <Box {...themeContext?.boxes.verOuterContainer}>{children}</Box>;
};


export const ContainerWithNoTopPadding: React.FC = ({
  children,
}) => (
  <Box
    display="block"
    margin={{
      base: { bottom: '0' },
      mediumLarge: { bottom: '10x' },
    }}
    padding={{ top: '0' }}
  >
    {children}
  </Box>
);

export const VCNModalBox: React.FC = ({ children }) => {
  return (
    <Box
      childrenStyles={css`
        & :not(:last-child) {
          ${({ theme: { themeTwo } }) => cssDeclaration(themeTwo, 'width', { min: '0' })};
        }
      `}
    >
      {children}
    </Box>
  );
};

export const InputIconBox: React.FC = ({ children }) => {
  return (
    <Box
      margin={{ bottom: '4x' }}
      direction='column'
      childrenStyles={css`
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          transition: background-color 3000s ease-in-out 0s;
        }
    `}
    >
      {children}
    </Box>
  );
};
