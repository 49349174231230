/* eslint-disable @typescript-eslint/naming-convention */
import { LoanBannerTypes, FullBannerProps, ConsumerProductDispute } from './types';
import { BannerNotificationsProps } from './index'
import { ProductStatuses, DisputeStatuses, PastPaymentStatuses, PaymentOrigins } from '@Constants';
import { isSplitPayProductSubtype } from '../../../PaymentMethods/utils/isSplitPayProductSubtype';

import { JWT_TOKEN_KEY } from '@Util';
import { isResolvedPaymentProtectedDispute,
  isResolvedPaymentProtectedMerchantFavorDispute
} from './Banners/POS/Dispute/Resolved'

import { accountCreditKey, disputeIdsKey, returnedPastDueKey } from '../common';
import { BannerSessionMap } from './Banners/PastDue/ReturnedPastDue';

import { isAfter, isBefore, isEqual } from 'date-fns';
import { hasNoValidPaymentMethods } from 'src/g2/servicing/components/ServicingTasks/utils/hasNoValidPaymentMethods';

const showsChargedOff: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { charge_off_information } } = bannerProps as FullBannerProps

  return (charge_off_information?.is_operationally_charged_off) || false;
}

const hasBadPaymentMethod: (props: BannerNotificationsProps) => boolean = ({ bannerProps: { identity } }) => (
  identity.has_bad_payment_method
);

// bad personal information flag to be added in CF-5794

const productHasPastDueAmount: (props: FullBannerProps) => boolean = ({ servicingAccount }) => {
  return !!(
    servicingAccount.product.is_past_due
    && servicingAccount.product.past_due_amount
    && (servicingAccount.product.past_due_amount?.cents) > 0)
}

const isPastDue: (props: BannerNotificationsProps ) => boolean = ({ bannerProps }) => {
  const fullProps = bannerProps as FullBannerProps
  return productHasPastDueAmount(fullProps)
}

const returnedPastDue: (props: BannerNotificationsProps ) => boolean = ({ bannerProps }) => {
  const fullProps = bannerProps as FullBannerProps
  const { servicingAccount: { all_past_payments } } = bannerProps as FullBannerProps

  if ( !all_past_payments.length ) { return false; }
  const mostRecentPayment = getMostRecentPayment(all_past_payments);

  const mostRecentPaymentWasReturned = mostRecentPayment?.status === PastPaymentStatuses.Returned

  return productHasPastDueAmount(fullProps) && mostRecentPaymentWasReturned
}

const payoffQuote: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { payoff_information, product } } = bannerProps as FullBannerProps
  const productStatus = product.status

  return productStatus !== ProductStatuses.PaidOff && !!payoff_information?.scheduled_payoff_quote_uuid;
}

const isExpiredAndSplitPay: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { product, virtual_card } } = bannerProps as FullBannerProps
  const expiredAndNotUsed = !!virtual_card && virtual_card.is_expired && !virtual_card.is_used

  return expiredAndNotUsed && isSplitPayProductSubtype(product.product_subtype)
}

const isExpiredAndNotSplitPay: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { product, virtual_card } } = bannerProps as FullBannerProps
  const expiredAndNotUsed = !!virtual_card && virtual_card.is_expired && !virtual_card.is_used

  return expiredAndNotUsed && !isSplitPayProductSubtype(product.product_subtype)
}

const isNotUsedSplitPay: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { product, virtual_card } } = bannerProps as FullBannerProps

  return !!virtual_card && !virtual_card?.is_used && isSplitPayProductSubtype(product.product_subtype)
}

const isNotUsedAndNotSplitPay: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { product, virtual_card } } = bannerProps as FullBannerProps

  return !!virtual_card && !virtual_card?.is_used && !isSplitPayProductSubtype(product.product_subtype)
}

const activePaymentProtectedDispute: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { disputes } } = bannerProps as FullBannerProps

  return disputes?.some(dispute =>
    dispute && dispute.status !== DisputeStatuses.Closed && dispute.is_payment_protection_applied
  );
}

const resolvedPaymentProtectedDispute: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { disputes } } = bannerProps as FullBannerProps

  return disputes?.some(dispute => (
    dispute?.status === DisputeStatuses.Closed && dispute.is_payment_protection_applied
  )) && isResolvedPaymentProtectedDispute(disputes)

}
const resolvedPaymentProtectedMerchantFavor: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { disputes } } = bannerProps as FullBannerProps

  return disputes?.some(dispute => (
    dispute?.status === DisputeStatuses.Closed && dispute.is_payment_protection_applied
  )) && isResolvedPaymentProtectedMerchantFavorDispute(disputes)
}

const isReturnedPayment: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { all_past_payments, product } } = bannerProps as FullBannerProps

  if ( !all_past_payments.length ) { return false; }
  const mostRecentPayment = getMostRecentPayment(all_past_payments);

  const mostRecentPaymentWasReturned = mostRecentPayment?.status === PastPaymentStatuses.Returned
  const currentProduct = product.status === ProductStatuses.Current

  return currentProduct && mostRecentPaymentWasReturned
}

const sevenDaysBeforeNextInstallmentDateHelper = (next_installment_date: string | number | null) => {
  // Non-null assertion handled by check on values in Loan Banners index.tsx to return null if data unavailable
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const nextInstallmentDate = new Date(next_installment_date!)
  const currentDate = new Date();
  const parsedInstallmentDate = new Date(nextInstallmentDate.getTime() + nextInstallmentDate.getTimezoneOffset() * 60000);
  const sevenDaysBeforeNextInstallmentDate = new Date(parsedInstallmentDate)
    sevenDaysBeforeNextInstallmentDate.setDate(sevenDaysBeforeNextInstallmentDate.getDate() - 7);

    return (isEqual(currentDate, sevenDaysBeforeNextInstallmentDate) || isAfter(currentDate, sevenDaysBeforeNextInstallmentDate))
      && isBefore(currentDate, parsedInstallmentDate);
}

const isCurrentAndAutoPayOff: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { product } } = bannerProps as FullBannerProps

  const productStatus = product.status
  const currentAndAutoPayOff = !product.is_autopay && productStatus === ProductStatuses.Current
  const sevenDaysBeforeNextInstallmentDate =
    sevenDaysBeforeNextInstallmentDateHelper(product.next_installment_date)
  const isNotCovered = product.next_installment_amount?.cents !== 0

  return sevenDaysBeforeNextInstallmentDate && currentAndAutoPayOff && isNotCovered
}

const autoPayOn: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { product } } = bannerProps as FullBannerProps

  const sevenDaysBeforeNextInstallmentDate =
    sevenDaysBeforeNextInstallmentDateHelper(product.next_installment_date)
  const isNotCovered = product.next_installment_amount?.cents !== 0
  const isNotDisputeInstallment = product.next_non_zero_installment_payment?.origin !== PaymentOrigins.DisputeInstallment;

  return sevenDaysBeforeNextInstallmentDate && product.is_autopay && isNotCovered && isNotDisputeInstallment
}

const isPOSFullRefund: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { point_of_sale_information } } = bannerProps as FullBannerProps
  return !!point_of_sale_information.refund_information?.is_full_refund
}

const isPOSAccountCredit: (props: BannerNotificationsProps) => boolean = ({ bannerProps }) => {
  const { servicingAccount: { point_of_sale_information, product } } = bannerProps as FullBannerProps

  const refundAmount = point_of_sale_information.refund_information?.amount?.cents

  return !!refundAmount && refundAmount < (product.amount.cents || 0);
}

const noValidPaymentMethods: (props: BannerNotificationsProps) => boolean = ({
  bannerProps: {
    identity: {
      bank_accounts,
      debit_cards
    }
  }
  }) => hasNoValidPaymentMethods(bank_accounts, debit_cards)

const isBannerAlreadyViewedOnce: (product_uuid: string, sessionStorageKey: string) => boolean = (product_uuid, sessionStorageKey) => {
  const data = JSON.parse(localStorage.getItem(sessionStorageKey) || '[]') as string[];
  return data.includes(product_uuid);
}

const shouldDisplaySessionBanner: (product_uuid: string, sessionStorageKey: string) => boolean = (product_uuid, sessionStorageKey) => {
  const singleSessionBanners = localStorage.getItem(sessionStorageKey);
  const jwt = localStorage.getItem(JWT_TOKEN_KEY) as string;
  const bannerSessionIdMap = JSON.parse(singleSessionBanners || '{}') as BannerSessionMap;
  return !bannerSessionIdMap[product_uuid] || bannerSessionIdMap[product_uuid] === jwt;
}

const hasDisputesToDisplay: (disputes: Array<ConsumerProductDispute | null>) => boolean = disputes => {
  const previouslyShownDisputes = JSON.parse(localStorage.getItem(disputeIdsKey) || '[]');
  const disputesToDisplay = disputes.filter(dispute =>
    dispute && (dispute.status !== DisputeStatuses.Closed || !previouslyShownDisputes?.includes(dispute.dispute_id))
  );

  return !!disputesToDisplay.length;
}

type LoanBannerConditionsType = {
  [key in LoanBannerTypes]: (props: BannerNotificationsProps) => boolean | null;
}

export const loanBannerConditions: LoanBannerConditionsType = {
  [LoanBannerTypes.bankruptcyAutopayOptOut]: props => {
    const { servicingAccount: { product }, identity } = props.bannerProps as FullBannerProps;
    const isActiveLoan = [
      ProductStatuses.Current,
      ProductStatuses.Late,
    ].includes(product.status as ProductStatuses);

    return isActiveLoan && product.is_autopay && identity.get_bankruptcy_claim_disqualifiers.opt_out_autopay_bk;
  },
  [LoanBannerTypes.bankruptcyDischarged]: props => props.bannerProps.identity.is_bankruptcy_discharged,
  [LoanBannerTypes.paidOff]: props => {
    const { servicingAccount: { product } } = props.bannerProps as FullBannerProps
    return product.status === ProductStatuses.PaidOff
  },
  [LoanBannerTypes.chargeOff]: showsChargedOff,
  [LoanBannerTypes.refunded]: isPOSFullRefund,
  [LoanBannerTypes.expiredVirtualCardSplitPay]: isExpiredAndSplitPay,
  [LoanBannerTypes.expiredVirtualCardNotSplitPay]: isExpiredAndNotSplitPay,
  [LoanBannerTypes.notUsedVirtualCardSplitPay]: isNotUsedSplitPay,
  [LoanBannerTypes.notUsedVirtualCardNotSplitPay]: isNotUsedAndNotSplitPay,
  [LoanBannerTypes.activePaymentProtectedDispute]: activePaymentProtectedDispute,
  [LoanBannerTypes.returnedPaymentPastDue]: props => {
    const { servicingAccount: { product } } = props.bannerProps as FullBannerProps
    return returnedPastDue(props) && shouldDisplaySessionBanner(product.product_uuid, returnedPastDueKey)
  },
  [LoanBannerTypes.pastDue]: isPastDue,
  [LoanBannerTypes.payoffQuote]: payoffQuote,
  [LoanBannerTypes.autoPayOff]: isCurrentAndAutoPayOff,
  [LoanBannerTypes.autoPayOn]: autoPayOn,
  [LoanBannerTypes.resolvedPaymentProtectedDispute]: props => {
    const { servicingAccount: { disputes } } = props.bannerProps as FullBannerProps
    return resolvedPaymentProtectedDispute(props) && hasDisputesToDisplay(disputes)
  },
  [LoanBannerTypes.resolvedPaymentProtectedMerchantFavor]: props => {
    const { servicingAccount: { disputes } } = props.bannerProps as FullBannerProps
    return resolvedPaymentProtectedMerchantFavor(props) && hasDisputesToDisplay(disputes)
  },
  [LoanBannerTypes.accountCredit]: props => {
    const { servicingAccount: { product } } = props.bannerProps as FullBannerProps
    return isPOSAccountCredit({...props}) && !isBannerAlreadyViewedOnce(product.product_uuid, accountCreditKey)
  },
  [LoanBannerTypes.outstandingAmount]: props => {
    const fullProps = props.bannerProps as FullBannerProps
    return fullProps.servicingAccount.product.has_balance_post_maturity
    && fullProps.servicingAccount.product.outstanding_balance.cents > 0
  },
  [LoanBannerTypes.noPaymentMethods]: noValidPaymentMethods,
  [LoanBannerTypes.hasBadPaymentMethod]: hasBadPaymentMethod,
  [LoanBannerTypes.returnedPayment]:  isReturnedPayment,
  [LoanBannerTypes.badPersonalInformation]: props => props.bannerProps.identity.has_bad_personal_information,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMostRecentPayment = (payments: any[]) => {
  return payments.reduce((mostRecent, item) => {
    return !mostRecent
      || (item?.eff_date
        && mostRecent?.eff_date
        && item.eff_date > mostRecent.eff_date) ? item : mostRecent;
  }, null);
};
