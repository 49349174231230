import React from 'react';
import Styled from 'styled-components';
import { Headline } from '@amount/frontend-components';

// NOTE: this file exists because we want to override this specific text size only for td.
// When we rework dash to use the same components that design agrees on, this should be removed.
const BaseProductIdentifierHeadline = Styled(Headline)`
  font-size: 2.0rem;
`;

const ProductIdentifierHeadline: React.FC = props => (
  <BaseProductIdentifierHeadline scale='small' as='h3'>
    {props.children}
  </BaseProductIdentifierHeadline>
);


export default ProductIdentifierHeadline;
