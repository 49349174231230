import * as React from 'react';
import startCase from 'lodash-es/startCase';

import { ApplicationProductType } from '../../../../constants';
import {
  FragmentSelectionOnCreditCardAccount,
  FragmentSelectionOnLoan,
  SelectionOnCustomerApplication
} from '../queries/GetAccountHistoryData.graphql';
import { formatDate } from '../../../../util/date';
import { InfoText } from '../common';
import { NoaaDownloader } from '../../NoaaDownloader';
import { isPointOfSaleProduct } from '../../../../util';

interface IApplicationProps {
  application: SelectionOnCustomerApplication;
  product?: FragmentSelectionOnCreditCardAccount | FragmentSelectionOnLoan;
}

const status: (
  application: SelectionOnCustomerApplication,
  product?: FragmentSelectionOnCreditCardAccount | FragmentSelectionOnLoan
) => string | null = (app, product) => {
  if (product) {
    return (product.isIssued) ? 'Approved'
      : (product.wasCanceled) ? 'Canceled'
      : null;

  } else {
    return (app.declined) ? 'Denied'
      : (!app.isOpen) ? 'Closed'
      : null;
  }
};

const productName: (app: SelectionOnCustomerApplication) => string | null = app => {
  const FormattedProductTypeMap: Map<string, string> = new Map([
    [ApplicationProductType.Loan, 'Loan'],
    [ApplicationProductType.Refinance, 'Loan'],
    [ApplicationProductType.CreditCard, 'Card'],
  ]);

  return !!app.productType &&  FormattedProductTypeMap.get(app.productType) || 'Product';
};

type IAppSourceProps = Pick<SelectionOnCustomerApplication, 'source' | 'merchantName' | 'productType'>;

export const getAppSource: (props: IAppSourceProps) => string | null = ({ source, merchantName, productType }) => {
  if (isPointOfSaleProduct(productType) && merchantName) {
    return merchantName;
  } else if (source) {
    return startCase(source);
  }

  return null;
};

export const Application: React.FC<IApplicationProps> = props => {
  const { application: app, product } = props;

  const statusText = status(app, product);
  const appSource = getAppSource({ source: app.source, merchantName: app.merchantName, productType: app.productType });

  return (
    <>
      <h3><strong>{productName(app)} Application {app.createdAt && `on ${formatDate(app.createdAt)}`}</strong></h3>
      {!!appSource &&
        <InfoText>Applied through {appSource}</InfoText>
      }
      <InfoText>ID #{app.id}</InfoText>
      {!!statusText &&
        <InfoText>{statusText}</InfoText>
      }
      {AvantConfig.TenantConfig.accountHistory.showNoaa &&
        app.adverseActionNotices.map(adverseActionNotice =>
          <NoaaDownloader key={adverseActionNotice.id} appUuid={app.uuid} noaaUuid={adverseActionNotice.id} />
        )
      }
    </>
  );
};
