import React from 'react';

import { ApplicationBaseRoutes, ServicingRoutes } from '../../../../../../routes';
import { LinkAndTextWrapper } from '../../../common';

import { PastDuePaymentLink, UUIDProps } from './common';

export const OutstandingBalance: React.FC<UUIDProps> = ({ uuid }) => (
  <LinkAndTextWrapper>
    <span>
      There is an outstanding balance on your account. Schedule a payment to pay off your loan and close your account.
    </span>
    <PastDuePaymentLink
      text='Payoff Loan'
      dataEvent='bannerLoanPayoff'
      destination={`${ApplicationBaseRoutes.loanServicing}/${uuid}${ServicingRoutes.quote}`}
    />
  </LinkAndTextWrapper>
);
