import Styled from 'styled-components';
import {
  BaseButton,
  primaryButton,
} from '@amount/frontend-components';

export const SupportCtaStyled = Styled.div`
  ${BaseButton};
  ${primaryButton};
  max-width: 24em;
  margin: 1em auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;
