import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import AccountSettings from '../../components/Common/AccountSettings';
import { GetCustomerHomeInformation } from '../queries/GetCustomerHomeInformation.graphql';
import SessionManager from '../../util/sessionManager';
import Session from '../../components/Common/Session';
import ServerError from '../../components/Common/Error/ServerError';
import NotFound from '../../components/Common/Error/NotFound';
import WorkflowViewer from '../../components/Common/WorkflowViewer';
import AccountHistory from '../../components/Common/AccountHistory';
import { IUUIDRouteProps, UpdateBankAccountWorkflowFormattedName, Workflows } from '../../constants';
import { AccountRoutes, ApplicationBaseRoutes } from '../../routes';
import { Content } from '../../components/Common/SkipLink';
import ServicingDashSubHeader from '../../components/Common/ServicingDashSubHeader';
import { withWarningBanner } from '../helpers/withWarningBanner';
import { DashWrapper } from '../../components/Common/DashWrapper';
import { IServicingRoute } from 'src/components/Common/ServicingDashSubHeader/types';

interface IAccountDashProps extends RouteComponentProps {
  data?: GetCustomerHomeInformation;
}

const SingleProductBankWorkflow = () =>
  <WorkflowViewer workflow={Workflows.UpdateSingleProductBankAccount} url={ApplicationBaseRoutes.account} />;

const MultiProductBankWorkflow = () =>
  <WorkflowViewer workflow={Workflows.UpdateMultiProductBankAccount} url={ApplicationBaseRoutes.account} />;

const PaymentPreferenceWorkflow: React.FC<RouteComponentProps<IUUIDRouteProps>> = ({ match }) => (
  <WorkflowViewer
    workflow={Workflows.UpdatePaymentPreference}
    url={ApplicationBaseRoutes.account}
    productType={'Loan'}
    productUUID={match.params.uuid}
  />
);

const IncomeAndHousingWorkflow: React.FC<RouteComponentProps<IUUIDRouteProps>> = ({ match }) => (
  <WorkflowViewer
    workflow={Workflows.IncomeAndHousing}
    url={ApplicationBaseRoutes.account}
  />
);

const ManageDebitCardWorkflow: React.FC<RouteComponentProps<IUUIDRouteProps>> = ({ match }) => (
  <WorkflowViewer
    workflow={Workflows.ManageDebitCard}
    url={ApplicationBaseRoutes.account}
  />
);

const getRouteList: () => IServicingRoute[] = () => [
  {
    icon: 'nav-settings',
    name: 'Account Settings',
    route: `${ApplicationBaseRoutes.account}`,
    dataEvent: 'navAccount'
  }
];

const getLastBreadcrumbValue: (path: string) => string | null = path => {
  if (path.includes(AccountRoutes.bank)) {
    return UpdateBankAccountWorkflowFormattedName;
  } else if (path.includes(AccountRoutes.paymentPreference)) {
    return Workflows.UpdatePaymentPreference.formattedName;
  } else if (path.includes(AccountRoutes.incomeAndHousing)) {
    return Workflows.IncomeAndHousing.formattedName;
  } else if (path.includes(AccountRoutes.manageDebitCard)) {
    return Workflows.ManageDebitCard.formattedName;
  } else {
    return null;
  }
};

const PresentationalAccountDashFC: React.FC<IAccountDashProps> = props => {
  const { data, match: { path, url }, location: { pathname } } = props;

  if (!data) {
    return <ServerError />;
  } else if (!data.me) {
    props.history.replace(SessionManager.urlRouter.loggedOut);
    return null;
  }

  const hasMultiProductEnabled = !!data.me.hasMultiProductEnabled;
  const updateBankPath: string = `${path}${AccountRoutes.bank}`;
  const updatePaymentPreferencePath: string = `${path}${AccountRoutes.paymentPreference}/:uuid`;
  const incomeAndHousingPath: string = `${path}${AccountRoutes.incomeAndHousing}`;
  const manageDebitCardPath: string = `${path}${AccountRoutes.manageDebitCard}`;

  const workflowName: string | null = getLastBreadcrumbValue(pathname.replace(url, ''));
  const exitLinkPath: string | undefined = workflowName && `${ApplicationBaseRoutes.account}` || undefined;
  const exitLinkText: string | undefined = workflowName && `Exit ${workflowName}` || undefined;

  return (
    <Session {...props} me={data.me}>
      <DashWrapper>
        <Route path={[updateBankPath, updatePaymentPreferencePath, incomeAndHousingPath]}>
          <ServicingDashSubHeader
            {...props}
            exitLinkPathFromProps={exitLinkPath}
            exitLinkTextFromProps={exitLinkText}
            tabsConfig={getRouteList()}
          />
        </Route>
        <Content>
          <Switch>
            <Route exact={true} path={path} component={AccountSettings} />
            <Route
              exact={true}
              path={updateBankPath}
              component={hasMultiProductEnabled ? MultiProductBankWorkflow : SingleProductBankWorkflow}
            />
            <Route exact={true} path={updatePaymentPreferencePath} component={PaymentPreferenceWorkflow} />
            <Route
              exact={true}
              path={`${ApplicationBaseRoutes.account}${AccountRoutes.history}`}
              component={AccountHistory}
            />
            <Route exact={true} path={incomeAndHousingPath} component={IncomeAndHousingWorkflow} />
            <Route exact={true} path={manageDebitCardPath} component={ManageDebitCardWorkflow} />
            <Route component={NotFound} />
          </Switch>
        </Content>
      </DashWrapper>
    </Session>
  );
}

export const AccountDash = withWarningBanner(PresentationalAccountDashFC, 'home');
