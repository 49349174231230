import React from 'react';
import { Query } from 'react-apollo';

import { Loading } from '../Spinner/LoadingHOC';
import PageTitle from '../PageTitle';

import GetAccountHistoryDataQuery, {
  GetAccountHistoryData,
} from './queries/GetAccountHistoryData.graphql';
import { AccountHistory } from './Presentational';
import { IAccountHistoryProps } from './common';

const LiftedAccountHistory: React.FunctionComponent<IAccountHistoryProps> = props => (
  <Query<GetAccountHistoryData> query={GetAccountHistoryDataQuery}>
    {({ data, loading }) => (
      <Loading loading={loading}>
        <PageTitle pageTitle={AvantConfig.TenantConfig.pageTitles.accountHistory} />
        <AccountHistory {...props} data={data} />
      </Loading>
    )}
  </Query>
);

export default LiftedAccountHistory;
