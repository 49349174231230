/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable arrow-body-style */
import React from 'react';

import { ProductCardWrapper } from '../styledComponents';
import { LoanContent } from './LoanContent';
import { LoanIdentifier } from './LoanIdentifier';
import {
  SelectionOnConsumerProduct,
  SelectionOnConsumerRepaymentInformation,
  SelectionOnVirtualCardToken,
  SelectionOnConsumerPayoffInformation,
  SelectionOnConsumerPayment1,
} from '../../queries/GetCustomerHomeG2.graphql';
import { canAccessServicingDashboard } from 'src/g2/_shared/utils/canAccessServicingDashboard';
import { CustomerHomeCardBox } from 'src/components/Common/Box';
import { LoanBanners } from 'src/g2/servicing/components/_shared/BannerNotifications/LoanBanner';
import { FullBannerProps } from 'src/g2/servicing/components/_shared/BannerNotifications/LoanBanner/types';

export interface LoanProductCardProps {
  servicingAccountUUID: string;
  hasUnresolvedPaymentProtectionDispute: boolean;
  product: SelectionOnConsumerProduct;
  futurePayments: Array<SelectionOnConsumerPayment1 | null>;
  virtualCard: SelectionOnVirtualCardToken | null;
  repaymentInformation: SelectionOnConsumerRepaymentInformation;
  payoffInfo: SelectionOnConsumerPayoffInformation;
  bannerProps: FullBannerProps;
}

export const LoanProductCard: React.FunctionComponent<LoanProductCardProps> = ({
  product,
  servicingAccountUUID,
  futurePayments,
  virtualCard,
  hasUnresolvedPaymentProtectionDispute,
  repaymentInformation,
  payoffInfo,
  bannerProps,
}) => {
  const isDashboardAccessible = canAccessServicingDashboard({ isVCUsed: !!virtualCard?.is_used, productStatus: product.status });

  return (
    <ProductCardWrapper key={product.product_uuid}>
      <LoanBanners
        servicingAccountUUID={servicingAccountUUID}
        bannerProps={bannerProps}
      />
      <CustomerHomeCardBox>
        <LoanIdentifier
          product={product}
          servicingAccountUUID={servicingAccountUUID}
          virtualCard={virtualCard}
          isDashboardAccessible={isDashboardAccessible}
        />
        <LoanContent
          hasUnresolvedPaymentProtectionDispute={hasUnresolvedPaymentProtectionDispute}
          product={product}
          futurePayments={futurePayments}
          repaymentInformation={repaymentInformation}
          payoffInfo={payoffInfo}
          virtualCard={virtualCard}
          isDashboardAccessible={isDashboardAccessible}
          opt_out_autopay_bk={bannerProps.identity.get_bankruptcy_claim_disqualifiers.opt_out_autopay_bk}
        />
      </CustomerHomeCardBox>
    </ProductCardWrapper>
  );
};
