import React from 'react';
import { Pill, VALID_COLOR } from '@amount/frontend-components';
import { startCase } from 'lodash';

import { InfoText } from '../../common';
import { formatDate } from '../../../../../util/date';
import { ILoanProps, ProductHeader } from '../common';

import { loanTitle } from './common';

const pill = (
  { product: { isPaidOff, isSoldOff, wasRefinanced, wasCanceled, isBankruptcyDischarged, status } }: ILoanProps
) => {
  const text = isSoldOff ? 'Sold Off'
    : (wasRefinanced || isBankruptcyDischarged) ? 'Closed'
    : isPaidOff ? startCase(status)
    : wasCanceled ? 'Canceled'
    : 'Active';

  const color: VALID_COLOR = (isPaidOff || wasCanceled || wasRefinanced || isBankruptcyDischarged)
    ? 'inactive'
    : 'secondary';

  return (
    <Pill pillColor={color}>{text}</Pill>
  );
};

const info = (
  { product: { wasRefinanced, isPaidOff, wasCanceled, paidOffDate, createdAt } }: ILoanProps
) => {
  const text = wasRefinanced ? 'Refinanced'
    : isPaidOff ? `Paid Off ${paidOffDate && formatDate(paidOffDate)}`
    : wasCanceled ? null
    : `Issued ${formatDate(createdAt)}`;

  return (
    <>
      {!!text && <InfoText>{text}</InfoText>}
    </>
  );
};

export const LoanDescription: React.FC<ILoanProps> = props => {
  const { product: { id } } = props;

  return (
    <>
      <ProductHeader>
        <strong>{loanTitle(props)}</strong>{pill(props)}
      </ProductHeader>
      {info(props)}
      <InfoText>ID #{id}</InfoText>
    </>
  );
};
