import React from 'react';

import { formatDate } from '../../../util/date';
import { ApplicationProductType, NA } from '../../../constants';
import { formatString } from '../../../util/string';
import { NoaaDownloader } from '../../Common/NoaaDownloader';
import { isPointOfSaleProduct } from '../../../util';

import {
  ApplicationCardContainer,
  CardLabelWrapper,
  FlexColumnIE,
  getFormattedProductName,
  IApplicationCardProps,
} from './common';

const title: (productType: string | null, merchantName: string | null, productName: string | undefined) => string
  = (productType, merchantName, productName) => {
  const { companyName, product: { loan: { name } } } = AvantConfig.TenantConfig;
  if (productType === ApplicationProductType.PointOfSale) {
    const merchantCallout = merchantName ? ` with ${merchantName}` : '';

    return `Thank you for your recent ${name} application${merchantCallout}.`;
  }
  if (productType === ApplicationProductType.CreditCard) {
    return `Thank you for your recent ${productName} application`;
  }

  return `Thank you for your recent ${companyName} ${getFormattedProductName(productType, merchantName)} application`;
};

export class DeclinedCard extends React.PureComponent<IApplicationCardProps> {
  public render () {
    const {
      application: { createdAt, productType, uuid, adverseActionNotices, merchantName }
    } = this.props;

    const productName = this.props.application.productName || '';

    const createdAtDisplay: string = createdAt ? formatDate(createdAt) : NA;
    const declinedApplicationText: string[] = AvantConfig.TenantConfig.customerHome.declinedApplicationText;

    const formattedProductName = isPointOfSaleProduct(productType) ? getFormattedProductName(productType, merchantName) :
    productType === ApplicationProductType.CreditCard ? productName :
    `${AvantConfig.TenantConfig.companyName} ${getFormattedProductName(productType, merchantName)}`

    return (
      <ApplicationCardContainer onDismiss={this.handleDismiss}>
        <FlexColumnIE>
          <CardLabelWrapper as='h3'>
            <strong>{title(productType, merchantName, productName)}</strong>
          </CardLabelWrapper>
          {
            Object.values(declinedApplicationText)
              .map((text, i) => (
                <p key={i}>
                  {
                    formatString(
                      text,
                      value => (value === createdAtDisplay ? <strong key={`strong-${i}`}>{value}</strong> : value),
                      createdAtDisplay,
                      formattedProductName
                    )
                  }
                </p>
              ))
          }
          {AvantConfig.TenantConfig.customerHome.showDeclinedNoaa &&
            adverseActionNotices.map(adverseActionNotice =>
              <NoaaDownloader key={adverseActionNotice.id} appUuid={uuid} noaaUuid={adverseActionNotice.id} />
            )
          }
        </FlexColumnIE>
      </ApplicationCardContainer>
    );
  }

  private readonly handleDismiss: (event: React.MouseEvent<HTMLButtonElement>) => void = event => {
    event.preventDefault();

    this.props.dismissApplication(this.props.application);
  }
}
