import React from 'react';

import { LoanBanner } from '.';
import { bankruptcyDischarge } from '../bannerStyles';

export const BankruptcyDischargedBanner: React.FC = () => (
  <LoanBanner style={bankruptcyDischarge}>
    <span>{AvantConfig.TenantConfig.bankruptcyDischargedMessage}</span>
  </LoanBanner>
);
