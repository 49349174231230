import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import GetAllSetVcnCardQuery, {
  GetAllSetVcnCard,
  GetAllSetVcnCardInput
} from '../../../../modules/queries/GetAllSetVcnCard.graphql';
import { Box } from '@amount/frontend-components';
import { Loading } from '../../../../components/Common/Spinner/LoadingHOC';
import { useHistory, useParams } from 'react-router';
import PageTitle from '../../../../components/Common/PageTitle';
import { AllSetVCNCard } from './Presentational';
import { DashWrapper } from '../../../../components/Common/DashWrapper';
import ServerError from '../../../../components/Common/Error/ServerError';
import { useMarqetaJs } from '../../utils/marqeta/useMarqetaJs';
import { ServicingRoutes } from '../../../../routes';
import { REFERRER_KEY_VCNLIST } from '../../../../constants';
import { SessionG2 } from '../../../_shared/modules/SessionG2';
import { IIdentityCustomer } from 'src/gql';

const messageDuration = 1500; // 1.5 seconds

const LiftedAllSetVCNCard: React.FC = () => {
  const { uuid: accountUUID } = useParams<{ uuid: string }>();
  const hasCardStyles: boolean = true;
  const history = useHistory();

  useEffect(() => {
    const currentUUIDList = JSON.parse(localStorage.getItem(REFERRER_KEY_VCNLIST) || '[]');
    const isValidUUID = currentUUIDList.length === 0 || !currentUUIDList.includes(accountUUID);

    if (isValidUUID) {
      currentUUIDList.push(accountUUID);
      localStorage.setItem(REFERRER_KEY_VCNLIST, JSON.stringify(currentUUIDList));
    } else {
      history.replace(ServicingRoutes.dashboard);
    }
  }, []);
  const [buttonState, setButtonState] = useState<'default' | 'success' | 'failed'>('default');

  const successCopyPanCallback = () => {
    setButtonState('success');
    setTimeout(() => setButtonState('default'), messageDuration);
  }

  const errorCopyPanCallback = () => {
    setButtonState('failed');
    setTimeout(() => setButtonState('default'), messageDuration);
  }

  const { data, error: queryError, loading: isQueryLoading } = useQuery<GetAllSetVcnCard, GetAllSetVcnCardInput>(GetAllSetVcnCardQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      uuid: accountUUID,
    },
  });

  const { isLoading: isMarqetaLoading, hasError: hasMarqetaError } = useMarqetaJs(
    data?.account_management?.virtual_card.access_token,
    successCopyPanCallback,
    errorCopyPanCallback,
    hasCardStyles,
  );

  if ((!isQueryLoading && !isMarqetaLoading)
    && (queryError
      || hasMarqetaError
      || !data?.account_management?.product?.merchant_name
      || !data?.account_management?.merchant_information?.merchant_domain
      || !data?.account_management?.virtual_card
    )
  ) {
    return <ServerError />;
  }

  return (
    <>
      {(isQueryLoading || isMarqetaLoading) &&
        <Loading loading={isQueryLoading || isMarqetaLoading} />
      }
      {/* Containers that MarqetaJS injects iframes into must be present in DOM */}
      <Box display="block" hidden={isQueryLoading || isMarqetaLoading}>
        <SessionG2 identity={data?.identity as IIdentityCustomer}>
          <DashWrapper
            hideDrawerOnDesktop={true}
            headerLinksConfig={AvantConfig.TenantConfig.header.themeTwo.links.verifications}
            centeredLogo={true}
          >
            <PageTitle pageTitle={AvantConfig.TenantConfig.pageTitles.vcnCard} />
            <AllSetVCNCard data={data} copyVcnButtonState={buttonState} />
          </DashWrapper>
        </SessionG2>
      </Box>
    </>
  );
};

export { LiftedAllSetVCNCard };
