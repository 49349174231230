export const useByString = ( use_by: string ) => {
  const formatUseBy = use_by.replace(/\b[-]{1}/g, '/');
  const useBy = new Date(formatUseBy);
  const month = useBy.toLocaleString('default', { month: 'long' });

  const time = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(useBy)
    .toLowerCase()

  const formattedTime = time.split(" ").join("")

  const timezone = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
  }).format(useBy)

  const abbreviatedTimeZone = timezone.substr(timezone.indexOf(',') +2)
  // We need the timezone in CT/PT format without Daylight or Standard
  // except for Hawaii as they do not observe daylight savings
  const formatTimeZone = ['HST'].includes(abbreviatedTimeZone) ? abbreviatedTimeZone : abbreviatedTimeZone.replace(/(S|D)T$/, 'T');

  const useByString = `${formattedTime} ${formatTimeZone} on ${month} ${useBy.getDate()}, ${useBy.getFullYear()}`;

  return useByString;
};
