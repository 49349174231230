import React from "react"
import { Banner } from "@amount/frontend-components";
import BannerTextWrapper from "./BannerTextWrapper";

interface IShutdownBannerProps {
  message: string;
}

export const ApplyShutdownBanner: React.FC<IShutdownBannerProps> = ({ message }) => (
  <Banner bannerStyle='inactive'>
    <BannerTextWrapper>
      {message}
    </BannerTextWrapper>
  </Banner>
)
