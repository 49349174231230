import Styled from 'styled-components';
import { media } from '@amount/frontend-components';
import { Link } from 'react-router-dom';

import { FragmentSelectionOnCreditCardAccount, FragmentSelectionOnLoan } from '../queries/GetAccountHistoryData.graphql';
import { FlexColumn } from '../../CommonStyles';

export const LinkContainer = Styled(FlexColumn)`
  padding-top: 0.5em;

  ${media.small`
    padding-top: 0;
  `}

  a, button, small {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    text-align: left;

    ${media.small`
      text-align: right;
      justify-content: flex-end;
    `}

    ${media.medium`
      white-space: nowrap;
    `}
  }

  svg {
    height: .8em;
    width: .8em;
    display: inline-flex;
    margin-left: 0.375em;
    fill: ${({ theme: { link } }) => link.colorLink};
    &:hover {
      fill: ${({ theme: { link } }) => link.colorLinkHover};
    }
  }
`;

export const ApplicationWell = Styled(FlexColumn)`
  border-left: 3px solid ${props => props.theme.colorSlate10};
  padding-left: 1em;
  margin-top: 0.5em;
`;

export const ProductHeader = Styled.h2`
  display: flex;
  align-items: center;
`;

export const HSBCLink = Styled(Link)`
  text-decoration: none
`;

export interface ILoanProps {
  product: FragmentSelectionOnLoan;
  rowIndex: number;
}

export interface ICreditCardProps {
  product: FragmentSelectionOnCreditCardAccount;
  rowIndex: number;
}
