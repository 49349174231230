import { differenceInMilliseconds, parse } from 'date-fns';
import * as Sentry from '@sentry/browser';
import Cookies from 'universal-cookie';

import { isNewCardDashQueryParamSet, isReactIntegrationEnvironment, JWT_TOKEN_KEY, LS_ENABLED_CARD_DASH, TOKEN_COOKIE } from '../util';

import UrlRouter from './urlRouter';
import { safeStringCompare } from './safeStringCompare';
import heapHelper from './heapHelper';
import { getQueryStringParams } from '.';

interface IJWTPayloadBase {
  exp: number;
  iat: number;
  apiBaseUrl?: string | null;
  customerID?: string;
  customerUUID?: string;
  customerApplicationUUID?: string;
  enableNewCardDash?: boolean;
}

enum SessionRedirectRoute {
  Apply = 'apply',
  MerchantDecline = 'merchant_decline',
}

interface IRedirectWithSession {
  requestData: {
    [key: string]: string;
    redirect_route: SessionRedirectRoute;
  };
  fallbackUrl?: string;
}

const safeDecode: <JWTPayload>(base64String: string) => JWTPayload | null = base64String => {
  try {
    return JSON.parse(atob(base64String));
  } catch (e) {
    return null;
  }
};

const cookies = new Cookies();

// tenant grace period
const WARNING_GRACE_PERIOD: number = AvantConfig.TenantConfig.logoutGracePeriod;

/**
 * 30 minutes in milliseconds
 */
const MAX_EXP_TIME: number = 1800000;
const MILLISECONDS: number = 1000;

// one minute in milliseconds
const UPDATE_STATUS_INTERVAL: number = 180000;

const getLogoutMS: (expirationTime: Date) => number = expTime => Math.min(
  Math.abs(differenceInMilliseconds(expTime, new Date())),
  MAX_EXP_TIME
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const DEFAULT_API_URL: string = process.env.REACT_APP_API_URL!;

export const WARNING_EVENT: 'timer:warning' = 'timer:warning';
export const EXPIRED_EVENT: 'timer:expired' = 'timer:expired';
export const UPDATE_STATUS_EVENT: 'interval:update-status' = 'interval:update-status';

type Events =
  | typeof WARNING_EVENT
  | typeof EXPIRED_EVENT
  | typeof UPDATE_STATUS_EVENT;
type Callbacks = { [event in Events]: Function[] };
type Timers = { [event in Events]: number | null };

class SessionManager<JWTPayload extends IJWTPayloadBase = IJWTPayloadBase> {
  private _token: string | null = null;
  private readonly _timers: Timers = {
    [EXPIRED_EVENT]: null,
    [UPDATE_STATUS_EVENT]: null,
    [WARNING_EVENT]: null,
  };
  private readonly _callbacks: Callbacks = {
    [EXPIRED_EVENT]: [],
    [UPDATE_STATUS_EVENT]: [],
    [WARNING_EVENT]: [],
  };
  private readonly _urlRouter: UrlRouter = new UrlRouter(DEFAULT_API_URL);
  private _customerHasInteracted: boolean = false;

  constructor () {
    const qsParams: { [key: string]: string | undefined } = getQueryStringParams(window.location.search);
    const newToken = qsParams.jwtToken || cookies.get(TOKEN_COOKIE);

    this.setToken(newToken || localStorage.getItem(JWT_TOKEN_KEY));
    this.removeTokenCookie();

    window.addEventListener('click', () => this.recordInteraction());
    window.addEventListener('keydown', () => this.recordInteraction());
  }

  public get token (): string | null {
    return this._token;
  }

  public get urlRouter (): UrlRouter {
    return this._urlRouter;
  }

  public get hasSession (): boolean {
    return !!this.token;
  }

  public setToken (token: string | null) {
    // Make sure we dont override a newer token with an older one.
    const newToken = this.ensureNewestToken(token);

    if (!newToken || newToken === this.token) { return; }
    this._token = newToken;
    this.setTimers();
    this.setApiBaseUrl();
    this.setHeapData();
    this.setEnableCardDash();
    localStorage.setItem(JWT_TOKEN_KEY, newToken);
  }

  public subscribe (event: Events, callback: Function) {
    if (!this._callbacks[event]) {
      return;
    }
    this._callbacks[event].push(callback);
  }

  public async refreshToken (): Promise<boolean> {
    try {
      const response = await fetch(
        this._urlRouter.refreshTokenUrl, {
          headers: {
            Accept: 'application/json',
            'X-Avant-Token': this.token || ''
          }
        }
      );
      const { refreshToken }: { refreshToken: string } = await response.json();
      if (!refreshToken) { throw new Error('Something went wrong'); }
      this.setToken(refreshToken);

      return true;
    } catch (e) {
      return false;
    }
  }

  public logout (): void {
    this.clearTimers();
    this._token = null;
    localStorage.removeItem(JWT_TOKEN_KEY);
    localStorage.removeItem(LS_ENABLED_CARD_DASH);
  }

  public redirectWithSession: (props: IRedirectWithSession) => void = async ({ requestData, fallbackUrl }) => {
    if (!this.token) {
      if (fallbackUrl) {
        window.location.assign(fallbackUrl);
      }

      return;
    }

    try {
      const response = await fetch(this.urlRouter.bootstrapSession, {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
          'Content-Type': 'application/json',
          'X-Amount-Token': this.token
        },
        credentials: 'include'
      });

      if (response.ok) {
        const data: { url: string } = await response.json();
        window.location.assign(data.url);
      } else if (fallbackUrl) {
        window.location.assign(fallbackUrl);
      }
    } catch (e) {
      Sentry.captureException(e);
      if (fallbackUrl) {
        window.location.assign(fallbackUrl);
      }
    }
  }

  public redirectToApplicationWithSession: (appInfo: { uuid: string; id: string }) => void = async ({ uuid, id }) => {
    this.redirectWithSession({
      requestData: {
        customerApplicationUUID: uuid,
        redirect_route: SessionRedirectRoute.Apply,
      },
      fallbackUrl: this.urlRouter.getApplicationUrl(id)
    });
  }

  public redirectToMerchantDeclineWithSession: (appInfo: { uuid: string; id: string }) => void = async ({ uuid, id }) => {
    this.redirectWithSession({
      requestData: {
        customerApplicationUUID: uuid,
        redirect_route: SessionRedirectRoute.MerchantDecline,
      },
      fallbackUrl: this.urlRouter.getMerchantDeclineUrl(id)
    });
  }

  public removeTokenCookie() {
    const info = this.extractJWTPayload();
    if (!info?.apiBaseUrl) {
      return;
    }

    const apiBaseUrl = info.apiBaseUrl;
    const { hostname } = new URL(apiBaseUrl);
    cookies.remove(TOKEN_COOKIE, { path: '/', domain: hostname });
  }

  private setTimers () {
    this.clearTimers();

    const info = this.extractJWTPayload();
    if (!info) {
      return;
    }

    const parsedTime: Date = parse(info.exp * MILLISECONDS);
    const expiredMS: number = getLogoutMS(parsedTime);
    const warnMS: number = expiredMS - WARNING_GRACE_PERIOD;

    const warnTimer = window.setTimeout(() => this.warningTimer(), warnMS);
    const expiredTimer = window.setTimeout(() => this.sessionExpired(), expiredMS);
    const updateStatusTimer = window.setInterval(
      () => this.updateInteractionStatus(),
      UPDATE_STATUS_INTERVAL,
    );

    this._timers[WARNING_EVENT] = warnTimer;
    this._timers[EXPIRED_EVENT] = expiredTimer;
    this._timers[UPDATE_STATUS_EVENT] = updateStatusTimer;
  }

  private extractJWTPayload (): JWTPayload | null {
    if (!this._token) {
      return null;
    }

   return this.decodeJWT(this._token);
  }

  private decodeJWT(token: string): JWTPayload | null {
    const b64: string = token.split('.')[1];

    return safeDecode<JWTPayload>(b64);
  }


  private ensureNewestToken(newToken: string | null): string | null {
    const existingToken = localStorage.getItem(JWT_TOKEN_KEY);
    if (!existingToken || !newToken) return newToken || existingToken;

    const oldJWT = this.decodeJWT(existingToken) ;
    const newJWT = this.decodeJWT(newToken);

    // If either tokens do not parse as a JWT return the other token.
    if (!newJWT) return existingToken;
    if (!oldJWT) return newToken;

    // return the token issued latest.
    return oldJWT.iat < newJWT.iat ? newToken : existingToken;
  }

  private warningTimer () {
    this._emitEvent(WARNING_EVENT);
  }

  private sessionExpired () {
    this._emitEvent(EXPIRED_EVENT);
  }

  private clearTimers (): void {
    const expiredTimer = this._timers[EXPIRED_EVENT];
    const warningTimer = this._timers[WARNING_EVENT];
    const updateStatusTimer = this._timers[UPDATE_STATUS_EVENT];
    if (expiredTimer) { window.clearTimeout(expiredTimer); }
    if (warningTimer) { window.clearTimeout(warningTimer); }
    if (updateStatusTimer) { window.clearInterval(updateStatusTimer); }
    this._timers[EXPIRED_EVENT] = null;
    this._timers[WARNING_EVENT] = null;
    this._timers[UPDATE_STATUS_EVENT] = null;
  }

  private _emitEvent (event: Events): void {
    this._callbacks[event].forEach(fn => fn());
  }

  private setEnableCardDash () {
    const info = this.extractJWTPayload();
    if (info && info.enableNewCardDash) {
      localStorage.setItem(LS_ENABLED_CARD_DASH, info.enableNewCardDash.toString());
    } else if (isReactIntegrationEnvironment() && isNewCardDashQueryParamSet()) {
      localStorage.setItem(LS_ENABLED_CARD_DASH, 'true');
    }
  }

  private setApiBaseUrl () {
    // If not in integration environment, skip url lookup
    if (!isReactIntegrationEnvironment()) {
      return;
    }

    const info = this.extractJWTPayload();
    if (!info || !info.apiBaseUrl || safeStringCompare(info.apiBaseUrl, this._urlRouter.apiBaseUrl)) {
      return;
    }

    this._urlRouter.apiBaseUrl = info.apiBaseUrl;
  }

  private setHeapData () {
    const info = this.extractJWTPayload();
    if (!info || !info.customerID) {
      return;
    }

    heapHelper.identify(info.customerID);
  }

  private recordInteraction(): void {
    this._customerHasInteracted = true;
  }

  private updateInteractionStatus() {
    if (this._customerHasInteracted) {
      this._emitEvent(UPDATE_STATUS_EVENT);
    }
    this._customerHasInteracted = false;
  }
}

const sessionManager: SessionManager = new SessionManager();

export default sessionManager;
