import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { devToolsEnabled } from '../../common';
import { ApplicationBaseRoutes } from '../../../../routes';
import { VerTools } from '../VerTools';
import { CustomerHomeTools } from '../CustomerHomeTools';

export const NoTools: React.FC = () => (
  <p>No dev tools for this page.</p>
);

export const ToolsSection: React.FC = () => {
  if (!devToolsEnabled()) {
    return null;
  }

  return (
    <Switch>
      <Route path={`${ApplicationBaseRoutes.verify}/:uuid`} component={VerTools} />
      {/* <Route path={`${ApplicationBaseRoutes.loanServicing}/:uuid`} component={LoanTools} /> */}
      {/* <Route path={`${ApplicationBaseRoutes.cardServicing}/:uuid`} component={CardTools} /> */}
      <Route path='/' component={CustomerHomeTools} />
      <Route component={NoTools} />
    </Switch>
  );
};
