import React from 'react';
import { Banner, SVGIcon, VALID_COLOR } from '@amount/frontend-components';

import BannerTextWrapper from '../../BannerTextWrapper';

import { Close } from './Close';

export interface ILoanBannerProps {
  style?: VALID_COLOR;
  onDismiss? (): void;
}

export const LoanBanner: React.FC<ILoanBannerProps> = ({ style = 'inactive', onDismiss, children }) => (
  <Banner bannerStyle={style}>
    <BannerTextWrapper>
      {children}
      {onDismiss && (
        <Close onClick={onDismiss}>
          <SVGIcon icon='close' />
        </Close>
      )}
    </BannerTextWrapper>
  </Banner>
);
