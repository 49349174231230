import React from 'react';
import Styled from 'styled-components';
import {
  SVGIcon,
  VALID_SVG_ICONS,
  theme
} from '@amount/frontend-components';

import { IContactInfo } from '../../../../customTypings/avant-config/tenantConfig';

import { SupportCtaStyled } from './SupportCtaStyled';
import { SupportButtonLink } from './SupportButtonLink';
import { isLoan, isCreditCard } from '@amount/frontend-product-utilities';
import {
  FragmentSelectionOnLoan,
  GetCustomerAndAppID,
  SelectionOnCustomer,
  SelectionOnCustomerApplication
} from '../queries/GetCustomerAndAppId.graphql';
import { isPointOfSaleProduct, isInstallmentLoanProduct, isCreditCardProduct } from '../../../../util';
import { IModalProps } from '../../Modal';

export const ModalColumn = Styled.div`
  margin-bottom: 1em;
  font-size: ${!!AvantConfig.TenantConfig.support.hasUniqueSpecialistTextFormatting ?
    theme.themeTwo.global.font.size.bodyMedium : ''};
`;

export const SpecialistText = Styled.dd`
  margin: 0;
  text-align: ${!!AvantConfig.TenantConfig.support.hasUniqueSpecialistTextFormatting ? 'left' : 'center'}
  line-height: ${!!AvantConfig.TenantConfig.support.hasUniqueSpecialistTextFormatting ? '1.8em' : ''};
`;

const IconCta = Styled(SVGIcon)`
  width: 20px;
  height: 20px;
  margin: 0 0.5em 0 0;
  fill: ${props => props.theme.colorWhite};

  svg {
    height: 100%;
    width: 100%;
  }
`;
export interface ISupportModalProps extends IModalProps {
  data?: GetCustomerAndAppID;
}

export interface IGetSupportContactInfoProps {
  app: SelectionOnCustomer['getApplication'];
  product: SelectionOnCustomer['getProduct'];
}

interface ISupportLinkProps {
  dataEvent: string;
  href: string;
  icon: VALID_SVG_ICONS;
  content: string;
}

const SupportLink: React.FunctionComponent<ISupportLinkProps> = ({
  dataEvent,
  href,
  icon,
  content
}) => (
  <SupportButtonLink data-event={dataEvent} href={href}>
    <SupportCtaStyled>
      <IconCta icon={icon} />
      <span>{content}</span>
    </SupportCtaStyled>
  </SupportButtonLink>
);

export interface ISupportContentProps {
  contactInfo: IContactInfo;
  showContactInfoFirst: Boolean;
}

export const ContactButtons: React.FunctionComponent<ISupportContentProps> = ({
  contactInfo,
  showContactInfoFirst
}) => {
  const emailLink = (
    <SupportLink
      dataEvent='supportEmail'
      href={`mailto:${contactInfo.email}`}
      icon='email-outline'
      content={contactInfo.email}
    />
  );

  const phoneLink = (
    <SupportLink
      dataEvent='supportPhone'
      href={`tel:${contactInfo.phoneNumber.dialable}`}
      icon='phone'
      content={contactInfo.phoneNumber.readable}
    />
  );

  return !showContactInfoFirst ?
    (
      <>
        {phoneLink}
        {emailLink}
      </>
    )
    :
    (
      <>
        {emailLink}
        {phoneLink}
      </>
    );
};

const getAppInfo: (app: SelectionOnCustomerApplication) => IContactInfo | undefined = app => {
  if (isPointOfSaleProduct(app.productType)) {
    return AvantConfig.TenantConfig.support.verificationContactInfo?.pointOfSale;
  } else if (isInstallmentLoanProduct(app.productType)) {
    return AvantConfig.TenantConfig.support.verificationContactInfo?.loan;
  } else if (isCreditCardProduct(app.productType)) {
    return AvantConfig.TenantConfig.support.verificationContactInfo?.creditCard;
  }

  return undefined;
};


const getLoanInfo: (product: FragmentSelectionOnLoan) => IContactInfo | undefined = product => {
  if (!!product.pointOfSaleInformation) {
    return AvantConfig.TenantConfig.support.servicingContactInfo?.pointOfSale;
  }

  return AvantConfig.TenantConfig.support.servicingContactInfo?.loan;
};


const getAppOrProductSpecificInfo: (props: IGetSupportContactInfoProps) => IContactInfo | undefined = ({ app, product }) => {
  if (app) {
    return getAppInfo(app);
  } else if (!!product) {
    if (isLoan(product)) {
      return getLoanInfo(product);
    } else if (isCreditCard(product)) {
      return AvantConfig.TenantConfig.support.servicingContactInfo?.creditCard;
    }
  }

  return undefined;
};


export const getSupportContactInfo: (props: IGetSupportContactInfoProps) => IContactInfo = props => {
  const { phoneNumber, email, specialistText: contactHoursText } = AvantConfig.TenantConfig.support;
  const supportPhoneNumber = phoneNumber ?? { readable: '', dialable: '', };
  const defaultInfo: IContactInfo = { phoneNumber: supportPhoneNumber, email, contactHoursText }

  return getAppOrProductSpecificInfo(props) || defaultInfo;
};
