/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  IConsumerPayment,
  IMoney,
  PaymentAllocationType,
} from "src/gql";
import { NextPaymentInfo } from "src/g2/servicing/components/AccountManagementDash/Dash/NextPayment/utils/dataEntriesProps";
import {
  SelectionOnConsumerPayment1 as CustomerHomeDashG2Payment
} from "src/g2/_shared/components/CustomerHomeDashG2/queries/GetCustomerHomeG2.graphql";
import { SelectionOnConsumerPayment1 as AccountManagementPayment } from "src/modules/queries/GetAccountManagement.graphql";

type Payment = Pick<IConsumerPayment, 'eff_date' | 'amount'>;
type Money = Pick<IMoney, 'formatted' | 'amount'>;
type FuturePayment = Pick<IConsumerPayment, 'type' | 'is_installment' | 'eff_date'>;

const processPastDueInstallmentDate = ({
  allFuturePayments,
}: {
  allFuturePayments: FuturePayment[];
}): string | null => {
  const lastPastDuePaymentIndex: number = allFuturePayments
    .map((payment: FuturePayment) => payment.type)
    .lastIndexOf(PaymentAllocationType.towards_past_due);
  const installmentsAfterPastDue = allFuturePayments
    .slice(lastPastDuePaymentIndex + 1)
    .filter((payment: FuturePayment) => payment.is_installment);
  if (lastPastDuePaymentIndex === -1 || !installmentsAfterPastDue.length) {
    return allFuturePayments[0]?.eff_date || null;
  }
  return installmentsAfterPastDue[0].eff_date;
};

type AllowedPaymentTypes = CustomerHomeDashG2Payment | AccountManagementPayment;

export function getNextPaymentInfo ({
  nextNonZeroInstallmentPayment,
  nextInstallmentAmount,
  nextInstallmentOriginalAmount,
  nextInstallmentDate,
  nextPaymentScheduled,
  isPastDue,
  pastDueAmount,
  allFuturePayments,
}: {
  nextNonZeroInstallmentPayment: Payment | null;
  nextInstallmentAmount: Money | null;
  nextInstallmentOriginalAmount: Money | null;
  nextInstallmentDate: string | null;
  nextPaymentScheduled: boolean;
  isPastDue: boolean;
  pastDueAmount: Money | null;
  allFuturePayments: Array<AllowedPaymentTypes | null>;
}): NextPaymentInfo {
  const processedNextInstallmentDate = isPastDue && pastDueAmount?.amount === 0
    ? processPastDueInstallmentDate({
        allFuturePayments: allFuturePayments.filter((payment): payment is AllowedPaymentTypes => payment !== null),
      })
    : nextInstallmentDate;
  if (
    nextInstallmentAmount &&
    processedNextInstallmentDate &&
    nextInstallmentAmount?.amount !== 0 &&
    nextInstallmentAmount?.amount !== nextInstallmentOriginalAmount?.amount
  ) {
    return {
      amountFormatted: nextInstallmentAmount.formatted,
      date: processedNextInstallmentDate,
      paymentScheduled: false,
    };
  } else if (nextInstallmentOriginalAmount && processedNextInstallmentDate && nextPaymentScheduled) {
    return {
      amountFormatted: nextInstallmentOriginalAmount?.formatted,
      date: processedNextInstallmentDate,
      paymentScheduled: true,
    };
  }

  if (nextNonZeroInstallmentPayment?.eff_date && nextNonZeroInstallmentPayment?.amount) {
    return {
      amountFormatted: nextNonZeroInstallmentPayment.amount.formatted,
      date: nextNonZeroInstallmentPayment.eff_date,
      paymentScheduled: false,
    };
  }

  return null;
}
