import React from 'react';
import { HeaderDesktopOnlyLinkContainer, HeaderIcon, HeaderMobileOnlyLinkContainer } from '@amount/frontend-components';

import { IDrawerAnchor } from '.';

export const DrawerAnchor: React.FC<IDrawerAnchor> = ({ iconOpen, iconClosed, drawerOpen, breakPoint }) => (
  <>
    <HeaderDesktopOnlyLinkContainer breakPoint={breakPoint}>
      My Account
      <HeaderIcon open={drawerOpen} />
    </HeaderDesktopOnlyLinkContainer>
    <HeaderMobileOnlyLinkContainer breakPoint={breakPoint}>
      {
        iconOpen && iconClosed ?
          <HeaderIcon open={drawerOpen} iconOpen={iconOpen} iconClosed={iconClosed} iconColor='brandText' /> :
          <HeaderIcon open={drawerOpen} />
      }
    </HeaderMobileOnlyLinkContainer>
  </>
);
