import React from 'react';

import { usePaymentMethodsLink } from '../utils/usePaymentMethodsLink';
import { LoanBanner } from '.';
import { BannerBaseProps } from '../index';
import { BannerLinkAndTextWrapper } from '../../common';
import { BannerLink } from '../../../../../../../_shared/ThemeTwo/Link/BannerLink';

const { bannerMessages: { badBankOrBadDebit }, bannerLinks: { paymentMethods } } = AvantConfig.TenantConfig.accountManagementDash;

export const BadPaymentMethodBanner: React.FC<BannerBaseProps> = ({variant}) => {
  const { isPaymentMethodsPage, paymentMethodsPath } = usePaymentMethodsLink();

  return (
    <LoanBanner variant={variant}>
      <BannerLinkAndTextWrapper>
        <span>{badBankOrBadDebit}</span>

        {!isPaymentMethodsPage &&
          <BannerLink
            event="paymentMethods"
            to={paymentMethodsPath}
          >
            {paymentMethods}
          </BannerLink>
        }
      </BannerLinkAndTextWrapper>
    </LoanBanner>
  );
};
