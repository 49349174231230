import React from 'react';

import { PastDuePaymentLink } from './common';
import { BannerNotificationsProps } from '../../index';
import { BannerLinkAndTextWrapper } from '../../../common';
import { formatString } from '../../../../../../../../util/string';
import { LoanBanner } from '..';
import { FullBannerProps } from '../../types'
import { createAccountManagementPath } from 'src/routes';
import { ServicingTasks } from '@Constants';
import { useServicingTaskRedirect } from 'src/g2/servicing/components/ServicingTasks/utils/useServicingTaskRedirect';

const { outstandingAmount } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages

export const OutstandingBalanceBanner: React.FC<BannerNotificationsProps> = ({
  bannerProps,
  variant,
  servicingAccountUUID
}) => {
  const { getTaskLocation } = useServicingTaskRedirect();
  // non-null-assertion handled by checks on respective parent queries
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const makePaymentPath = createAccountManagementPath(servicingAccountUUID!, ServicingTasks.MakePayment.route);

  const {servicingAccount: { product }} = bannerProps as FullBannerProps
  const outstandingBalance = product.outstanding_balance.formatted;

  return (
    <LoanBanner variant={variant}>
      <BannerLinkAndTextWrapper>
        <span>
          {formatString(outstandingAmount, undefined, outstandingBalance)}
        </span>
        {!product.is_make_payment_disqualified && (
          <PastDuePaymentLink
            text='Schedule Payment'
            dataEvent='bannerLoanPayoff'
            destination={getTaskLocation(makePaymentPath)}
          />
        )}
      </BannerLinkAndTextWrapper>
    </LoanBanner>
  );
}
