import React from 'react';
import {
  Box, Heading, Text, TooltipLink, WithTooltipContainer
} from '@amount/frontend-components';
import VCNCard from './VcnCard';
import { formatString } from '../../../../../util/string';
import { formatDateWithTimeZone } from '../utils/formatDateWithTimeZone';
import { formatUrl } from '../utils/formatUrl';
import { GetAllSetVcnCard } from '../../../../../modules/queries/GetAllSetVcnCard.graphql';
import { CopyVcnButton } from './CopyVcnButton';
import { RouterLink } from '../../../../../_shared/ThemeTwo/Link/router-link.component';
import { ApplicationBaseRoutes } from 'src/routes';

const {
  accountInfoContent,
  amountContent,
  useByContent,
  header,
  linkText,
  merchantDomainContent,
  tooltipMessage
} = AvantConfig.TenantConfig.allSetVcnCard;

const {
  buttonText,
  successMessage,
  failureMessage,
} = AvantConfig.TenantConfig.allSetVcnCard.copyVcnButton.button;

export interface IAllSetVCNCard {
  data?: GetAllSetVcnCard;
  copyVcnButtonState: 'default' | 'success' | 'failed';
}

const AllSetVCNCard: React.FC<IAllSetVCNCard> = ({
  data,
  copyVcnButtonState
}) => {
  const amount = data?.account_management?.virtual_card?.credit_amount.formatted;
  const mDomain = data?.account_management?.merchant_information?.merchant_domain;
  const mName = data?.account_management?.product?.merchant_name;
  const useBy = data?.account_management?.virtual_card.use_by;

  return (
    <Box justifyContent='center' direction='column' overflow='hidden'>
      <Box justifyContent='center' margin={{ vertical: '4x' }}>
        <Heading.h1 align='center'>{header}</Heading.h1>
      </Box>
      <Box
        justifyContent='center'
        height='auto'
        margin={{
          medium: { bottom: '9x' }
        }}
      >
        <Box
          display='block'
          backgroundColor='white'
          padding={{
            bottom: '5x',
            base: { top: '3x', horizontal: '2x' },
            medium: { top: '4x', horizontal: '3x' }
          }}
          borderRadius='quadruple'
          width={{
            base: '100%',
            medium: 'narrow'
          }}
        >
          <VCNCard
            merchantName={mName}
          />
          <Box
            display='block'
            margin={{ base: { horizontal: '1x' } }}
            padding={{ medium: { horizontal: '2x' }, base: { horizontal: '0' } }}
          >
            <Text display='block' margin={{ bottom: '2x' }}>
              {
                formatString(
                  merchantDomainContent, undefined, <strong data-testid='merchantDomain'>{mDomain && formatUrl(mDomain)}</strong>
                )
              }
            </Text>
            <Box margin={{ bottom: 'double' }}>
              <Text>
                <WithTooltipContainer>
                  {formatString(amountContent, undefined, <strong data-testid='amount'>{amount}</strong>)}
                  <TooltipLink tooltipId='vcnCard' srText={tooltipMessage} data-testid='tooltip'>
                    {formatString(tooltipMessage, undefined, <strong>{amount}</strong>)}
                  </TooltipLink>
                </WithTooltipContainer>
              </Text>
            </Box>
            <Box justifyContent='center'>
              <Box
                id='mq-copy-pan-container'
                margin={{base: { top: '3x', bottom: '7x', }, medium: { top: '4x', bottom: '7x' } }}
                height='48px'
                width={{ base: 'full', medium: '13.75em' }}
                position='relative'
                direction='column'
              >
                <CopyVcnButton
                  buttonState={copyVcnButtonState}
                  variant='secondary'
                  buttonText={buttonText}
                  successMessage={successMessage}
                  failureMessage={failureMessage}
                />
              </Box>
            </Box>
            <Text data-testid='useByTime'>
              {formatString(useByContent, undefined, useBy && formatDateWithTimeZone(useBy), mDomain && formatUrl(mDomain))}
            </Text>
            <Text data-testid='accountInfoContent' display='block' margin={{ top: '2x' }}>
              {accountInfoContent}
              <RouterLink.inline to={ApplicationBaseRoutes.baseRoute} target='_blank'>{linkText}</RouterLink.inline>
            </Text>
          </Box>
        </Box>
      </Box>
    </Box >
  );
};

export { AllSetVCNCard };
