import React, { useState } from 'react';

import { BannerNotificationsProps } from '../types';
import { generateLocalStorageKeyWithId, getLocalStorageInfo, ILocalStorageInfo } from '../../../../../util';

import { LoanBanner } from '.';

const hideRefinanceBannerKey: string = 'hideRefinanceBanner';

type RefinancedBannerProps = Pick<BannerNotificationsProps, 'isDismissable' | 'uuid'>;
export const RefinancedBanner: React.FC<RefinancedBannerProps> = ({ isDismissable, uuid }) => {
  const LS_HIDE_REFINANCE_BANNER_KEY = generateLocalStorageKeyWithId(hideRefinanceBannerKey, uuid);
  const hideRefinanceBanner: ILocalStorageInfo = getLocalStorageInfo(LS_HIDE_REFINANCE_BANNER_KEY);

  const [hideRefinance, setHideRefinance] = useState(hideRefinanceBanner.isTrue());

  if (hideRefinance) {
    return null;
  }

  const onDismiss: () => void = () => {
    hideRefinanceBanner.setTrue();
    setHideRefinance(true);
  };

  return (
    <LoanBanner onDismiss={isDismissable ? onDismiss : undefined}>
      <span>This loan has been refinanced. Your loan details and payment schedule have been updated.</span>
    </LoanBanner>
  );
};
