import React from 'react';
import { Link } from 'react-router-dom';
import { SVGIcon } from '@amount/frontend-components';

import { ApplicationBaseRoutes } from '../../../../../routes';
import { ICreditCardProps } from '../common';
import { useNewCardDash } from '../../../../../util';

export const CreditCardDashLink: React.FC<ICreditCardProps> = ({ product, rowIndex }) => {
  const { uuid, isActive } = product;

  return (
    <>
      {useNewCardDash() && isActive && (
        <Link
          to={`${ApplicationBaseRoutes.cardServicing}/${uuid}`}
          data-event={`cardDashboard-${rowIndex}`}
        >
          Credit Card Dashboard
          <SVGIcon icon='view-new-window' />
        </Link>
      )}
    </>
  );
};
