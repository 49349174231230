import React from 'react';

import { LoanBanner } from '.';
import { BannerNotificationsProps } from '../index';
import { BannerLinkAndTextWrapper } from '../../common';
import { tenantConfigToString } from '@Util/string';
import { BannerLink } from '../../../../../../../_shared/ThemeTwo/Link/BannerLink';
import { FullBannerProps } from '../types';
import { ServicingTasks } from '@Constants';
import { createAccountManagementPath } from 'src/routes';
import { formatDateWithFullMonthName } from '@Util/date';
import { useServicingTaskRedirect } from 'src/g2/servicing/components/ServicingTasks/utils/useServicingTaskRedirect';

const { bannerMessages: { currentAccountNoAutopay }, bannerLinks: { schedulePayment } } = AvantConfig.TenantConfig.accountManagementDash;

export const AutoPayOffBanner: React.FC<BannerNotificationsProps> =
  ({ variant, servicingAccountUUID, bannerProps }) => {
  const {servicingAccount: { product }} = bannerProps as FullBannerProps
  const { getTaskLocation } = useServicingTaskRedirect();
  // non-null-assertion handled by checks on respective parent queries
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const makePaymentPath = createAccountManagementPath(servicingAccountUUID!, ServicingTasks.MakePayment.route);

  return (
    <LoanBanner variant={variant}>
      <BannerLinkAndTextWrapper>
        <span>
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            tenantConfigToString(currentAccountNoAutopay, [formatDateWithFullMonthName(product.next_installment_date!)])
          }
        </span>
        {!product.is_make_payment_disqualified && (
          <BannerLink
            event='bannerAutoPayOff'
            to={getTaskLocation(makePaymentPath)}
          >
            {schedulePayment}
          </BannerLink>
        )}
      </BannerLinkAndTextWrapper>
    </LoanBanner>
  );
};
