import React from "react";
import {
  Text,
  Card,
  CardLabel,
  media,
} from '@amount/frontend-components';
import Styled from 'styled-components';

const copy = AvantConfig.TenantConfig.customerHome.noActivePlansCard;

const NoProductCard = Styled(Card)`
  border: 0;
  padding: 2.4rem;
  border-radius: 0;


  ${media.medium`
    padding: 3.2rem;
    border-radius: 0.4rem;
  `}
`;

const CardLabelWrapper = Styled(CardLabel)`
  line-height: 1.2;
  margin-bottom: 0.8rem;
`;

export const NoActivePlansCard: React.FC = () => (
  <NoProductCard>
    <CardLabelWrapper>
      {copy.header}
    </CardLabelWrapper>
    <Text
      size='body'
      lineHeight='1.5'
      >
      {copy.subheader}
    </Text>
  </NoProductCard>
);
