import React from 'react';
import Styled from 'styled-components';
import { media } from '@amount/frontend-components';

import Header, { DrawerLinks, HeaderLinks, IDrawerIcon, IHeaderProps } from './common';

const HeaderBadge = Styled.div`
  width: 50.76px;
  height: 45.57px
  margin-left: 1em;

  svg {
    display: block;
  }

  ${media.small`
    width: 142px;
    height: 45px;
  `}
`;

const TDBadge: React.ReactNode = (
  <HeaderBadge>
    <picture>
      {/* eslint-disable  @typescript-eslint/no-require-imports */}
      <source
        srcSet={require('@amount/frontend-components/src/images/partners/td/logo/logo-td-bank.svg')}
        media='(min-width: 600.1px)'
      />
      <img
        src={require('@amount/frontend-components/src/images/partners/td/logo/logo-td-bank.svg')}
        srcSet={require('@amount/frontend-components/src/images/partners/td/logo/logo-td-shield.svg')}
        alt='TD Bank Badge'
      />
      {/* eslint-enable */}
    </picture>
  </HeaderBadge>
);

const loanHeaderLinks: HeaderLinks[] = [
  HeaderLinks.Faq,
  HeaderLinks.SupportLink,
];

const posHeaderLinks: HeaderLinks[] = [
  HeaderLinks.ReturnToMerchantLink,
  HeaderLinks.SupportLink,
];

const defaultDrawerLinks: DrawerLinks[] = [
  DrawerLinks.SupportLink,
  DrawerLinks.Home,
  DrawerLinks.AccountSettings,
  DrawerLinks.AccountHistory,
  DrawerLinks.Logout,
];

const posDrawerLinks: DrawerLinks[] = [
  DrawerLinks.ReturnToMerchantLink,
  ...defaultDrawerLinks
];

const loanDrawerLinks: DrawerLinks[] = [
  DrawerLinks.Faq,
  ...defaultDrawerLinks
];

const headerLinks: (isPointOfSale: boolean) => HeaderLinks[] = isPointOfSale => (
  isPointOfSale ? posHeaderLinks : loanHeaderLinks
);

const drawerLinks: (isPointOfSale: boolean) => DrawerLinks[] = isPointOfSale => (
  isPointOfSale ? posDrawerLinks : loanDrawerLinks
);

const TDHeader: React.FunctionComponent<IHeaderProps> = props => {
  const hLinks: HeaderLinks[] = headerLinks(!!props.pointOfSaleInfo?.merchantName);
  const dLinks: DrawerLinks[] = drawerLinks(!!props.pointOfSaleInfo?.merchantName);

  const drawerIcon: IDrawerIcon = {
    iconOpen: 'close',
    iconClosed: 'menu'
  };

  return (
    <Header
      rightLogo={TDBadge}
      headerLinks={hLinks}
      drawerLinks={dLinks}
      drawerIcon={drawerIcon}
      linksBreakPoint='medium'
      applicationId={props.applicationId}
      applicationUuid={props.applicationUuid}
      {...props}
    />
  );
};

export default TDHeader;
