import React from 'react';
import Styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, media, SVGIcon } from '@amount/frontend-components';

import { FlexColumn, FlexRow } from '../Common/CommonStyles';
import { ApplicationBaseRoutes } from '../../routes';
import sessionManager from '../../util/sessionManager';
import { useNewCardDash } from '../../util';

export enum ProductStatuses {
  Current = 'current',
  Late = 'late',
  PaidOff = 'paid_off',
  ChargedOff = 'charged_off',
  Applied = 'applied',
  Approved = 'approved',
  Cancelled = 'cancelled',
  Authorization = 'authorized',
  InDefault = 'in_default',
  Transferred = 'transferred'
}

export const CardContainer = Styled.div`
  margin-bottom: 0.75em;
`;

export const HalfContainer = Styled(FlexColumn)`
  flex: 1 1 50%;
`;

export const Divider = Styled.div`
  background-color: ${props => props.theme.colorSlate10};
  margin: 1.5em 0;
  height: 1px;
  width: 100%;

  ${media.large`
    height: auto;
    margin: 0em 1.5em;
    width: 1.5px;
  `}
`;

export const ViewButtonWrapper = Styled(Button)`
  margin: 0.5em 0 0 0.5em;
  white-space: nowrap;
`;

export const ButtonWrapper = Styled.div`
  button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1.25em;
  }
`;

export const ProductInfoWrapper = Styled(FlexRow)`
  flex-wrap: wrap;

  ${media.large`
    flex-wrap: nowrap;
  `}
`;

export const ProductCardWrapper = Styled.div<{ width?: string}>`
  margin-bottom: 1.5em;
  width: ${props => props.width};
`;

export const CardContentPaddingContainer = Styled.div`
  padding: 1.5em 2em;
`;

export const StyledProductDetails = Styled(FlexColumn)<{ marginBottom?: string }>`
  justify-content: space-between;
  margin-bottom: ${props => props.marginBottom};

  ${media.small`
    flex-direction: row;
  `}

  button {
    display: flex;
    justify-content: center;
    min-width: 7em;
  }
`;

export const StyledViewArrowSVG = Styled(SVGIcon)`
  svg {
    display: flex;
    height: 1.2em;
    margin-left: 0.25em;
    margin-right: 0em;
    width: 0.8125em;
  }`;

StyledProductDetails.defaultProps = {
  marginBottom: '1.5em'
};

interface IViewCardProps {
  uuid: string;
}

export const ViewCardLink: React.FunctionComponent<IViewCardProps> = ({
  uuid,
  children
}) => (
  useNewCardDash()
    ? <Link data-event='viewCreditCard' tabIndex={-1} to={`${ApplicationBaseRoutes.cardServicing}/${uuid}`}>{children}</Link>
    : <a data-event='viewCreditCard' tabIndex={-1} href={sessionManager.urlRouter.getCardDashboardUrl(uuid)}>{children}</a>
);
