import React from "react";
import { HeaderLink } from "@amount/frontend-components";

import SessionManager from "@Util/sessionManager";
import { HeaderLinkProps } from "../constants";

export const LogOut: React.FC<HeaderLinkProps> = ({ variant, linkLabel, onClickCallback }) => (
  <HeaderLink
    href={SessionManager.urlRouter.logoutUrl}
    onClick={() => {
      onClickCallback && onClickCallback();
      SessionManager.logout();
      window.location.assign(SessionManager.urlRouter.logoutUrl);
    }}
    variant={variant}
  >
    {linkLabel}
  </HeaderLink>
);
