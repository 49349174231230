import Styled from 'styled-components';
import { Datapoint } from '@amount/frontend-components';

export enum CardBrand {
  AVANT = 'avant',
  MLS = 'mls',
}

export const DatapointHeader = Styled(Datapoint)`
  margin: 0;
`;
