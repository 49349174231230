import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { NavButton } from '@amount/frontend-components';

import { TakeoverLoader } from '../TakeoverLoader';
import { devToolsEnabled, IProductUuid } from '../../common';
import { devToolsClient } from '../../devToolsApolloClient';

import ApproveProductMutation, {
  ApproveProduct as ApproveProductData,
  ApproveProductInput
} from './queries/ApproveProduct.graphql';

export const ApproveProduct: React.FC<IProductUuid> = ({ uuid }) => {
  if (!devToolsEnabled()) {
    return null;
  }
  const [approveProduct, { loading }] = useMutation<ApproveProductData, ApproveProductInput>(
    ApproveProductMutation,
    {
      variables: { input: { customerApplicationUUID: uuid }},
      client: devToolsClient,
    },
  );

  const onClick = async () => {
    const response = await approveProduct();

    if (response.data?.approveProduct?.success) {
      window.location.reload();
    } else {
      alert('Failed to approve product, see backend error log');
    }
  };

  return (
    <>
      {
        loading && (
          <TakeoverLoader />
        )
      }
      <NavButton onClick={onClick}>
        Approve Product and Skip Ver
      </NavButton>
    </>
  );
};
