'use strict';

require('babel-polyfill');

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Number.prototype.toLocaleString() polyfill
require ('./Number.prototype.toLocaleString-polyfill');

// Object.setPrototypeOf polyfill
require('./Object.setPrototypeOf-polyfill');

// picturefill for picture/srcSet
require('picturefill');

// new Url() ie11
require('url-polyfill');
