import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import GetAllSetVcnCardQuery, {
  GetAllSetVcnCard,
  GetAllSetVcnCardInput
} from '../../../../modules/queries/GetAllSetVcnCard.graphql';
import { VCNModal } from './Presentational/VcnModal';
import { useMarqetaJs } from '../../utils/marqeta/useMarqetaJs';

interface ILiftedVcnModal {
  accountUUID: string;
  handleModalClose(): void;
  isShown: boolean;
}

const messageDuration = 1500; // 1.5 seconds

export const LiftedVcnModal: React.FC<ILiftedVcnModal> = ({ accountUUID, handleModalClose, isShown }) => {
  const [buttonState, setButtonState] = useState<'default' | 'success' | 'failed'>('default');
  let displayError = false;

  const successCopyPanCallback = () => {
    setButtonState('success');
    setTimeout(() => setButtonState('default'), messageDuration);
  }

  const errorCopyPanCallback = () => {
    setButtonState('failed');
    setTimeout(() => setButtonState('default'), messageDuration);
  }

  const { data, error: queryError, loading: isQueryLoading } = useQuery<GetAllSetVcnCard, GetAllSetVcnCardInput>(GetAllSetVcnCardQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      uuid: accountUUID
    },
  });

  const { isLoading: isMarqetaLoading, hasError: hasMarqetaError } = useMarqetaJs(
    data?.account_management?.virtual_card.access_token,
    successCopyPanCallback,
    errorCopyPanCallback,
  );

  if (queryError
    || hasMarqetaError
    || !data?.account_management?.product?.merchant_name
    || !data?.account_management?.merchant_information?.merchant_domain
    || !data?.account_management?.virtual_card
  ) {
    displayError = true;
  }

  return (
    <VCNModal
      accountUUID={accountUUID}
      data={data}
      handleModalClose={handleModalClose}
      isShown={isShown}
      displayLoader={isQueryLoading || isMarqetaLoading}
      displayError={displayError}
      copyVcnButtonState={buttonState}
    />
  );
};
