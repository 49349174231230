import * as React from 'react';
import { Query } from 'react-apollo';
import Styled from 'styled-components';
import {
  Headline,
  LayoutSkinny,
  ListNumber,
  ListNumberElement,
  ListUnorderedElement,
  UnorderedList,
} from '@amount/frontend-components';
import { RouteComponentProps } from 'react-router-dom';

import Header from '../../Common/Header';
import PageTitle from '../../Common/PageTitle';
import Session from '../../Common/Session';
import { Loading } from '../../Common/Spinner/LoadingHOC';
import { IUUIDRouteProps } from '../../../constants';
import { ContentPaddingContainer } from '../../Common/CommonStyles';

import GetCreditFreezeStatusQuery, {
  GetCreditFreezeStatus
} from './queries/GetCreditFreezeStatus.graphql';

interface ICreditFreeze extends RouteComponentProps<IUUIDRouteProps> {
  data: GetCreditFreezeStatus;
}

const CustomHeadline = Styled(Headline)`
  margin: 0.3em 0;
`;

const Link = Styled.a`
  text-decoration: underline;
`;

const CreditFreeze: React.FunctionComponent<ICreditFreeze> = props => (
  <Session {...props} me={props.data.me}>
    <Header />
    <ContentPaddingContainer>
      <LayoutSkinny>
        <CustomHeadline scale='large' as='h1'>
          We weren't able to access your credit information due to
          a freeze on your account.
        </CustomHeadline>
        <p>
          A credit freeze is a method that blocks access to a consumer's credit
          information. A credit freeze allows a consumer to control and
          restrict access to their credit report.
        </p>
        <Headline scale='medium' as='h2'>
          How to continue your application
        </Headline>
        <ListNumber>
          <ListNumberElement>
            Contact TransUnion to lift your credit freeze
          </ListNumberElement>
          <UnorderedList>
            <ListUnorderedElement>
              <strong>Online</strong>: Log in to your account
              at <Link href='https://www.transunion.com/credit-freeze'>
                freeze.transunion.com
              </Link>
            </ListUnorderedElement>
            <ListUnorderedElement>
              <strong>Phone</strong>: (888) 909-8872
            </ListUnorderedElement>
          </UnorderedList>
          <ListNumberElement>
            Lift the freeze on your credit report
          </ListNumberElement>
          <ListNumberElement>
            Please go to <Link href='https://www.avant.com/apply'>avant.com/apply</Link> to
            restart your application
          </ListNumberElement>
        </ListNumber>
        <p>
          You can replace your credit freeze after you're done accessing your credit.
        </p>
      </LayoutSkinny>
    </ContentPaddingContainer>
  </Session>
);

export const LiftedCreditFreeze: React.FunctionComponent<RouteComponentProps<IUUIDRouteProps>> = props => (
  <Query<GetCreditFreezeStatus>
    query={GetCreditFreezeStatusQuery}
    fetchPolicy='cache-and-network'
    variables={{ uuid: props.match.params.uuid }}
  >
    {({ data, loading }) => {
      const shouldShowPage = !!data?.me?.getApplication?.hasCreditFreeze;
      if (!loading && !shouldShowPage) {
        props.history.replace('/');

        return null;
      }

      return (
        <Loading loading={loading}>
          { !!data?.me && (
            <>
              <PageTitle pageTitle={AvantConfig.TenantConfig.pageTitles.creditFreeze} />
              <CreditFreeze {...props} data={data} />
            </>
          )}
        </Loading>
      );
    }}
  </Query>
);
