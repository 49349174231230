export default {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Product',
        possibleTypes: [{ name: 'CreditCardAccount' }, { name: 'Loan' }],
      },
      {
        kind: 'INTERFACE',
        name: 'Verifications__Task__Interface__V2',
        possibleTypes: [
          { name: 'Verifications__AcceptNewTermsTask__V2' },
          { name: 'Verifications__AdditionalIncomeTask__V2' },
          { name: 'Verifications__UnlockCreditReportTask__V2' },
          { name: 'Verifications__AddressTask__V2' },
          { name: 'Verifications__AcceptCounterOfferTask__V2' },
          { name: 'Verifications__DocumentUploadPartnerTask__V2' },
          { name: 'Verifications__DocumentUploadTask__V2' },
          { name: 'Verifications__DocumentUploadTaskOneRequired__V2' },
          { name: 'Verifications__Task__V2' },
          { name: 'Verifications__UploadTask__V2' },
          { name: 'Verifications__VendorTask__V2' },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Verifications__Task__Interface',
        possibleTypes: [
          { name: 'Verifications__Task' },
          { name: 'Verifications__UploadTask' },
          { name: 'Verifications__VendorTask' },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'PaymentAccount',
        possibleTypes: [{ name: 'BankAccount' }, { name: 'DebitCard' }],
      },
      {
        kind: 'INTERFACE',
        name: 'WorkflowInput',
        possibleTypes: [
          { name: 'WorkflowCheckboxInput' },
          { name: 'WorkflowDateInput' },
          { name: 'WorkflowEmailInput' },
          { name: 'WorkflowNumberInput' },
          { name: 'WorkflowRadioInput' },
          { name: 'WorkflowSelectInput' },
          { name: 'WorkflowTextAreaInput' },
          { name: 'WorkflowTextInput' },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'CreditCardTransactionInterface',
        possibleTypes: [
          { name: 'CreditCardDeclinedTransaction' },
          { name: 'CreditCardPendingTransaction' },
          { name: 'CreditCardPostedTransaction' },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Contract',
        possibleTypes: [
          { name: 'ApplicationContract' },
          { name: 'LoanContract' },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Verifications__DocumentUpload__Task__Interface__V2',
        possibleTypes: [
          { name: 'Verifications__DocumentUploadTask__V2' },
          { name: 'Verifications__DocumentUploadTaskOneRequired__V2' },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'WorkflowInputValueInterface',
        possibleTypes: [
          { name: 'WorkflowInputValue' },
          { name: 'WorkflowInputValueRadio' },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'WorkflowInputValueDetailsInterface',
        possibleTypes: [
          { name: 'WorkflowInputValueRadioCard' },
          { name: 'WorkflowInputValueRadioCardWithInput' },
        ],
      },
    ],
  },
};
