import {
  compareDesc,
  format,
  isAfter,
  isBefore,
  parse,
  setDate,
  subDays
} from 'date-fns';

const THIRTY_DAYS: number = 30;

export type CustomDate = Date | string | number;

export const get30DaysAgo: () => string = () =>
  format(subDays(new Date(), THIRTY_DAYS), 'MMMM D, YYYY');

export const formatDayOrdinal: (day: number) => string = day => format(setDate(new Date(), day), 'Do');

export const formatDate: (date: CustomDate) => string = date => format(date, 'MMM D, YYYY');

export const formatDateWithFullMonthName: (date: CustomDate) => string = date => format(date, 'MMMM D, YYYY');

export const formatTableDate: (date: CustomDate) => string = date => format(date, 'MM/DD/YYYY');

export const isFuture = (date: CustomDate) => isAfter(date, new Date());

export const isPast = (date: CustomDate) => isBefore(date, new Date());

export const compareDescending = (left: CustomDate, right: CustomDate) => compareDesc(parse(right), parse(left));

export const compareDateString: (left?: string | null, right?: string | null) => number = (left, right) => {
  if (!left && !right) {
    return 0;
  } else if (!left) {
    return -1;
  } else if (!right) {
    return 1;
  }

  return compareDescending(left, right);
};

const buildRange: (length: number, offset?: number) => number[] = (
  length,
  offset = 1
) =>
  Array.from(new Array(length), (_, i) => i + offset);

const buildRangeBw: (start: number, end: number) => number[] = (start, end) =>
  buildRange(end - start + 1, start);

const NUM_MONTHS: number = 12;
const NUM_DAYS: number = 31;
const START_YEAR: number = 1900;
const AGE_OFFSET: number = 18;
const DOB_END_YEAR: number = new Date().getFullYear() - AGE_OFFSET;
const SCHEDULE_OFFSET: number = 2;
const SCHEDULE_END_YEAR: number = new Date().getFullYear() + SCHEDULE_OFFSET;

export const MONTHS: number[] = buildRange(NUM_MONTHS);
export const DAYS: number[] = buildRange(NUM_DAYS);
export const DOB_YEARS: number[] = buildRangeBw(START_YEAR, DOB_END_YEAR).reverse();
export const SCHEDULE_YEARS: number[] = buildRangeBw(new Date().getFullYear(), SCHEDULE_END_YEAR);

export const MONTH_LABELS: Map<number, string> = new Map();

// Setup MONTH_LABELS like this: {0: 'Jan', 1: 'Feb'...}
for (let index = 0; index < NUM_MONTHS; index += 1) {
  const date = parse(`${index + 1}/1/${START_YEAR}`);
  MONTH_LABELS.set(index, date.toLocaleString('en-us', { month: 'short' }));
}

export const getFormattedDayOfEachMonth: (day?: number | string[]) => string = day => {
  const dayToNumber = Number(day)
  const ordinalNumber = formatDayOrdinal(dayToNumber)

  return `${ordinalNumber} of each month`
};
