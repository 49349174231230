import React from 'react';
import * as Sentry from '@sentry/browser';

export default class ErrorBoundary extends React.Component<{}, { error: boolean }> {
  public state = {
    error: false
  };

  public componentDidCatch (error: Error, errorInfo: React.ErrorInfo) {
    Sentry.captureException(error);
    Sentry.setExtra('errorInfo', JSON.stringify(errorInfo));
    this.setState({ error: true });
  }

  public render () {
    if (this.state.error) {
      return (
        <div>
          The application has entered an unrecoverable state. Please make sure that
          your Browser and Operating System are up to date.
        </div>
      );
    }

    return (
      <>
        {this.props.children}
      </>
    );
  }
}
