import React from 'react';
import {
  Datapoint,
  DatapointLabel,
  Headline,
  Pill,
  RadioCard,
  RadioCardDataPoint,
} from '@amount/frontend-components';
import { SelectionOnWorkflowInputValueRadio } from '@amount/workflow-js';
import camelCase from 'lodash-es/camelCase';

import { HeaderContainer, IWorkflowRadioInputProps, RadioCardDetails, RadioContentContainer } from './common';

export interface IRadioValueProps extends IWorkflowRadioInputProps {
  opt: SelectionOnWorkflowInputValueRadio;
}

export const WorkflowRadioCard: React.FunctionComponent<IRadioValueProps> = ({
  opt,
  input,
  value,
  updateInput,
}) => (
  <RadioCard
    key={`${input.name}-${opt.value}`}
    id={opt.value}
    name={input.name}
    defaultValue={opt.value}
    placeholder={input.placeholder || undefined}
    required={!!input.required}
    onChange={e => updateInput(e.currentTarget.value)}
    checked={value === opt.value}
    data-event={camelCase(`${opt.displayValue}RadioCardInput`)}
  >
    {
      !!opt.details && opt.details.__typename === 'WorkflowInputValueRadioCard' && (
        <RadioContentContainer>
          <HeaderContainer>
            <Headline as='h4' scale='small'>{opt.details.header}</Headline>
            {!!opt.details.headerPill && <Pill pillColor='success' margin='0'>{opt.details.headerPill}</Pill>}
          </HeaderContainer>
          {opt.details.subheader && <p>{opt.details.subheader}</p>}
          <RadioCardDetails>
            {opt.details.dataPoints && opt.details.dataPoints.map((info, i) => info && (
              <RadioCardDataPoint key={`details-${i}`}>
                {info.header}
                <Datapoint size='small'>{info.content}</Datapoint>
                <DatapointLabel>{info.footer}</DatapointLabel>
              </RadioCardDataPoint>
            ))}
          </RadioCardDetails>
          {opt.details.footer && <DatapointLabel>{opt.details.footer}</DatapointLabel>}
        </RadioContentContainer>
    )}
  </RadioCard>
);
