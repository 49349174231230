import Styled from 'styled-components';
import { Datapoint } from '@amount/frontend-components';

// NOTE: this file exists because we want to override this specific text size only for td.
// When we rework dash to use the same components that design agrees on, this should be removed.
const DatapointLabelWrapper = Styled(Datapoint)`
  font-size: 1.8rem;
`;

export default DatapointLabelWrapper;
