import { compareDateString } from '../../../../../../util/date';
import {
  HistoryViewModel,
  AccountHistoryListProps,
  TagTypes
} from '../types';
import accountToHistoryViewModel from './accountToHistoryViewModel';
import applicationToHistoryViewModel, {
  isCanceledApplication,
  isDeclinedApplication,
  isOpenApplication
} from './applicationToHistoryViewModel';

const TAG_SORT_ORDER = [
  TagTypes.Active,
  TagTypes.PaidOff,
  TagTypes.Canceled,
  undefined
];

const byDateComparator = (a: HistoryViewModel, b: HistoryViewModel) => compareDateString(b.sortDate, a.sortDate);
const byProductStatusComparator = (a: HistoryViewModel, b: HistoryViewModel) => {
  if (a.tag === b.tag) {
    return compareDateString(b.sortDate, a.sortDate);
  }

  return TAG_SORT_ORDER.indexOf(a.tag) - TAG_SORT_ORDER.indexOf(b.tag);
};

const processHistoryIntoViewModels: (props: AccountHistoryListProps) => HistoryViewModel[] = ({ accounts, applications }) => {
  const closedApplications = applications.filter(application => !isOpenApplication(application));
  const declinedAndCanceledApplications = closedApplications.filter(
    application => isDeclinedApplication(application) || isCanceledApplication(application)
  );
  const applicationIdsWithProducts = accounts.map(account => account.product.application.id);
  const orphanedClosedApplications = closedApplications
    .filter(application => !declinedAndCanceledApplications.includes(application))
    .filter(application => !applicationIdsWithProducts.includes(application.id));
  const accountsWithoutDeclinedAndCanceled = accounts
    .filter(account => !declinedAndCanceledApplications.find(app => app.id === account.product.application.id))
    .map(accountToHistoryViewModel)
    .filter((vm): vm is HistoryViewModel => vm !== null)
    .sort(byProductStatusComparator);

  return [
    ...accountsWithoutDeclinedAndCanceled,
    ...[
      ...declinedAndCanceledApplications.map(applicationToHistoryViewModel),
      ...orphanedClosedApplications.map(applicationToHistoryViewModel),
    ].filter((vm): vm is HistoryViewModel => vm !== null).sort(byDateComparator)
  ];
};

export default processHistoryIntoViewModels;
