import { VALID_BANNER_TYPES } from '@amount/frontend-components';

import { IIdentityBankAccount, IIdentityDebitCard } from 'src/gql';

export interface ConsumerProductDispute {
  is_payment_protection_applied: boolean;
  status: string;
  dispute_id: string;
  resolution_amount: {
    formatted: string;
    cents: number;
    amount: number;
  } | null,
  resolution_decision: string | null;
  resolution_date: string | null;
}

export interface IdentityBannerProps {
  identity: {
    id: string;
    customer_uuid: string;
    is_bankruptcy_discharged: boolean;
    bank_accounts: Array<Partial<IIdentityBankAccount>>;
    debit_cards: Array<Partial<IIdentityDebitCard>>;
    can_new_payment_method_be_added: boolean;
    has_bad_payment_method: boolean;
    has_bad_personal_information: boolean;
    get_bankruptcy_claim_disqualifiers: {
      opt_out_autopay_bk: boolean;
    }
  },
}

export interface FullBannerAllPastPaymentProps {
  status: string;
  eff_date: string;
}
export interface FullBannerProps extends IdentityBannerProps {
  servicingAccount: {
    id: string;
    uuid: string;
    all_past_payments: FullBannerAllPastPaymentProps[];
    product: {
      id: string;
      is_autopay: boolean;
      is_make_payment_disqualified: boolean;
      has_balance_post_maturity: boolean;
      is_past_due: boolean;
      past_due_amount: {
        formatted: string;
        cents: number;
        amount: number;
      } | null;
      product_uuid: string;
      product_subtype: string;
      amount: {
        formatted: string;
        cents: number;
        amount: number;
      }
      status: string;
      next_installment_date: string | null;
      next_installment_amount: {
        formatted: string;
        cents: number;
        amount: number;
      } | null;
      next_non_zero_installment_payment: {
        origin: string;
      } | null;
      outstanding_balance: {
        cents: number;
        formatted: string;
      }
    }
    virtual_card?: {
      credit_amount: {
        formatted: string;
        cents: number;
        amount: number;
      }
      expiration_time: string;
      is_expired: boolean;
      is_used: boolean;
      use_by: string;
    }
    disputes: Array<{
      is_payment_protection_applied: boolean;
      status: string;
      dispute_id: string;
      resolution_amount: {
        formatted: string;
        cents: number;
        amount: number;
      } | null,
      resolution_decision: string | null;
      resolution_date: string | null;
    } | null>;
    payoff_information: {
      payoff_balance: {
        formatted: string;
        cents: number;
        amount: number;
      }
      scheduled_payoff_quote_uuid: string | null;
      has_active_payoff_quote: boolean;
      payment_eff_date: string | null;
    }
    point_of_sale_information: {
      adjusted_loan_amount: {
        formatted: string;
        cents: number;
        amount: number;
      }
      refund_information: {
        amount?: {
          formatted: string;
          cents: number;
          amount: number;
        }
        is_full_refund: boolean;
      } | null;
    }
    charge_off_information: {
      is_operationally_charged_off: boolean;
    } | null
  }
}


export enum LoanBannerTypes {
  bankruptcyAutopayOptOut = 'bankruptcyAutopayOptOut',
  bankruptcyDischarged = 'bankruptcyDischarged',
  paidOff = 'paidOff',
  chargeOff = 'chargeOff',
  refunded = 'refunded',
  expiredVirtualCardSplitPay = 'expiredVirtualCardSplitPay',
  expiredVirtualCardNotSplitPay = 'expiredVirtualCardNotSplitPay',
  notUsedVirtualCardSplitPay = 'notUsedVirtualCardSplitPay',
  notUsedVirtualCardNotSplitPay = 'notUsedVirtualCardNotSplitPay',
  activePaymentProtectedDispute = 'activePaymentProtectedDispute',
  returnedPaymentPastDue = 'returnedPaymentPastDue',
  pastDue = 'pastDue',
  payoffQuote = 'payoffQuote',
  autoPayOff = 'autoPayOff',
  autoPayOn = 'autoPayOn',
  resolvedPaymentProtectedDispute = 'resolvedPaymentProtectedDispute',
  resolvedPaymentProtectedMerchantFavor = 'resolvedPaymentProtectedMerchantFavor',
  accountCredit = 'accountCredit',
  outstandingAmount = 'outstandingAmount',
  noPaymentMethods = 'noPaymentMethods',
  hasBadPaymentMethod = 'hasBadPaymentMethod',
  returnedPayment = 'returnedPayment',
  badPersonalInformation = 'badPersonalInformation'
}

export enum IdentityLoanBannerTypes {
  noPaymentMethods = 'noPaymentMethods',
  hasBadPaymentMethod = 'hasBadPaymentMethod',
  badPersonalInformation = 'badPersonalInformation'
}

export enum ServicingAccountLoanBannerTypes {
  bankruptcyAutopayOptOut = 'bankruptcyAutopayOptOut',
  bankruptcyDischarged = 'bankruptcyDischarged',
  paidOff = 'paidOff',
  chargeOff = 'chargeOff',
  refunded = 'refunded',
  expiredVirtualCardSplitPay = 'expiredVirtualCardSplitPay',
  expiredVirtualCardNotSplitPay = 'expiredVirtualCardNotSplitPay',
  notUsedVirtualCardSplitPay = 'notUsedVirtualCardSplitPay',
  notUsedVirtualCardNotSplitPay = 'notUsedVirtualCardNotSplitPay',
  activePaymentProtectedDispute = 'activePaymentProtectedDispute',
  returnedPaymentPastDue = 'returnedPaymentPastDue',
  pastDue = 'pastDue',
  payoffQuote = 'payoffQuote',
  autoPayOff = 'autoPayOff',
  autoPayOn = 'autoPayOn',
  resolvedPaymentProtectedDispute = 'resolvedPaymentProtectedDispute',
  resolvedPaymentProtectedMerchantFavor = 'resolvedPaymentProtectedMerchantFavor',
  accountCredit = 'accountCredit',
  outstandingAmount = 'outstandingAmount',
  returnedPayment = 'returnedPayment',
}
export interface LoanBannerConfigItem {
  type: string,
  variant: VALID_BANNER_TYPES
}
