import Styled from 'styled-components';

import { SupportCtaStyled } from './SupportCtaStyled';

export const SupportButtonLink = Styled.a`
  text-decoration: none;

  &:focus > ${SupportCtaStyled} {
    background: ${({ theme: { button: { primary } } }) => primary.colorBackgroundFocus};
    border: 1px solid ${({ theme: { button: { primary } } }) => primary.colorBackgroundFocus};
    color: ${({ theme }) => theme.colorWhite};
    outline: ${({ theme }) => theme.outline};
  }
`;
