import * as React from 'react';

import { ApplicationCardText, CardLabelWrapper, FlexColumnIE, IApplicationCardContentProps } from './common';
import ApplicationCardCta from './ApplicationCardCta';
import ApplicationCardContentLayout from './ApplicationCardContentLayout';

const ApplicationCardContent: React.FunctionComponent<IApplicationCardContentProps> = props => {
  const {
    cardLink,
    cardHeadline,
    currentStage,
    dataEvent
  } = props;

  return (
    <ApplicationCardContentLayout>
      <FlexColumnIE>
        {cardHeadline && <CardLabelWrapper id='card-headline' as='h3'>{cardHeadline}</CardLabelWrapper>}
        <ApplicationCardText stage={currentStage} />
      </FlexColumnIE>
      <ApplicationCardCta cardLink={cardLink} dataEvent={dataEvent} inline={true} />
    </ApplicationCardContentLayout>
  );
};

export default ApplicationCardContent;
