import * as React from 'react';
import {
  Headline,
  Legend,
  TextAreaInput,
} from '@amount/frontend-components';
import { FragmentSelectionOnWorkflowTextAreaInput, IInputProps } from '@amount/workflow-js';
import camelCase from 'lodash-es/camelCase';

import { WorkflowInputMaxWidthWrapper } from './common';

interface IWorkflowTextAreaInputProps extends IInputProps {
  input: FragmentSelectionOnWorkflowTextAreaInput;
}

export const WorkflowTextAreaInput: React.FunctionComponent<IWorkflowTextAreaInputProps> = ({
  input,
  updateInput,
  value,
}) => (
  <>
    {(input.title || input.placeholder) && (
      <Headline scale='small' as='label' htmlFor={camelCase(input.placeholder || undefined)}>
        {input.title || input.placeholder}
      </Headline>
    )}
    <WorkflowInputMaxWidthWrapper>
      <TextAreaInput
        id={camelCase(input.placeholder || undefined)}
        defaultValue={value || ''}
        placeholder={input.placeholder || undefined}
        required={!!input.required}
        maxLength={input.maxLength || undefined}
        minLength={input.minLength || undefined}
        title={input.title || undefined}
        onChange={e => updateInput(e.currentTarget.value)}
        data-event={camelCase(`${input.placeholder || ''}TextAreaInput`)}
      />
      {
        input.extraInfo && <Legend>{input.extraInfo}</Legend>
      }
    </WorkflowInputMaxWidthWrapper>
  </>
);
