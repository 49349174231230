import React, { ReactNode, useState } from 'react';
import Styled from 'styled-components';
import { creditCardHasBalance, creditCardSatisfiedMinPayDue } from '@amount/frontend-product-utilities';
import { Banner, BannerLink, Button, SVGIcon } from '@amount/frontend-components';

/* eslint-disable max-len */
import { FragmentSelectionOnCreditCardAccount, SelectionOnCustomer } from '../../CustomerHomeDash/queries/GetCustomerHomeInformation.graphql';
import { AccountRoutes, ApplicationBaseRoutes, CardSettingsRoutes, PaymentRoutes, ServicingRoutes } from '../../../routes';
import { NA } from '../../../constants';
import { formatDate } from '../../../util/date';
import { dismissIncomeAndHousing, incomeAndHousingWasDismissed } from '../../../util';

import { CCLinkText, CreditCardLink, IAnalyticCardLink, ICardLink } from './CreditCardLink';
import BannerTextWrapper, { InlineAnchor } from './BannerTextWrapper';
import { AlertStyles, DefaultStyles, IBannerStyles, LinkAndTextWrapper, PrimaryStyles } from './common';

type CreditCardBannersProps = Pick<
  FragmentSelectionOnCreditCardAccount,
  | 'adjustedMinimumPaymentDue'
  | 'autopayActive'
  | 'isAutopayActivationPending'
  | 'overlimit'
  | 'creditCard'
  | 'currentBalance'
  | 'uuid'
  | 'wasClosed'
  | 'isLate'
  | 'locked'
  | 'minimumPaymentDueDate'
  | 'payments'
  | 'financialSituationReviewEligible'
  | 'productName'
  | 'isSoldOff'
> & Pick<SelectionOnCustomer, 'eligibleToUpdateIncomeAndHousing'>
  ;

export enum BannerType {
  fsr = 'Financial Situation Review',
  locked = 'Locked',
  delinquentAndOverlimit = 'Delinquent and Overlimit',
  delinquent = 'Delinquent',
  overlimit = 'Overlimit',
  closedWithBalance = 'Closed with Balance',
  closedWithoutBalance = 'Closed without Balance',
  autopayStarting = 'Autopay Starting',
  activateCard = 'Activate Card',
  enrollInAutopay = 'Enroll in Autopay',
  incomeAndHousing = 'Income and Housing',
  sold = 'Sold'
}

const incomeAndHousingEligible: (eligibleToUpdateIncomeAndHousing: boolean) => boolean = eligibleToUpdateIncomeAndHousing => (
  eligibleToUpdateIncomeAndHousing && !incomeAndHousingWasDismissed()
);


// eslint-disable-next-line complexity
export const bannerType: (accountInfo: CreditCardBannersProps) => BannerType | null = accountInfo => {
  const {
    adjustedMinimumPaymentDue,
    currentBalance,
    autopayActive,
    isAutopayActivationPending,
    overlimit,
    wasClosed,
    isLate,
    locked,
    minimumPaymentDueDate,
    payments,
    creditCard,
    eligibleToUpdateIncomeAndHousing,
    isSoldOff,
  } = accountInfo;

  // This function defines the banner order. The order of these conditional checks is intentional, and important.
  if (isSoldOff) {
    return BannerType.sold;
  }
  if (accountInfo.financialSituationReviewEligible) {
    return BannerType.fsr;
  }
  if (locked) {
    return BannerType.locked;
  }
  if (isLate && overlimit) {
    return BannerType.delinquentAndOverlimit;
  }
  if (isLate && !overlimit) {
    return BannerType.delinquent;
  }
  if (overlimit && !isLate) {
    return BannerType.overlimit;
  }
  if (wasClosed && creditCardHasBalance(currentBalance)) {
    return BannerType.closedWithBalance;
  }
  if (wasClosed && !creditCardHasBalance(currentBalance)) {
    return BannerType.closedWithoutBalance;
  }
  if (isAutopayActivationPending &&
    !creditCardSatisfiedMinPayDue(payments, adjustedMinimumPaymentDue && adjustedMinimumPaymentDue.cents, minimumPaymentDueDate)) {
    return BannerType.autopayStarting;
  }
  if (creditCard && creditCard.isActivatable) {
    return BannerType.activateCard;
  }
  if (incomeAndHousingEligible(eligibleToUpdateIncomeAndHousing)) {
    return BannerType.incomeAndHousing;
  }
  if (!autopayActive) {
    return BannerType.enrollInAutopay;
  }

  return null;
};

// Exposing default padding size for banner here to use for dismissable icon
const defaultPaddingSize = '.75em';

const HorizontalDiv = Styled.div`
  flex-direction: row;
  padding-right: 2.1em;
`;

const TransparentButton = Styled(Button)`
  background-color: transparent;
  border-width: 0;
  width: 2.1em;
  position: absolute;
  right: 0;
  top: 0;
  padding: ${defaultPaddingSize}

  // this overrides BBVA new button styles that requires a span tag to wrap its children
  span {
    padding: 0
    border: none;
  }
`;

interface IBanner {
  style: IBannerStyles;
  link (uuid: string): IAnalyticCardLink | null;
  content (data: CreditCardBannersProps): ReactNode;
  onClick? (): void;
  // Assumption made that only dismissable banners will present an onDismiss
  onDismiss? (): void;
}

const DismissableContent: React.FC<{ onDismiss (): void }> = ({ onDismiss, children }) => (
  <HorizontalDiv>
    {children}
    <TransparentButton onClick={onDismiss}>
      <SVGIcon icon={'close'} />
    </TransparentButton>
  </HorizontalDiv>
);

const fsrLink: (uuid: string) => ICardLink = uuid => ({
  url: `${ApplicationBaseRoutes.cardServicing}/${uuid}${ServicingRoutes.fsr}`,
  text: CCLinkText.FINANCIAL_SITUATION_REVIEW
});

const fsrContent = (
  <span>
    Take as little as 3 minutes to provide us with information about your financial situation to see if you can lower your monthly payment.
  </span>
);

const fsrBanner: IBanner = {
  style: PrimaryStyles,
  link: (uuid: string) => ({ ...fsrLink(uuid), dataEvent: 'bannerCCFSR' }),
  content: () => fsrContent
};

const creditCardPhoneNumber = AvantConfig.TenantConfig.creditCard && AvantConfig.TenantConfig.creditCard.paymentsPhoneNumber;
const makePaymentLink: (uuid: string) => ICardLink = uuid => ({
  url: `${ApplicationBaseRoutes.cardServicing}/${uuid}${ServicingRoutes.payments}${PaymentRoutes.schedule}`,
  text: CCLinkText.MAKE_PAYMENT
});

const lockedBannerContent: (data: CreditCardBannersProps) => ReactNode = ({ productName }) => {
  return (
    !!creditCardPhoneNumber ? (
      <span>
        Your {productName} account has been locked, please call us at{' '}
        <InlineAnchor
          bannerStyle={AlertStyles.style}
          href={`tel:${creditCardPhoneNumber.dialable}`}
          data-event='cardLockedSupportNumber'
        >
          {creditCardPhoneNumber.readable}
        </InlineAnchor>
        .
      </span>
    ) : <span>Your credit card account has been locked.</span>
  )
}

const lockedBanner: IBanner = {
  style: AlertStyles,
  link: () => null,
  content: lockedBannerContent
};

const delinquentAndOverlimitContent = (
  <span>
    Your account is over limit and past due.
    Please make a payment to bring your account current and within your credit limit.
  </span>
);

const delinquentAndOverlimitBanner: IBanner = {
  style: AlertStyles,
  link: (uuid: string) => ({ ...makePaymentLink(uuid), dataEvent: 'bannerCCMakePaymentOverlimitLate' }),
  content: () => delinquentAndOverlimitContent
};

const delinquentContent: (data: CreditCardBannersProps) => ReactNode = ({ adjustedMinimumPaymentDue, productName }) => {
  const minimumPayment = adjustedMinimumPaymentDue && adjustedMinimumPaymentDue.formatted || NA;

  return !!creditCardPhoneNumber ? (
    <span>
      Your {productName} bill is past due, please make a payment of {minimumPayment} today, or contact us at{' '}
      <InlineAnchor
        bannerStyle={AlertStyles.style}
        href={`tel:${creditCardPhoneNumber.dialable}`}
      >
        {creditCardPhoneNumber.readable}
      </InlineAnchor>
      .
    </span>
  ) : <span>Your {productName} bill is past due, please make a payment of {minimumPayment} today.</span>;
};

const delinquentBanner: IBanner = {
  style: AlertStyles,
  link: (uuid: string) => ({ ...makePaymentLink(uuid), dataEvent: 'bannerCCMakePaymentLate' }),
  content: delinquentContent
};

const overlimitContent = (
  <span>
    Your account is over limit. Please pay down your balance to bring your account within your limit.
  </span>
);

const overlimitBanner: IBanner = {
  style: AlertStyles,
  link: (uuid: string) => ({ ...makePaymentLink(uuid), dataEvent: 'bannerCCMakePaymentOverlimit' }),
  content: () => overlimitContent
};

const closedBannerContent = (data: CreditCardBannersProps): ReactNode => {
  const { creditCard, currentBalance, productName } = data;
  if (!creditCard) {
    return null;
  }
  const bannerText = `Your ${productName} account ending in *${creditCard.lastFour} has been closed.`;

  return <span>{bannerText}{!!creditCardHasBalance(currentBalance) && ' Pay now to avoid incurring additional late fees.'}</span>;
};

const closedWithBalanceBanner: IBanner = {
  style: DefaultStyles,
  link: (uuid: string) => ({ ...makePaymentLink(uuid), dataEvent: 'bannerCCMakePaymentClosed' }),
  content: closedBannerContent
};

const closedWithoutBalanceBanner: IBanner = {
  style: DefaultStyles,
  link: () => null,
  content: closedBannerContent
};

const autopayStartingContent = (data: CreditCardBannersProps): ReactNode => (
  <span>
    You will still need to make your payment due {data.minimumPaymentDueDate ? formatDate(data.minimumPaymentDueDate) : NA}.
    AutoPay will begin at the end of your next statement period.
  </span>
);

const autopayStartingBanner: IBanner = {
  style: PrimaryStyles,
  link: () => null,
  content: autopayStartingContent
};

const activateCardContent = (data: CreditCardBannersProps): ReactNode => (
  <span>
    Activate your credit card! It's quick and easy. Start making purchases with your new card today.
  </span>
);

const activateCardLink: (uuid: string) => ICardLink = uuid => ({
  url: `${ApplicationBaseRoutes.cardServicing}/${uuid}${ServicingRoutes.activation}`,
  text: CCLinkText.ACTIVATE_NOW,
});

const activateCardBanner: IBanner = {
  style: PrimaryStyles,
  link: (uuid: string) => ({ ...activateCardLink(uuid), dataEvent: 'bannerCCActivateCard' }),
  content: activateCardContent
};

const incomeAndHousingLink: () => ICardLink = () => ({
  url: `${ApplicationBaseRoutes.account}${AccountRoutes.incomeAndHousing}`,
  text: CCLinkText.UPDATE_YOUR_FINANCIAL_INFORMATION,
});

const incomeAndHousingContent = (
  <span>Help us see if you qualify for a credit line increase by updating your financial information.</span>
);

const incomeAndHousingBanner: IBanner = {
  style: PrimaryStyles,
  link: () => ({ ...incomeAndHousingLink(), dataEvent: 'bannerIncomeAndHousing' }),
  content: () => incomeAndHousingContent,
  onDismiss: dismissIncomeAndHousing,
};

const autopayLink: (uuid: string) => ICardLink = uuid => ({
  url: `${ApplicationBaseRoutes.cardServicing}/${uuid}${ServicingRoutes.settings}${CardSettingsRoutes.autopayEnroll}`,
  text: CCLinkText.ENROLL_IN_AUTOPAY,
});

const enrollInAutopayContent = (
  <span>
    You are not currently enrolled in Autopay. Enroll today to avoid missing payments and incurring fees.
  </span>
);

const enrollInAutopayBanner: IBanner = {
  style: PrimaryStyles,
  link: (uuid: string) => ({ ...autopayLink(uuid), dataEvent: 'bannerCCAutopayEnroll' }),
  content: () => enrollInAutopayContent
};

const soldContent = (
  <span>
    Your account has been sold. Please refer to the communication sent to you for the contact information of the debt buyer. In case you have already sent payment to Avant, the payment will be forwarded to the debt buyer.
  </span>
);

const soldBanner: IBanner = {
  style: PrimaryStyles,
  link: () => null,
  content: () => soldContent
};

const generateBannerMap = (data: CreditCardBannersProps): Map<BannerType, IBanner> => (
  new Map<BannerType, IBanner>([
    [BannerType.fsr, fsrBanner],
    [BannerType.locked, lockedBanner],
    [BannerType.delinquentAndOverlimit, delinquentAndOverlimitBanner],
    [BannerType.delinquent, delinquentBanner],
    [BannerType.overlimit, overlimitBanner],
    [BannerType.closedWithBalance, closedWithBalanceBanner],
    [BannerType.closedWithoutBalance, closedWithoutBalanceBanner],
    [BannerType.autopayStarting, autopayStartingBanner],
    [BannerType.activateCard, activateCardBanner],
    [BannerType.incomeAndHousing, incomeAndHousingBanner],
    [BannerType.enrollInAutopay, enrollInAutopayBanner],
    [BannerType.sold, soldBanner]
  ])
);

const CreditCardBanner: React.FunctionComponent<CreditCardBannersProps> = props => {
  const { uuid, creditCard } = props;
  // This looks odd - the state value here is used to reload the banner if one is dismissed
  // it will always be set to false on render, and an update to true from a banner dismiss click
  // will rerender with it set to true
  const [, shouldReload] = useState<boolean>(false);
  const fetchedBannerType = bannerType(props);

  // Can't use truthy here for fetchedBannerType as 0 is a valid enum (locked)
  if (!creditCard || fetchedBannerType === null) {
    return null;
  }

  const banner = generateBannerMap(props)
    .get(fetchedBannerType);
  if (!banner) {
    return null;
  }
  const { link, style, content, onDismiss } = banner;
  const generatedLink = link(uuid);
  const dismissBanner = () => {
    if (onDismiss) {
      onDismiss();
    }
    shouldReload(true);
  };

  return (
    <Banner bannerStyle={style.style} padding={defaultPaddingSize}>
      <BannerTextWrapper>
        <LinkAndTextWrapper>
          {onDismiss ?
            <DismissableContent onDismiss={dismissBanner}>{content(props)}</DismissableContent> :
            content(props)
          }
          {!!generatedLink && (
            <BannerLink bannerStyle={style.style}>
              <CreditCardLink link={generatedLink} uuid={uuid} />
            </BannerLink>
          )}
        </LinkAndTextWrapper>
      </BannerTextWrapper>
    </Banner>
  );
};

export default CreditCardBanner;
