import {
  FragmentSelectionOnWorkflowRadioInput1,
  IInputProps,
  SelectionOnWorkflowInputValueRadio
} from '@amount/workflow-js';
import Styled from 'styled-components';
import { Headline, media, RadioCardDataPointContainer } from '@amount/frontend-components';

import { FlexRow } from '../../../CommonStyles';

export interface IWorkflowRadioInputProps extends IInputProps {
  input: FragmentSelectionOnWorkflowRadioInput1;
}

export interface IRadioValueProps extends IWorkflowRadioInputProps {
  opt: SelectionOnWorkflowInputValueRadio;
}

export const HeaderContainer = Styled(FlexRow)`
  flex-wrap: wrap;

  ${Headline} {
    margin-right: 0.375em;
  }
`;

export const RadioContentContainer = Styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-gap: 0.75em;

  ${media.medium`
      height: 100%;
      max-height: 100%;
  `}
`;

export const RadioCardDetails = Styled(RadioCardDataPointContainer)`
  align-items: end;
`;
