import Bowser from 'bowser';

export interface IPlatformDetails {
  PlatformType?: string;
  vendor?: string;
  model?: string;
}

export const enum DeviceOS {
  ios = 'ios',
  android = 'android',
  desktop = 'desktop'
}

export interface ISupportedBrowser {
  mobile: {
    chrome: string;
    safari: string;
    'Samsung Internet for Android': string;
  };
  desktop: {
    chrome: string;
    firefox: string;
    edge: string;
  };
  tablet: {
    chrome: string;
    safari: string;
    'Samsung Internet for Android': string;
  };
}

export const enum Browser {
  chrome = 'Chrome',
  firefox = 'Firefox',
  edge = 'Microsoft Edge',
  safari = 'Safari',
  samsung = 'Samsung internet'
}

export const getDeviceOS: () => DeviceOS = () => {
  const device = Bowser.parse(window.navigator.userAgent);

  return device.os.name === 'iOS' ? DeviceOS.ios : device.os.name === 'Android' ? DeviceOS.android : DeviceOS.desktop;
};

export const isValidBrowser: (browsers: ISupportedBrowser, platformBrowsers: { [key: string]: string[] }) =>
  boolean = (browsers, platformBrowsers) => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  return !!browser.satisfies(browsers) && platformBrowsers[getDeviceOS()].includes(browser.getBrowserName());
};

export const isMobile: () => boolean = () => getDeviceOS() !== DeviceOS.desktop ? true : false;
