import React from 'react';
import { ScreenReaderOnly } from '@amount/frontend-components';

const MLSLogo: React.FC = () => (
  <>
    {/* eslint-disable-next-line @typescript-eslint/no-require-imports */}
    <img src={require('../../../../images/co-brand/mls.svg')} alt='MLS | Avant Logo' />
    <ScreenReaderOnly>MLS | Avant Logo</ScreenReaderOnly>
  </>
);

export default MLSLogo;
