import React from 'react';
import ReactDOM from 'react-dom';
import {
  IModalContent,
  InnerContainer,
  ModalContent,
  ModalDialog,
  ModalOverlay,
} from '@amount/frontend-components';

const modalIsDefined = (element: HTMLElement | null): HTMLElement => {
  if (!element && process.env.NODE_ENV !== 'test') {
    throw new Error('Missing Modal Root');
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return element!;
};

export type CloseFunction = (e: Event | MouseEvent | KeyboardEvent | React.SyntheticEvent) => void;

export interface IModalProps {
  close: CloseFunction;
  show: boolean;
  takeOver?: boolean;
  modalContent?: IModalContent;
  modalDialogMaxWidth?: string;
  modalStyle?: IModalStyle;
}
export interface IModalStyle {
  borderRadius?: string,
  width?: string,
  height?: string,
  margin?: string,
}
interface IModalState {
  showModal: boolean;
}

const BODY_CLASS: string = 'modal__show';
const TAB_KEY_CODE: number = 9;
const isHTMLElement = (element: Element | Text | null): element is HTMLElement => !!element && !!(element as HTMLElement).focus;

// eslint-disable-next-line max-len
const FOCUSABLE_ELEMENT_QUERY: string = `a[href], a[role=button], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable]`;
const ANIMATION_DURATION = 150;

interface IModalChromeProps extends Pick<IModalProps, 'takeOver' | 'modalContent' | 'modalDialogMaxWidth' | 'modalStyle'> {
  modalRef: React.RefObject<HTMLDivElement>;
}

const ModalChrome: React.FunctionComponent<IModalChromeProps> = props => (
  <>
    {props.takeOver ? (
      <InnerContainer>
        {props.children}
      </InnerContainer>
    ) : (
      <ModalDialog maxWidth={props.modalDialogMaxWidth} style={props.modalStyle}>
        <ModalContent
          {...props.modalContent}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={props.modalRef as any}
          data-test-id='modal-content'
        >
          {props.children}
        </ModalContent>
      </ModalDialog>
    )}
  </>
);

class Modal extends React.Component<IModalProps, IModalState> {
  public state: Readonly<IModalState> = {
    showModal: false
  };

  private readonly el: HTMLDivElement;
  private readonly modalRoot: HTMLElement;
  private readonly modalRef = React.createRef<HTMLDivElement>();
  private invokingElement!: Element | null;

  constructor (props: IModalProps) {
    super(props);

    this.state = { showModal: props.show };

    this.el = document.createElement('div');

    this.modalRoot = modalIsDefined(document.getElementById('modal-root'));

    if (props.show) {
      document.body.classList.add(BODY_CLASS);
      this.invokingElement = document.activeElement;
      setTimeout(() => this.focusFirstElement(), ANIMATION_DURATION);
    }
  }

  public componentDidMount () {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.modalRoot.appendChild(this.el);

    // allow closing modal by hitting the 'Escape' key
    document.addEventListener('keydown', this.keyListener);
  }

  public componentWillUnmount () {
    this.modalRoot.removeChild(this.el);
    if (this.props.show) {
      document.body.classList.remove(BODY_CLASS);
    }

    document.removeEventListener('keydown', this.keyListener);
  }

  public componentDidUpdate () {
    if (this.props.show !== this.state.showModal) {
      if (this.props.show) {
        document.body.classList.add(BODY_CLASS);
        this.setState(({ showModal: true }));
        this.invokingElement = document.activeElement;
        setTimeout(() => this.focusFirstElement(), ANIMATION_DURATION);
      } else {
        document.body.classList.remove(BODY_CLASS);
        setTimeout(
          () => {
            this.setState(({ showModal: false }));
            if (isHTMLElement(this.invokingElement)) {
              this.invokingElement.focus();
            }
          },
          ANIMATION_DURATION
        );
      }
    }
  }

  public render () {
    if (this.state.showModal) {
      return ReactDOM.createPortal(
        (
          <div
            role='dialog'
            aria-modal={this.state.showModal}
            aria-label={this.props['aria-label']}
            aria-labelledby={this.props['aria-labelledby']}
          >
            <ModalOverlay show={this.props.show} takeOver={this.props.takeOver} onClick={this.clickOutListener}>
              <ModalChrome {...this.props} takeOver={this.props.takeOver} modalRef={this.modalRef}>
                {this.props.children}
              </ModalChrome>
            </ModalOverlay>
          </div>
        ),
        this.el,
      );
    }

    return null;
  }

  private readonly focusFirstElement: () => void = () => {
    const [first] = this.getFirstAndLastFocusableElements();
    if (first) { first.focus(); }
  }

  private readonly keyListener: (event: KeyboardEvent) => void = e => {
    if (!this.props.show) { return; }
    if (e.key === 'Escape') {
      this.props.close(e);

      return;
    }

    this.tabListener(e);
  }

  private readonly tabListener: (event: KeyboardEvent) => void = e => {
    if (e.key !== 'Tab' && e.keyCode !== TAB_KEY_CODE) { return; }

    const [first, last]: HTMLElement[] = this.getFirstAndLastFocusableElements();

    // no tab-able elements, preventDefault and return
    if (!first) {
      e.preventDefault();

      return;
    }

    // there's only one element or we've reached the end of our list, so focus the first one
    if (!last || document.activeElement === last) {
      first.focus();
      e.preventDefault();

      return;
    }

    // we're cycling backwards and reach the first element, so focus the last one
    if (e.shiftKey && document.activeElement === first) {
      last.focus();
      e.preventDefault();

      return;
    }
  }

  // close when area outside of Modal is clicked #wcag
  private readonly clickOutListener: React.MouseEventHandler<HTMLDivElement> = e => {
    const modalRef: React.RefObject<HTMLDivElement> | null = this.modalRef;
    if (!modalRef) { return; }
    const modalContent = modalRef.current;
    if (!modalContent || modalContent.contains(e.target as Element)) { return; }

    this.props.close(e);
  }

  private readonly getFirstAndLastFocusableElements: () => HTMLElement[] = () => {
    const focusableElements = Array
      .from(this.el.querySelectorAll(FOCUSABLE_ELEMENT_QUERY))
      .filter(isHTMLElement);

    if (!focusableElements.length) { return []; }
    if (focusableElements.length === 1) { return [focusableElements[0]]; }

    return [focusableElements[0], focusableElements[focusableElements.length - 1]];
  }
}

export default Modal;
