import React from 'react';
import { Box } from '@amount/frontend-components';

import RepaymentDataDisplay from './RepaymentDataDisplay';

const { amountPaidLabel, amountRemainingLabel } = AvantConfig.TenantConfig.repaymentDetails;

export interface IRepaymentContentProps {
  formattedPaidPrincipal?: string;
  formattedPrincipalOutstandingAmount?: string;
}

export const RepaymentContent: React.FC<IRepaymentContentProps> = (props: IRepaymentContentProps) => {
  const {
    formattedPaidPrincipal,
    formattedPrincipalOutstandingAmount
  } = props;

  return (
    <Box
      margin={{ left: '3x' }}
      direction="column"
      flexBasis="auto"
      alignSelf="center"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <RepaymentDataDisplay
        title={amountPaidLabel}
        content={formattedPaidPrincipal || ''}
        containerMargin={{ bottom: '2x' }}
      />
      <RepaymentDataDisplay
        title={amountRemainingLabel}
        content={formattedPrincipalOutstandingAmount || ''}
      />
    </Box>
  );
};
