import Styled from 'styled-components';
import { media } from '@amount/frontend-components';

import { ContentPaddingContainer, FlexColumn } from '../CommonStyles';

export const WorkflowContainer = Styled(ContentPaddingContainer)`
  max-width: 800px;
  margin: 0 auto;
`;

export const ActionContainer = Styled(FlexColumn)`
  align-items: center;

  ${media.small`
    flex-direction: row;
  `}

  button,
  a {
    margin-right: 0;
    margin-bottom: 0.5em;

    &:last-child {
      margin-right: 0;
    }

    ${media.small`
      margin-right: 1em;
      margin-bottom: 0;
    `}
  }
`;

export const ErrorDiv = Styled.div`
  color: ${({ theme: { brand } }) => brand.colorAlert};
  padding: 0 0 1em;
`;
