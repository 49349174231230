/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-danger */
import React from 'react';

import { LoanBanner } from '.';
import { BannerBaseProps } from '../index';

const { paidOff } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages;

export const PaidOffBanner: React.FC<BannerBaseProps> = ({variant}) => (
  <LoanBanner variant={variant}>
    <span dangerouslySetInnerHTML={{ __html: paidOff }}></span>
  </LoanBanner>
);
