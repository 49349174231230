import React from 'react';

import { formatDate } from '../../../../../../util/date';

import { PayoffQuoteProps } from './common';

export const ScheduledPayoffQuote: React.FunctionComponent<PayoffQuoteProps> = ({ payoffInformation }) => (
  <span>
      Your total loan balance of <strong>{payoffInformation?.scheduledQuote?.amount.formatted}</strong> is scheduled to
      {' '}be paid off on <strong>{formatDate(payoffInformation?.scheduledQuote?.paymentScheduledDate || '')}</strong>.
      {' '}No further action is required.
  </span>
);
