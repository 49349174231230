import React from "react";
import { StyledHeaderList, useMediaQuery } from '@amount/frontend-components';

import { HeaderDropdown } from "./HeaderDropdown";
import { HeaderLinkMap } from "./links/constants";
import { HeaderProps } from "..";

export const HeaderLinksList: React.FC<Partial<HeaderProps>> = ({ hideDropdownOnDesktop, headerLinksConfig }) => {
  const isMobileViewPort = !useMediaQuery('small');
  const { headerLinks, drawerLinks } = headerLinksConfig || {};
  const shouldDisplayDropdown = !!(drawerLinks && !hideDropdownOnDesktop || isMobileViewPort);

  if (headerLinks && drawerLinks) {
    return (
      <StyledHeaderList>
        {Object.values(headerLinks)?.map(({ type, label, externalUrl }) => {
          const Link = HeaderLinkMap[type];

          return (
            <Link variant='default' linkLabel={label} externalUrl={externalUrl} key={`header-link-${label}`} />
          );
        })}

        {shouldDisplayDropdown && <HeaderDropdown drawerLinks={drawerLinks} />}
      </StyledHeaderList>
    );
  } else {
    return null;
  }
};
