import React from 'react';
import Styled from 'styled-components';
import {
  Button,
  Card,
  media,
} from '@amount/frontend-components';

import {
  FragmentSelectionOnCreditCardAccount
} from '../../../../../components/CustomerHomeDash/queries/GetCustomerHomeInformation.graphql';
import CreditCardBanner from '../../../../../components/Common/BannerNotifications/CreditCardBanner';
import {
  ButtonWrapper,
  CardContentPaddingContainer,
  Divider,
  HalfContainer,
  ProductCardWrapper,
  ProductInfoWrapper,
  StyledViewArrowSVG,
  ViewCardLink
} from '../../../../../components/CustomerHomeDash/common';
import { ConditionalRenderWrapper } from '../../../../../components/Common/CommonStyles';
import { CustomerHomeBannerWrapper } from '../../../../../components/Common/BannerNotifications/BannerTextWrapper';

import CreditCardIdentifier from './CreditCardIdentifier';
import NextPaymentCreditCard from './NextPaymentCreditCard';
import { CardImage } from './CardImage/CardImage';

export interface ICreditCardProductCardProps {
  product: FragmentSelectionOnCreditCardAccount;
  eligibleToUpdateIncomeAndHousing: boolean;
}

const CreditCardImageWrapper = Styled.div`
  display: flex;
  flex: 1 1 100%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0em;
  padding-top: 1em;
  ${media.medium`
    padding-left: 1em;
    padding-top: 0;
  `}
  ${media.ie11`
    flex: 1 1 auto;
  `}

  img {
    height: 10.5em;
    width: 16.5em;
  }
`;

const CreditCardProductCard: React.FunctionComponent<ICreditCardProductCardProps> = ({ product, eligibleToUpdateIncomeAndHousing }) => {
  if (!product.creditCard) {
    return null;
  }

  const { uuid, creditCard: { lastFour }, productName, cardImageUrl, cardImageName } = product;

  return (
    <ProductCardWrapper>
      <Card padding='0'>
        <CustomerHomeBannerWrapper>
          <CreditCardBanner {...product} eligibleToUpdateIncomeAndHousing={eligibleToUpdateIncomeAndHousing} />
        </CustomerHomeBannerWrapper>
        <CardContentPaddingContainer>
          <CreditCardIdentifier productName={productName} lastFour={lastFour} uuid={uuid} />
          <ProductInfoWrapper>
            <HalfContainer>
              <CreditCardImageWrapper>
                <CardImage cardImageUrl={cardImageUrl} cardImageName={cardImageName} />
              </CreditCardImageWrapper>
            </HalfContainer>
            <>
              <Divider />
              <HalfContainer>
                <NextPaymentCreditCard {...product} />
              </HalfContainer>
            </>
          </ProductInfoWrapper>
          <ConditionalRenderWrapper hiddenOnMobile={false}>
            <ViewCardLink uuid={uuid}>
              <ButtonWrapper>
                <Button buttonStyle='secondary' inline={true}>
                  <strong>View Card</strong>
                  <StyledViewArrowSVG icon='forward-arrow' iconStyle='brand' />
                </Button>
              </ButtonWrapper>
            </ViewCardLink>
          </ConditionalRenderWrapper>
        </CardContentPaddingContainer>
      </Card>
    </ProductCardWrapper>
  );
};

export default CreditCardProductCard;
