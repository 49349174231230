import React from 'react';
import { format } from 'date-fns';
import Styled from 'styled-components';
import {
  FixedRightColumn,
  LayoutContainer,
  media,
  ResponsiveWideColumn,
  RowSpan,
} from '@amount/frontend-components';
import { Query } from 'react-apollo';

import { Loading } from '../Common/Spinner/LoadingHOC';
import ServerError from '../Common/Error/ServerError';
import { FlexColumn } from '../Common/CommonStyles';
import {
  BackgroundProviderBox,
  ContentPaddingContainerBox,
  MaxWidthContainerBox
} from '../Common/Box';

import GetCustomerHomeInformationQuery, {
  GetCustomerHomeInformation
} from './queries/GetCustomerHomeInformation.graphql';
import CustomerHomeCards from './CustomerHomeCards';
import CrossSellOffers from './CrossSellOffers';
import { GreetingHeadline } from './GreetingHeadline';

interface ICustomerHomeProps {
  data?: GetCustomerHomeInformation;
}

const RightColumn = Styled(FixedRightColumn)`

  ${media.mediumLarge`
    display: flex;
  `}
`;

const CustomerHomeCardsWrapper = Styled.div`
  ${media.medium`
    ${!AvantConfig.TenantConfig.customerHome.crossSellOffers && 'width: 675px;'} /* SHORT TERM SOLUTION */
  `}
`;

export class CustomerHome extends React.PureComponent<ICustomerHomeProps> {
  public render () {
    const {
      data: { me } = { me: null },
    } = this.props;

    if (!me) {
      return <ServerError />;
    }

    return (
      <BackgroundProviderBox>
        <MaxWidthContainerBox>
          <ContentPaddingContainerBox>
            <RowSpan>
              {!!(me && me.firstName) && (
                <GreetingHeadline scale='large' as='h1'>
                  Hello, {me.firstName}
                </GreetingHeadline>
              )}
              {AvantConfig.TenantConfig.customerHome.showDate && (
                <span data-test-hidden={true}>Today is {format(new Date(), 'dddd, MMMM Do, YYYY')}.</span>
              )}
            </RowSpan>
              <LayoutContainer>
                <ResponsiveWideColumn>
                  <FlexColumn>
                    <CustomerHomeCardsWrapper>
                      <CustomerHomeCards {...me} />
                    </CustomerHomeCardsWrapper>
                  </FlexColumn>
                </ResponsiveWideColumn>
                <RightColumn>
                  <CrossSellOffers crossSellInformation={me.crossSellInformation || []} />
                </RightColumn>
              </LayoutContainer>
          </ContentPaddingContainerBox>
        </MaxWidthContainerBox>
      </BackgroundProviderBox>
    );
  }
}

const LiftedCustomerHome: React.FunctionComponent<ICustomerHomeProps> = props => (
  <Query<GetCustomerHomeInformation>
    query={GetCustomerHomeInformationQuery}
    fetchPolicy='cache-and-network'
  >
    {({ data, loading }) => (
      <Loading loading={loading}>
        <CustomerHome {...props} data={data} />
      </Loading>
    )}
  </Query>
);

export default LiftedCustomerHome;
