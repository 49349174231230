import React from 'react';
import { Link } from 'react-router-dom';
import { BannerLink } from '@amount/frontend-components';

import { BannerNotificationsProps } from '../../types';

export type PastDueProps = Pick<BannerNotificationsProps,
  'gracePeriodEndDate' | 'hasOutstandingBalance' | 'pastDueAmount' | 'uuid'>;

export type UUIDProps = Pick<PastDueProps, 'uuid'>;

interface IPastDuePaymentLinkProps {
  text: string;
  destination: string;
  dataEvent: string;
}

export const PastDuePaymentLink: React.FC<IPastDuePaymentLinkProps> = ({ text, destination, dataEvent}) => (
  <BannerLink bannerStyle='alert'>
      <Link data-event={dataEvent} to={destination}>{text}</Link>
  </BannerLink>
);
