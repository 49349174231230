import React from 'react';
import {
  Modal,
  Button_t2_,
  Text,
  Box
} from '@amount/frontend-components';

import SessionManager from '../../../util/sessionManager'

export const LoggedOut: React.FunctionComponent = () =>  {
  const loggedOutConfig = AvantConfig.TenantConfig.session.logout;

  return (
    <Modal
      isShown={true}
      ariaModal={true}
      ariaLabelledby='logout-modal'
      cardWidth={{ max: 'narrow' }}
      heading={{
        text: loggedOutConfig.headingText,
        styles: {
          align: 'center',
          family: 'optimist_light',
          lineHeight: '1.2',
          margin: { horizontal: 'auto' },
          size: 'heroLarge',
        }
      }}
      handleModalClose={() => location.href = SessionManager.urlRouter.loginUrl}
    >
     <Box margin={{top: '3x', bottom: '4x' }} direction='column'>
        {Object.values(loggedOutConfig.bodyText).map((bodyText, idx) => (
          <Text key={`logout-text-${idx}`} lineHeight='1.5' align='center'>
            {bodyText}
          </Text>
        ))}
      </Box>
      <Box justifyContent='center'>
        <Button_t2_
          variant='primary'
          flexBasis={{ base: '100%', medium: '55%' }}
          onClick={() => location.href = SessionManager.urlRouter.loginUrl}
        >
          {loggedOutConfig.logInButtonText}
        </Button_t2_>
      </Box>
    </Modal>
  )
};
