import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';

import { IUUIDRouteProps } from '../../../constants';
import { ApplicationBaseRoutes } from '../../../routes';

import GetCustomerAndAppIDQuery, {
  GetCustomerAndAppID,
  GetCustomerAndAppIDInput
} from './queries/GetCustomerAndAppId.graphql';
import SupportModalComponent, { ISupportModalProps } from './Presentational';

const isVerifications: (path: string) => boolean = path =>
  path.startsWith(ApplicationBaseRoutes.verify);

const isServicing: (path: string) => boolean = path =>
  path.startsWith(ApplicationBaseRoutes.loanServicing) || path.startsWith(ApplicationBaseRoutes.cardServicing);

const SupportModal: React.FunctionComponent<ISupportModalProps & RouteComponentProps<IUUIDRouteProps>> = props => (
  <Query<GetCustomerAndAppID, GetCustomerAndAppIDInput>
    query={GetCustomerAndAppIDQuery}
    variables={{
      uuid: props.match.params.uuid,
      hasApplication: isVerifications(props.match.path),
      hasProduct: isServicing(props.match.path)
    }}
  >
    {({ data, loading }) => (
      !loading && <SupportModalComponent {...props} data={data} />
    )}
  </Query>
);

export default withRouter(
  SupportModal
);
