import React from 'react';
import Styled from 'styled-components';
import { Banner, MaxWidthContainer } from '@amount/frontend-components';
import sanitizeHtml from 'sanitize-html';

import { BannerTextWrapperStyles } from './BannerTextWrapper';

interface IGlobalWarningBannerProps {
  htmlContent?: string | null;
}

const bannerStyle = AvantConfig.TenantConfig.warningBanner.style;

const GlobalBannerTextWrapper = Styled(MaxWidthContainer)`
  ${BannerTextWrapperStyles}

  a {
    color: ${({ theme: { banner } }) => banner[bannerStyle].colorLink};
    font-weight: ${({ theme: { banner }}) => banner.linkTextWeight};
    text-decoration: underline;
  }
`;

export const GlobalWarningBanner: React.FC<IGlobalWarningBannerProps> = ({ htmlContent }) => {
  if (!htmlContent) {
    return null;
  }

  return (
    <Banner bannerStyle={bannerStyle}>
      <GlobalBannerTextWrapper>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(htmlContent) }} />
      </GlobalBannerTextWrapper>
    </Banner>
  );
};
