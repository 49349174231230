import React from 'react';

import { ServicingRoutes } from '../../../../../../routes';
import { BannerNotificationsProps } from '../../types';

import { ActiveFinancialSituationReview } from './ActiveFinancialSituationReview';

type LossMitigationAcceptanceProps = Pick<BannerNotificationsProps, 'lossMitigationOfferExpirationDate' | 'uuid'>;
export const LossMitigationAcceptance: React.FC<LossMitigationAcceptanceProps> = ({ lossMitigationOfferExpirationDate, uuid}) => (
  <ActiveFinancialSituationReview
    uuid={uuid}
    bannerText='Your payment plan offer expires on {0}'
    bannerLink='View Offer'
    expirationDate={lossMitigationOfferExpirationDate || ''}
    workflowRoute={ServicingRoutes.paymentPlanEnrollment}
  />
);
