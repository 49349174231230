import React from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import {
    CardLabel,
    HorizontalDivider,
    HTableKey,
    HTableRow,
    HTableValue,
    InlineTextLink,
  } from '@amount/frontend-components';

const LinkWrapper = Styled.div<{ pad?: boolean }>`
  ${props => props.pad && `padding-left: 0.5em`};
  display: inline-block;
`;

const HTableContain = Styled.div`
  margin: 0 0 2.75em;
`;

const HTableRowSettings = Styled(HTableRow)`
  padding: .8em 0 0 0;
`;

export interface ILink {
  text: string;
  linkUrl: string;
  labelledBy?: string;
  dataEvent: string;
  id?: string;
}

export interface IHTableRow {
  label?: string;
  key: string;
  value: string | null;
  isHtml?: boolean;
  link?: ILink;
  id?: string;
}

interface IHTable {
  title: string;
  titleLink?: ILink;
  rows: IHTableRow[];
  titleId?: string;
  supportInfo?: {
    supportPhone?: string;
    supportEmail?: string;
  };
}

const TableLabel = Styled(CardLabel)`
  display: inline-block;
`;

const TableSubtitle = Styled.div`
  margin-bottom: 1em;
`;

const SettingsTable: React.FunctionComponent<IHTable> = props => (
  <HTableContain>
    <TableLabel id={props.titleId} as='h2'>{props.title}</TableLabel>
    {props.titleLink && (
      <LinkWrapper pad={true}>
        <Link
          id={props.titleLink.id}
          aria-labelledby={props.titleLink.labelledBy}
          data-event={props.titleLink.dataEvent}
          to={props.titleLink.linkUrl}
        >
          {props.titleLink.text}
        </Link>
      </LinkWrapper>
    )}
    {props.supportInfo && (
      <TableSubtitle>
        To update your name, address, or email, call{' '}
        <InlineTextLink href={`tel:${props.supportInfo.supportPhone}`}>{props.supportInfo.supportPhone}</InlineTextLink>
        {' '}or email{' '}
        <InlineTextLink href={`mailto:${props.supportInfo.supportEmail}`}>{props.supportInfo.supportEmail}</InlineTextLink>
      </TableSubtitle>
    )}
    <HorizontalDivider />
    <dl>
      {props.rows.map(row => (
        <HTableRowSettings key={row.key}>
          <HTableKey id={row.id} as='dt'>{row.label || row.key}</HTableKey>
          <HTableValue as='dd'>
            {row.isHtml && row.value
              // eslint-disable-next-line react/no-danger
              ? <span dangerouslySetInnerHTML={{ __html: row.value}} />
              : <span>{row.value}</span>
            }
            {row.link && (
              <LinkWrapper pad={!!row.value}>
                <Link
                  id={row.link.id}
                  aria-labelledby={row.link.labelledBy}
                  data-event={row.link.dataEvent}
                  to={row.link.linkUrl}
                >
                  {row.link.text}
                </Link>
              </LinkWrapper>
            )}
          </HTableValue>
        </HTableRowSettings>
      ))}
    </dl>
  </HTableContain>
);

export default SettingsTable;
