import React, { useEffect, useState } from 'react';
import ReactConfetti, { Props as ReactConfettiProps } from 'react-confetti';
import { theme } from '@amount/frontend-components';
import { transparentize } from 'polished';

enum ShapeType {
  SmallRectangle,
  Rectangle,
  Circle,
  Bentangle
}

const numberOfShapes: number = Object.keys(ShapeType).length;

const rectangleWidth: number = 12;
const rectangleHeight: number = 28;
const smallRectangleWidth: number = 5;
const smallRectangleHeight: number = 10;
const bentangleWidth: number = 20;
const bentangleHeight: number = 8;
const circleRadius: number = 5;

// eslint-disable-next-line
const getRandomInt: (min: number, max: number) => number = (max, min) => Math.floor(Math.random() * (max - min)) + min;

interface IParticle {
  shapeType?: ShapeType;
}

// This needs to be a traditional function so the Particle object's this context is passed in.
function drawConfettiShape (ctx: CanvasRenderingContext2D) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-this-alias, no-invalid-this
  const particle: IParticle = this;

  if (!particle.shapeType && particle.shapeType !== 0) {
    const shapeType: number = getRandomInt(0, numberOfShapes);
    particle.shapeType = shapeType;
  }

  switch (particle.shapeType) {
    case ShapeType.Rectangle:
      ctx.fillRect(-rectangleWidth / 2, -rectangleHeight / 2, rectangleWidth, rectangleHeight);
      break;
    case ShapeType.Circle:
      ctx.beginPath();
      ctx.arc(0, 0, circleRadius, 0, Math.PI * 2);
      ctx.stroke();
      ctx.fill();
      break;
    case ShapeType.SmallRectangle:
      ctx.fillRect(-smallRectangleWidth / 2, -smallRectangleHeight / 2, smallRectangleWidth, smallRectangleHeight);
      break;
    case ShapeType.Bentangle:
      const x: number = 0;
      const y: number = 0;
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x, y + bentangleHeight);
      ctx.quadraticCurveTo(bentangleWidth / 2, y + (bentangleHeight * 2), x + bentangleWidth, y + bentangleHeight);
      ctx.lineTo(x + bentangleWidth, y);
      ctx.quadraticCurveTo(bentangleWidth / 2, y + bentangleHeight, x, y);
      ctx.stroke();
      ctx.fill();
      break;
    default:
  }
  ctx.closePath();
  ctx.restore();
}

const halfOpacity: number = 0.5;
const DefaultConfettiSettings: ReactConfettiProps = {
  colors: [theme.brand.colorPrimary, transparentize(halfOpacity, theme.brand.colorPrimary)],
  opacity: 0.9,
  initialVelocityY: -15,
  numberOfPieces: 150
};

export const Confetti: React.FC<ReactConfettiProps> = props => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(document.documentElement.scrollHeight);

  useEffect(() => {
    const onResize: () => void = () => {
      setWidth(window.innerWidth);
      setHeight(document.documentElement.scrollHeight);
    };

    window.addEventListener('resize', onResize);

    // Removes event listener on cleanup
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return (
    <ReactConfetti
      width={width}
      height={height}
      style={{ zIndex: 9999 }} // Needed to be on top of everything
      drawShape={drawConfettiShape}
      {...DefaultConfettiSettings}
      {...props} // overwrites default settings if props provided
    />
  );
};
