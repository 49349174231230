import React from 'react';
import { Link } from 'react-router-dom';
import { Logo as AvantLogo, HeaderLogo } from '@amount/frontend-components';
import { CardBrand } from 'src/g1/servicing/components/CreditCard/CardDash/common';
import MLSLogo from './MLSLogo';

const logoComponents: { [key in CardBrand]: React.FC } = {
  [CardBrand.MLS]: MLSLogo,
  [CardBrand.AVANT]: AvantLogo
}

const HeaderLogoContainer: React.FC<{ cardBrand?: string | null}> = ({ cardBrand }) => {

  const LogoComponent = cardBrand && logoComponents[cardBrand.toLowerCase()] ? logoComponents[cardBrand.toLowerCase()]
  : logoComponents[CardBrand.AVANT];

  return (
      <HeaderLogo>
      <Link
        to="/"
        data-event="homeLogo"
        aria-label={AvantConfig.TenantConfig.logo.linkAriaLabel}
      >
        <LogoComponent data-test-id="header-logo" />
      </Link>
    </HeaderLogo>
  )
};

export default HeaderLogoContainer;
