import React, { ReactElement } from 'react';
import Styled from 'styled-components';
import {
  Headline,
  HorizontalDivider,
  MaxWidthContainer,
  media,
} from '@amount/frontend-components';
import { isLoan } from '@amount/frontend-product-utilities';

import {
  FragmentSelectionOnCreditCardAccount,
  FragmentSelectionOnLoan,
  SelectionOnCustomerApplication,
  SelectionOnCustomerApplicationConnection,
  SelectionOnProductConnection,
} from '../queries/GetAccountHistoryData.graphql';
import ServerError from '../../Error/ServerError';
import { ContentPaddingContainer, FlexColumn, NoDataWrapper, SettingsTableLayout } from '../../CommonStyles';
import { IAccountHistoryProps } from '../common';
import { mergeSortedArrays } from '../../../../util/mergeSortedArrays';

import { Application } from './Application';
import { IssuedLoan } from './Loan/IssuedLoan';
import { IssuedCreditCard } from './CreditCard/IssuedCreditCard';

const HeaderContainer = Styled.div`
  padding-bottom: 1.25em;
`;

const AccountHistoryContainer = Styled(MaxWidthContainer)`
  flex-direction: column;
`;

const FlexDiv = Styled(FlexColumn)`
  justify-content: space-between;

  strong {
    line-height: 24px;
  }

  ${media.small`
    flex-direction: row;
  `}
`;

interface IAccountHistoryListProps {
  customerApplications: SelectionOnCustomerApplicationConnection;
  products: SelectionOnProductConnection;
}

interface IIssuedProductProps {
  product: FragmentSelectionOnCreditCardAccount | FragmentSelectionOnLoan;
  rowIndex: number;
}

interface IInfo {
  date?: string;
  component: React.ReactNode;
}

const renderProduct: (props: IIssuedProductProps) => ReactElement = ({ product, rowIndex }) => (
  <FlexColumn key={product.uuid}>
    <FlexDiv>
      {isLoan(product)
        ? <IssuedLoan product={product} rowIndex={rowIndex} />
        : <IssuedCreditCard product={product} rowIndex={rowIndex} />
      }
    </FlexDiv>
    <HorizontalDivider margin='1em 0' />
  </FlexColumn>
);

const renderApplication: (app: SelectionOnCustomerApplication) => ReactElement = app => (
  <FlexColumn key={app.uuid}>
    <FlexDiv>
      <FlexColumn>
        <Application application={app} />
      </FlexColumn>
    </FlexDiv>
    <HorizontalDivider margin='1em 0' />
  </FlexColumn>
);

const AccountHistoryList: React.FC<IAccountHistoryListProps> = ({
  customerApplications,
  products,
}) => {
  if (!customerApplications.edges.length && !products.edges.length) {
    return <NoDataWrapper>You have no past products or past applications at this time.</NoDataWrapper>;
  }

  // Applications do not always have a Product associated with them. Therefore we must loop through both
  // a customer's Products and Applications. While adding Products to the list we add the Application
  // uuid to this set to ensure we do not display the same Application twice.
  const applicationSet: Set<string> = new Set<string>();
  const productList: IInfo[] = products.edges.reduce<IInfo[]>(
    (acc, curr, rowIndex) => {
      const product = curr.node;

      if (product.isActive || product.wasCanceled) {
        acc.push({
          date: product.customerApplication.createdAt || '',
          component: renderProduct({ product, rowIndex })
        });
        applicationSet.add(product.customerApplication.uuid);
      }

      return acc;
    },
    []
  );

  const appList: IInfo[] = customerApplications.edges.reduce<IInfo[]>(
    (acc, curr) => {
      const { node: { uuid, isOpen, declined, createdAt} } = curr;

      // Only show declined or closed applications
      if ((declined || !isOpen) && !applicationSet.has(uuid)) {
        acc.push({
          date: createdAt || '',
          component: renderApplication(curr.node)
        });
      }

      return acc;
    },
    []
  );

  if (!appList.length && !productList.length) {
    return (
      <NoDataWrapper>You have no past products or past applications at this time.</NoDataWrapper>
    );
  } else {
    const sortedList = mergeSortedArrays(appList, productList);

    return (
      <>
        {sortedList.map(({ component }) => component)}
      </>
    );
  }
};

export const AccountHistory: React.FunctionComponent<IAccountHistoryProps> = props => {
  const {
    data: { me } = { me: null },
  } = props;

  if (!me || (!me.customerApplications && !me.products)) {
    return <ServerError />;
  }

  return (
    <AccountHistoryContainer>
      <SettingsTableLayout as='div'>
        <ContentPaddingContainer>
          <HeaderContainer>
            <Headline scale='large' as='h1'>Account History</Headline>
          </HeaderContainer>
          <AccountHistoryList {...me} />
        </ContentPaddingContainer>
      </SettingsTableLayout>
    </AccountHistoryContainer>
  );
};
