import React from 'react';
import { Pill, VALID_COLOR } from '@amount/frontend-components';

import { InfoText } from '../../common';
import { formatDate } from '../../../../../util/date';
import { ICreditCardProps, ProductHeader } from '../common';

const title = ({product: { creditCard }}: ICreditCardProps) =>
  `Credit Card${creditCard ? ` ${creditCard.lastFour}` : ''}`;

const pill = (
  { product: { isSoldOff } }: ICreditCardProps) => {
  const text = isSoldOff ? 'Sold'
    : 'Active';

  const color: VALID_COLOR = (isSoldOff)
    ? 'inactive'
    : 'secondary';

  return (
    <Pill pillColor={color}>{text}</Pill>
  );
};


const info = ({ product: { createdAt }}: ICreditCardProps) =>
  `Issued ${formatDate(createdAt)}`;

export const CreditCardDescription: React.FC<ICreditCardProps> = props => {
  const { product: { id } } = props;

  return (
    <>
      <ProductHeader>
        <strong>{title(props)}</strong>{pill(props)}
      </ProductHeader>
      <InfoText>{info(props)}</InfoText>
      <InfoText>ID #{id}</InfoText>
    </>
  );
};
