import React from 'react';
import { ScreenReaderOnly } from '@amount/frontend-components';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

type NewWindowLinkType = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'rel'> ;

const NewWindowLink: React.FunctionComponent<NewWindowLinkType> = props => (
  <a target='_blank' rel='noopener noreferrer' {...props}>
    {props.children}
    <ScreenReaderOnly>Link opens in a new window</ScreenReaderOnly>
  </a>
);

export default NewWindowLink;
