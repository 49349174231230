import * as React from 'react';
import { Headline } from '@amount/frontend-components';

import { devToolsEnabled } from '../../common';
import { SectionWrapper } from '../common';

import { IssueAndFundProductG2Section } from './IssueAndFundProductG2';
import { IssueAndFundProductSection } from './IssueAndFundProduct';
import { AppContext } from '@Util/AppContextWrapper/AppContext';

export const CustomerHomeTools: React.FC = () => {
  if (!devToolsEnabled()) {
    return null;
  }

  const { enableG2Dashboards } = React.useContext(AppContext);

  return (
    <SectionWrapper>
      <Headline scale='small' as='h1'>CustomerHomeTools</Headline>
      {enableG2Dashboards ? <IssueAndFundProductG2Section /> : <IssueAndFundProductSection />}
    </SectionWrapper>
  );
};
