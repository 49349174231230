import React from 'react';
import Styled from 'styled-components';
import {
  Caption,
  CardLabel,
} from '@amount/frontend-components';

import { ITransferredLoanContent } from './common';

const Paragraph = Styled(Caption)<{ padding?: string }>`
  ${props => props.padding && `padding: ${props.padding};`}
`;

export const TransferredLoanContent: React.FunctionComponent<ITransferredLoanContent> = () => {
  const transferredAccount = AvantConfig.TenantConfig.transferredAccount;

  if (!transferredAccount) { return null; }
  const { phoneNumber, transferredPartner, closingDateNotice } = transferredAccount;

  return (
    <>
      <CardLabel as='h3'>
        Your {transferredPartner} loan application and closing process are complete.
      </CardLabel>
      <Paragraph padding='0.50em 0'>
        Please check your email inbox for confirmation of your loan closing and don’t forget to save a copy of your {transferredPartner}
        {' '}Account Agreement for your records.
      </Paragraph>
      <Paragraph>
        {closingDateNotice}
        {phoneNumber && (
          <>
            {' '}Please contact{' '}
            <a href={`tel:${phoneNumber.dialable}`}>
              {phoneNumber.readable}
            </a> for funding questions or concerns.
          </>
        )}
      </Paragraph>
      {transferredAccount.showThankYou && (
        <Paragraph>
          Thank you for choosing {transferredPartner}.
        </Paragraph>
      )}
    </>
  );
};
