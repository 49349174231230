/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Box, Text, Link, ITextProps } from '@amount/frontend-components';

import {
  Divider,
  HalfContainer,
} from '../styledComponents';
import { NextPaymentLoanCard, NextPaymentLoanCardProps } from './NextPaymentLoanCard';
import {
  SelectionOnConsumerProduct,
  SelectionOnConsumerRepaymentInformation,
  SelectionOnConsumerPayoffInformation,
  SelectionOnVirtualCardToken,
  SelectionOnConsumerPayment1,
} from '../../queries/GetCustomerHomeG2.graphql';
import { ProductStatuses } from '@Constants';
import { isSplitPayProductSubtype } from 'src/g2/servicing/components/PaymentMethods/utils/isSplitPayProductSubtype';
import { RepaymentCircularProgress } from 'src/g2/servicing/components/AccountManagementDash/_shared/RepaymentCircularProgress';

const {
  cardCopy,
  nextPaymentLoanCard,
  downloadContractLinkCopy,
  repaymentProgress
} = AvantConfig.TenantConfig.customerHome.loanProductCard;

const headingProps: ITextProps = {
  size: 'heroSmall',
  lineHeight: '1.2',
  padding: { bottom: '2x' },
  forwardedAs: 'h2',
}

interface ILoanContentProps {
  hasUnresolvedPaymentProtectionDispute: boolean;
  product: SelectionOnConsumerProduct;
  futurePayments: Array<SelectionOnConsumerPayment1 | null>;
  repaymentInformation: SelectionOnConsumerRepaymentInformation;
  payoffInfo: SelectionOnConsumerPayoffInformation;
  virtualCard: SelectionOnVirtualCardToken | null;
  isDashboardAccessible: boolean;
  opt_out_autopay_bk: boolean;
}

export const LoanContent: React.FunctionComponent<ILoanContentProps> = ({
  futurePayments,
  hasUnresolvedPaymentProtectionDispute,
  product,
  repaymentInformation,
  payoffInfo,
  virtualCard,
  isDashboardAccessible,
  opt_out_autopay_bk,
}) => {
  const { adjusted_amount_progress, adjusted_paid_amount, adjusted_remaining_amount } = repaymentInformation;

  const formattedPaidPrincipal = adjusted_paid_amount.formatted
  const formattedPrincipalOutstandingAmount = adjusted_remaining_amount.formatted
  const repaymentCircularProgressProps = { adjusted_amount_progress, formattedPaidPrincipal, formattedPrincipalOutstandingAmount }

  const amountDueText = product.is_past_due && product.past_due_amount
    ? nextPaymentLoanCard.heading.late
    : nextPaymentLoanCard.heading.default;

  const nextPaymentCardProps: NextPaymentLoanCardProps = {
    hasUnresolvedPaymentProtectionDispute,
    is_autopay: product.is_autopay,
    is_past_due: product.is_past_due,
    past_due_amount: product.past_due_amount,
    payments: futurePayments,
    product_status: product.status,
    payoffInfo,
    nextNonZeroInstallmentPayment: product.next_non_zero_installment_payment,
    nextInstallmentAmount: product.next_installment_amount,
    nextInstallmentOriginalAmount: product.next_installment_original_amount,
    nextInstallmentDate: product.next_installment_date,
    nextPaymentScheduled: product.is_next_payment_scheduled,
    opt_out_autopay_bk,
  }

  const isProductAuthorized = product.status === ProductStatuses.Authorized;
  const isSplitPay = isSplitPayProductSubtype(product.product_subtype);
  const isVCUsed = !!virtualCard?.is_used;
  const isVCExpired = !!virtualCard?.is_expired;

  return (
    <>
      {!isDashboardAccessible ? (
        <Box direction='column' padding={{ top: '2x' }}>
          {shouldShowDashboardSettingUpMessage({ isVCUsed, isVCExpired, isProductAuthorized }) && (
            <Text margin={{ bottom: '2x' }}>
              {withoutLoanDetailsCardCopy({ isVCUsed, isSplitPay, isProductAuthorized })}
            </Text>
          )}
          <Text>
            <Link.inline
              target={AvantConfig.TenantConfig.documentLinkTarget}
              href={product.contract_url}
            >
              {downloadContractLinkCopy}
            </Link.inline>
          </Text>
        </Box>
      ) : (
        <>
          <Box display={{ base: 'none', medium: 'flex' }} direction='row' flexWrap='nowrap' padding={{ top: '3x' }}>
            <HalfContainer>
              <Text {...headingProps}>
                {repaymentProgress.heading}
              </Text>
              <RepaymentCircularProgress {...repaymentCircularProgressProps} />
            </HalfContainer>
            <Divider />
            <HalfContainer>
              <Text {...headingProps}>
                {amountDueText}
              </Text>
              <NextPaymentLoanCard {...nextPaymentCardProps} />
            </HalfContainer>
          </Box>
          <Box display={{ base: 'block', medium: 'none' }} padding={{ top: '0' }}>
            <HalfContainer>
              <Text {...headingProps}>
                {amountDueText}
              </Text>
              <NextPaymentLoanCard {...nextPaymentCardProps} />
            </HalfContainer>
            <Divider />
            <HalfContainer>
              <Text {...headingProps}>
                {repaymentProgress.heading}
              </Text>
              <RepaymentCircularProgress {...repaymentCircularProgressProps} />
            </HalfContainer>
          </Box>
        </>
      )}
    </>
  );
};

function withoutLoanDetailsCardCopy ({
  isVCUsed, isSplitPay, isProductAuthorized
}: {
  isVCUsed: boolean;
  isSplitPay: boolean;
  isProductAuthorized: boolean;
}) {
  if (!isSplitPay && isVCUsed && isProductAuthorized) {
    return cardCopy.virtualCardUsed;
  }

  if (!isSplitPay) {
    return cardCopy.virtualCardUnusedUIL;
  } else if (isVCUsed && isProductAuthorized) {
    return cardCopy.virtualCardUsedButAuthorizedSplitPay;
  } else {
    return cardCopy.virtualCardUnusedSplitPay;
  }
}

function shouldShowDashboardSettingUpMessage ({
  isVCExpired, isVCUsed, isProductAuthorized
}: {
  isVCExpired: boolean;
  isVCUsed: boolean;
  isProductAuthorized: boolean;
}) {
  if (isVCExpired && !isVCUsed) {
    return false;
  }

  if (isProductAuthorized || !isVCUsed) {
    return true;
  }

  return false;
}
