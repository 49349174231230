import React from 'react';

import { formatDate } from '../../../../../../util/date';

import { PayoffQuoteProps } from './common';

export const PaperCheckPayoffQuote: React.FunctionComponent<PayoffQuoteProps> = ({ payoffInformation }) => (
  <span>
      Please mail a check {!!AvantConfig.TenantConfig.mailAddress && <>to <strong>{AvantConfig.TenantConfig.mailAddress}</strong></>}
      {' '}with your payment of <strong>{payoffInformation?.scheduledQuote?.amount.formatted || ''}</strong>
      {' '}by <strong>{formatDate(payoffInformation?.scheduledQuote?.paymentScheduledDate || '')}</strong> to pay off your loan.
      {' '}We've sent you an email with more details.
  </span>
);
