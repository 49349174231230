import Styled from 'styled-components';

import {
  FormError as FECError,
  SVGIcon
} from '@amount/frontend-components';
import React from 'react';

interface IFormErrorProps {
  id?: string;
}

const FormErrorWithIconInline = Styled.div`
  display: flex;
  flex-direction: row;
`;

const FormErrorInlineIcon = Styled.div`
  padding-right: 0.4em;
  align-items: center;
  margin: 0.5em 0 0 0;
  svg {
    width: 1.1em;
    height: 1.1em;
    fill: ${({ theme }) => theme.brand.colorAlert};
  }
`;

export const FormError: React.FunctionComponent<IFormErrorProps> = props =>
  (
    <FormErrorWithIconInline>
      {
        !!AvantConfig.TenantConfig.showIconWithErrorMessage && (
          <FormErrorInlineIcon>
            <SVGIcon icon='circle-delete' />
          </FormErrorInlineIcon>
        )
      }
      <FECError {...props}>
        {props.children}
      </FECError>
    </FormErrorWithIconInline>
  );
