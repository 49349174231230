import React from 'react';
import {
  ModalContent,
  ModalCtaButton,
  ModalDialog,
  ModalOverlay
} from '@amount/frontend-components';

import SessionManager from '../../../util/sessionManager';
import { ModalHeadline } from '../CommonStyles';

export const LoggedOut: React.FunctionComponent = () => (
  <ModalOverlay show={true}>
    <ModalDialog>
      <ModalContent>
        <ModalHeadline
          scale='medium'
          as='h2'
        >
          You must log in to continue
        </ModalHeadline>
        <a href={SessionManager.urlRouter.loginUrl}>
          <ModalCtaButton>
            Log In
          </ModalCtaButton>
        </a>
      </ModalContent>
    </ModalDialog>
  </ModalOverlay>
);
