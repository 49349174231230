import React from 'react';
import Styled from 'styled-components';
import { Spinner as AvantSpinner } from '@amount/frontend-components';

interface IAvantSpinnerProps extends React.SVGAttributes<SVGElement> {
  small?: boolean;
}

interface ISpinnerProps extends IAvantSpinnerProps {
  margin?: string;
}

const SpinnerWrap = Styled.div<ISpinnerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const Spinner: React.FunctionComponent<ISpinnerProps> = ({
  margin,
  ...rest
}) => (
  <SpinnerWrap margin={margin}>
    <AvantSpinner {...rest} />
  </SpinnerWrap>
);
Spinner.displayName = 'Spinner';

Spinner.defaultProps = {
  margin: '80px 0'
};

export default Spinner;
