import React from 'react';

import { LoanBanner } from '.';
import { BannerBaseProps } from '../index';

const { dischargedBankruptcy } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages

export const BankruptcyDischargedBanner: React.FC<BannerBaseProps> = ({variant}) => (
  <LoanBanner variant={variant}>
    <span>{dischargedBankruptcy}</span>
  </LoanBanner>
);
