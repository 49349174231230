import React from 'react';
import { Link } from 'react-router-dom';

import { useNewCardDash } from '../../../util';
import sessionManager from '../../../util/sessionManager';

interface ICreditCardLinkProps {
  uuid: string;
  link: IAnalyticCardLink;
}

export interface ICardLink {
  text: CCLinkText;
  url: string;
}

export interface IAnalyticCardLink extends ICardLink {
  dataEvent: string;
}

export enum CCLinkText {
  MAKE_PAYMENT = 'Make a Payment',
  ENROLL_IN_AUTOPAY = 'Enroll in Autopay',
  ACTIVATE_NOW = 'Activate Now',
  UPDATE_YOUR_FINANCIAL_INFORMATION = 'Update Your Financial Information',
  FINANCIAL_SITUATION_REVIEW = 'See plan options'
}

const getOldCCDashboardUrl: (text: CCLinkText, uuid: string) => string = (text, uuid) => {
  switch (text) {
    case CCLinkText.MAKE_PAYMENT:
      return sessionManager.urlRouter.getCardMakePaymentUrl(uuid);
    case CCLinkText.ENROLL_IN_AUTOPAY:
      return sessionManager.urlRouter.getCardAutopayUrl(uuid);
    case CCLinkText.ACTIVATE_NOW:
      return sessionManager.urlRouter.getCardActivationUrl(uuid);
    case CCLinkText.UPDATE_YOUR_FINANCIAL_INFORMATION:
      return sessionManager.urlRouter.getUpdateFinancialInformationUrl(uuid);
    default:
      return sessionManager.urlRouter.cardUrl;
  }
};

export const CreditCardLink: React.FunctionComponent<ICreditCardLinkProps> = ({ link, uuid }) => {
  const { url, text, dataEvent } = link;
  if (useNewCardDash()) {

    return <Link data-event={dataEvent} to={url}>{text}</Link>;
  }

  // Old dash, can remove once CC fully implemented
  return (
    <a data-event={dataEvent} href={getOldCCDashboardUrl(text, uuid)}>
      {text}
    </a>
  );
};
