import React from 'react';
import Styled from 'styled-components';
import { ModalClose, ModalCtaButton, NavButton } from '@amount/frontend-components';
import * as Sentry from '@sentry/browser';

import SessionManager from '../../../../util/sessionManager';
import Modal, { IModalProps } from '../../Modal';
import { ModalHeadline } from '../../CommonStyles';
import ServerError from '../../Error/ServerError';

const StyledModalCtaButton = Styled(ModalCtaButton)`
  width: 75%;
`;

const StyledNavButton = Styled(NavButton)`
  margin: 1em auto;
`;

interface IReturnToMerchant extends IModalProps {
  merchantName?: string;
  applicationUuid?: string;
  applicationId?: string;
}

export const returnToMerchantRedirect: (applicationId?: string, applicationUuid?: string) => void = (
  applicationId, applicationUuid
) => {
  if (!(applicationId && applicationUuid)) {
    Sentry.captureMessage('Failed to redirect to merchant: Id not provided', Sentry.Severity.Error);
    return;
  }
  SessionManager.redirectToMerchantDeclineWithSession({uuid: applicationUuid, id: applicationId});
};

// no safety from missing props an issue?
export const ReturnToMerchantModal: React.FC<IReturnToMerchant> = props => {
  const { close, show, merchantName, applicationUuid, applicationId } = props;

  if (!applicationId || !applicationUuid) {
    Sentry.captureMessage('Failed to redirect to merchant: Id not provided', Sentry.Severity.Error);

    return <ServerError />;
  }
  const returnToMerchantAction = () => returnToMerchantRedirect(applicationId, applicationUuid);

  return (
    <Modal show={show} close={close} aria-labelledby='return-to-merchant-modal-header' >
      <ModalClose data-event='closeReturnToMerchantModal' close={close} />
      <ModalHeadline id='return-to-merchant-modal-header' scale='medium' as='h2'>
        You are about to leave {AvantConfig.TenantConfig.companyName}
      </ModalHeadline>
      <p>You will be taken back to {merchantName}</p>
      <StyledModalCtaButton data-event='returnToMerchant' onClick={returnToMerchantAction}>
        Go to {merchantName}
      </StyledModalCtaButton>
      <StyledNavButton data-event='closeReturnToMerchantModal' onClick={close}>
        Cancel
      </StyledNavButton>
    </Modal>
  );
};
