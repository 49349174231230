import Styled from 'styled-components';
import { NavButton } from '@amount/frontend-components';

export const StandaloneButton = Styled(NavButton)`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 0.375em;
    height: 0.75em;
    width: 0.75em;
  }
`;

export const StandaloneButtonWithIcon = StandaloneButton;
