import React from 'react';

import { LoanBanner } from '../';

export const DashboardSetupBanner: React.FC = () => (
  <LoanBanner>
    <span>
      Your loan dashboard is being set up. We'll send you an email when its ready for you to access.
    </span>
  </LoanBanner>
);
