import React from 'react';
import Styled from 'styled-components';
import { NavButton, SVGIcon } from '@amount/frontend-components';
import { Link } from 'react-router-dom';

import { PaddedStandaloneLinkWrapper } from '../../Common/StandaloneLink';

import { ApplicationCardCtaProps } from './common';

export const AlignedNavButton = Styled(NavButton)`
  align-items: center;
`;

const ApplicationCardCta: React.FunctionComponent<ApplicationCardCtaProps> = props => {
  const {
    cardLink,
    dataEvent
  } = props;

  return (
    <>
      {cardLink && (
        <PaddedStandaloneLinkWrapper>
          {({ icon }) => {
            const sharedProps = {
              'aria-labelledby': 'card-headline',
              'data-event': dataEvent
            };
            const SvgIcon: React.ReactNode = <SVGIcon icon={icon} iconStyle='link' />;

            if (cardLink.onClick) {
              return (
                // eslint-disable-next-line @typescript-eslint/unbound-method
                <AlignedNavButton {...sharedProps} onClick={cardLink.onClick}>
                  {cardLink.text}
                  {SvgIcon}
                </AlignedNavButton>
              );
            } else if (cardLink.route) {
              return cardLink.redirect
                ? (
                  <a {...sharedProps} href={cardLink.route}>
                    {cardLink.text}
                    {SvgIcon}
                  </a>
                )
                : (
                  <Link {...sharedProps} to={cardLink.route}>
                    {cardLink.text}
                    {SvgIcon}
                  </Link>
                );
            }

            return null;
          }}
        </PaddedStandaloneLinkWrapper>
      )}
    </>
  );
};

export default ApplicationCardCta;
