import { resetScrollToTop } from '@Util/scrollToTop';
import React, { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from 'src/g2/servicing/modules/shared/types';
import ServerError from '../../../../../components/Common/Error/ServerError';
import { initialState } from './common/constants';

import {
  ServicingPageContextStateProps,
  ServicingPageContextActionProps,
  servicingTaskContextReducer,
  ServicingPageContextAction
} from './utils/servicingPageReducer';

interface ServicingPageContextProps {
  state: ServicingPageContextStateProps,
  dispatch: Dispatch<ServicingPageContextActionProps>
}

export const ServicingPageContext = React.createContext<ServicingPageContextProps>({
  state: initialState,
  dispatch: () => null,
});

interface ServicingPageContextProviderProps {
  children: React.ReactChild;
  userContext?: UserContext;
}

export const ServicingPageContextProvider: React.FC<ServicingPageContextProviderProps> = ({ children, userContext }) => {
  const [state, dispatch] = useReducer(servicingTaskContextReducer, {...initialState, userContext});

  const { hasServerError } = state;

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const history = useHistory();

  useEffect(() => {
    return history.listen(() => dispatch({ type: ServicingPageContextAction.CLEAR_ALL_BANNERS }))
  }, [history.location.pathname]);

  useEffect(() => {
    resetScrollToTop();
  }, [state.availableBanners]);

  return (
    <ServicingPageContext.Provider value={contextValue}>
      {hasServerError && <ServerError />}

      {!hasServerError && children}

    </ServicingPageContext.Provider>
  );
}
