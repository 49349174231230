import React from 'react';
import { paymentMethodsPath } from '../../../../../../../routes';

import { LoanBanner } from '.';
import { BannerBaseProps } from '../index';
import { BannerLinkAndTextWrapper } from '../../common';
import { BannerLink } from '../../../../../../../_shared/ThemeTwo/Link/BannerLink';

const { bannerMessages: { returnedPaymentAndCurrent }, bannerLinks: { paymentMethods: returnedPaymentLinkCopy } }
  = AvantConfig.TenantConfig.accountManagementDash;

export const ReturnedPaymentBanner: React.FC<BannerBaseProps> = ({variant}) => (
  <LoanBanner variant={variant}>
    <BannerLinkAndTextWrapper>
      <span>
        <span>{returnedPaymentAndCurrent}</span>
      </span>
      <BannerLink
        event="paymentMethods"
        to={paymentMethodsPath}
      >
        {returnedPaymentLinkCopy}
      </BannerLink>
    </BannerLinkAndTextWrapper>
  </LoanBanner>
);
