import React from 'react';

import { LoanBanner } from '..';
import { BannerBaseProps } from '../../index';

const { posFullRefund } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages

export const RefundedBanner: React.FC<BannerBaseProps> = ({variant}) => (
  <LoanBanner variant={variant}>
    <span>{posFullRefund}</span>
  </LoanBanner>
);
