import Styled, { css } from 'styled-components';
import {
  BreakPointsMap,
  Button,
  FormRow,
  Headline,
  Input,
  LayoutSkinny,
  media,
  NavButton,
  RowSpan,
  SelectInput,
  HeaderContainer,
} from '@amount/frontend-components';
import React from 'react';

export const FlexColumn = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = Styled.div`
  display: flex;
  flex-direction: row;
`;

export const ConditionalRenderWrapper = Styled.div<{ hiddenOnMobile: boolean }>`
  ${({ hiddenOnMobile }) => !!hiddenOnMobile ? css`
    @media (max-width: ${BreakPointsMap.mediumLarge}em) {
      display: none;
    }
  ` : css`
    @media (min-width: ${BreakPointsMap.mediumLarge}em) {
      display: none;
    }
  `}
`;

export const VerWarningCta = Styled.div`
  margin: 1.5em 0;
`;

export const VerWarningForm = Styled.div`
  overflow: auto;

  ${media.small`
    ${Input} {
      max-width: ${AvantConfig.TenantConfig.boxTheme.shared.inputMaxWidth};
    }
  `}
`;

export const ModalHeadline = Styled(Headline)`
  margin-bottom: 0.75em;
  width: 100%;

  ${media.small`
    margin-bottom: 0.5em;
  `}
`;

export const SplashMasthead = Styled.div`
  width: 100%;
  margin-bottom: 2em;

  ${media.small`
    max-width: 50em;
  `}
`;

export const PhoneFormRow = Styled(FormRow)`
  align-items: center;
  margin-bottom: 1em;
`;

export const InlineFormGroup = Styled.div`
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.5em;
  }

  ${SelectInput} {
    margin-right: 0;
  }

  ${media.small`
    ${SelectInput} {
      width: auto;
    }
  `}
`;

export const InlineLink = Styled.a`
  text-decoration: underline;
  white-space: nowrap;
`;

export const ContentPaddingContainer = Styled.div`
  width: 100%;
  padding: 2em 5%;

  ${media.xlarge`
    padding: 3em 0 2em;
  `}
`;

export const SettingsTableLayout = Styled(LayoutSkinny)`
  ${media.small`
    min-width: 42em;
  `};
`;

export const AutopayWrapper = Styled(FlexRow)<{ paddingTop?: string }>`
  flex-wrap: nowrap;
  align-items: center;
  padding-top: ${({ paddingTop }) => paddingTop || '0.5em'};

  div {
    display: flex;
  }

  svg {
    height: 16px;
    width: 16px;
    margin: 0 0.25em 0 0;
  }
`;

export const FlexCenter = Styled.div`
  display: flex;
  align-items: center;
`;

export const FlexCenterJustify = Styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const CustomMarginRowSpan = Styled(RowSpan)<{ bottomMargin: string }>`
  margin-bottom: ${({ bottomMargin }) => bottomMargin};
`;

export const TooltipInline = Styled(FlexRow)`
  align-items: center;

  svg {
    padding-left: 0.375em;
  }

  /*
   * IE 10+ does not handle SVGs with a viewport without a height or width.
   * see https://css-tricks.com/scale-svg/
   */
  ${media.ie11`
     svg {
       height: 1em; // best guess for tooltips
     }
  `}
`;

export const TablePaddingWrapper = Styled.div`
  padding: 1em 1em;

  ${media.medium`
    padding: 2em 4em;
  `}
`;

export const SelectWrapper = Styled.div`
  flex-grow: 1;
  padding: 1em 0em;

 ${media.medium`
    max-width: 18.75em;
    min-width: 12em;
  `}
`;

export const NoDataWrapper = Styled.div`
  background-color: ${props => props.theme.colorSlate10};
  padding: 1.25em;
  width: fit-content;
  margin: 2em 0 0.5em;
`;

export const DataWrapper = Styled(FlexColumn)`
  flex-wrap: nowrap;
`;

export const InlineButtonContainer = Styled(FlexColumn)`
  align-items: center;
  div {
    width: 100%;
  }

  ${media.small`
    flex-direction: row;
    div {
      width: unset;
    }
  `}

  button,
  a {
    margin-right: 0;
    margin-bottom: 0.5em;

    ${media.small`
      &:last-child {
        margin-right: 2em;
      }

      margin-right: 1em;
      margin-bottom: 0;
    `}
  }
`;

export const MarginButton = Styled(Button)`
  margin-top: 1.5em;
  margin-bottom: 1em;

  ${media.small`
    margin: .5em 0em;
  `}
`;

export const NavButtonMargin = Styled(NavButton)`
  margin-top: 0.8em;
`;

export const NavButtonInline = Styled(NavButton)`
  display: inline;
`;

export const TextOverflow = Styled.p`
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const headerProps = AvantConfig.TenantConfig.header

export const HeaderOuterContainer = Styled(HeaderContainer)`
  height: ${headerProps.height.small};

  ${media.large`
    height: ${headerProps.height.large};
  `}

`;

export const visuallyHiddenStyles = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const PreloadFontBase: React.FunctionComponent<{ fontFamily: string }> = ({ fontFamily }) => (
  <div aria-hidden="true">
    <span style={{ fontFamily }}></span>
  </div>
);

export const PreloadFont = Styled(PreloadFontBase)`
  ${visuallyHiddenStyles}
`
