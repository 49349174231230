import { useEffect } from "react";
import { useLocation } from "react-router";

export function ScrollToTop () {
  const { pathname } = useLocation();

  useEffect(() => {
    resetScrollToTop();
  }, [pathname]);

  return null;
}

export const resetScrollToTop = () => {
  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  } catch (error) {
    // fallback for older browsers
    window.scrollTo(0, 0);
  }
}
