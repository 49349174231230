import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { DocumentNode } from "graphql";

import { Loading } from "../../../../../components/Common/Spinner/LoadingHOC";
import { useServicingPageContext } from "../ServicingPage/utils/useServicingPageContext";


interface LiftedQueryPropsBase<QueryProps> {
  query: DocumentNode;
  dataIsLoaded(data?: QueryProps): boolean;
  renderOnSuccess(data: QueryProps): JSX.Element;
  showLoadingIcon?: boolean;
}

// https://github.com/microsoft/TypeScript/issues/12815#issuecomment-266250230
interface LiftedQueryPropsWithNoVariables<QueryProps> extends LiftedQueryPropsBase<QueryProps> {
  variables?: undefined;
}

interface LiftedQueryPropsWithVariables<QueryProps, VariablesProps> extends LiftedQueryPropsBase<QueryProps> {
  variables?: VariablesProps;
}

type LiftedQueryProps<QueryProps, VariablesProps> =
LiftedQueryPropsWithNoVariables<QueryProps>
  | LiftedQueryPropsWithVariables<QueryProps, VariablesProps>

export function LiftedQuery <QueryProps, VariablesProps = {}> (
  props: LiftedQueryProps<QueryProps, VariablesProps>
): JSX.Element | null {

  const {
    query,
    variables = {},
    dataIsLoaded,
    renderOnSuccess,
    showLoadingIcon = true
  } = props;

  const { setServerError } = useServicingPageContext();

  const { loading, data } = useQuery<QueryProps>(
    query,
    {
      variables,
      fetchPolicy: 'cache-and-network'
    }
  );

  const dataLoadedSuccessfully = dataIsLoaded(data);

  const hasInsufficientData = !loading && !dataLoadedSuccessfully;

  useEffect(() => {
    if ( hasInsufficientData ) { setServerError(); }
  }, [hasInsufficientData]);

  if ( loading && showLoadingIcon ) { return <Loading loading={loading} /> }

  if ( dataLoadedSuccessfully ) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return renderOnSuccess(data!);
  }

  return null;
}
