import React, { useContext } from 'react';
import { AppContext } from '../../../util/AppContextWrapper/AppContext';
import { ThemeTwoServerError } from '../../../_shared/ThemeTwo/Error/ThemeTwoServerError';
import ThemeOneServerError from './ThemeOneServerError';

const ServerError: React.FunctionComponent = () => {

  const { enableG2Dashboards } = useContext(AppContext);

  return (
    enableG2Dashboards ?
      <ThemeTwoServerError />
    :
      <ThemeOneServerError />
  )
};

export default ServerError;
