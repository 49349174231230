import React, { useEffect } from 'react';

import { BannerNotificationsProps } from '../../index';
import { accountCreditKey } from '../../../common';
import { LoanBanner } from '..';
import { formatString } from '../../../../../../../../util/string';
import { FullBannerProps } from '../../types';

const { accountCredit } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages;

export const AccountCreditBanner: React.FC<BannerNotificationsProps> = ({ bannerProps, variant }) => {
  const { servicingAccount: { product, point_of_sale_information } } = bannerProps as FullBannerProps
  const refundAmount = point_of_sale_information.refund_information?.amount?.formatted

  useEffect(() => () => {
    const productIds = JSON.parse(localStorage.getItem(accountCreditKey) || '[]') as string[];
    productIds.push(product.product_uuid);
    localStorage.setItem(accountCreditKey, JSON.stringify(productIds));
  }, []);

  return (
    <LoanBanner variant={variant}>
      <span>
      {formatString(accountCredit, undefined, refundAmount || '')}
      </span>
    </LoanBanner>
  );
};
