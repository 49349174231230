import { VALID_COLOR } from '@amount/frontend-components';

// G2TODO - when converting the associated components to G2
// move these into the bannerSelections tenant configs
export const bankruptcyDischarge: VALID_COLOR = 'inactive';
export const chargeOff: VALID_COLOR = 'inactive';
export const refunded: VALID_COLOR = 'inactive';
export const payOff: VALID_COLOR = 'inactive';
export const accountCredit: VALID_COLOR = 'inactive';
export const activePaymentProtectedDispute: VALID_COLOR = 'alert';
export const activeNonPaymentProtectedDispute: VALID_COLOR = 'alert';
export const resolvedPaymentProtectedDispute: VALID_COLOR = 'inactive';
export const resolvedNonPaymentProtectedDispute: VALID_COLOR = 'inactive';
export const dashboardSetup: VALID_COLOR = 'inactive';
export const paymentPreference: VALID_COLOR = 'inactive';
