import React from 'react';
import Loadable from 'react-loadable';

import { Loading } from './components/Common/Spinner/LoadingHOC';

function loadableWrapper<T> (loader: () => Promise<React.ComponentType<T>>): React.ComponentType<T> & Loadable.LoadableComponent {
    return Loadable({
      loader,
      loading () {
        return <Loading loading={true} />;
      },
      delay: 200,
      timeout: 10000, // 10 seconds
    });
}

export default loadableWrapper;
