import React from 'react';

import { BannerNotificationsProps } from '../index';
import { LoanBanner } from '.';
import { BannerLinkAndTextWrapper } from '../../common';
import { createAccountManagementPath } from 'src/routes';
import { ServicingTasks } from '@Constants';
import { FullBannerProps } from '../types';
import { BannerLink } from '../../../../../../../_shared/ThemeTwo/Link/BannerLink';
import { useServicingTaskRedirect } from 'src/g2/servicing/components/ServicingTasks/utils/useServicingTaskRedirect';

const {
  bannerMessages: { chargeOff, chargeOffWithPayment },
  bannerLinks: { schedulePayment: chargeOffLinkCopy }
} = AvantConfig.TenantConfig.accountManagementDash;

export const ChargeOffBanner: React.FC<BannerNotificationsProps> = ({ variant, servicingAccountUUID, bannerProps }) => {
  const {servicingAccount: { product: { is_make_payment_disqualified: isMakePaymentDisqualified } }} = bannerProps as FullBannerProps;
  const { getTaskLocation } = useServicingTaskRedirect();
  // non-null-assertion handled by checks on respective parent queries
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const makePaymentPath = createAccountManagementPath(servicingAccountUUID!, ServicingTasks.MakePayment.route);

  return (
    <LoanBanner variant={variant}>
      <BannerLinkAndTextWrapper>
        <span>{isMakePaymentDisqualified ? chargeOff : chargeOffWithPayment}</span>
        {!isMakePaymentDisqualified &&
          <BannerLink
            event='bannerLoanChargeOffPayoff'
            to={getTaskLocation(makePaymentPath)}
          >
            {chargeOffLinkCopy}
          </BannerLink>
        }
      </BannerLinkAndTextWrapper>
    </LoanBanner>
  );
};
