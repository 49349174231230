import * as React from 'react';
import Styled from 'styled-components';
import {
  FieldSet,
  FormGroupRadio,
  Headline,
  Legend,
} from '@amount/frontend-components';

import { WorkflowRadioCard } from './RadioCard';
import { IWorkflowRadioInputProps } from './common';
import { WorkflowRadioButton } from './RadioButton';
import { WorkflowRadioCardWithInput } from './RadioCardWithInput';

const PaddedContainer = Styled.div`
  padding: 1em 0;
  position: relative;
`;

export const WorkflowRadioInput: React.FunctionComponent<IWorkflowRadioInputProps> = props => (
  <FieldSet>
    {props.input.placeholder && (
      <Legend>
        <Headline scale='small' as='h3'>
          {props.input.placeholder}
        </Headline>
      </Legend>
    )}
    <PaddedContainer>
      <FormGroupRadio inline={true}>
        {props.input.valuesAndDetails
          .map(opt => {
            if (!!opt.details) {
              switch (opt.details.__typename) {
                case 'WorkflowInputValueRadioCard':
                  return <WorkflowRadioCard key={opt.value} opt={opt} {...props} />;
                case 'WorkflowInputValueRadioCardWithInput':
                  return <WorkflowRadioCardWithInput key={opt.value} opt={opt} {...props} />;
                default:
              }
            }

            // Default / Fallback use RadioButton
            return <WorkflowRadioButton key={opt.value} opt={opt} {...props} />;
          }
        )}
      </FormGroupRadio>
    </PaddedContainer>
  </FieldSet>
);
