import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Styled from 'styled-components';
import { Button, SelectInput, SelectOption } from '@amount/frontend-components';

import { TakeoverLoader } from '../TakeoverLoader';
import { devToolsEnabled } from '../../common';
import { devToolsClient } from '../../devToolsApolloClient';
import { Loading } from '../../../../components/Common/Spinner/LoadingHOC';
// eslint-disable-next-line max-len
import IssueAndFundProductMutation, { IssueAndFundProduct as IssueAndFundProductData, IssueAndFundProductInput } from './queries/IssueAndFundProduct.graphql';
import GetProductListG2Query, { GetProductListG2 } from '@Util/servicing/queries/GetProductListG2.graphql';

const ButtonWithMargin = Styled(Button)`
  margin-top: 1em;
`;

interface IIssueAndFund {
  data: GetProductListG2;
}

const IssueAndFund: React.FC<IIssueAndFund> = ({ data }) => {
  if (!devToolsEnabled()) {
    return null;
  }

  const products = data.identity?.servicing_accounts.map(account => account.product) || [];

  if (!products.length) {
    return <p>No products</p>;
  }

  const [currentProductUuid, setCurrentProductUuid] = useState<string>(products[0].product_uuid);

  const [issueAndFundProduct, { loading: mutationLoading }] = useMutation<IssueAndFundProductData, IssueAndFundProductInput>(
    IssueAndFundProductMutation,
    {
      variables: { input: { productUUID: currentProductUuid} },
      client: devToolsClient,
    },
  );

  const onClick = async () => {
    const response = await issueAndFundProduct();

    if (response.data?.issueAndFundProduct?.success) {
      window.location.reload();
    } else {
      alert('Failed to issue and fund product, see backend error log');
    }
  };

  const handleChange: (event: React.FormEvent<HTMLSelectElement>) => void = event => {
    if (!event.currentTarget) {
      return;
    }

    const newProduct = products.find(({ product_uuid }) => product_uuid === event.currentTarget.value);

    setCurrentProductUuid(newProduct?.product_uuid || '');
  };

  return (
    <>
      {
        mutationLoading && (
          <TakeoverLoader />
        )
      }
      <label>
        Product
      </label>
      <SelectInput onChange={handleChange}>
        {
          products.map<React.ReactNode>(product => (
            <SelectOption key={product.product_uuid} value={product.product_uuid}>{`${product.product_type} - ${product.id}`}</SelectOption>
          ))
        }
      </SelectInput>
      <ButtonWithMargin onClick={onClick} size='tiny'>
        Fund and Issue Loan
      </ButtonWithMargin>
    </>
  );
};

export const IssueAndFundProductG2Section: React.FC = () => {
  const { loading, data } = useQuery<GetProductListG2>(
    GetProductListG2Query,
  );

  if (loading) {
    return <Loading loading={true} />;
  }

  if (!data) {
    return <p>Error loading IssueAndFundProduct</p>;
  }

  return <IssueAndFund data={data} />;
};
