import React from 'react';
import { Link } from 'react-router-dom';
import { SVGIcon } from '@amount/frontend-components';

import { ApplicationBaseRoutes } from '../../../../../routes';
import { ILoanProps } from '../common';

export const LoanDashLink: React.FC<ILoanProps> = ({ product, rowIndex }) => {
  const { uuid, isRegions, isFunded } = product;

  return (
    <>
      {AvantConfig.TenantConfig.hasLoanServicing && !isRegions && isFunded && (
        <Link
          to={`${ApplicationBaseRoutes.loanServicing}/${uuid}`}
          data-event={`loanDashboard-${rowIndex}`}
        >
          Loan Dashboard
          <SVGIcon icon='view-new-window' />
        </Link>
      )}
    </>
  );
};
