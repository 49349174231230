import React from 'react';

import { LoanBanner } from '../..';
import { BannerBaseProps } from '../../../index';

const { expiredVCardNotSplitPay } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages;

export const ExpiredNotSplitPayVCBanner: React.FC<BannerBaseProps> = ({ variant }) => (
  <LoanBanner variant={variant}>
    <span>{expiredVCardNotSplitPay}</span>
  </LoanBanner>
);
