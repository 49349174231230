import React, { useState } from 'react';

import { BannerNotificationsProps } from '../types';
import { generateLocalStorageKeyWithId, getLocalStorageInfo, ILocalStorageInfo } from '../../../../../util';

import { LoanBanner } from '.';

const hidePaymentPlanBannerKey: string = 'hidePaymentPlanBanner';

type PaymentPlanBannerProps = Pick<BannerNotificationsProps, 'isDismissable' | 'uuid'>;
export const PaymentPlanBanner: React.FC<PaymentPlanBannerProps> = ({ isDismissable, uuid }) => {
  const LS_HIDE_PAYMENT_PLAN_BANNER_KEY = generateLocalStorageKeyWithId(hidePaymentPlanBannerKey, uuid);
  const hidePaymentPlanBanner: ILocalStorageInfo = getLocalStorageInfo(LS_HIDE_PAYMENT_PLAN_BANNER_KEY);
  const [hidePaymentPlan, setHidePaymentPlan] = useState(hidePaymentPlanBanner.isTrue());

  if (hidePaymentPlan) {
    return null;
  }

  const onDismiss: () => void = () => {
    hidePaymentPlanBanner.setTrue();
    setHidePaymentPlan(true);
  };

  return (
    <LoanBanner onDismiss={isDismissable ? onDismiss : undefined}>
      <span>This loan is currently on a payment plan. Your loan details and terms may have changed.</span>
    </LoanBanner>
  );
};
