import React from 'react';
import { Box, Heading, Text } from '@amount/frontend-components';
import { PageTemplate } from '../../../components/Common/Box';
import { BaseTenantTheme } from '../../../config/tenantTheme.base';

export const ThemeTwoServerError: React.FunctionComponent = () => {

  const {serverErrorText, errorHeader} = BaseTenantTheme.errors;
  const { headline, text } = AvantConfig.TenantConfig.errorMessages.themeTwoServerError;

  return (
    <PageTemplate
      width='narrow-2'
      slots={{
        header: (
          <Heading.h1 {...errorHeader}>
            {headline}
          </Heading.h1>

        ),
        content: (
          Object.values(text).map((errorText, i) => (
            <Box padding={i === 0 ? { bottom: '4x' } : {}} key={i}>
              <Text {...serverErrorText}>
                {errorText}
              </Text>
            </Box>
          ))
        )
      }}
    />
  )
};
