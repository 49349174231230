import React, { useState } from "react";
import {
  HeaderDropdown as HeaderDropdownFEC,
} from "@amount/frontend-components";

import { DrawerLinkMap, DrawerLinkTypes } from "./links/constants";

const { drawerLabel } = AvantConfig.TenantConfig.header.themeTwo;

interface HeaderDropdownProps {
  drawerLinks: Array<{
    type: DrawerLinkTypes;
    label: string;
    externalUrl?: string;
  }>;
}

export const HeaderDropdown: React.FC<HeaderDropdownProps> = ({ drawerLinks }) => {
  const [isOpen, toggleIsOpen] = useState(false);

  return (
    <HeaderDropdownFEC
      buttonText={drawerLabel || ''}
      isOpen={isOpen}
      onClick={() => toggleIsOpen(!isOpen)}
      closeDrawer={() => toggleIsOpen(false)}
    >
      {Object.values(drawerLinks)?.map(({ type, label, externalUrl }) => {
        const Link = DrawerLinkMap[type];

        return (
          <Link
            variant='dropdown'
            linkLabel={label}
            externalUrl={externalUrl}
            key={`drawer-link-${label}`}
            onClickCallback={() => toggleIsOpen(false)}
          />
        );
      })}
    </HeaderDropdownFEC>
  );
};
