import Styled, { css } from 'styled-components';
import { Banner, MaxWidthContainer, media, VALID_COLOR } from '@amount/frontend-components';
import { TenantTheme } from 'src/config/tenantTheme';

const BannerAnchorButtonStyles = css`
  a:not([role=button]) {
    border: 1px solid currentColor;
    margin-top: 1em;
    padding: 0.5em;
    text-align: center;
    text-decoration: none;
    width: 100%;

    ${media.small`
      border: none;
      margin-top: 0;
      padding: 0 0 0 0.25em;
      text-decoration: underline;
      width: auto;
    `}
  }
`;

export const CenterAlignedBannerText = css`
  align-content: center;
  display: flex;
  justify-content: center;
  text-align: center;

  ${media.small`
    flex-wrap: nowrap;
    padding: 0 1.5em;
  `}

  ${media.xlarge`
    padding: 0;
  `}

  div, span {
    text-align: center;
  }
`;

export const LeftAlignedBannerText = css`
  align-content: center;
  display: inline;
  justify-content: center;
  text-align: center;

  ${media.small`
    flex-wrap: nowrap;
    padding: 0 1.5em;
  `}

  ${media.xlarge`
    padding: 0 1.5em;
  `}

  div, span {
    text-align: center;
  }
`;

const alignBannerText = (bannerTextAlignment: string) => {
  switch(bannerTextAlignment) {
    case 'center':
      return CenterAlignedBannerText;
    case 'left':
      return LeftAlignedBannerText;
    default:
      return CenterAlignedBannerText;
  }
}

export const BannerTextWrapperStyles = alignBannerText(TenantTheme.banners.bannerTextAlignment);

const BannerTextWrapper = Styled(MaxWidthContainer)`
  ${BannerTextWrapperStyles}
  ${BannerAnchorButtonStyles}
`;

export default BannerTextWrapper;

export const InlineAnchor = Styled.a<{ bannerStyle: VALID_COLOR }>`
  color: ${({ theme: { banner }, bannerStyle }) => banner[bannerStyle].colorLink};

  &:hover {
    color: ${({ theme: { banner }, bannerStyle }) => banner[bannerStyle].colorLink};
  }
`;

export const CustomerHomeBannerWrapper = Styled.div`
  ${BannerTextWrapper} {
    display: inline-flex;
    padding: 0.5em 1em;
    text-align: left;

    a {
      border: none;
      display: block;
      margin-top: 0;
      padding: 0;
      text-align: left;
      text-decoration: underline;
      width: fit-content;
    }

    div, span {
      text-align: left;
    }

    ${InlineAnchor} {
      display: inline;
    }
  }

  ${Banner} {
    border-top-width: 0;
  }
`;
