import { LocationDescriptorObject } from 'history';
import React from 'react';
import { RouterLink } from './router-link.component';

interface BannerLinkProps {
  event: string;
  to: string | LocationDescriptorObject;
}

export const BannerLink = ({ children, event, to }: React.PropsWithChildren<BannerLinkProps>) => (
  <RouterLink.inline
    data-event={event}
    to={to}
    style={{ color: 'black' }}
  >
    {children}
  </RouterLink.inline>
);
