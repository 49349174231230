import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface IGuardedRouteProps extends RouteProps {
  isGuarded: boolean;
  redirectPath: string;
}

export const GuardedRoute = (props: IGuardedRouteProps) => {
  const { component: Component, isGuarded, redirectPath, ...rest} = props;

    return (
        <Route {...rest} render={props => (
          isGuarded === true
            ? Component && <Component {...props} />
            : <Redirect to={redirectPath} />
          )}
        />
    );
  };
