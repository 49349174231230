import React from 'react';

import { LoanBanner } from '../../';
import { activePaymentProtectedDispute, activeNonPaymentProtectedDispute } from '../../../bannerStyles';
import { PointOfSaleProps } from '../../../types';

type ActiveDisputeBannerProps = Pick<PointOfSaleProps, 'dispute'>;

const ActivePaymentProtectedDisputeBanner: React.FC = () => (
  <LoanBanner style={activePaymentProtectedDispute}>
    <span>
      There is currently a dispute open under your account.
      {' '}You are not obligated to make payments and you will not accrue fees until the dispute has been resolved.
      {' '}Automatic payments will be suspended during this period, but you may schedule one-time payments.
    </span>
  </LoanBanner>
);

const ActiveNonPaymentProtectedDisputeBanner: React.FC = () => {
   const phoneNumber = AvantConfig.TenantConfig.support.servicingContactInfo?.pointOfSale?.phoneNumber.readable;
   const displayablePhoneNumber = !!phoneNumber ? ` at ${phoneNumber} ` : ' ';

   return (
   <LoanBanner style={activeNonPaymentProtectedDispute}>
     <span>
       There is currently a dispute open under your account.
       {' '}During the resolution period, you are still required to make payments.
       {' '}Please contact us{displayablePhoneNumber}with any questions related to your account.
     </span>
   </LoanBanner>
 );
 };

export const ActiveDisputeBanner: React.FC<ActiveDisputeBannerProps> = ({ dispute }) =>
  dispute?.paymentProtected
    ? <ActivePaymentProtectedDisputeBanner />
    : <ActiveNonPaymentProtectedDisputeBanner />;
