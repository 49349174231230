import { LocationDescriptorObject } from 'history';
import React from 'react';
import { BannerLink } from 'src/_shared/ThemeTwo/Link/BannerLink';
interface IPastDuePaymentLinkProps {
  text: string;
  destination: string | LocationDescriptorObject;
  dataEvent: string;
}

export const PastDuePaymentLink: React.FC<IPastDuePaymentLinkProps> = ({ text, destination, dataEvent}) => (
  <BannerLink
    event={dataEvent}
    to={destination}
  >
    {text}
  </BannerLink>
);
