import loadableWrapper from '../Loadable';

export const LoadableVerDash = loadableWrapper(
  async () => {
    const { default: component } = await import(/* webpackChunkName: "VerDash" */ './VerDash/VerDash');

    return component;
  }
);

