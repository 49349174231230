import React from 'react';
import Styled from 'styled-components';
import {
  Datapoint,
  DatapointLabel,
  SVGIcon,
} from '@amount/frontend-components';

import { FragmentSelectionOnCreditCardAccount } from '../../../../../components/CustomerHomeDash/queries/GetCustomerHomeInformation.graphql';
import { NA } from '../../../../../constants';
import { AutopayWrapper, DataWrapper, FlexColumn } from '../../../../../components/Common/CommonStyles';
import { AvailableCreditTextWrapper, CreditLimitWrapper, CurrentBalanceTextWrapper } from '../CardDash/CreditCardSummary/common';

const AvailableCreditWrapper = Styled(FlexColumn)`
  margin-bottom: 0.5em;
  padding-right: 0;
  padding-top: 0.5em;
`;

const CurrentBalanceWrapper = Styled(FlexColumn)`
  padding-right: 0;
  padding-top: 1em;
`;

type NextPaymentCreditCard = Pick<
  FragmentSelectionOnCreditCardAccount,
  | 'creditLimit'
  | 'autopayActive'
  | 'availableTotalCredit'
  | 'currentBalance'
  | 'overlimit'
  | 'isLate'
  | 'wasClosed'
>;

const NextPaymentCreditCard: React.FunctionComponent<NextPaymentCreditCard> = ({
  creditLimit,
  autopayActive,
  availableTotalCredit,
  currentBalance,
  overlimit,
  isLate,
  wasClosed,
}) => (
  <DataWrapper>
    <AvailableCreditWrapper>
      <DatapointLabel>Available Credit</DatapointLabel>
      <AvailableCreditTextWrapper overlimit={overlimit} wasClosed={wasClosed} isLate={isLate}>
        <Datapoint size='small'>
          {(availableTotalCredit && availableTotalCredit.formatted) ? availableTotalCredit.formatted : NA}
        </Datapoint>
      </AvailableCreditTextWrapper>
      <CreditLimitWrapper wasClosed={wasClosed}>
        {creditLimit && creditLimit.formatted ? creditLimit.formatted : NA} Credit Limit
      </CreditLimitWrapper>
    </AvailableCreditWrapper>
    <CurrentBalanceWrapper>
      <DatapointLabel>Current Balance</DatapointLabel>
      <CurrentBalanceTextWrapper wasClosed={wasClosed} currentBalance={currentBalance}>
        <Datapoint size='small'>
          {(currentBalance && currentBalance.formatted) ? currentBalance.formatted : NA}
        </Datapoint>
      </CurrentBalanceTextWrapper>
      {autopayActive && (
        <AutopayWrapper>
          <SVGIcon icon='sync' iconStyle='brand' />
          <span>Autopay is on for this card</span>
        </AutopayWrapper>
      )}
    </CurrentBalanceWrapper>
  </DataWrapper>
  );

export default NextPaymentCreditCard;
