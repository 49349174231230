import { ServicingPageContextStateProps } from "../utils/servicingPageReducer";

export const initialState: ServicingPageContextStateProps = {
  hasServerError: false,
  availableBanners: [],
};

export enum ServicingBannerVariants {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}
