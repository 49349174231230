import React from 'react';
import { BannerBaseProps } from '../../../index'
import { LoanBanner } from '../..';

const { activePaymentProtectedDispute } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages;

export const ActivePaymentProtectedDisputeBanner: React.FC<BannerBaseProps> = ({variant}) => (
  <LoanBanner variant={variant}>
    <span>{activePaymentProtectedDispute}</span>
  </LoanBanner>
);
