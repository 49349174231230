import React from 'react';
import { Box } from '@amount/frontend-components';

export interface IEqualHeaderSection {
  contentAlignment?: string;
  alignSelf?: string;
}

const EqualHeaderSection: React.FC<IEqualHeaderSection> = ({
  children,
  contentAlignment,
  alignSelf,
}) => (
  <Box
    flexBasis="0"
    flexGrow="1"
    flexShrink="1"
    justifyContent={contentAlignment}
    alignSelf={alignSelf}
  >
    {children}
  </Box>
);

export default EqualHeaderSection;
