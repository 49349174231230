import React from 'react';
import { Helmet } from 'react-helmet';

interface IPageTitleProps {
  pageTitle: string;
}

const PageTitle: React.FunctionComponent<IPageTitleProps> = ({ pageTitle }) => {
  const pageTitleAppended: string = AvantConfig.TenantConfig.appendPageTitleWithPartnerName ?
    `${pageTitle} - ${AvantConfig.TenantConfig.companyName}` :
    `${pageTitle}`;

  return (
    <Helmet>
      <title>{`${pageTitleAppended}`}</title>
      <meta name='description' content={`${pageTitleAppended}`} />
    </Helmet>
  )
};

export default PageTitle;
