import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { NavButton } from '@amount/frontend-components';

import { TakeoverLoader } from '../TakeoverLoader';
import { devToolsEnabled, IProductUuid } from '../../common';
import { devToolsClient } from '../../devToolsApolloClient';

import ConfirmBankAccountMutation, {
  ConfirmBankAccount as ConfirmBankAccountData,
  ConfirmBankAccountInput
} from './queries/ConfirmBankAccount.graphql';

export const ConfirmBankAccount: React.FC<IProductUuid> = ({ uuid }) => {
  if (!devToolsEnabled()) {
    return null;
  }
  const [confirmBankAccount, { loading }] = useMutation<ConfirmBankAccountData, ConfirmBankAccountInput>(
    ConfirmBankAccountMutation,
    {
      variables: { input: { customerApplicationUUID: uuid }},
      client: devToolsClient,
    },
  );

  const onClick = async () => {
    const response = await confirmBankAccount();

    if (response.data?.confirmBankAccount?.success) {
      window.location.reload();
    } else {
      alert('Failed to confirm bank account, see backend error log');
    }
  };

  return (
    <>
      {
        loading && (
          <TakeoverLoader />
        )
      }
      <NavButton onClick={onClick}>
        Confirm Bank Account
      </NavButton>
    </>
  );
};
