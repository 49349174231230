/* eslint-disable react/no-danger */
import React from 'react';

import { createAccountManagementPath } from '../../../../../../../../routes';
import { BannerLinkAndTextWrapper, returnedPastDueKey } from '../../../common';
import { LoanBanner } from '..';
import { PastDuePaymentLink } from './common';
import { BannerNotificationsProps } from '../../index';
import { JWT_TOKEN_KEY } from '@Util';
import { tenantConfigToString } from '@Util/string';
import { FullBannerProps } from '../../types';
import { ServicingTasks } from '@Constants';
import { useServicingTaskRedirect } from 'src/g2/servicing/components/ServicingTasks/utils/useServicingTaskRedirect';

const { returnedPaymentAndPastDue } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages;
export interface BannerSessionMap {
  [key: string]: string;
}

export const ReturnedPastDueBanner: React.FC<BannerNotificationsProps> =
 ({ bannerProps, servicingAccountUUID, variant}) => {
  const { getTaskLocation } = useServicingTaskRedirect();
  // non-null-assertion handled by checks on respective parent queries
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const makePaymentPath = createAccountManagementPath(servicingAccountUUID!, ServicingTasks.MakePayment.route);
  const {servicingAccount: { product }} = bannerProps as FullBannerProps

  const pastDueAmount = product.past_due_amount?.formatted;
  const bannerCopy = tenantConfigToString(returnedPaymentAndPastDue, [pastDueAmount]);

  const singleSessionBanners = localStorage.getItem(returnedPastDueKey);
  const jwt = localStorage.getItem(JWT_TOKEN_KEY) as string;
  const bannerSessionIdMap = JSON.parse(singleSessionBanners || '{}') as BannerSessionMap;
  if (!bannerSessionIdMap[product.product_uuid]) {
    bannerSessionIdMap[product.product_uuid] = jwt;
    localStorage.setItem(returnedPastDueKey, JSON.stringify(bannerSessionIdMap));
  }

  return (
    <LoanBanner variant={variant}>
      <BannerLinkAndTextWrapper>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <span dangerouslySetInnerHTML={{ __html: bannerCopy }}></span>
        {!product.is_make_payment_disqualified && (
          <PastDuePaymentLink
            text='Schedule Payment'
            dataEvent='bannerLoanSchedulePayment'
            destination={getTaskLocation(makePaymentPath)}
          />
        )}
      </BannerLinkAndTextWrapper>
    </LoanBanner>
  );
};
