/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import {
  Box,
  Text,
  SVGIcon
} from '@amount/frontend-components';
import { getNextPaymentInfo } from '@Util/getNextPaymentInfo';
import { NA, ZeroAmountFormatted } from '@Constants';
import { formatDate } from '@Util/date';

import {
  SelectionOnMoney,
  SelectionOnConsumerPayoffInformation,
  SelectionOnConsumerPayment1,
  SelectionOnConsumerPayment2
} from '../../queries/GetCustomerHomeG2.graphql';
import { NextPaymentLoanCardDisplay } from './NextPaymentLoanCardDisplay';

const nextPaymentLoanCardCopy = AvantConfig.TenantConfig.customerHome.loanProductCard.nextPaymentLoanCard;

export interface NextPaymentLoanCardProps {
  is_autopay: boolean;
  is_past_due: boolean;
  nextNonZeroInstallmentPayment: SelectionOnConsumerPayment2 | null;
  nextInstallmentAmount: SelectionOnMoney | null;
  nextInstallmentOriginalAmount: SelectionOnMoney | null;
  nextInstallmentDate: string | null;
  nextPaymentScheduled: boolean;
  past_due_amount: SelectionOnMoney | null;
  payments: Array<SelectionOnConsumerPayment1 | null>;
  hasUnresolvedPaymentProtectionDispute: boolean;
  product_status: string;
  payoffInfo: SelectionOnConsumerPayoffInformation;
  opt_out_autopay_bk: boolean;
}

export const NextPaymentLoanCard: React.FunctionComponent<NextPaymentLoanCardProps> = ({
  is_past_due,
  is_autopay,
  nextNonZeroInstallmentPayment,
  nextInstallmentAmount,
  nextInstallmentOriginalAmount,
  nextInstallmentDate,
  nextPaymentScheduled,
  past_due_amount,
  hasUnresolvedPaymentProtectionDispute,
  payoffInfo,
  payments,
  opt_out_autopay_bk,
}) => {
  const payoffScheduled = !!payoffInfo.scheduled_payoff_quote_uuid;
  const displayColor = is_past_due ? 'error' : 'inherit';
  const amountLabel = is_past_due ? nextPaymentLoanCardCopy.amountLabel.isPastDue : nextPaymentLoanCardCopy.amountLabel.default;
  const nextPayment = getNextPaymentInfo({
    nextNonZeroInstallmentPayment,
    nextInstallmentAmount,
    nextInstallmentOriginalAmount,
    nextInstallmentDate,
    nextPaymentScheduled,
    isPastDue: is_past_due,
    pastDueAmount: past_due_amount,
    allFuturePayments: payments,
  });
  const paymentDate = nextPayment?.date;
  const amountDue = is_past_due ? past_due_amount?.formatted : nextPayment?.amountFormatted;
  let autopayColor;
  let autopayContent;

  if (opt_out_autopay_bk) {
    autopayColor = 'gray500';
    autopayContent = nextPaymentLoanCardCopy.autopayContent.isPausedForBankruptcy;
  } else if (hasUnresolvedPaymentProtectionDispute) {
    autopayColor = 'gray500';
    autopayContent = nextPaymentLoanCardCopy.autopayContent.isUnresolved;
  } else if (is_autopay) {
    autopayColor = 'success';
    autopayContent = nextPaymentLoanCardCopy.autopayContent.isOn;
  } else {
    autopayColor = 'gray500';
    autopayContent = nextPaymentLoanCardCopy.autopayContent.isOff;
  }

  return(
    <Box
      direction="column"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      {!is_past_due &&
        <NextPaymentLoanCardDisplay
          title={nextPaymentLoanCardCopy.dueDateLabel}
          content={getDueDateValue({ hasUnresolvedPaymentProtectionDispute, paymentDate, payoffScheduled })}
          color={displayColor}
        />
      }
      <Box direction='row' flexWrap='nowrap' alignItems='center' padding={{ top: '2x', bottom: '2x' }}>
        <SVGIcon icon='autopay' iconStyle='brand' color={autopayColor} size='2.5x' />
          { (!hasUnresolvedPaymentProtectionDispute && !opt_out_autopay_bk && is_autopay) ?
            <Text family='optimist_semibold' display='flex' margin={{ left: 'quadruple' }}>{autopayContent}</Text> :
            <Text color={autopayColor} display='flex' margin={{ left: 'quadruple' }}>{autopayContent}</Text>
          }
      </Box>
      <NextPaymentLoanCardDisplay
        title={amountLabel}
        content={getAmountDueValue({ hasUnresolvedPaymentProtectionDispute, payoffScheduled, amountDue })}
        color={displayColor}
      />
    </Box>
  )
};

function getDueDateValue ({
  hasUnresolvedPaymentProtectionDispute, paymentDate, payoffScheduled
}: {
  hasUnresolvedPaymentProtectionDispute: boolean;
  paymentDate: string | undefined;
  payoffScheduled: boolean;
}) {
  if (hasUnresolvedPaymentProtectionDispute) {
    return 'On Hold';
  } else if (!paymentDate || payoffScheduled) {
    return NA;
  } else {
    return formatDate(paymentDate);
  }
}

function getAmountDueValue ({
  hasUnresolvedPaymentProtectionDispute, payoffScheduled, amountDue
}: {
  hasUnresolvedPaymentProtectionDispute: boolean;
  payoffScheduled: boolean;
  amountDue?: string;
}) {
  if (hasUnresolvedPaymentProtectionDispute) {
    return ZeroAmountFormatted;
  } else if (payoffScheduled || !amountDue) {
    return NA;
  } else {
    return amountDue;
  }
}
