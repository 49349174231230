import React from 'react';
import { Link } from 'react-router-dom';
import { BannerLink } from '@amount/frontend-components';

import { ApplicationBaseRoutes, ServicingRoutes } from '../../../../../../routes';
import { LinkAndTextWrapper } from '../../../common';
import { BannerNotificationsProps } from '../../types';
import { ProductStatuses } from '../../../../../CustomerHomeDash/common';

type EligibleFinancialSituationReviewProps = Pick<BannerNotificationsProps, 'uuid' | 'status'>;
export const EligibleFinancialSituationReview: React.FC<EligibleFinancialSituationReviewProps> = ({ uuid, status }) => {
  const isCurrent = status === ProductStatuses.Current;

  return (
    <LinkAndTextWrapper>
      <span>
        {isCurrent ?
          AvantConfig.TenantConfig.banners.currentCustomerFsrEligible.text :
          AvantConfig.TenantConfig.banners.fsrEligible.text
        }
      </span>
      <BannerLink bannerStyle={isCurrent ? 'success' : 'alert'}>
        <Link to={`${ApplicationBaseRoutes.loanServicing}/${uuid}${ServicingRoutes.fsr}`} data-event='bannerLoanFsrEligible'>
          {isCurrent ?
            AvantConfig.TenantConfig.banners.currentCustomerFsrEligible.link :
            AvantConfig.TenantConfig.banners.fsrEligible.link
          }
        </Link>
      </BannerLink>
    </LinkAndTextWrapper>
  );
};
