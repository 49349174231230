/* eslint-disable padding-line-between-statements */
/* eslint-disable arrow-body-style */
import React from 'react';

import { BannerNotificationsProps, LoanBannerTypes } from './types';

import { loanBannerConditions } from './loanBannerConditions';

import { SoldOffBanner } from './Banners/SoldOff';
import { BankruptcyDischargedBanner } from './Banners/BankruptcyDischarged';
import { PaymentPlanBanner } from './Banners/PaymentPlan';
import { PaidOffBanner } from './Banners/PaidOff';
import { IncompleteSettlement } from './Banners/IncompleteSettlement';
import { ChargeOffBanner } from './Banners/ChargeOff';
import { PayoffQuote } from './Banners/PayoffQuote';
import { BadBankBanner } from './Banners/BadBank';
import { RefinancedBanner } from './Banners/Refinanced';
import { RemotelyCreatedCheckBanner } from './Banners/RemotelyCreatedCheck';
import { PastDueBanner } from './Banners/PastDue';
import { FinancialSituationReview } from './Banners/FinancialSituationReview';
import { AccountCreditBanner } from './Banners/POS/AccountCredit';
import { RefundedBanner } from './Banners/POS/Refunded';
import { DashboardSetupBanner } from './Banners/POS/DashboardSetup';
import { DisputeBanner } from './Banners/POS/Dispute';
import { PaymentPreferencesBanner } from './Banners/POS/PaymentPreferences';
import { Settled } from './Banners/ChargeOff/Settled';

const { bannerSelections } = AvantConfig.TenantConfig.loanDash;

export const LoanBanners: React.FC<BannerNotificationsProps> = props  => {
  const {
    pointOfSaleInformation,
    isDismissable,
    uuid,
  } = props;

  const Banners = {
    [LoanBannerTypes.soldOff]: <SoldOffBanner {...props} />,
    [LoanBannerTypes.settled]: <Settled />,
    [LoanBannerTypes.bankruptcyDischarged]: <BankruptcyDischargedBanner {...props} />,
    [LoanBannerTypes.refinanced]: <RefinancedBanner {...props} isDismissable={isDismissable} />,
    [LoanBannerTypes.paidOff]: <PaidOffBanner {...props} />,
    [LoanBannerTypes.incompleteSettlement]: <IncompleteSettlement />,
    [LoanBannerTypes.chargeOff]: <ChargeOffBanner {...props} />,
    [LoanBannerTypes.refunded]: <RefundedBanner />,
    [LoanBannerTypes.dispute]: pointOfSaleInformation && <DisputeBanner {...pointOfSaleInformation} />,
    [LoanBannerTypes.payoffQuote]: <PayoffQuote {...props} />,
    [LoanBannerTypes.hasPaymentPlanAndBadBank]: <BadBankBanner {...props} />,
    [LoanBannerTypes.paymentPlan]: <PaymentPlanBanner {...props} isDismissable={isDismissable} />,
    [LoanBannerTypes.accountCredit]: pointOfSaleInformation &&
      <AccountCreditBanner {...pointOfSaleInformation} uuid={uuid} isDismissable={isDismissable} />,
    [LoanBannerTypes.financialSituationReview]: <FinancialSituationReview {...props} />,
    [LoanBannerTypes.paymentPreferences]: pointOfSaleInformation && <PaymentPreferencesBanner {...pointOfSaleInformation} uuid={uuid} />,
    [LoanBannerTypes.hasNoPaymentPlanAndBadBank]: <BadBankBanner {...props} />,
    [LoanBannerTypes.remotelyCreatedCheck]: <RemotelyCreatedCheckBanner {...props} />,
    [LoanBannerTypes.pastDute]: <PastDueBanner {...props} />,
    [LoanBannerTypes.dashboardSetup]: <DashboardSetupBanner />
  };

  const bannerToDisplay = Object.values(bannerSelections).find(bannerSelection => {
    return !!loanBannerConditions[bannerSelection]?.(props);
  });

  return !!bannerToDisplay ? Banners[bannerToDisplay] : null;
};
