import { LocationDescriptorObject } from "history";
import React, { createContext } from "react";
import { WorkflowSessionData } from "src/g2/servicing/components/ServicingTasks/utils/Api/UseWorkflowLogger/types";
import { ServicingTaskStateStack } from "src/g2/servicing/components/ServicingTasks/utils/useServicingTaskRedirect";

interface ContextDef {
  isG2User: boolean;
  enableG2Dashboards: boolean;
  lastNavigation: {
    from: LocationDescriptorObject<ServicingTaskStateStack> | null;
    to: LocationDescriptorObject<ServicingTaskStateStack> | null;
  };
  workflowSessionRef: React.MutableRefObject<WorkflowSessionData>;
  sessionRefreshUrl: string | null;
}

export const AppContext = createContext<ContextDef>({
  isG2User: false,
  enableG2Dashboards: false,
  lastNavigation: { from: null, to: null },
  workflowSessionRef: { current: { rank: 1, pastActions: [], activeWorkflowSessionUuids: [] } },
  sessionRefreshUrl: null,
});
