/* eslint-disable @typescript-eslint/dot-notation */
import Styled from 'styled-components';
import { media, VALID_COLOR, Box } from '@amount/frontend-components';
import { generateLocalStorageKey } from '@Util';

export const accountCreditKey =  generateLocalStorageKey('viewOnceBanner');
export const returnedPastDueKey = generateLocalStorageKey('singleSessionBanners');
export const disputeIdsKey = generateLocalStorageKey('viewDisputeBannerOnce');

export const BannerLinkAndTextWrapper = Styled.div`
  flex-flow: wrap column;

  > span:not(:last-child) {
    margin-right: ${({ theme: { themeTwo: { global } } }) => global.edgeSize['quadruple']};
    display: block;
    ${media.medium`
      display: inline;
    `}
  }

  a {
    ${media.medium`
      display: inline-block;
      float: initial;
    `}
  }

  ${media.small`
    flex-flow: nowrap row;
  `}

`;

export const DoubleBannerWrapper = Styled(Box)`
  margin-bottom: ${({ theme: { themeTwo: { global } } }) => global.edgeSize['single']};
  &:empty { margin-bottom: 0; }
`;

export interface IBannerStyles {
  style: VALID_COLOR;
}

export const AlertStyles: IBannerStyles = {
  style: 'alert',
};

export const PrimaryStyles: IBannerStyles = {
  style: 'primary',
};

export const DefaultStyles: IBannerStyles = {
  style: 'inactive'
};
