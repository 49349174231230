import * as React from 'react';
import {
  Headline,
  Input,
} from '@amount/frontend-components';
import { FragmentSelectionOnWorkflowNumberInput } from '@amount/workflow-js';
import camelCase from 'lodash-es/camelCase';

import { IWorkflowInputBaseProps, WorkflowInputMaxWidthWrapper } from './common';

interface IWorkflowNumberInputProps extends IWorkflowInputBaseProps {
  input: FragmentSelectionOnWorkflowNumberInput;
}

export const WorkflowNumberInput: React.FunctionComponent<IWorkflowNumberInputProps> = ({
  input,
  updateInput,
  value,
  onClickCallback,
  innerInputRef
}) => (
  <>
    { input.placeholder && (
      <Headline scale='small' as='label' htmlFor={camelCase(input.placeholder || undefined)}>
        {input.placeholder}
      </Headline>
    )}
    <WorkflowInputMaxWidthWrapper>
      <Input
        id={camelCase(input.placeholder || undefined)}
        ref={innerInputRef}
        placeholder={input.placeholder || undefined}
        defaultValue={value || ''}
        required={!!input.required}
        icon={input.format === 'currency' ? 'dollar' : undefined} // Need to update frontend-components to show icon
        type='number'
        min={input.min || undefined}
        max={input.max || undefined}
        step={input.step || 'any'}
        disabled={!!input.readonly}
        onChange={e => updateInput(e.currentTarget.value)}
        onClick={onClickCallback}
        data-event={camelCase(`${input.placeholder || ''}NumberInput`)}
      />
    </WorkflowInputMaxWidthWrapper>
    { input.description && (
      <p>{input.description}</p>
    )}
  </>
);
