export const UNAUTHORIZED_STATUS: number = 401;

export const createVerificationsChannel: (applicationUuid: string) => string = applicationUuid => `verifications-app${applicationUuid}`;
export const REFRESH_VERIFICATIONS_EVENT = 'refresh_verifications';

export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
export const PUSHER_PATH = process.env.REACT_APP_PUSHER_ENDPOINT;
export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;

export const NA: string = 'N/A';
export const ZeroAmountFormatted: string = '$0.00';
export const ROUTING_NUMBER_LENGTH: number = 9;
export const BANK_ACCOUNT_NUMBER_MAX_LENGTH = 17;

export const enum APPLICATION_STAGE {
  Declined = 'declined',
  Personal = 'personal',
  Verification = 'verification',
  PersonalContinued = 'personal_continued',
  BankAccount = 'bank_account',
  Contract = 'contract',
  RatesTerms = 'rates_terms',
  AdditionalInformation = 'additional_information',
  Completed = 'completed',
  LoanOffer = 'loan_offer'
}

export enum ApplicationStatus {
  Open = 'open',
  Closed = 'closed',
}

export const enum ApplicationProductType {
  Loan = 'installment',
  CreditCard = 'credit_card',
  Refinance = 'refinance',
  PointOfSale = 'point_of_sale',
}

enum WorkflowNames {
  MakePayment = 'MakePayment',
  CancelPayment = 'CancelPayment',
  EditPayment = 'EditPayment',
  QuotePayoff = 'QuotePayoff',
  UpdateSingleProductBankAccount = 'UpdateSingleProductBankAccount',
  UpdateMultiProductBankAccount = 'UpdateMultiProductBankAccount',
  UpdatePaymentPreference = 'UpdatePaymentPreference',
  CheckBalanceAndQuotePayoff = 'CheckBalanceAndQuotePayoff',
  CancelPayoffQuote = 'CancelPayoffQuote',
  FinancialSituationReview = 'FinancialSituationReview',
  FinancialSituationReviewV2 = 'FinancialSituationReviewV2',
  AutopayEnroll = 'AutopayEnroll',
  CardFinancialSituationReview = 'CardFinancialSituationReview',
  CardMakePayment = 'CardMakePayment',
  CardCancelPayment = 'CardCancelPayment',
  CardAutopayEnroll = 'CardAutopayEnroll',
  CardAutopayUnenroll = 'CardAutopayUnenroll',
  Paperless = 'Paperless',
  AchOptIn = 'AchOptIn',
  LossMitigationPlanAcceptance = 'LossMitigationPlanAcceptance',
  SettlementsAcceptance = 'SettlementsAcceptance',
  CardmemberAgreement = 'CardmemberAgreement',
  ChangePaymentMethod = 'ChangePaymentMethod',
  ActivateCard = 'ActivateCard',
  IncomeAndHousing = 'IncomeAndHousing',
  RequestCreditLineIncrease = 'RequestCreditLineIncrease',
  ManageCardLock = 'ManageCardLock',
  TravelNotification = 'TravelNotification',
  ManageDebitCard = 'ManageDebitCard',
}

export interface IWorkflow {
  name: string;
  formattedName: string;
}

export const UpdateBankAccountWorkflowFormattedName = 'Update Bank Account';

export const Workflows: { [key in WorkflowNames]: IWorkflow } = {
  [WorkflowNames.MakePayment]: { name: 'make_payment', formattedName: 'Schedule a Payment' },
  [WorkflowNames.CancelPayment]: { name: 'cancel_payment', formattedName: 'Cancel Payment' },
  [WorkflowNames.EditPayment]: { name: 'edit_payment', formattedName: 'Edit Payment' },
  [WorkflowNames.QuotePayoff]: { name: 'quote_payoff', formattedName: AvantConfig.TenantConfig.workflows.quote_payoff.formattedName },
  [WorkflowNames.UpdateMultiProductBankAccount]: { name: 'update_bank_account_mp', formattedName: UpdateBankAccountWorkflowFormattedName },
  [WorkflowNames.UpdateSingleProductBankAccount]: { name: 'update_bank_account', formattedName: UpdateBankAccountWorkflowFormattedName },
  [WorkflowNames.UpdatePaymentPreference]: { name: 'payment_preference', formattedName: 'Update Payment Preference' },
  [WorkflowNames.CheckBalanceAndQuotePayoff]: {
    name: 'check_balance_and_quote_payoff',
    formattedName: AvantConfig.TenantConfig.workflows.quote_payoff.formattedName
  },
  [WorkflowNames.CancelPayoffQuote]: {
    name: 'cancel_payoff_quote',
    formattedName: AvantConfig.TenantConfig.workflows.cancel_quote_payoff.formattedName
  },
  [WorkflowNames.FinancialSituationReview]: { name: 'financial_situation_review', formattedName: 'Financial Situation Review' },
  [WorkflowNames.FinancialSituationReviewV2]: { name: 'financial_situation_review_v2', formattedName: 'Financial Situation Review' },
  [WorkflowNames.AutopayEnroll]: { name: 'ach_opt_in', formattedName: 'Enroll in Autopay' },
  [WorkflowNames.CardFinancialSituationReview]: { name: 'new_financial_situation_review', formattedName: 'Financial Situation Review' },
  [WorkflowNames.CardMakePayment]: { name: 'make_card_payment', formattedName: 'Make a Payment' },
  [WorkflowNames.CardCancelPayment]: { name: 'cancel_card_payment', formattedName: 'Cancel a Payment' },
  [WorkflowNames.CardAutopayEnroll]: { name: 'manage_autopay', formattedName: 'Enroll in Autopay' },
  [WorkflowNames.CardAutopayUnenroll]: { name: 'manage_autopay', formattedName: 'Unenroll from Autopay' },
  [WorkflowNames.Paperless]: { name: 'manage_paperless', formattedName: 'Paperless' },
  [WorkflowNames.AchOptIn]: { name: 'ach_opt_in', formattedName: 'Opt-in to ACH' },
  [WorkflowNames.LossMitigationPlanAcceptance]: { name: 'loss_mitigation_plan_acceptance', formattedName: 'Payment Plan Enrollment' },
  [WorkflowNames.SettlementsAcceptance]: { name: 'settlements_acceptance', formattedName: 'Settlement Enrollment' },
  [WorkflowNames.CardmemberAgreement]: { name: 'cardmember_agreement', formattedName: 'Cardmember Agreement' },
  [WorkflowNames.ChangePaymentMethod]: { name: 'edit_card_payment_method', formattedName: 'Change Payment Method' },
  [WorkflowNames.ActivateCard]: { name: 'activate_card', formattedName: 'Activate Card' },
  [WorkflowNames.IncomeAndHousing]: { name: 'income_and_housing', formattedName: 'Income and Housing' },
  [WorkflowNames.RequestCreditLineIncrease]: { name: 'request_credit_line_increase', formattedName: 'Request Credit Line Increase' },
  [WorkflowNames.ManageCardLock]: { name: 'manage_card_lock', formattedName: 'Manage Lock Card' },
  [WorkflowNames.TravelNotification]: { name: 'travel_notification', formattedName: 'Travel Notification' },
  [WorkflowNames.ManageDebitCard]: { name: 'manage_saved_debit_card', formattedName: 'Manage Debit Card' }

};

export enum PaymentMethods {
  PaperCheck = 'paper_check',
  Ach = 'ach',
  CreditCard = 'credit_card'
}

export enum BankAccountTypes {
  Checking = 'checking',
  Savings = 'savings',
}

export enum PhoneType {
  Home = 'home',
  Mobile = 'mobile',
  Work = 'work'
}

export enum AddressType {
  Mailing = 'mailing',
  Residential = 'residential'
}

export enum ProductSubType {
  Uil = 'uil',
  SplitPay = 'split_pay'
}

export enum PastPaymentStatuses {
  Completed = 'completed',
  Created = 'created',
  Returned = 'returned',
}

export enum ResolutionDecisions {
  MerchantFavor = 'merchant_favor',
  CustomerFavorMerchantChargeBack = 'customer_favor_merchant_chargeback',
  CustomerFavorBankChargeBack = 'customer_favor_bank_chargeback',
}

export enum DisputeStatuses {
  ChallengeWindow = 'challenge_window',
  EvidenceWindowOpen = 'evidence_window_open',
  SpecialistReview = 'specialist_review',
  Closed = 'closed'
}

export enum ProductStatuses {
  Current = 'current',
  Late = 'late',
  PaidOff = 'paid_off',
  ChargedOff = 'charged_off',
  Applied = 'applied',
  Approved = 'approved',
  Cancelled = 'cancelled',
  Authorized = 'authorized',
  InDefault = 'in_default',
  Transferred = 'transferred'
}

export enum PaymentOrigins {
  DisputeInstallment = 'Dispute Installment',
}

export interface IPaymentId {
  paymentID: string;
}

export interface IUUIDRouteProps {
  uuid: string;
}

export enum ServicingTaskNames {
  AddMailingAddress = 'AddMailingAddress',
  EditResidentialAddress = 'EditResidentialAddress',
  EditMailingAddress = 'EditMailingAddress',
  AddPaymentMethod = 'AddPaymentMethod',
  DeleteMailingAddress = 'DeleteMailingAddress',
  RemovePaymentMethod = 'RemovePaymentMethod',
  MakePayment = 'MakePayment',
  EditEmailAddress = 'EditEmailAddress',
  AddPhoneNumber = 'AddPhoneNumber',
  EditPrimaryPhoneNumber = 'EditPrimaryPhoneNumber',
  EditSecondaryPhoneNumber = 'EditSecondaryPhoneNumber',
  RemoveSecondaryPhoneNumber = 'RemoveSecondaryPhoneNumber',
  EnrollAutopay = 'EnrollAutopay',
  ManageAutopay = 'ManageAutopay',
  CancelPayment = 'CancelPayment',
  CancelPayoff = 'CancelPayoff',
  SchedulePayoff = 'SchedulePayoff',
  EditPayment = 'EditPayment',
}

export enum UnsavedChangesModalAllowedPaths {
  addPaymentMethod = '/addPaymentMethod',
}

export interface ServicingTaskConfigProps {
  name: string;
  formattedName: string;
  route: string;
}

export const ServicingTasks: { [key in ServicingTaskNames]: ServicingTaskConfigProps } = {
  [ServicingTaskNames.AddMailingAddress]: {
    name: 'addMailingAddress',
    formattedName: 'Add Mailing Address',
    route: '/addMailingAddress'
  },
  [ServicingTaskNames.EditMailingAddress]: {
    name: 'editMailingAddress',
    formattedName: 'Edit Mailing Address',
    route: '/editMailingAddress'
  },
  [ServicingTaskNames.EditResidentialAddress]: {
    name: 'editResidentialAddress',
    formattedName: 'Edit Residential Address',
    route: '/editResidentialAddress'
  },
  [ServicingTaskNames.AddPaymentMethod]: {
    name: 'addPaymentMethod',
    formattedName: 'Add Payment Method',
    route: '/addPaymentMethod'
  },
  [ServicingTaskNames.DeleteMailingAddress]: {
    name: 'deleteMailingAddress',
    formattedName: 'Delete Mailing Address',
    route: '/deleteMailingAddress'
  },
  [ServicingTaskNames.RemovePaymentMethod]: {
    name: 'removePaymentMethod',
    formattedName: 'Remove Payment Method',
    route: '/removePaymentMethod'
  },
  [ServicingTaskNames.MakePayment]: {
    name: 'makePayment',
    formattedName: 'Schedule Payment',
    route: '/makePayment'
  },
  [ServicingTaskNames.EditEmailAddress]: {
    name: 'editEmailAddress',
    formattedName: 'Edit Email',
    route: '/editEmailAddress'
  },
  [ServicingTaskNames.AddPhoneNumber]: {
    name: 'addPhoneNumber',
    formattedName: 'Add Phone Number',
    route: '/addPhoneNumber'
  },
  [ServicingTaskNames.EditSecondaryPhoneNumber]: {
    name: 'editSecondaryPhoneNumber',
    formattedName: 'Edit Secondary Phone Number',
    route: '/editSecondaryPhoneNumber'
  },
  [ServicingTaskNames.EditPrimaryPhoneNumber]: {
    name: 'editPrimaryPhoneNumber',
    formattedName: 'Edit Primary Phone Number',
    route: '/editPrimaryPhoneNumber'
  },
  [ServicingTaskNames.RemoveSecondaryPhoneNumber]: {
    name: 'removeSecondaryPhoneNumber',
    formattedName: 'Remove Phone',
    route: '/removeSecondaryPhoneNumber'
  },
  [ServicingTaskNames.EnrollAutopay]: {
    name: 'enrollAutopay',
    formattedName: 'Enroll In AutoPay',
    route: '/enrollAutopay'
  },
  [ServicingTaskNames.ManageAutopay]: {
    name: 'manageAutopay',
    formattedName: 'Manage AutoPay',
    route: '/manageAutopay'
  },
  [ServicingTaskNames.CancelPayment]: {
    name: 'cancelPayment',
    formattedName: 'Cancel Payment',
    route: '/cancelPayment'
  },
  [ServicingTaskNames.CancelPayoff]: {
    name: 'cancelPayoff',
    formattedName: 'Cancel Payoff',
    route: '/cancelPayoff'
  },
  [ServicingTaskNames.SchedulePayoff]: {
    name: 'schedulePayoff',
    formattedName: 'Payoff Purchase',
    route: '/schedulePayoff'
  },
  [ServicingTaskNames.EditPayment]: {
    name: 'editPayment',
    formattedName: 'Edit Payment',
    route: '/editPayment'
  },
};

export const states = [
  '', 'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA',
  'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD',
  'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'
];

export const REFERRER_KEY_VCNLIST = `${process.env.TENANT}:vcnCardUUIDList`;
