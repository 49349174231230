import React from 'react';
import Styled from 'styled-components';
import { Headline, media, RowSpan, LinkButton } from '@amount/frontend-components';
import { CustomMarginRowSpan } from '../../../Common/CommonStyles'

import {
  SelectionOnCrossSellInformationData
} from '../../queries/GetCustomerHomeInformation.graphql';

import {
  StyledCrossSellCard
} from './StyledCrossSellCard';

const DepositAccountContent = Styled.div`
  ${media.small`
    margin-left: 11em;
    margin-right: 5em;
  `}

  ${media.mediumLarge`
    margin-left: 0;
    margin-right: 0;
  `}

  button {
    margin-top: 2em;
  }
`;

interface IDepositAccountProps {
  data: SelectionOnCrossSellInformationData;
}

export const DepositAccount: React.FunctionComponent<IDepositAccountProps> = ({
  data
}) => {

  const crossSellText = AvantConfig.TenantConfig.customerHome.crossSellOffers;

  if (!data.url || !crossSellText) {
    return null;
  }

  return (
    <StyledCrossSellCard padding='2em'>
      <DepositAccountContent>
        <RowSpan>
          <Headline scale='large' as='h3'>
            {crossSellText.depositAccount.headerTextLine}
          </Headline>
        </RowSpan>
        <CustomMarginRowSpan bottomMargin='2em'>
          <p>
            {crossSellText.depositAccount.paragraphText}
          </p>
        </CustomMarginRowSpan>
        <LinkButton
          buttonStyle='primary'
          href={data.url}
          data-event='linkToDepositAccountCrossSell'
          rel='noopener noreferrer'
          target='_blank'
        >
          {crossSellText.depositAccount.buttonText}
        </LinkButton>
      </DepositAccountContent>
    </StyledCrossSellCard>
  );
};
