import React from 'react';
import {
  DecorativeElement,
  InnerContainer,
  SplashHeadline,
  SplashIcon,
  VerContainer,
  VerLayoutSplash,
} from '@amount/frontend-components';
const ServerError: React.FunctionComponent = () => (
  <VerContainer>
    <InnerContainer>
      <VerLayoutSplash>
        {AvantConfig.TenantConfig.errorMessages.serverError.showImage && (
          <SplashIcon>
            <DecorativeElement image='503' alt='Error Image' />
          </SplashIcon>
        )}
        <SplashHeadline icon='circle-alert' scale='large' as='h1'>
          {AvantConfig.TenantConfig.errorMessages.serverError.headline}
        </SplashHeadline>
        {/* XXX: FIX! */}
        {Object.values(AvantConfig.TenantConfig.errorMessages.serverError.text)
          .map((text, i) => (
            <p key={i}>
              {text}
            </p>
          )
        )}
      </VerLayoutSplash>
    </InnerContainer>
  </VerContainer>
);
export default ServerError;
