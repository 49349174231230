import React from 'react';
import Styled from 'styled-components';

export const CONTENT_ID = 'content';

const StyledMain = Styled.main`
  flex-grow: 1;

  &:focus {
    outline: none;
  }
`;

export const Content: React.FunctionComponent = props => (
  <StyledMain {...props} id={CONTENT_ID} tabIndex={-1}>
    {props.children}
  </StyledMain>
);

const SkipLink = Styled.a`
  display: block;
  overflow: hidden;
  width: 0;
  top: 0;
  position: absolute;

  &:focus, &:active {
    width: 10em;
  }
`;

export const SkipToContentLink: React.FunctionComponent = () => (
  <SkipLink href={`#${CONTENT_ID}`}>Skip to content</SkipLink>
);
