const { mapValues, isString, isObject, isArray, mergeWith } = require('lodash');

/**
 * @type {import("../src/customTypings/avant-config/utils").IReplaceStringsInObject}
 */
const replaceStringsInObject = obj => {
    if (isString(obj)) { return JSON.stringify(obj); }

    return mapValues(obj, value => {
      if (isString(value)) {
        return JSON.stringify(value);
      } else if (Array.isArray(value)) {
        return value.map(replaceStringsInObject);
      } else if (isObject(value)) {
        return replaceStringsInObject(value);
      } else {
        return value;
      }
    });
}

function mergeCopyArrays(objValue, srcValue) {
  if (isArray(objValue)) {
    return srcValue;
  }
}

function mergeWithCopyArrays(obj, src) {
  return mergeWith(obj, src, mergeCopyArrays)
}

module.exports = {
  mergeWithCopyArrays,
  replaceStringsInObject
};
