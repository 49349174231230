/* eslint-disable */
// graphql typescript definitions

export interface IGraphQLResponseRoot {
  data?: IQuery | IMutation;
  errors?: Array<IGraphQLResponseError>;
}

export interface IGraphQLResponseError {
  /** Required for all errors */
  message: string;
  locations?: Array<IGraphQLResponseErrorLocation>;
  /** 7.2.2 says 'GraphQL servers may provide additional entries to error' */
  [propName: string]: any;
}

export interface IGraphQLResponseErrorLocation {
  line: number;
  column: number;
}

/**
 * Query root
 */
export interface IQuery {
  __typename: 'Query';
  account_management: IAccountManagement | null;
  clientInstall: IClientInstall | null;
  featureFlags: Array<string>;
  identity: IIdentityCustomer | null;
  identity_configs: IIdentityConfigs | null;
  me: ICustomer;

  /**
   * @deprecated "Use me.getApplication instead"
   */
  myApplication: ICustomerApplication;
  warningBanners: IWarningBanners;
  warning_banners: ICustomerManagementWarningBanners;
}

export interface IAccountManagementOnQueryArguments {
  account_uuid?: string | null;
  account_id?: string | null;
  product_uuid?: string | null;
  product_type?: string | null;
}

/**
 * Core object of the AccountManagement consumer domain.
 */
export interface IAccountManagement {
  __typename: 'AccountManagement';
  all_future_payments: Array<IConsumerPayment | null>;
  all_past_payments: Array<IConsumerPayment | null>;
  all_payments: Array<IConsumerPayment | null>;
  autopay_validations: IConsumerValidationError;
  cancel_payment_validations: IConsumerValidationError;
  cancel_payoff_quote_validations: IConsumerValidationError;
  charge_off_information: IConsumerChargeOffInformation | null;
  create_payment_allocation_types: Array<IConsumerCreatePaymentAllocationType | null>;
  create_payoff_quote_validations: IConsumerValidationError;
  credit_loan_tasks: Array<IConsumerCreditLoanTask>;
  disputes: Array<IConsumerProductDispute | null>;
  get_payment_allocation_breakdown_for_payment: Array<IConsumerPaymentAllocationInformationInputType>;
  id: string;
  is_payment_within_payoff_threshold: boolean;
  late_fees: Array<IConsumerLateFee | null>;
  merchant_information: IConsumerPointOfSaleMerchantInformation | null;
  min_and_max_payment_dates: IConsumerMinMaxPaymentDate;
  operational_statuses: Array<string>;
  opted_in_payment_method: IConsumerPaymentMethod | null;
  payment_validations: IConsumerValidationError;
  payments_affected_by_payoff_quote: IConsumerPaymentsAffectedByPayoffQuote;
  payoff_information: IConsumerPayoffInformation;
  point_of_sale_information: IConsumerPointOfSaleInformation;
  product: IConsumerProduct;
  products_opted_into_payment_method: Array<IConsumerProduct | null>;
  repayment_information: IConsumerRepaymentInformation;
  schedule_payoff_quote_validations: IConsumerValidationError;
  servicer: IServicer;
  uuid: string;
  valid_payment_methods: IConsumerValidPaymentMethods;

  /**
   * The virtual card record associated with the servicing account.
   */
  virtual_card: IVirtualCardToken;
}

export interface IAutopayValidationsOnAccountManagementArguments {
  payment_method_uuid: string;
  payment_uuid?: string | null;
}

export interface ICancelPaymentValidationsOnAccountManagementArguments {
  payment_uuid: string;
}

export interface ICancelPayoffQuoteValidationsOnAccountManagementArguments {
  payoff_quote_uuid: string;
}

export interface IGetPaymentAllocationBreakdownForPaymentOnAccountManagementArguments {
  payment_allocation_type?: PaymentAllocationType | null;
  date: string;
  amount_cents: number;
}

export interface IIsPaymentWithinPayoffThresholdOnAccountManagementArguments {
  payment_amount_cents?: number | null;
}

export interface IMinAndMaxPaymentDatesOnAccountManagementArguments {
  payment_allocation_type?: PaymentAllocationType | null;
  payment_method?: string | null;
}

export interface IPaymentValidationsOnAccountManagementArguments {
  date?: string | null;
  amount_cents?: number | null;
  payment_allocation_type?: string | null;
  payment_method_uuid?: string | null;
  payment_uuid?: string | null;
}

export interface IPaymentsAffectedByPayoffQuoteOnAccountManagementArguments {
  payoff_payment_date: string;
  payment_method_uuid: string;
  quote_start_date?: string | null;
}

export interface IProductsOptedIntoPaymentMethodOnAccountManagementArguments {
  payment_account_uuid?: string | null;
  payment_account_type?: PaymentAccountType | null;
}

export interface ISchedulePayoffQuoteValidationsOnAccountManagementArguments {
  payment_date: string;
  payoff_quote_uuid: string;
  payment_method_uuid: string;
}

/**
 * A Loan Payment
 */
export interface IConsumerPayment {
  __typename: 'ConsumerPayment';
  amount: IMoney;
  balance_effective_date: string;
  cash_flow_effective_date: string;
  created_by: string | null;
  editable_fields: Array<string>;
  eff_date: string;
  grace_period_info: IConsumerGracePeriodInfo;
  id: string;
  installment: IConsumerInstallment | null;
  is_cancelable: boolean;
  is_editable: boolean;
  is_inactive: boolean;
  is_installment: boolean;
  is_instantiated: boolean;
  is_past_due: boolean;
  is_past_untaken_payment: boolean;
  is_posted_late: boolean;
  loan_task: IAccountManagementLoanTask | null;
  one_time_courtesy_info: IConsumerOneTimeCourtesyInfo;
  ops_applied_change_in_system: boolean;
  origin: string;
  payment_account_type: PaymentAccountType | null;
  payment_account_uuid: string | null;
  payment_allocation: Array<IConsumerPaymentAllocationInformationType>;
  payment_breakdown: IConsumerPaymentBreakdown | null;
  payment_deductions: Array<IConsumerPaymentDeduction>;
  payment_deferments: Array<IConsumerPaymentDeferment>;
  payment_method: string | null;
  payment_method_type: string | null;
  payment_method_uuid: string | null;
  status: string;
  type: string | null;
  uuid: string;
}

/**
 * Represents a Money type.
 */
export interface IMoney {
  __typename: 'Money';
  amount: number;
  cents: number;
  currencyCode: string;
  formatted: string;
}

export interface IFormattedOnMoneyArguments {
  noCentsIfWhole?: boolean | null;
  signBeforeSymbol?: boolean | null;
}

/**
 * The grace period given before a payment is due
 */
export interface IConsumerGracePeriodInfo {
  __typename: 'ConsumerGracePeriodInfo';
  agent_used_grace_period: boolean;
  customer_used_grace_period: boolean;
  is_eligible_for_grace_period: boolean;
  is_grace_period_used: boolean;
  max_grace_period_date: string | null;
  min_grace_period_date: string | null;
}

/**
 * An installment
 */
export interface IConsumerInstallment {
  __typename: 'ConsumerInstallment';
  active: boolean;
  adjusted_installment_date: string;
  allocated_payments: Array<IConsumerInstallmentAllocatedPaymentInformation>;
  amount: IMoney;
  eff_date: string;
  id: string;
  installment_date: string;
  interest: IMoney;
  late_fee: IMoney;
  original_payment_amount: IMoney;
  original_payment_date: string | null;
  payment_amount: IMoney;
  repayment_day: number;
  status: string;
}

/**
 * A payment allocated for a particular installment
 */
export interface IConsumerInstallmentAllocatedPaymentInformation {
  __typename: 'ConsumerInstallmentAllocatedPaymentInformation';
  amount: IMoney;
  date: string;
  payment_id: string;
  type: InstallmentAllocatedPaymentType | null;
}

/**
 * Valid allocated payment types for installments
 */
export const enum InstallmentAllocatedPaymentType {
  scheduled_payment = 'scheduled_payment',
  completed_payment = 'completed_payment',
  chargeback = 'chargeback',
  void = 'void',
  credit = 'credit',
  product_return = 'product_return',
}

/**
 * A Loan Task
 */
export interface IAccountManagementLoanTask {
  __typename: 'AccountManagementLoanTask';
  amount: IMoney | null;
  eff_date: string;
  id: string;
  parent_id: string | null;
  status: string | null;
  status_humanized: string | null;
  type: string | null;
  uuid: string;
}

/**
 * Whether or not a one time courtesy was used
 */
export interface IConsumerOneTimeCourtesyInfo {
  __typename: 'ConsumerOneTimeCourtesyInfo';
  is_eligible_for_otc: boolean;
  is_one_time: boolean;
}

/**
 * Valid payment account types
 */
export const enum PaymentAccountType {
  bank_account = 'bank_account',
  debit_card = 'debit_card',
}

/**
 * Information about which installments a payment will allocate towards
 */
export interface IConsumerPaymentAllocationInformationType {
  __typename: 'ConsumerPaymentAllocationInformationType';
  allocated_installments: Array<IConsumerPaymentAllocationInformation>;
  type: PaymentAllocationType;
}

/**
 * Information about a particular installment that a payment will allocate towards
 */
export interface IConsumerPaymentAllocationInformation {
  __typename: 'ConsumerPaymentAllocationInformation';
  amount: IMoney;
  date: string;
  id: number | null;
}

/**
 * Valid payment allocation types
 */
export const enum PaymentAllocationType {
  towards_past_due = 'towards_past_due',
  towards_installment = 'towards_installment',
  towards_balance = 'towards_balance',
  installment = 'installment',
  payoff_quote = 'payoff_quote',
  down_payment = 'down_payment',
}

/**
 * A Payment Breakdown
 */
export interface IConsumerPaymentBreakdown {
  __typename: 'ConsumerPaymentBreakdown';
  effective_date: string;
  interest: IMoney;
  late_fees: IMoney;
  loan_task_id: string;
  nsf_fees: IMoney;
  overpayment: IMoney;
  principal: IMoney;
  recovery: boolean;
}

/**
 * Information about a payment deduction
 */
export interface IConsumerPaymentDeduction {
  __typename: 'ConsumerPaymentDeduction';
  amount: IMoney;
  created_at: string;
  id: string | null;
  initiating_payment_id: string | null;
  payment_id: string | null;
}

/**
 * Information about a payment deferment
 */
export interface IConsumerPaymentDeferment {
  __typename: 'ConsumerPaymentDeferment';
  created_at: string;
  id: string | null;
  new_date: string;
  old_date: string;
  type: string;
}

/**
 * A ValidationError
 */
export interface IConsumerValidationError {
  __typename: 'ConsumerValidationError';
  errors: Array<IAccountManagementError>;
  is_success: boolean;
}

/**
 * A descriptive error
 */
export interface IAccountManagementError {
  __typename: 'AccountManagementError';
  error_code: AccountManagementErrorCode | null;
  field: string | null;
  message: string;
  type: string | null;
}

/**
 * Valid error codes
 */
export const enum AccountManagementErrorCode {
  /**
   * Missing input property
   */
  APP_01 = 'APP_01',

  /**
   * Invalid input/character
   */
  APP_02 = 'APP_02',

  /**
   * Type mismatches
   */
  APP_03 = 'APP_03',

  /**
   * Default Error Code
   */
  APP_04 = 'APP_04',

  /**
   * Invalid amount
   */
  PMT_01 = 'PMT_01',

  /**
   * Amount is greater than remaining balance
   */
  PMT_02 = 'PMT_02',

  /**
   * Payment cannot be less than your installment amount
   */
  PMT_03 = 'PMT_03',

  /**
   * Invalid dates
   */
  DT_01 = 'DT_01',

  /**
   * Date cannot be on a non-business day
   */
  DT_02 = 'DT_02',

  /**
   * Date should be before the payoff date
   */
  DT_03 = 'DT_03',

  /**
   * Date should be before or after cut off time
   */
  DT_04 = 'DT_04',

  /**
   * Payment cannot be applied before the installment
   */
  DT_05 = 'DT_05',

  /**
   * Payment method selected will be expired at the time of selected date
   */
  DT_06 = 'DT_06',

  /**
   * Date not in specified range
   */
  DT_07 = 'DT_07',

  /**
   * Date is past max date
   */
  DT_08 = 'DT_08',

  /**
   * Zero payoff amount for that given date
   */
  DT_09 = 'DT_09',

  /**
   * Invalid start date
   */
  SCR_01 = 'SCR_01',

  /**
   * Invalid end date
   */
  SCR_02 = 'SCR_02',

  /**
   * Invalid SCRA request
   */
  SCR_03 = 'SCR_03',

  /**
   * Unable to opt out
   */
  AP_01 = 'AP_01',

  /**
   * Already opted out
   */
  AP_02 = 'AP_02',

  /**
   * Unable to opt-out of Next Installment (cut off)
   */
  AP_03 = 'AP_03',

  /**
   * Unable to opt-in an invalid payment method
   */
  AP_04 = 'AP_04',

  /**
   * Duplicate debit card/Bank Account
   */
  BDE_01 = 'BDE_01',

  /**
   * Routing Number not found in our list of banks
   */
  BDE_02 = 'BDE_02',

  /**
   * Account type must be checking or savings
   */
  BED_03 = 'BED_03',

  /**
   * Account number format error
   */
  BED_04 = 'BED_04',

  /**
   * Routing number format error
   */
  BED_05 = 'BED_05',

  /**
   * Unable to add a debit card without a valid product
   */
  BDE_06 = 'BDE_06',

  /**
   * Payment method not found
   */
  AMPM_01 = 'AMPM_01',

  /**
   * Missing bank account info
   */
  GCT_01 = 'GCT_01',

  /**
   * Duplicate information
   */
  GCT_02 = 'GCT_02',

  /**
   * Cut off time failed
   */
  GCT_03 = 'GCT_03',

  /**
   * Unavailable to verify
   */
  GCT_04 = 'GCT_04',

  /**
   * cc_invalid
   */
  PG_01 = 'PG_01',

  /**
   * dc_invalid
   */
  PG_02 = 'PG_02',

  /**
   * cvv_invalid
   */
  PG_03 = 'PG_03',

  /**
   * cc_invalid
   */
  cc_invalid = 'cc_invalid',

  /**
   * dc_invalid
   */
  dc_invalid = 'dc_invalid',

  /**
   * cvv_invalid
   */
  cvv_invalid = 'cvv_invalid',

  /**
   * zip_invalid
   */
  zip_invalid = 'zip_invalid',

  /**
   * cvv_or_zip_invalid
   */
  cvv_or_zip_invalid = 'cvv_or_zip_invalid',

  /**
   * issuer_country_invalid
   */
  issuer_country_invalid = 'issuer_country_invalid',

  /**
   * payment_type_not_accepted
   */
  payment_type_not_accepted = 'payment_type_not_accepted',

  /**
   * card_type_not_accepted
   */
  card_type_not_accepted = 'card_type_not_accepted',

  /**
   * card_network_not_accepted
   */
  card_network_not_accepted = 'card_network_not_accepted',

  /**
   * funds_insufficient
   */
  funds_insufficient = 'funds_insufficient',

  /**
   * card_details_invalid
   */
  card_details_invalid = 'card_details_invalid',

  /**
   * validate_card_failure
   */
  validate_card_failure = 'validate_card_failure',

  /**
   * authorize_card_failure
   */
  authorize_card_failure = 'authorize_card_failure',

  /**
   * void_card_failure
   */
  void_card_failure = 'void_card_failure',

  /**
   * refund_card_failure
   */
  refund_card_failure = 'refund_card_failure',

  /**
   * expiration_date_passed
   */
  expiration_date_passed = 'expiration_date_passed',

  /**
   * card_expired
   */
  card_expired = 'card_expired',

  /**
   * validate_card_timeout
   */
  validate_card_timeout = 'validate_card_timeout',

  /**
   * authorize_card_timeout
   */
  authorize_card_timeout = 'authorize_card_timeout',

  /**
   * void_card_timeout
   */
  void_card_timeout = 'void_card_timeout',

  /**
   * refund_card_timeout
   */
  refund_card_timeout = 'refund_card_timeout',

  /**
   * You have exceeded the {type} cutoff time of {cutoff_time}. Please select another date or payment method.
   */
  SPQ_01 = 'SPQ_01',

  /**
   * Bank account (ACH) payments must be scheduled for a business day
   */
  SPQ_02 = 'SPQ_02',

  /**
   * The payment method you selected will be expired at the time of your selected
   * date. Please select another date before your payment method expires or select
   * another payment method.
   */
  SPQ_03 = 'SPQ_03',

  /**
   * Something went wrong, please try again
   */
  SPQ_04 = 'SPQ_04',

  /**
   * This payment has begun processing and can’t be canceled
   */
  CP_01 = 'CP_01',

  /**
   * This payment has begun processing and can’t be canceled
   */
  CPQ_01 = 'CPQ_01',

  /**
   * No fraud exists for fraud_uuid
   */
  FRD_01 = 'FRD_01',

  /**
   * is_confirmed is missing
   */
  FRD_02 = 'FRD_02',

  /**
   * AUT_01
   */
  AUT_01 = 'AUT_01',

  /**
   * AUT_02
   */
  AUT_02 = 'AUT_02',

  /**
   * AUT_03
   */
  AUT_03 = 'AUT_03',

  /**
   * AUT_04
   */
  AUT_04 = 'AUT_04',

  /**
   * AUT_05
   */
  AUT_05 = 'AUT_05',

  /**
   * AUT_06
   */
  AUT_06 = 'AUT_06',

  /**
   * AUT_07
   */
  AUT_07 = 'AUT_07',

  /**
   * AUT_08
   */
  AUT_08 = 'AUT_08',

  /**
   * AUT_09
   */
  AUT_09 = 'AUT_09',

  /**
   * AUT_10
   */
  AUT_10 = 'AUT_10',

  /**
   * AUT_11
   */
  AUT_11 = 'AUT_11',

  /**
   * AUT_12
   */
  AUT_12 = 'AUT_12',

  /**
   * payment_loan_task uuid is required
   */
  REF_01 = 'REF_01',

  /**
   * Payment Doesn't exit
   */
  REF_02 = 'REF_02',

  /**
   * refund type is required
   */
  REF_03 = 'REF_03',

  /**
   * select a valid type
   */
  REF_04 = 'REF_04',

  /**
   * refund reason is required
   */
  REF_05 = 'REF_05',

  /**
   * select a valid reason
   */
  REF_06 = 'REF_06',

  /**
   * amount > 0.01
   */
  REF_07 = 'REF_07',

  /**
   * refund_uuid is required
   */
  REF_08 = 'REF_08',

  /**
   * status is required
   */
  REF_09 = 'REF_09',

  /**
   * valid status/decision
   */
  REF_10 = 'REF_10',

  /**
   * reject_reason is required if status = 'rejected'
   */
  REF_11 = 'REF_11',

  /**
   * no refund found with the given uuid
   */
  REF_12 = 'REF_12',

  /**
   * Case type is required
   */
  COL_01 = 'COL_01',

  /**
   * Collection case already exists
   */
  COL_02 = 'COL_02',

  /**
   * Collection agency name is required
   */
  COL_03 = 'COL_03',

  /**
   * Collection agency already exists
   */
  COL_04 = 'COL_04',

  /**
   * Collection case doesn't exists
   */
  COL_05 = 'COL_05',

  /**
   * Collection agency exists
   */
  COL_06 = 'COL_06',

  /**
   * Collection agency doesn't exists
   */
  COL_07 = 'COL_07',

  /**
   * Address_1 is required
   */
  ADR1_01 = 'ADR1_01',

  /**
   * Address_1 has invalid values
   */
  ADR1_02 = 'ADR1_02',

  /**
   * Address_2 has invalid values
   */
  ADR2_02 = 'ADR2_02',

  /**
   * City is required
   */
  CTY_01 = 'CTY_01',

  /**
   * City has invalid values
   */
  CTY_02 = 'CTY_02',

  /**
   * State is required
   */
  STA_01 = 'STA_01',

  /**
   * State is invalid
   */
  STA_02 = 'STA_02',

  /**
   * Zip is required
   */
  ZIP_01 = 'ZIP_01',

  /**
   * Zip is invalid
   */
  ZIP_02 = 'ZIP_02',

  /**
   * Amount is required
   */
  AMT_01 = 'AMT_01',

  /**
   * Amount must be greater than one
   */
  AMT_02 = 'AMT_02',

  /**
   * Amount must be less than payoff
   */
  AMT_03 = 'AMT_03',

  /**
   * Date is required
   */
  DTE_01 = 'DTE_01',

  /**
   * Credit failed due to amount value
   */
  CRE_01 = 'CRE_01',

  /**
   * Credit failed due to eff date
   */
  CRE_02 = 'CRE_02',

  /**
   * Reason is required
   */
  CRE_03 = 'CRE_03',

  /**
   * credit failed due to reason
   */
  CRE_04 = 'CRE_04',
}

export interface IConsumerChargeOffInformation {
  __typename: 'ConsumerChargeOffInformation';
  charge_off_amount: IMoney | null;
  charge_off_balances: IConsumerChargeOffBalances | null;
  disable_make_payment: boolean | null;
  effective_date: string | null;
  expected_charged_off_date: string | null;
  is_operationally_charged_off: boolean;
  reason: string | null;
  recovered_amount: IMoney | null;
  transaction_date: string | null;
}

export interface IConsumerChargeOffBalances {
  __typename: 'ConsumerChargeOffBalances';
  active_charged_off_fees: IMoney | null;
  active_charged_off_interest: IMoney | null;
  active_charged_off_principal: IMoney | null;
  recovered_fees: IMoney | null;
  recovered_interest: IMoney | null;
  recovered_principal: IMoney | null;
}

/**
 * Information about a single payment allocation type that can be used for creating a payment
 */
export interface IConsumerCreatePaymentAllocationType {
  __typename: 'ConsumerCreatePaymentAllocationType';
  is_valid: boolean;
  max_payment_amount_cents: number | null;
  name: PaymentAllocationType;
}

/**
 * A Credit Loan Task
 */
export interface IConsumerCreditLoanTask {
  __typename: 'ConsumerCreditLoanTask';
  amount: IMoney;
  credit_type: string;
  eff_date: string;
  id: string;
  status: string;
  status_humanized: string;
  transaction_breakdown: IConsumerTransactionBreakdown | null;
  type: string;
}

/**
 * A Financial Transaction Breakdown
 */
export interface IConsumerTransactionBreakdown {
  __typename: 'ConsumerTransactionBreakdown';
  effective_date: string;
  interest: IMoney;
  is_recovery: boolean;
  late_fees: IMoney;
  nsf_fees: IMoney;
  overpayment: IMoney;
  payment_id: string | null;
  principal: IMoney;
}

/**
 * A Product Dispute
 */
export interface IConsumerProductDispute {
  __typename: 'ConsumerProductDispute';
  active: boolean;
  amount: IMoney | null;
  case_id: string | null;
  date: string;
  description: string;
  dispute_id: string;
  is_payment_protection_applied: boolean;
  reason: string;
  resolution_amount: IMoney | null;
  resolution_date: string | null;
  resolution_decision: string | null;
  resolution_description: string | null;
  resolution_due_date: string | null;
  status: string;
  status_humanized: string;
}

/**
 * Information about which installments a payment will allocate towards
 */
export interface IConsumerPaymentAllocationInformationInputType {
  __typename: 'ConsumerPaymentAllocationInformationInputType';
  input_type: PaymentAllocationType;
  payment_allocation_information_types: Array<IConsumerPaymentAllocationInformationType>;
}

/**
 * Consumer Late Fee
 */
export interface IConsumerLateFee {
  __typename: 'ConsumerLateFee';
  amount: IMoney;
  effective_date: string;
  installment_id: number;
  loan_task_id: number;
  status: string;
}

/**
 * A point of sale merchant
 */
export interface IConsumerPointOfSaleMerchantInformation {
  __typename: 'ConsumerPointOfSaleMerchantInformation';
  merchant_domain: string | null;
  merchant_identifier: string | null;
  merchant_image_url: string | null;
  merchant_name: string | null;
  merchant_order_id: string | null;
}

/**
 * Minimum and maximum dates to make a payment
 */
export interface IConsumerMinMaxPaymentDate {
  __typename: 'ConsumerMinMaxPaymentDate';
  max_payment_date: string | null;
  min_payment_date: string | null;
}

/**
 * An Account Management Payment Method
 */
export interface IConsumerPaymentMethod {
  __typename: 'ConsumerPaymentMethod';
  payment_account_type: PaymentAccountType;
  payment_account_uuid: string;
}

/**
 * Payments affected by Payoff Quote
 */
export interface IConsumerPaymentsAffectedByPayoffQuote {
  __typename: 'ConsumerPaymentsAffectedByPayoffQuote';
  affected_additional_payments: Array<IConsumerPayment>;
  affected_installment_payments: Array<IConsumerPayment>;
  affected_nsf_payments: Array<IConsumerPayment>;
  inflight_additional_payments: Array<IConsumerPayment>;
  inflight_installment_payments: Array<IConsumerPayment>;
  inflight_nsf_payments: Array<IConsumerPayment>;
}

/**
 * Information needed to schedule a payoff quote
 */
export interface IConsumerPayoffInformation {
  __typename: 'ConsumerPayoffInformation';
  has_active_payoff_quote: boolean;
  is_cancellable: boolean;
  is_payoff_quote_disqualified: boolean;
  max_payoff_date: string;
  min_payoff_date: string;
  most_recent_payoff_quote_status: string | null;
  payment_account_type: string | null;
  payment_account_uuid: string | null;
  payment_eff_date: string | null;
  payoff_balance: IMoney;
  payoff_quote_uuid: string | null;
  scheduled_payoff_quote_uuid: string | null;
  valid_payoff_reasons: Array<Array<string>>;
}

export interface IConsumerPointOfSaleInformation {
  __typename: 'ConsumerPointOfSaleInformation';
  adjusted_loan_amount: IMoney;
  last_date_loan_amount_changed: string | null;
  latest_product_return_date: string | null;
  merchant_image_url: string | null;
  merchant_name: string;
  refund_information: IConsumerPointOfSaleRefundInformation | null;
}

export interface IConsumerPointOfSaleRefundInformation {
  __typename: 'ConsumerPointOfSaleRefundInformation';
  amount: IMoney;
  is_full_refund: boolean;
}

/**
 * Information about products
 */
export interface IConsumerProduct {
  __typename: 'ConsumerProduct';
  amount: IMoney;
  application: ICustomerApplication;
  contract_signed_date: string | null;
  contract_url: string;
  credit_score_disclosure: string | null;
  current_state: string;
  down_payment_amount: IMoney | null;
  eligible_for_fsc: boolean;
  eligible_for_grace_period: boolean;
  fdic_interest_rate: number;
  financial_situation_review_eligible: boolean;
  funding_date: string;
  has_balance_post_maturity: boolean;
  has_future_editable_payment: boolean;
  id: string;
  installments: Array<IConsumerInstallment>;
  interest_rate: number;
  is_autopay: boolean;
  is_autopay_disqualified: boolean;
  is_make_payment_disqualified: boolean;
  is_next_payment_scheduled: boolean;
  is_past_due: boolean;
  late_date: string | null;
  loss_mitigation_offer_expiration_date: string | null;
  loss_mitigation_plan_acceptance_eligible: boolean;
  merchant_name: string | null;
  next_installment_amount: IMoney | null;
  next_installment_date: string | null;
  next_installment_original_amount: IMoney | null;
  next_non_zero_installment_payment: IConsumerPayment | null;
  next_nonzero_installment_amount: IMoney | null;
  next_nonzero_installment_date: string | null;
  next_nonzero_unadjusted_installment_date: string | null;
  opted_in_payment_account_type: PaymentAccountType | null;
  opted_in_payment_account_uuid: string | null;
  origination_application_uuid: string;
  origination_state: string;
  outstanding_balance: IMoney;
  paid_off_date: string | null;
  past_due_amount: IMoney | null;
  payment_cutoff_time: string;
  product_id: number;
  product_name: string;
  product_owner: string;
  product_subtype: string;
  product_type: string;
  product_uuid: string;
  repayment_day_of_month: number | null;
  servicing_account_uuid: string;
  settlement_acceptance_eligible: boolean;
  settlement_offer_expiration_date: string | null;
  status: string;
  typical_payment_amount: IMoney | null;
}

export interface ICustomerApplication {
  __typename: 'CustomerApplication';
  adverseActionNotices: Array<IAdverseActionNotice>;
  applicationOwner: string;
  approved: boolean;
  badEmail: boolean;
  canceled: boolean;
  closed: boolean;
  closedAt: string | null;
  contact_info: IApplicantContactInfo | null;
  createdAt: string | null;
  creditHardPullConsent: boolean;
  creditScoreDisclosureConsentHtml: string | null;
  currentVerificationsStage: string | null;
  declineScenario: IDeclineScenario | null;
  declined: boolean;
  employment: IApplicantEmployment;
  experiments: ICustomerApplicationExperiments;
  expired: boolean;

  /**
   * decline reason === :tu_security_freeze
   */
  hasCreditFreeze: boolean;
  id: string;
  inProgress: boolean;
  income: IApplicantIncome;

  /**
   * available income types
   */
  income_types: Array<string>;
  isComplete: boolean;
  isContractReSign: boolean | null;
  isG2: boolean;
  isInApplyStage: boolean;
  isInVerification: boolean;
  isOpen: boolean;
  isRefinance: boolean | null;
  lead: ILead | null;
  merchantIdentifier: string | null;
  merchantName: string | null;

  /**
   * @deprecated "This redirect has been moved into a backend controller action"
   */
  merchantRedirectUrls: IMerchantRedirectUrls | null;
  needsToViewApplication: boolean | null;
  phoneVerifications: IPhoneVerifications | null;
  product: Product | null;
  productName: string | null;
  productSubtype: string | null;
  productType: string | null;
  promotionCode: string | null;
  purpose: string | null;
  referrerUrl: string | null;

  /**
   * @deprecated "dead code being replaced by new doc service tasks"
   */
  requiredBankStatements: number | null;
  riskBasedPricingHtml: string | null;
  riskDisclosureConsent: boolean | null;
  source: string | null;
  stage: string | null;
  status: string | null;
  updatedAt: string | null;
  uuid: string;
  verificationTasksV2: Array<Verifications__Task__Interface__V2>;
}

export interface IAdverseActionNotice {
  __typename: 'AdverseActionNotice';
  effectiveTimestamp: string;
  html: string | null;
  id: string;
  noticeType: string;
  policy: string;
  reasonNotSent: string | null;

  /**
   * List of adverse action notice reasons
   */
  reasons: Array<string>;
  sent: boolean | null;
  uuid: string;
}

/**
 * Applicant Contact Info
 */
export interface IApplicantContactInfo {
  __typename: 'ApplicantContactInfo';
  email: string;
  home_phone: IPhoneNumber | null;
  latest_home_phone: IPhoneNumber | null;
}

export interface IPhoneNumber {
  __typename: 'PhoneNumber';
  formatted: string;
  number: string;
}

/**
 * Information about a DeclineScenario
 */
export interface IDeclineScenario {
  __typename: 'DeclineScenario';
  noticeUrl: string | null;
}

/**
 * Applicant Employment
 */
export interface IApplicantEmployment {
  __typename: 'ApplicantEmployment';
  employer_name: string | null;
}

export interface ICustomerApplicationExperiments {
  __typename: 'CustomerApplication__Experiments';
  id: string;
  uuid: string;
  yodleeRISEnabled: boolean | null;
}

/**
 * Applicant Income
 */
export interface IApplicantIncome {
  __typename: 'ApplicantIncome';
  income_type: string | null;
  monthly_net_income: number | null;
}

/**
 * A customer application Lead
 */
export interface ILead {
  __typename: 'Lead';
  id: string;
  isPostLead: boolean;
  isPrefill: boolean;
  source: string;
  uuid: string;
}

export interface IMerchantRedirectUrls {
  __typename: 'MerchantRedirectUrls';
  redirectApprovedUrl: string | null;
  redirectDeclinedUrl: string | null;
}

export interface IPhoneVerifications {
  __typename: 'PhoneVerifications';
  codesEntered: number;
  codesSent: number;
  lastCodeSentSuccessfully: boolean;
  lastVerificationType: string;
}

export type Product = ICreditCardAccount | ILoan;

export interface IProduct {
  __typename: 'Product';
  appliedOrApproved: boolean;
  createdAt: string;
  customerApplication: ICustomerApplication;
  id: string;

  /**
   * Checks if the product is active and should be displayed.
   */
  isActive: boolean;
  isIssued: boolean;
  status: string;
  uuid: string;
  verificationTasks: IVerification;
}

/**
 * Do not add to this list it's deprecated
 */
export interface IVerification {
  __typename: 'Verification';

  /**
   * @deprecated "do not add to this list"
   */
  acceptNewLoanTerms: IVerificationsAcceptNewTermsTaskV2 | null;

  /**
   * @deprecated "do not add to this list"
   */
  addAdditionalIncome: IVerificationsAdditionalIncomeTaskV2 | null;

  /**
   * @deprecated "do not add to this list"
   */
  bankAccountConnection: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  bankInformation: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  confirmBankAccountInformation: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  confirmPersonalInformation: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  confirmPersonalInformationDateOfBirth: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  confirmPersonalInformationSsn: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  docsBenefitsDocumentation: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  docsDepositStatement: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  docsOther: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  docsPayStub: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  docsTaxReturn: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  docsVoidedCheck: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  docsVoidedCheckWithInstruction: IVerificationsUploadTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  emailConfirmation: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  idScan: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  idScanWithVendor: IVerificationsVendorTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  personalIdentification: IVerificationsTask | null;

  /**
   * @deprecated "do not add to this list"
   */
  unlockCreditReport: IVerificationsUnlockCreditReportTaskV2 | null;

  /**
   * @deprecated "do not add to this list"
   */
  updateAddress: IVerificationsAddressTaskV2 | null;

  /**
   * @deprecated "do not add to this list"
   */
  validateAddress: IVerificationsAddressTaskV2 | null;

  /**
   * @deprecated "do not add to this list"
   */
  verifyPhoneNumber: IVerificationsTask | null;
}

export interface IVerificationsAcceptNewTermsTaskV2 {
  __typename: 'Verifications__AcceptNewTermsTask__V2';
  id: string;
  initialTerms: IVerificationsTerms;
  name: string;
  newTerms: IVerificationsTerms;
  sortIndex: number | null;
  status: VerificationsStatus;
}

export type Verifications__Task__Interface__V2 =
  | IVerificationsAcceptNewTermsTaskV2
  | IVerificationsAdditionalIncomeTaskV2
  | IVerificationsUnlockCreditReportTaskV2
  | IVerificationsAddressTaskV2
  | IVerificationsAcceptCounterOfferTaskV2
  | IVerificationsDocumentUploadPartnerTaskV2
  | IVerificationsDocumentUploadTaskV2
  | IVerificationsDocumentUploadTaskOneRequiredV2
  | IVerificationsTaskV2
  | IVerificationsUploadTaskV2
  | IVerificationsVendorTaskV2;

export interface IVerificationsTaskInterfaceV2 {
  __typename: 'Verifications__Task__Interface__V2';
  id: string;
  name: string;
  sortIndex: number | null;
  status: VerificationsStatus;
}

export const enum VerificationsStatus {
  opened = 'opened',
  submitted = 'submitted',
  reopened = 'reopened',
  completed = 'completed',
}

/**
 * Fields used to encapsulate terms for ver tasks
 */
export interface IVerificationsTerms {
  __typename: 'Verifications__Terms';
  amount: IMoney;
  apr: number;
  monthlyPayment: IMoney;
  term: number;
}

export interface IVerificationsAdditionalIncomeTaskV2 {
  __typename: 'Verifications__AdditionalIncomeTask__V2';
  additionalIncomeRequired: boolean;
  id: string;
  name: string;
  sortIndex: number | null;
  status: VerificationsStatus;
}

export interface IVerificationsTask {
  __typename: 'Verifications__Task';
  completed: boolean;
  failed: boolean;
  id: string;
  pending: boolean;
  reopened: boolean;
  required: boolean;
}

export type Verifications__Task__Interface =
  | IVerificationsTask
  | IVerificationsUploadTask
  | IVerificationsVendorTask;

export interface IVerificationsTaskInterface {
  __typename: 'Verifications__Task__Interface';
  completed: boolean;
  failed: boolean;
  id: string;
  pending: boolean;
  reopened: boolean;
  required: boolean;
}

export interface IVerificationsUploadTask {
  __typename: 'Verifications__UploadTask';
  completed: boolean;
  failed: boolean;
  id: string;
  instructions: IVerificationsTaskInstruction;
  pending: boolean;
  reopened: boolean;
  required: boolean;
}

export interface IVerificationsTaskInstruction {
  __typename: 'Verifications__TaskInstruction';
  applicantInstruction: Array<string>;
  blockers: Array<string>;
  headerInstruction: string | null;
  images: Array<string>;
}

export interface IVerificationsVendorTask {
  __typename: 'Verifications__VendorTask';
  completed: boolean;
  failed: boolean;
  id: string;
  pending: boolean;
  reopened: boolean;
  required: boolean;
  vendor: VendorType | null;
}

/**
 * Valid Vendor Types for Ver Tasks
 */
export const enum VendorType {
  mitek = 'mitek',
  jumio = 'jumio',
}

export interface IVerificationsUnlockCreditReportTaskV2 {
  __typename: 'Verifications__UnlockCreditReportTask__V2';
  bureauAddress: IVerificationsAddress | null;
  bureauPartner: string;
  bureauPhoneNumber: string;
  bureauWebsite: string | null;
  failureWaitingPeriodHours: string;
  id: string;
  name: string;
  reportRestrictionType: string;
  sortIndex: number | null;
  staleOutDate: string;
  status: VerificationsStatus;
}

/**
 * Fields used to encapsulate an address for ver tasks
 */
export interface IVerificationsAddress {
  __typename: 'Verifications__Address';
  city: string;
  errors: Array<string>;
  line1: string;
  line2: string;
  state: string;
  zipCode: string;
}

export interface IVerificationsAddressTaskV2 {
  __typename: 'Verifications__AddressTask__V2';
  allowResendingExisting: boolean;
  blockers: Array<string> | null;
  id: string;
  name: string;
  originalAddress: IVerificationsAddress;
  sortIndex: number | null;
  status: VerificationsStatus;
  validAddresses: Array<IVerificationsAddress> | null;
}

/**
 * Information needed to display on repayment progress card
 */
export interface IConsumerRepaymentInformation {
  __typename: 'ConsumerRepaymentInformation';
  adjusted_amount_progress: number;
  adjusted_interest_outstanding_amount: IMoney;
  adjusted_paid_amount: IMoney;
  adjusted_paid_fees_amount: IMoney;
  adjusted_paid_interest_amount: IMoney;
  adjusted_paid_principal_amount: IMoney;
  adjusted_principal_outstanding_amount: IMoney;
  adjusted_remaining_amount: IMoney;
  fees_credit_amount: IMoney;
  fees_outstanding_amount: IMoney;
  fees_paid_amount: IMoney;
  finance_charge_amount: IMoney | null;
  interest_credit_amount: IMoney;
  interest_outstanding_amount: IMoney;
  paid_interest: IMoney;
  paid_principal: IMoney;
  payments_remaining: number;
  principal_credit_amount: IMoney;
  principal_outstanding_amount: IMoney;
  principal_progress: number;
  total_credit_amount: IMoney;
  total_outstanding_amount: IMoney;
  total_paid_amount: IMoney;
  total_payment_amount: IMoney | null;
  total_payments: number;
}

/**
 * A servicing account servicer
 */
export interface IServicer {
  __typename: 'Servicer';
  address: string | null;
  email_address: string;
  name: string;
  phone_number: string;
}

/**
 * Valid payment methods for the account
 */
export interface IConsumerValidPaymentMethods {
  __typename: 'ConsumerValidPaymentMethods';
  valid_bank_accounts: Array<IConsumerBankAccount>;
  valid_debit_cards: Array<IConsumerDebitCard>;
}

/**
 * A single bank account
 */
export interface IConsumerBankAccount {
  __typename: 'ConsumerBankAccount';
  account_number: string;
  account_type: string;
  active: boolean;
  bad_account: boolean;
  created_at: string;
  name: string | null;
  payment_method_uuid: string;
  routing_number: string;
  uuid: string;
}

/**
 * A single debit card
 */
export interface IConsumerDebitCard {
  __typename: 'ConsumerDebitCard';
  active: boolean;
  bad_debit_card: boolean;
  card_alias: string | null;
  card_brand: string;
  created_at: string;
  debit_card_uuid: string;
  expiration_month: string;
  expiration_year: string;
  expires_within_30_days: boolean;
  is_expired: boolean;
  last_4: string;
  payment_method_uuid: string;
}

/**
 * A VirtualCard Token Reference
 */
export interface IVirtualCardToken {
  __typename: 'VirtualCardToken';

  /**
   * The token used to provide access
   */
  access_token: string;

  /**
   * Last 4 digits of the virtual card
   */
  card_last_4: string;

  /**
   * The current status of the card, including "expired"
   */
  card_status: CardStatus;

  /**
   * The token used to uniquely ID the vendor card record
   */
  card_token: string;

  /**
   * Status update records for the virtual card
   */
  card_transactions: Array<IVirtualCardTransaction | null>;

  /**
   * When the record was created
   */
  created_at: string;

  /**
   * The credit limit of the card
   */
  credit_amount: IMoney;

  /**
   * The time after which the card is not usable
   */
  expiration_time: string;
  id: string;

  /**
   * Whether or not the virtual card is expired. It is not recommended to use this as time changes.
   */
  is_expired: boolean;

  /**
   * Whether or not the card has been used.
   */
  is_used: boolean;

  /**
   * The merchant that the card is whitelisted for
   */
  merchant: IVirtualCardMerchant;

  /**
   * When the record was last updated
   */
  updated_at: string;

  /**
   * Use By datetime of temporary card
   */
  use_by: string;

  /**
   * The token used to uniquely ID the vendor user record
   */
  user_token: string;
}

export interface ICreatedAtOnVirtualCardTokenArguments {
  as_date: boolean;
}

export interface IExpirationTimeOnVirtualCardTokenArguments {
  as_date: boolean;
}

export interface IUpdatedAtOnVirtualCardTokenArguments {
  as_date: boolean;
}

export interface IUseByOnVirtualCardTokenArguments {
  as_date: boolean;
}

/**
 * The set of valid card statuses
 */
export const enum CardStatus {
  inactive = 'inactive',
  active = 'active',
  pending = 'pending',
  expired = 'expired',
  reinstated = 'reinstated',
  suspended = 'suspended',
  terminated = 'terminated',
}

/**
 * A record of updates to the token from a vendor webhook
 */
export interface IVirtualCardTransaction {
  __typename: 'VirtualCardTransaction';
  created_at: string;
  disputable: boolean;
  id: string;
  transaction_additional_info: string | null;
  transaction_amount: IMoney | null;
  transaction_reason: string | null;
  transaction_state: TransactionState | null;
  transaction_token: string;
  transaction_type: string;
  updated_at: string;
  vendor_timestamp: string | null;
  virtual_card_merchant_id: string | null;
}

/**
 * The set of valid states returned from a transaction
 */
export const enum TransactionState {
  pending = 'pending',
  completion = 'completion',
  cleared = 'cleared',
  declined = 'declined',
  error = 'error',
}

/**
 * A reference to the VirtualCard Merchant Definition
 */
export interface IVirtualCardMerchant {
  __typename: 'VirtualCardMerchant';
  created_at: string;
  id: string;
  merchant_group_name: string | null;
  merchant_group_token: string | null;
  merchant_identifier: string;
  merchant_ids: Array<string> | null;
  updated_at: string;
}

export interface IClientInstall {
  __typename: 'ClientInstall';
  allowAccountStatusNotifications: boolean | null;
  allowPaymentReminder7DaysNotifications: boolean | null;
  allowPaymentReminderNotifications: boolean | null;
  allowPaymentStatusNotifications: boolean | null;
  allowPaymentSuccessNotifications: boolean | null;
  createdAt: string;
  deviceToken: string | null;
  id: string;
  parseInstallationId: string | null;
  updatedAt: string;
  uuid: string;
}

/**
 * Core object of the CustomerManagement domain.
 */
export interface IIdentityCustomer {
  __typename: 'Identity__Customer';
  addresses: Array<IIdentityAddress>;
  applications: Array<ICustomerApplication>;
  bank_accounts: Array<IIdentityBankAccount>;
  bankruptcy_cases: Array<IBankruptcy>;
  bankruptcy_claims: Array<IBankruptcyClaim>;
  can_new_payment_method_be_added: boolean;
  card_form_render_information: ICardFormRenderInformation;
  consent_info: IConsentInfo;
  customer_uuid: string;
  debit_cards: Array<IIdentityDebitCard>;
  display_bad_bank_account_banner_on_customer_dash: boolean;
  displayable_phone_numbers: Array<IIdentityPhoneNumber>;
  email_addresses: Array<IEmailAddress>;
  get_bankruptcy_claim_disqualifiers: IBankruptcyClaimDisqualifiers;
  get_customer_information: IIdentityCustomerInformation;
  get_deactivation_status: boolean;
  has_bad_payment_method: boolean;
  has_bad_personal_information: boolean;
  id: string;
  is_bad_email: boolean;
  is_bankruptcy_discharged: boolean;
  is_under_scra: boolean;
  onetime_payments_with_payment_method: Array<ICustomerManagementPayment>;
  operational_statuses: Array<string>;
  phone_numbers: Array<IIdentityPhoneNumber>;
  servicing_account_uuids: Array<string>;
  servicing_accounts: Array<IAccountManagement>;
  usable_bank_accounts: Array<IUsableBankAccount>;
  usable_debit_cards: Array<IUsableDebitCard>;
  validate_bank_account_info: IBankAccountValidationResponse;
  faker: boolean | null;
}

export interface IIsBankruptcyDischargedOnIdentityCustomerArguments {
  since?: string | null;
  as_of?: string | null;
}

export interface IOnetimePaymentsWithPaymentMethodOnIdentityCustomerArguments {
  payment_account_uuid: string;
  payment_account_type: string;
}

export interface IValidateBankAccountInfoOnIdentityCustomerArguments {
  routing_number: string;
  account_number: string;
}

/**
 * A single address hash
 */
export interface IIdentityAddress {
  __typename: 'IdentityAddress';
  address_1: string;
  address_2: string | null;
  address_3: string | null;
  address_is_bad: boolean;
  address_type: string;
  city: string;
  is_primary: boolean;
  rent_or_own: string | null;
  state: string;
  time_at_address: string | null;
  uuid: string;
  zip: string;
  zip4: string | null;
}

/**
 * A single bank account
 */
export interface IIdentityBankAccount {
  __typename: 'IdentityBankAccount';
  account_number: string;
  account_type: string;
  active: boolean;
  bad_account: boolean;
  created_at: string;
  name: string | null;
  routing_number: string;
  uuid: string;
}

/**
 * A single bankruptcy case.
 */
export interface IBankruptcy {
  __typename: 'Bankruptcy';
  attorney_email: string | null;
  attorney_name: string | null;
  attorney_phone_number: IFormattedPhoneNumber | null;
  case_number: string | null;
  chapter: string;
  court_district: string | null;
  current_event_id: number;
  current_event_uuid: string;
  events: Array<IBankruptcyEvent>;
  filed_date: string;
  law_firm_city: string | null;
  law_firm_email: string | null;
  law_firm_name: string | null;
  law_firm_phone: IFormattedPhoneNumber | null;
  law_firm_state: string | null;
  law_firm_street_address: string | null;
  law_firm_suite: string | null;
  law_firm_zip_code: string | null;
  location_of_filing: string | null;
  proof_of_claim_date: string | null;
  representation_confirmed: string | null;
  status: string;
}

/**
 * A hash that contains a raw phone number and its formatted state
 */
export interface IFormattedPhoneNumber {
  __typename: 'FormattedPhoneNumber';
  formatted: string;
  number: string;
}

/**
 * An element of a bankruptcy's history.
 */
export interface IBankruptcyEvent {
  __typename: 'BankruptcyEvent';
  chapter: string;
  date: string;
  event_created_at: string;
  event_id: number;
  event_uuid: string;
  status: string;
}

/**
 * A claimed bankruptcy
 */
export interface IBankruptcyClaim {
  __typename: 'BankruptcyClaim';
  attorney_email: string | null;
  attorney_name: string | null;
  attorney_phone_number: IFormattedPhoneNumber | null;
  case_number: string | null;
  chapter: string;
  court_district: string | null;
  created_at: string;
  eff_date: string;
  id: number;
  law_firm_city: string | null;
  law_firm_email: string | null;
  law_firm_name: string | null;
  law_firm_phone: IFormattedPhoneNumber | null;
  law_firm_state: string | null;
  law_firm_street_address: string | null;
  law_firm_suite: string | null;
  law_firm_zip_code: string | null;
  location_of_filing: string | null;
  proof_of_claim_date: string | null;
  representation_confirmed: string | null;
  status: string;
  uuid: string;
}

/**
 * Information for credit and debit card forms for frontend to call payment gateway
 */
export interface ICardFormRenderInformation {
  __typename: 'CardFormRenderInformation';
  account_reference: string | null;
  card_types_available: Array<string | null> | null;
  customer_id: string | null;
  customer_name: string | null;
  debit_card_payment_method_key: string | null;
  errors: Array<IError | null> | null;
  financial_owner: string | null;
  months: Array<Array<string | null> | null> | null;
  payment_gateway_url: string | null;
  state: string | null;
  us_states: Array<IAddressState | null> | null;
  user_token: string | null;
  years: Array<string | null> | null;
  zip: string | null;
}

/**
 * A descriptive error
 */
export interface IError {
  __typename: 'Error';
  error_code: ErrorCode | null;
  field: string | null;
  message: string;
  type: string | null;
}

/**
 * Valid error codes
 */
export const enum ErrorCode {
  /**
   * Missing input property
   */
  APP_01 = 'APP_01',

  /**
   * Invalid input/character
   */
  APP_02 = 'APP_02',

  /**
   * Type mismatches
   */
  APP_03 = 'APP_03',

  /**
   * Default Error Code
   */
  APP_04 = 'APP_04',

  /**
   * Invalid amount
   */
  PMT_01 = 'PMT_01',

  /**
   * Amount is greater than remaining balance
   */
  PMT_02 = 'PMT_02',

  /**
   * Payment cannot be less than your installment amount
   */
  PMT_03 = 'PMT_03',

  /**
   * Invalid dates
   */
  DT_01 = 'DT_01',

  /**
   * Date cannot be on a non-business day
   */
  DT_02 = 'DT_02',

  /**
   * Date should be before the payoff date
   */
  DT_03 = 'DT_03',

  /**
   * Date should be before or after cut off time
   */
  DT_04 = 'DT_04',

  /**
   * Payment cannot be applied before the installment
   */
  DT_05 = 'DT_05',

  /**
   * Payment method selected will be expired at the time of selected date
   */
  DT_06 = 'DT_06',

  /**
   * Date not in specified range
   */
  DT_07 = 'DT_07',

  /**
   * Date is past max date
   */
  DT_08 = 'DT_08',

  /**
   * Zero payoff amount for that given date
   */
  DT_09 = 'DT_09',

  /**
   * Unable to opt out
   */
  AP_01 = 'AP_01',

  /**
   * Already opted out
   */
  AP_02 = 'AP_02',

  /**
   * Unable to opt-out of Next Installment (cut off)
   */
  AP_03 = 'AP_03',

  /**
   * Unable to opt-in an invalid payment method
   */
  AP_04 = 'AP_04',

  /**
   * Duplicate debit card/Bank Account
   */
  BDE_01 = 'BDE_01',

  /**
   * Routing Number not found in our list of banks
   */
  BDE_02 = 'BDE_02',

  /**
   * Account type must be checking or savings
   */
  BED_03 = 'BED_03',

  /**
   * Account number format error
   */
  BED_04 = 'BED_04',

  /**
   * Routing number format error
   */
  BED_05 = 'BED_05',

  /**
   * Unable to add a debit card without a valid product
   */
  BDE_06 = 'BDE_06',

  /**
   * Payment method not found
   */
  AMPM_01 = 'AMPM_01',

  /**
   * Missing bank account info
   */
  GCT_01 = 'GCT_01',

  /**
   * Duplicate information
   */
  GCT_02 = 'GCT_02',

  /**
   * Cut off time failed
   */
  GCT_03 = 'GCT_03',

  /**
   * Unavailable to verify
   */
  GCT_04 = 'GCT_04',

  /**
   * cc_invalid
   */
  PG_01 = 'PG_01',

  /**
   * dc_invalid
   */
  PG_02 = 'PG_02',

  /**
   * cvv_invalid
   */
  PG_03 = 'PG_03',

  /**
   * cc_invalid
   */
  cc_invalid = 'cc_invalid',

  /**
   * dc_invalid
   */
  dc_invalid = 'dc_invalid',

  /**
   * cvv_invalid
   */
  cvv_invalid = 'cvv_invalid',

  /**
   * zip_invalid
   */
  zip_invalid = 'zip_invalid',

  /**
   * cvv_or_zip_invalid
   */
  cvv_or_zip_invalid = 'cvv_or_zip_invalid',

  /**
   * issuer_country_invalid
   */
  issuer_country_invalid = 'issuer_country_invalid',

  /**
   * payment_type_not_accepted
   */
  payment_type_not_accepted = 'payment_type_not_accepted',

  /**
   * card_type_not_accepted
   */
  card_type_not_accepted = 'card_type_not_accepted',

  /**
   * card_network_not_accepted
   */
  card_network_not_accepted = 'card_network_not_accepted',

  /**
   * funds_insufficient
   */
  funds_insufficient = 'funds_insufficient',

  /**
   * card_details_invalid
   */
  card_details_invalid = 'card_details_invalid',

  /**
   * validate_card_failure
   */
  validate_card_failure = 'validate_card_failure',

  /**
   * authorize_card_failure
   */
  authorize_card_failure = 'authorize_card_failure',

  /**
   * void_card_failure
   */
  void_card_failure = 'void_card_failure',

  /**
   * refund_card_failure
   */
  refund_card_failure = 'refund_card_failure',

  /**
   * expiration_date_passed
   */
  expiration_date_passed = 'expiration_date_passed',

  /**
   * card_expired
   */
  card_expired = 'card_expired',

  /**
   * validate_card_timeout
   */
  validate_card_timeout = 'validate_card_timeout',

  /**
   * authorize_card_timeout
   */
  authorize_card_timeout = 'authorize_card_timeout',

  /**
   * void_card_timeout
   */
  void_card_timeout = 'void_card_timeout',

  /**
   * refund_card_timeout
   */
  refund_card_timeout = 'refund_card_timeout',

  /**
   * You have exceeded the {type} cutoff time of {cutoff_time}. Please select another date or payment method.
   */
  SPQ_01 = 'SPQ_01',

  /**
   * Bank account (ACH) payments must be scheduled for a business day
   */
  SPQ_02 = 'SPQ_02',

  /**
   * The payment method you selected will be expired at the time of your selected
   * date. Please select another date before your payment method expires or select
   * another payment method.
   */
  SPQ_03 = 'SPQ_03',

  /**
   * Something went wrong, please try again
   */
  SPQ_04 = 'SPQ_04',

  /**
   * This payment has begun processing and can’t be canceled
   */
  CP_01 = 'CP_01',

  /**
   * This payment has begun processing and can’t be canceled
   */
  CPQ_01 = 'CPQ_01',

  /**
   * Timeline generation error
   */
  BRC_01 = 'BRC_01',

  /**
   * Effective date is required
   */
  BRC_02 = 'BRC_02',

  /**
   * Chapter is required
   */
  BRC_03 = 'BRC_03',

  /**
   * Select a valid chapter
   */
  BRC_04 = 'BRC_04',

  /**
   * Case number is required
   */
  BRC_05 = 'BRC_05',

  /**
   * Bankruptcy doesn’t exist
   */
  BRC_06 = 'BRC_06',

  /**
   * Address is required
   */
  ADR1_01 = 'ADR1_01',

  /**
   * Contains invalid characters
   */
  ADR1_02 = 'ADR1_02',

  /**
   * Contains invalid characters
   */
  ADR2_02 = 'ADR2_02',

  /**
   * City is required
   */
  CTY_01 = 'CTY_01',

  /**
   * Contains invalid characters
   */
  CTY_02 = 'CTY_02',

  /**
   * State is required
   */
  STA_01 = 'STA_01',

  /**
   * Select valid state
   */
  STA_02 = 'STA_02',

  /**
   * Zip code is required
   */
  ZIP_01 = 'ZIP_01',

  /**
   * Contains invalid characters
   */
  ZIP_02 = 'ZIP_02',

  /**
   * Customer is not deceased
   */
  DEC_01 = 'DEC_01',

  /**
   * Customer is already deceased
   */
  DEC_02 = 'DEC_02',

  /**
   * Amount is required
   */
  AMT_01 = 'AMT_01',

  /**
   * Date is required
   */
  DTE_01 = 'DTE_01',

  /**
   * Credit failed due to amount value
   */
  CRE_01 = 'CRE_01',

  /**
   * Credit failed due to eff date
   */
  CRE_02 = 'CRE_02',

  /**
   * Reason is required
   */
  CRE_03 = 'CRE_03',

  /**
   * credit failed due to reason
   */
  CRE_04 = 'CRE_04',
}

/**
 * A name and abbreviation for a US state
 */
export interface IAddressState {
  __typename: 'AddressState';
  abbreviation: string;
  name: string;
}

/**
 * Customer Consent Info
 */
export interface IConsentInfo {
  __typename: 'ConsentInfo';
  affiliates_marketing_consent: boolean;
  cease_and_desist: boolean;
  creditworthiness_information_sharing_consent: boolean;
  do_not_call: boolean;
  do_not_email: boolean;
  do_not_mail: boolean;
  do_not_robocall: boolean;
  do_not_share_information: boolean;
  do_not_sms: boolean;
  electronic_communications_consent: boolean;
  non_affiliates_marketing_consent: boolean;
}

/**
 * A single debit card
 */
export interface IIdentityDebitCard {
  __typename: 'IdentityDebitCard';
  active: boolean;
  bad_debit_card: boolean;
  card_alias: string | null;
  card_brand: string;
  card_type: string | null;
  created_at: string;
  debit_card_uuid: string;
  expiration_month: string;
  expiration_year: string;
  expires_within_30_days: boolean;
  is_bad: boolean;
  is_expired: boolean;
  last_4: string;
}

/**
 * A single phone number
 */
export interface IIdentityPhoneNumber {
  __typename: 'IdentityPhoneNumber';
  active: boolean;
  created_at: string;
  do_not_auto_dial: boolean;
  do_not_call: boolean;
  do_not_sms: boolean;
  ext: string | null;
  number: IFormattedPhoneNumber | null;
  number_is_bad: boolean;
  phone_type: string;
  primary_phone: boolean;
  uuid: string;
}

/**
 * A single email hash
 */
export interface IEmailAddress {
  __typename: 'EmailAddress';
  email_address: string;
  is_bad: boolean;
}

/**
 * Bankruptcy Claim Disqualifiers
 */
export interface IBankruptcyClaimDisqualifiers {
  __typename: 'BankruptcyClaimDisqualifiers';
  has_claims: boolean;
  opt_out_autopay_bk: boolean;
}

/**
 * A single customer's information
 */
export interface IIdentityCustomerInformation {
  __typename: 'IdentityCustomerInformation';
  addresses: Array<IIdentityAddress>;
  customer_id: string;
  customer_uuid: string;
  date_of_birth: string;
  email_addresses: Array<IEmailAddress>;
  first_name: string;
  last_name: string;
  phone_numbers: Array<IIdentityPhoneNumber>;
  ssn: string;
  ssn_last_4: string;
}

/**
 * A Payment and associated product info
 */
export interface ICustomerManagementPayment {
  __typename: 'CustomerManagementPayment';
  amount: IMoney;
  balance_effective_date: string;
  cash_flow_effective_date: string;
  eff_date: string;
  merchant_name: string | null;
  payment_id: string;
  product_id: string;
  product_name: string;
}

/**
 * A single bank account
 */
export interface IUsableBankAccount {
  __typename: 'UsableBankAccount';
  account_number: string;
  account_type: string;
  created_at: string;
  name: string | null;
  routing_number: string;
  uuid: string;
}

/**
 * A single usable debit card
 */
export interface IUsableDebitCard {
  __typename: 'UsableDebitCard';
  card_alias: string | null;
  card_brand: string;
  created_at: string;
  debit_card_uuid: string;
  expiration_month: string;
  expiration_year: string;
  expires_within_30_days: boolean;
  last_4: string;
}

/**
 * Bank Account Validation Response
 */
export interface IBankAccountValidationResponse {
  __typename: 'BankAccountValidationResponse';
  is_valid: boolean;
  validation_errors: Array<IError>;
}

/**
 * Configuration object of the CustomerManagement domain.
 */
export interface IIdentityConfigs {
  __typename: 'Identity__Configs';
  address_states: Array<IAddressState>;
  use_g2: boolean;
}

/**
 * Information about a customer
 */
export interface ICustomer {
  __typename: 'Customer';
  activePaymentAccounts: Array<PaymentAccount>;
  address: IAddress | null;
  badEmail: boolean | null;
  bankAccount: IBankAccount | null;
  bankAccounts: Array<IBankAccount>;
  canManageDebitCard: boolean;
  canReapply: boolean;
  canUpdateBankAccount: boolean;
  complaints: Array<IComplaint> | null;
  consents: ICustomerConsent | null;
  crossSellInformation: Array<ICrossSellInformation>;
  customerApplications: ICustomerApplicationConnection;
  debitCards: IDebitCardConnection;
  display_bad_bank_account_banner_on_customer_dash: boolean;
  eligibleToUpdateIncomeAndHousing: boolean;
  email: string | null;
  firstName: string | null;
  getApplication: ICustomerApplication | null;
  getProduct: Product | null;
  getWorkflow: IWorkflow;
  hasMultiProductEnabled: boolean | null;
  homePhone: string | null;

  /**
   * returns phone number object with a number field that returns a string without
   * dashs and parenthesis ('5555555555') and a formatted field that returns a
   * string in '(555) 555-5555' format
   */
  homePhoneNumber: IPhoneNumber | null;
  id: string;
  inCoolDownPeriod: boolean;
  income: IIncome | null;
  isDormant: boolean | null;
  isOptedIntoDirectDebit: boolean | null;
  isReturningCustomer: boolean | null;
  lastName: string;
  name: string | null;
  nextEligibleDate: string | null;

  /**
   * obfuscated email with 5 interstitial * between the first and last letter of
   * the local-part. Example: ab@test.com -> a*****b@test.com.
   */
  obfuscatedEmail: string | null;
  products: IProductConnection;
  uuid: string;
}

export interface ICustomerApplicationsOnCustomerArguments {
  /**
   * Returns the first _n_ elements from the list.
   */
  first?: number | null;

  /**
   * Returns the elements in the list that come after the specified global ID.
   */
  after?: string | null;

  /**
   * Returns the last _n_ elements from the list.
   */
  last?: number | null;

  /**
   * Returns the elements in the list that come before the specified global ID.
   */
  before?: string | null;
}

export interface IDebitCardsOnCustomerArguments {
  /**
   * Returns the first _n_ elements from the list.
   */
  first?: number | null;

  /**
   * Returns the elements in the list that come after the specified global ID.
   */
  after?: string | null;

  /**
   * Returns the last _n_ elements from the list.
   */
  last?: number | null;

  /**
   * Returns the elements in the list that come before the specified global ID.
   */
  before?: string | null;
}

export interface IGetApplicationOnCustomerArguments {
  uuid: string;
}

export interface IGetProductOnCustomerArguments {
  uuid: string;
}

export interface IGetWorkflowOnCustomerArguments {
  workflowName: string;
  productType?: string | null;
  productUUID?: string | null;
  params?: any | null;
}

export interface IProductsOnCustomerArguments {
  /**
   * Returns the first _n_ elements from the list.
   */
  first?: number | null;

  /**
   * Returns the elements in the list that come after the specified global ID.
   */
  after?: string | null;

  /**
   * Returns the last _n_ elements from the list.
   */
  last?: number | null;

  /**
   * Returns the elements in the list that come before the specified global ID.
   */
  before?: string | null;
}

/**
 * Payment accounts that can be used to make payments
 */
export type PaymentAccount = IBankAccount | IDebitCard;

/**
 * Payment accounts that can be used to make payments
 */
export interface IPaymentAccount {
  __typename: 'PaymentAccount';
  createdAt: string;
  uuid: string | null;
}

/**
 * An address
 */
export interface IAddress {
  __typename: 'Address';
  address1: string | null;
  address2: string | null;
  address3: string | null;
  city: string | null;
  createdAt: string;
  formattedAddress: string | null;
  houseName: string | null;
  houseNumber: string | null;
  id: string;
  rentOrOwn: string | null;
  state: string | null;
  timeAtAddress: string | null;
  updatedAt: string;
  uuid: string;
  zip: string | null;
}

export interface IBankAccount {
  __typename: 'BankAccount';
  accountNumberLast4: string | null;
  accountType: string | null;
  badAccount: boolean | null;
  bankName: string | null;
  createdAt: string;
  id: string | null;
  routingNumber: string | null;
  uuid: string | null;
}

export interface IComplaint {
  __typename: 'Complaint';
  customer_uuid: string;
  date_received: string;
  description: string;
  id: string;
  product_id: string;
  product_type: string;
  resolution: IComplaintResolution | null;
  resolution_due_date: string;
  status: string;
  tier: number;
}

export interface IComplaintResolution {
  __typename: 'ComplaintResolution';
  cause_detail: string | null;
  date_resolved: string | null;
  description: string | null;
  id: string | null;
}

export interface ICustomerConsent {
  __typename: 'CustomerConsent';
  acceptedCurrentOnlineVerificationEula: boolean;
  doNotCall: boolean;
  doNotEmail: boolean;
  doNotMail: boolean;
  doNotRobocall: boolean;
  doNotShareInformation: boolean;
  doNotSms: boolean;
  electronicCommunicationsConsent: boolean;
  id: string;
  uuid: string;
}

export interface ICrossSellInformation {
  __typename: 'CrossSellInformation';
  crossSellType: string;
  data: ICrossSellInformationData;
}

export interface ICrossSellInformationData {
  __typename: 'CrossSellInformationData';
  url: string;
}

/**
 * The connection type for CustomerApplication.
 */
export interface ICustomerApplicationConnection {
  __typename: 'CustomerApplicationConnection';
  edges: Array<ICustomerApplicationEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface ICustomerApplicationEdge {
  __typename: 'CustomerApplicationEdge';

  /**
   * A cursor for use in pagination.
   */
  cursor: string;
  node: ICustomerApplication;
}

/**
 * Information about pagination in a connection.
 */
export interface IPageInfo {
  __typename: 'PageInfo';

  /**
   * When paginating forwards, the cursor to continue
   */
  endCursor: string | null;

  /**
   * Indicates if there are more pages to fetch
   */
  hasNextPage: boolean;

  /**
   * Indicates if there are any pages prior to the current page
   */
  hasPreviousPage: boolean;

  /**
   * When paginating backwards, the cursor to continue
   */
  startCursor: string | null;
}

/**
 * The connection type for DebitCard.
 */
export interface IDebitCardConnection {
  __typename: 'DebitCardConnection';
  edges: Array<IDebitCardEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IDebitCardEdge {
  __typename: 'DebitCardEdge';

  /**
   * A cursor for use in pagination.
   */
  cursor: string;
  node: IDebitCard;
}

export interface IDebitCard {
  __typename: 'DebitCard';

  /**
   * The card's alias
   */
  cardAlias: string | null;

  /**
   * The card's brand
   */
  cardBrand: string | null;

  /**
   * The card's last 4 number
   */
  cardNumberLast4: string | null;

  /**
   * The card's type
   */
  cardType: string | null;
  createdAt: string;

  /**
   * The debit card's id
   */
  id: string;

  /**
   * The debit card's id
   */
  uuid: string;
}

export interface IWorkflow {
  __typename: 'Workflow';
  id: string;
  name: string;
  state: string;
  step: IWorkflowStep;
  steps: Array<IWorkflowStep>;
  uuid: string;
}

export interface IWorkflowStep {
  __typename: 'WorkflowStep';
  actionToEmphasize: string | null;
  actions: Array<IWorkflowAction>;
  error: any | null;
  errors: Array<IInputError>;
  id: string;
  inputs: Array<WorkflowInput>;
  name: string;
  onExit: IWorkflowOnExit | null;
  opts: any | null;
  script: any | null;
  uuid: string;
}

export interface IWorkflowAction {
  __typename: 'WorkflowAction';
  displayName: string;
  emphasized: boolean | null;
  isFinish: boolean;
  name: string;
  opts: any;
  secondary: boolean | null;
}

/**
 * An error linked to a particular input in a workflow step
 */
export interface IInputError {
  __typename: 'InputError';
  input: string | null;
  message: string | null;
}

/**
 * An input for a workflow
 */
export type WorkflowInput =
  | IWorkflowCheckboxInput
  | IWorkflowDateInput
  | IWorkflowEmailInput
  | IWorkflowNumberInput
  | IWorkflowRadioInput
  | IWorkflowSelectInput
  | IWorkflowTextAreaInput
  | IWorkflowTextInput;

/**
 * An input for a workflow
 */
export interface IWorkflowInput {
  __typename: 'WorkflowInput';
  name: string;
  placeholder: string | null;
  required: boolean | null;
  type: WorkflowInputType;
  value: string | null;
}

/**
 * Valid Input Types for a Workflow
 */
export const enum WorkflowInputType {
  textarea = 'textarea',
  text = 'text',
  date = 'date',
  checkbox = 'checkbox',
  number = 'number',
  select = 'select',
  radios = 'radios',
}

export interface IWorkflowOnExit {
  __typename: 'WorkflowOnExit';
  cancelText: string;
  confirmationText: string;
  text: string;
}

export interface IIncome {
  __typename: 'Income';
  id: string;
  payFrequency: PayFrequency | null;
  type: string;
  uuid: string;
}

/**
 * Types of pay frequencies
 */
export const enum PayFrequency {
  biweekly = 'biweekly',
  weekly = 'weekly',
  twice_monthly = 'twice_monthly',
  monthly = 'monthly',
  annual = 'annual',
}

/**
 * The connection type for Product.
 */
export interface IProductConnection {
  __typename: 'ProductConnection';
  edges: Array<IProductEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IProductEdge {
  __typename: 'ProductEdge';

  /**
   * A cursor for use in pagination.
   */
  cursor: string;
  node: Product;
}

/**
 * Global Warning Banners
 */
export interface IWarningBanners {
  __typename: 'WarningBanners';
  apply: any | null;
  authentication: any | null;
  content: any | null;
  home: any | null;
  servicing: any | null;
  verification: any | null;
}

/**
 * Global Warning Banners
 */
export interface ICustomerManagementWarningBanners {
  __typename: 'CustomerManagementWarningBanners';
  apply: any | null;
  authentication: any | null;
  content: any | null;
  home: any | null;
  servicing: any | null;
  verification: any | null;
}

/**
 * Mutation root
 */
export interface IMutation {
  __typename: 'Mutation';
  account_management: IMutationConsumerAccountManagement | null;
  identity: IMutationCustomer | null;
  servicing: IMutationsServicing;
  updateClientInstall: IMutationsUpdateClientInstallPayload | null;
  updateEmail: IMutationsUpdateEmailPayload | null;
  updatePhoneNumber: IMutationsUpdatePhoneNumberPayload | null;
  verification: IMutationsVerification;
  workflows: IMutationsWorkflow | null;
}

export interface IAccountManagementOnMutationArguments {
  account_uuid?: string | null;
  account_id?: string | null;
  product_uuid?: string | null;
  product_type?: string | null;
}

export interface IUpdateClientInstallOnMutationArguments {
  input: IMutationsUpdateClientInstallInput;
}

export interface IUpdateEmailOnMutationArguments {
  input: IMutationsUpdateEmailInput;
}

export interface IUpdatePhoneNumberOnMutationArguments {
  input: IMutationsUpdatePhoneNumberInput;
}

/**
 * Account Management Mutations which are accessible via customer facing clients
 */
export interface IMutationConsumerAccountManagement {
  __typename: 'Mutation__Consumer__AccountManagement';
  cancel_payment: IMutationsConsumerAccountManagementCancelPaymentPayload | null;
  cancel_payoff_quote: IMutationsAccountManagementCancelPayoffQuotePayload | null;
  create_payment: IMutationsConsumerAccountManagementCreatePaymentPayload | null;
  create_payment_auth_script: IMutationsConsumerAccountManagementCreatePaymentAuthScriptPayload | null;
  create_payoff_quote: IMutationsAccountManagementCreatePayoffQuotePayload | null;
  edit_payment: IMutationsConsumerAccountManagementEditPaymentPayload | null;
  opted_in_out_toggle: IMutationsConsumerAccountManagementOptedInOutTogglePayload | null;
  schedule_payoff_quote: IMutationsAccountManagementSchedulePayoffQuotePayload | null;
}

export interface ICancelPaymentOnMutationConsumerAccountManagementArguments {
  input: IMutationsConsumerAccountManagementCancelPaymentInput;
}

export interface ICancelPayoffQuoteOnMutationConsumerAccountManagementArguments {
  input: IMutationsAccountManagementCancelPayoffQuoteInput;
}

export interface ICreatePaymentOnMutationConsumerAccountManagementArguments {
  input: IMutationsConsumerAccountManagementCreatePaymentInput;
}

export interface ICreatePaymentAuthScriptOnMutationConsumerAccountManagementArguments {
  input: IMutationsConsumerAccountManagementCreatePaymentAuthScriptInput;
}

export interface ICreatePayoffQuoteOnMutationConsumerAccountManagementArguments {
  input: IMutationsAccountManagementCreatePayoffQuoteInput;
}

export interface IEditPaymentOnMutationConsumerAccountManagementArguments {
  input: IMutationsConsumerAccountManagementEditPaymentInput;
}

export interface IOptedInOutToggleOnMutationConsumerAccountManagementArguments {
  input: IMutationsConsumerAccountManagementOptedInOutToggleInput;
}

export interface ISchedulePayoffQuoteOnMutationConsumerAccountManagementArguments {
  input: IMutationsAccountManagementSchedulePayoffQuoteInput;
}

/**
 * Autogenerated input type of Mutations__Consumer__AccountManagement__CancelPayment
 */
export interface IMutationsConsumerAccountManagementCancelPaymentInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  payment_uuid: string;
}

/**
 * Autogenerated return type of Mutations__Consumer__AccountManagement__CancelPayment
 */
export interface IMutationsConsumerAccountManagementCancelPaymentPayload {
  __typename: 'Mutations__Consumer__AccountManagement__CancelPaymentPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IAccountManagementError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__AccountManagement__CancelPayoffQuote
 */
export interface IMutationsAccountManagementCancelPayoffQuoteInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  payoff_quote_uuid: string;
}

/**
 * Autogenerated return type of Mutations__AccountManagement__CancelPayoffQuote
 */
export interface IMutationsAccountManagementCancelPayoffQuotePayload {
  __typename: 'Mutations__AccountManagement__CancelPayoffQuotePayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IAccountManagementError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__Consumer__AccountManagement__CreatePayment
 */
export interface IMutationsConsumerAccountManagementCreatePaymentInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  amount_cents: number;
  date: string;
  payment_method_uuid: string;
  payment_allocation_type: PaymentAllocationType;
}

/**
 * Autogenerated return type of Mutations__Consumer__AccountManagement__CreatePayment
 */
export interface IMutationsConsumerAccountManagementCreatePaymentPayload {
  __typename: 'Mutations__Consumer__AccountManagement__CreatePaymentPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IAccountManagementError>;
  is_success: boolean;
  payment_uuid: string | null;
}

/**
 * Autogenerated input type of Mutations__Consumer__AccountManagement__CreatePaymentAuthScript
 */
export interface IMutationsConsumerAccountManagementCreatePaymentAuthScriptInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  authorization_type: PaymentAuthorizationType;
  data?: string | null;
}

/**
 * Valid payment authorization types
 */
export const enum PaymentAuthorizationType {
  one_time = 'one_time',
  autopay = 'autopay',
}

/**
 * Autogenerated return type of Mutations__Consumer__AccountManagement__CreatePaymentAuthScript
 */
export interface IMutationsConsumerAccountManagementCreatePaymentAuthScriptPayload {
  __typename: 'Mutations__Consumer__AccountManagement__CreatePaymentAuthScriptPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IAccountManagementError>;
  is_success: boolean;
  payment_auth_script: string | null;
  payment_auth_script_uuid: string | null;
}

/**
 * Autogenerated input type of Mutations__AccountManagement__CreatePayoffQuote
 */
export interface IMutationsAccountManagementCreatePayoffQuoteInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  payoff_reason: string;
}

/**
 * Autogenerated return type of Mutations__AccountManagement__CreatePayoffQuote
 */
export interface IMutationsAccountManagementCreatePayoffQuotePayload {
  __typename: 'Mutations__AccountManagement__CreatePayoffQuotePayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IAccountManagementError>;
  is_success: boolean;
  payoff_quote_uuid: string | null;
}

/**
 * Autogenerated input type of Mutations__Consumer__AccountManagement__EditPayment
 */
export interface IMutationsConsumerAccountManagementEditPaymentInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  payment_uuid: string;
  payment_method_uuid?: string | null;
  amount_cents?: number | null;
  date?: string | null;
  payment_auth_script_uuid?: string | null;
}

/**
 * Autogenerated return type of Mutations__Consumer__AccountManagement__EditPayment
 */
export interface IMutationsConsumerAccountManagementEditPaymentPayload {
  __typename: 'Mutations__Consumer__AccountManagement__EditPaymentPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IAccountManagementError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__Consumer__AccountManagement__OptedInOutToggle
 */
export interface IMutationsConsumerAccountManagementOptedInOutToggleInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  payment_method_uuid: string;
  opt_in: boolean;
  payment_uuid?: string | null;
  payment_auth_script_uuid?: string | null;
}

/**
 * Autogenerated return type of Mutations__Consumer__AccountManagement__OptedInOutToggle
 */
export interface IMutationsConsumerAccountManagementOptedInOutTogglePayload {
  __typename: 'Mutations__Consumer__AccountManagement__OptedInOutTogglePayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IAccountManagementError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__AccountManagement__SchedulePayoffQuote
 */
export interface IMutationsAccountManagementSchedulePayoffQuoteInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  payment_date: string;
  payment_method_uuid: string;
  payoff_quote_uuid: string;
}

/**
 * Autogenerated return type of Mutations__AccountManagement__SchedulePayoffQuote
 */
export interface IMutationsAccountManagementSchedulePayoffQuotePayload {
  __typename: 'Mutations__AccountManagement__SchedulePayoffQuotePayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IAccountManagementError>;
  is_success: boolean;
}

/**
 * Customer Management Mutations which are accessible via customer facing clients
 */
export interface IMutationCustomer {
  __typename: 'Mutation__Customer';
  activate_bank_account: IMutationsCustomerManagementConsumerActivateBankAccountPayload | null;
  add_bank_account: IMutationsCustomerManagementConsumerAddBankAccountPayload | null;
  add_debit_card: IMutationsCustomerManagementConsumerAddDebitCardPayload | null;
  add_new_address: IMutationsCustomerManagementConsumerAddNewAddressPayload | null;
  add_phone_number: IMutationsCustomerManagementConsumerAddPhoneNumberPayload | null;
  add_unconfirmed_email_address: IMutationsCustomerManagementConsumerAddUnconfirmedEmailAddressPayload | null;
  create_complaint: IMutationsCustomerManagementConsumerCreateComplaintPayload | null;
  create_workflow_note: IMutationsConsumerCustomerManagementCreateWorkflowNotePayload | null;
  deactivate_bank_account: IMutationsCustomerManagementConsumerDeactivateBankAccountPayload | null;
  deactivate_debit_card: IMutationsCustomerManagementConsumerDeactivateDebitCardPayload | null;
  deactivate_phone_number: IMutationsCustomerManagementConsumerDeactivatePhoneNumberPayload | null;
  remove_address: IMutationsCustomerManagementConsumerRemoveAddressPayload | null;
  update_address: IMutationsCustomerManagementConsumerUpdateAddressPayload | null;
  update_consents: IMutationsConsumerCustomerManagementUpdateConsentsPayload | null;
  update_phone_number: IMutationsCustomerManagementConsumerUpdatePhoneNumberPayload | null;
  workflow_log: IMutationsConsumerCustomerManagementWorkflowLogPayload | null;
}

export interface IActivateBankAccountOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerActivateBankAccountInput;
}

export interface IAddBankAccountOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerAddBankAccountInput;
}

export interface IAddDebitCardOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerAddDebitCardInput;
}

export interface IAddNewAddressOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerAddNewAddressInput;
}

export interface IAddPhoneNumberOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerAddPhoneNumberInput;
}

export interface IAddUnconfirmedEmailAddressOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerAddUnconfirmedEmailAddressInput;
}

export interface ICreateComplaintOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerCreateComplaintInput;
}

export interface ICreateWorkflowNoteOnMutationCustomerArguments {
  input: IMutationsConsumerCustomerManagementCreateWorkflowNoteInput;
}

export interface IDeactivateBankAccountOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerDeactivateBankAccountInput;
}

export interface IDeactivateDebitCardOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerDeactivateDebitCardInput;
}

export interface IDeactivatePhoneNumberOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerDeactivatePhoneNumberInput;
}

export interface IRemoveAddressOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerRemoveAddressInput;
}

export interface IUpdateAddressOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerUpdateAddressInput;
}

export interface IUpdateConsentsOnMutationCustomerArguments {
  input: IMutationsConsumerCustomerManagementUpdateConsentsInput;
}

export interface IUpdatePhoneNumberOnMutationCustomerArguments {
  input: IMutationsCustomerManagementConsumerUpdatePhoneNumberInput;
}

export interface IWorkflowLogOnMutationCustomerArguments {
  input: IMutationsConsumerCustomerManagementWorkflowLogInput;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__ActivateBankAccount
 */
export interface IMutationsCustomerManagementConsumerActivateBankAccountInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  bank_account_uuid: string;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__ActivateBankAccount
 */
export interface IMutationsCustomerManagementConsumerActivateBankAccountPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__ActivateBankAccountPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__AddBankAccount
 */
export interface IMutationsCustomerManagementConsumerAddBankAccountInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  account_type?: string | null;
  routing_number: string;
  account_number: string;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__AddBankAccount
 */
export interface IMutationsCustomerManagementConsumerAddBankAccountPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__AddBankAccountPayload';
  bank_account_uuid: string | null;

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__AddDebitCard
 */
export interface IMutationsCustomerManagementConsumerAddDebitCardInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  last_4: string;
  card_brand: string;
  card_type: string;
  expiration_month: string;
  expiration_year: string;
  token: string;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__AddDebitCard
 */
export interface IMutationsCustomerManagementConsumerAddDebitCardPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__AddDebitCardPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  debit_card_uuid: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__AddNewAddress
 */
export interface IMutationsCustomerManagementConsumerAddNewAddressInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  address_type?: string | null;
  address_1: string;
  address_2?: string | null;
  address_3?: string | null;
  city: string;
  state: string;
  zip: string;
  zip4?: string | null;
  time_at_address?: string | null;
  rent_or_own?: string | null;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__AddNewAddress
 */
export interface IMutationsCustomerManagementConsumerAddNewAddressPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__AddNewAddressPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__AddPhoneNumber
 */
export interface IMutationsCustomerManagementConsumerAddPhoneNumberInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  number: string;
  phone_type: string;
  ext?: string | null;
  do_not_call?: boolean | null;
  do_not_auto_dial?: boolean | null;
  do_not_sms?: boolean | null;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__AddPhoneNumber
 */
export interface IMutationsCustomerManagementConsumerAddPhoneNumberPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__AddPhoneNumberPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__AddUnconfirmedEmailAddress
 */
export interface IMutationsCustomerManagementConsumerAddUnconfirmedEmailAddressInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  email_address: string;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__AddUnconfirmedEmailAddress
 */
export interface IMutationsCustomerManagementConsumerAddUnconfirmedEmailAddressPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__AddUnconfirmedEmailAddressPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__CreateComplaint
 */
export interface IMutationsCustomerManagementConsumerCreateComplaintInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  date_received: string;
  description: string;
  communication_channel: string;
  admin_user_name: string;
  admin_user_email: string;
  primary_issue: string;
  secondary_issue?: string | null;
  alleged_violation: boolean;
  regulatory_body_mentioned: boolean;
  targeted_executive_officer?: boolean | null;
  data_breach_alleged?: boolean | null;
  lawsuit_alleged?: boolean | null;
  allegation_reason?: string | null;
  helpscout_id?: number | null;
  third_party_complaint_id?: number | null;
  media_complaint?: boolean | null;
  date_resolved?: string | null;
  resolution_description?: string | null;
  root_cause?: string | null;
  root_cause_detail?: string | null;
  resolution_compensation?: boolean | null;
  resolution_time_reason?: string | null;
  resolution_time_notice?: string | null;
  communication_target?: string | null;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__CreateComplaint
 */
export interface IMutationsCustomerManagementConsumerCreateComplaintPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__CreateComplaintPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__Consumer__CustomerManagement__CreateWorkflowNote
 */
export interface IMutationsConsumerCustomerManagementCreateWorkflowNoteInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  note_type: string;
  text?: string | null;
  note_action: string;
  originator_type?: string | null;
  originator_uuid?: string | null;
}

/**
 * Autogenerated return type of Mutations__Consumer__CustomerManagement__CreateWorkflowNote
 */
export interface IMutationsConsumerCustomerManagementCreateWorkflowNotePayload {
  __typename: 'Mutations__Consumer__CustomerManagement__CreateWorkflowNotePayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__DeactivateBankAccount
 */
export interface IMutationsCustomerManagementConsumerDeactivateBankAccountInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  bank_account_uuid: string;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__DeactivateBankAccount
 */
export interface IMutationsCustomerManagementConsumerDeactivateBankAccountPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__DeactivateBankAccountPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__DeactivateDebitCard
 */
export interface IMutationsCustomerManagementConsumerDeactivateDebitCardInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  debit_card_uuid: string;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__DeactivateDebitCard
 */
export interface IMutationsCustomerManagementConsumerDeactivateDebitCardPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__DeactivateDebitCardPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__DeactivatePhoneNumber
 */
export interface IMutationsCustomerManagementConsumerDeactivatePhoneNumberInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  phone_number_uuid: string;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__DeactivatePhoneNumber
 */
export interface IMutationsCustomerManagementConsumerDeactivatePhoneNumberPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__DeactivatePhoneNumberPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__RemoveAddress
 */
export interface IMutationsCustomerManagementConsumerRemoveAddressInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  address_uuid: string;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__RemoveAddress
 */
export interface IMutationsCustomerManagementConsumerRemoveAddressPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__RemoveAddressPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__UpdateAddress
 */
export interface IMutationsCustomerManagementConsumerUpdateAddressInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  address_uuid: string;
  address_type?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  address_3?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  zip4?: string | null;
  time_at_address?: string | null;
  rent_or_own?: string | null;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__UpdateAddress
 */
export interface IMutationsCustomerManagementConsumerUpdateAddressPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__UpdateAddressPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__Consumer__CustomerManagement__UpdateConsents
 */
export interface IMutationsConsumerCustomerManagementUpdateConsentsInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  do_not_call?: boolean | null;
  do_not_email?: boolean | null;
  do_not_robocall?: boolean | null;
  do_not_mail?: boolean | null;
  do_not_sms?: boolean | null;
  do_not_marketing_robocall?: boolean | null;
  do_not_share_information?: boolean | null;
  electronic_communications_consent?: boolean | null;
  selected_payment_method?: boolean | null;
  needs_to_create_own_password?: boolean | null;
  email_marketing_consent?: boolean | null;
  terms_of_use?: boolean | null;
  receive_calls_about_account?: boolean | null;
}

/**
 * Autogenerated return type of Mutations__Consumer__CustomerManagement__UpdateConsents
 */
export interface IMutationsConsumerCustomerManagementUpdateConsentsPayload {
  __typename: 'Mutations__Consumer__CustomerManagement__UpdateConsentsPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__CustomerManagement__Consumer__UpdatePhoneNumber
 */
export interface IMutationsCustomerManagementConsumerUpdatePhoneNumberInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  phone_number_uuid: string;
  number?: string | null;
  ext?: string | null;
  do_not_call?: boolean | null;
  do_not_auto_dial?: boolean | null;
  do_not_sms?: boolean | null;
}

/**
 * Autogenerated return type of Mutations__CustomerManagement__Consumer__UpdatePhoneNumber
 */
export interface IMutationsCustomerManagementConsumerUpdatePhoneNumberPayload {
  __typename: 'Mutations__CustomerManagement__Consumer__UpdatePhoneNumberPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Autogenerated input type of Mutations__Consumer__CustomerManagement__WorkflowLog
 */
export interface IMutationsConsumerCustomerManagementWorkflowLogInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  session_uuid: string;
  workflow_name: string;
  current_action: string;
  session_type_actions: Array<string>;
  step_enter: string;
  step_exit: string;
  session_type_steps: Array<string>;
  client: string;
  product_type?: string | null;
  product_id?: string | null;
  product_uuid?: string | null;
  session_type_rank: number;
  metadata?: any | null;
  session_type_metadata?: any | null;
}

/**
 * Autogenerated return type of Mutations__Consumer__CustomerManagement__WorkflowLog
 */
export interface IMutationsConsumerCustomerManagementWorkflowLogPayload {
  __typename: 'Mutations__Consumer__CustomerManagement__WorkflowLogPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  errors: Array<IError>;
  is_success: boolean;
}

/**
 * Servicing Mutations
 */
export interface IMutationsServicing {
  __typename: 'Mutations__Servicing';
  completeOnboarding: IMutationsCompleteOnboardingPayload | null;
  fraudAlertEnroll: IMutationsFraudAlertEnrollPayload | null;
  fraudAlertUnenroll: IMutationsFraudAlertUnenrollPayload | null;
  updatePaperless: IMutationsUpdatePaperlessPayload | null;
}

export interface ICompleteOnboardingOnMutationsServicingArguments {
  input: IMutationsCompleteOnboardingInput;
}

export interface IFraudAlertEnrollOnMutationsServicingArguments {
  input: IMutationsFraudAlertEnrollInput;
}

export interface IFraudAlertUnenrollOnMutationsServicingArguments {
  input: IMutationsFraudAlertUnenrollInput;
}

export interface IUpdatePaperlessOnMutationsServicingArguments {
  input: IMutationsUpdatePaperlessInput;
}

/**
 * Autogenerated input type of Mutations__CompleteOnboarding
 */
export interface IMutationsCompleteOnboardingInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  creditCardAccountUuid: string;
}

/**
 * Autogenerated return type of Mutations__CompleteOnboarding
 */
export interface IMutationsCompleteOnboardingPayload {
  __typename: 'Mutations__CompleteOnboardingPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  creditCardAccount: ICreditCardAccount | null;
  error: string | null;
  success: boolean;
}

export interface ICreditCardAccount {
  __typename: 'CreditCardAccount';
  adjustedMinimumPaymentDue: IMoney | null;
  annualMembershipFee: IMoney | null;
  appliedOrApproved: boolean;
  autopayActive: boolean;
  autopayPlan: IAutopayPlan | null;
  availableCashAmount: IMoney | null;
  availableTotalCredit: IMoney | null;
  brand: string | null;
  canUseTravelNotification: boolean;
  cardImageName: string | null;
  cardImageUrl: string | null;
  cashAdvanceApr: number | null;
  cashAdvanceBalance: IMoney | null;
  cashAdvanceLimit: IMoney | null;
  createdAt: string;
  creditCard: ICreditCard | null;
  creditCards: Array<ICreditCard>;
  creditLimit: IMoney | null;
  currentBalance: IMoney | null;
  currentMinimumPaymentDue: IMoney | null;
  customerApplication: ICustomerApplication;
  declinedTransactions: Array<ICreditCardDeclinedTransaction>;

  /**
   * Card is eligible for financial situation review workflow
   */
  financialSituationReviewEligible: boolean;
  fiservUpc15: string | null;
  fraudAlerts: boolean;
  id: string;

  /**
   * Credit Card is !cancelled? and !rejected? and isIssued or isClosed.
   */
  isActive: boolean;

  /**
   * Autopay activate is still pending for customer
   */
  isAutopayActivationPending: boolean;
  isCurrent: boolean;
  isDelinquent: boolean;
  isIssued: boolean;
  isLate: boolean;
  isSoldOff: boolean | null;
  lastPaymentAmount: IMoney | null;
  lastPaymentDate: string | null;
  lastStatementBalance: IMoney | null;
  lastStatementEndDate: string | null;
  lastStatementStartDate: string | null;

  /**
   * This is different from lockedOut! This is if a user's account is locked. There
   * is some overlap between the two, but they are distinct.
   */
  locked: boolean | null;

  /**
   * If a user is locked out of their account. This could be because of bankruptcy,
   * the account being frozen, or a card being Stolen
   */
  lockedOut: boolean | null;
  minimumPaymentDue: IMoney | null;
  minimumPaymentDueDate: string | null;
  monthOnBooks: number | null;
  onboardComplete: boolean;
  openDate: string | null;
  overlimit: boolean;
  paperless: boolean;
  pastDue: boolean;
  pastDueAmount: IMoney | null;
  paymentAccount: IBankAccount | null;
  payments: Array<ICreditCardPayment>;
  pendingTransactions: Array<ICreditCardPendingTransaction>;
  postedTransactions: Array<ICreditCardPostedTransaction>;
  productName: string | null;
  purchaseAPR: number | null;
  rewards: ICreditCardAccountRewards;
  simpleID: string;
  statementDates: Array<string>;
  statements: Array<ICreditCardStatement>;
  status: string;
  transactions: Array<CreditCardTransactionInterface>;
  updatedAt: string;
  uuid: string;

  /**
   * @deprecated "legacy code -- use verificationTasksV2 on CustomerApplication instead"
   */
  verificationTasks: IVerification;
  wasCanceled: boolean;
  wasClosed: boolean;
  wasRejected: boolean;
}

export interface IDeclinedTransactionsOnCreditCardAccountArguments {
  statementDate?: string | null;
}

export interface IPendingTransactionsOnCreditCardAccountArguments {
  statementDate?: string | null;
}

export interface IPostedTransactionsOnCreditCardAccountArguments {
  statementDate?: string | null;
}

export interface ITransactionsOnCreditCardAccountArguments {
  statementDate?: string | null;
}

export interface IAutopayPlan {
  __typename: 'AutopayPlan';
  createdAt: string;
  id: string;
  isActive: boolean;
  paymentAccount: IBankAccount | null;
  paymentDayOfMonth: number;
  status: string;
  strategy: CreditCardAutopayPlanStategy;
  strategyReadable: string | null;
  updatedAt: string;
}

/**
 * Types of Credit Card Autopay plan strategies
 */
export const enum CreditCardAutopayPlanStategy {
  min = 'min',
  statement = 'statement',
}

export interface ICreditCard {
  __typename: 'CreditCard';
  createdAt: string;
  id: string;
  isActivatable: boolean;
  isLockable: boolean;
  isUnlockable: boolean;
  lastFour: string;
  simpleId: string;
  updatedAt: string;
}

/**
 * A declined credit card transaction
 */
export interface ICreditCardDeclinedTransaction {
  __typename: 'CreditCardDeclinedTransaction';
  amount: IMoney;
  authTime: string;
  category: string | null;
  categoryDisplay: string | null;
  description: string;
  id: string;
  merchantCity: string | null;
  merchantName: string;
  merchantState: string;
  merchantZip: string;
  status: string;
  transactionDate: string;
}

/**
 * A credit card transaction
 */
export type CreditCardTransactionInterface =
  | ICreditCardDeclinedTransaction
  | ICreditCardPendingTransaction
  | ICreditCardPostedTransaction;

/**
 * A credit card transaction
 */
export interface ICreditCardTransactionInterface {
  __typename: 'CreditCardTransactionInterface';
  amount: IMoney;
  description: string;
  id: string;
  merchantCity: string | null;
  merchantState: string | null;
  transactionDate: string | null;
}

export interface ICreditCardPayment {
  __typename: 'CreditCardPayment';
  activePaymentAccount: PaymentAccount | null;
  amount: IMoney;
  confirmationID: string;
  createdAt: string;
  date: string;
  debit_card_id: string | null;
  id: string;
  origin: string;
  originReadable: string;
  paymentAccount: IBankAccount | null;
  payment_account_id: string | null;
  payment_method: string | null;
  postDate: string | null;
  returnCode: string | null;
  returnedAt: string | null;
  simpleID: string;
  status: string;
  strategy: CreditCardPaymentStategy;
  updatedAt: string;
}

/**
 * Types of Credit Card Payment strategies
 */
export const enum CreditCardPaymentStategy {
  min = 'min',
  statement = 'statement',
  current = 'current',
  other = 'other',
}

/**
 * A pending credit card transaction
 */
export interface ICreditCardPendingTransaction {
  __typename: 'CreditCardPendingTransaction';
  amount: IMoney;
  authTime: string;
  category: string | null;
  categoryDisplay: string | null;
  description: string;
  id: string;
  merchantCity: string | null;
  merchantName: string;
  merchantState: string;
  merchantZip: string;
  status: string;
  transactionDate: string;
}

/**
 * A credit card posted transaction
 */
export interface ICreditCardPostedTransaction {
  __typename: 'CreditCardPostedTransaction';
  amount: IMoney;
  category: string | null;
  description: string;
  fullDescription: string;
  id: string;
  isPayment: boolean;
  isReturn: boolean;
  merchantCity: string | null;
  merchantState: string | null;
  merchantTranCode: string;
  postDate: string | null;
  transactionCode: string;
  transactionDate: string | null;
  typeDisplay: string;
}

export interface ICreditCardAccountRewards {
  __typename: 'CreditCardAccountRewards';
  balance: number | null;
  enabled: boolean;
  isDark: boolean;
  rewardPercentOfPurchase: number;
  rewardsByTimeframe: ICreditCardRewardsByTimeframe;
  rewards_type_is_dollars: boolean | null;
}

export interface ICreditCardRewardsByTimeframe {
  __typename: 'CreditCardRewardsByTimeframe';
  currentCycle: ICreditCardRewardCurrentCycleProps;
  lastCycle: ICreditCardRewardLastCycleProps;
  lifetime: ICreditCardRewardTimeframeProps;
  thisYear: ICreditCardRewardTimeframeProps;
}

export interface ICreditCardRewardCurrentCycleProps {
  __typename: 'CreditCardRewardCurrentCycleProps';
  summary: ICreditCardRewardAmountProps;
}

export interface ICreditCardRewardAmountProps {
  __typename: 'CreditCardRewardAmountProps';
  rewardValue: number;
  transactionCount: number;
}

export interface ICreditCardRewardLastCycleProps {
  __typename: 'CreditCardRewardLastCycleProps';
  summary: ICreditCardRewardAmountProps;
}

export interface ICreditCardRewardTimeframeProps {
  __typename: 'CreditCardRewardTimeframeProps';
  rewards: Array<ICreditCardRewardTimeframeIntervalProps>;
  summary: ICreditCardRewardAmountProps;
}

export interface ICreditCardRewardTimeframeIntervalProps {
  __typename: 'CreditCardRewardTimeframeIntervalProps';
  endDate: string;
  rewardValue: number;
  startDate: string;
  transactionCount: number;
}

/**
 * A credit card statement
 */
export interface ICreditCardStatement {
  __typename: 'CreditCardStatement';
  id: string;
  minimumPaymentDue: IMoney;
  pdfUrl: string | null;
  statementBalance: IMoney;
  statementEndDate: string;
}

/**
 * Autogenerated input type of Mutations__FraudAlertEnroll
 */
export interface IMutationsFraudAlertEnrollInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  creditCardAccountId: string;
  phoneNumber: string;
}

/**
 * Autogenerated return type of Mutations__FraudAlertEnroll
 */
export interface IMutationsFraudAlertEnrollPayload {
  __typename: 'Mutations__FraudAlertEnrollPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__FraudAlertUnenroll
 */
export interface IMutationsFraudAlertUnenrollInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  creditCardAccountId: string;
}

/**
 * Autogenerated return type of Mutations__FraudAlertUnenroll
 */
export interface IMutationsFraudAlertUnenrollPayload {
  __typename: 'Mutations__FraudAlertUnenrollPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__UpdatePaperless
 */
export interface IMutationsUpdatePaperlessInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  creditCardAccountId: string;
  paperless: boolean;
}

/**
 * Autogenerated return type of Mutations__UpdatePaperless
 */
export interface IMutationsUpdatePaperlessPayload {
  __typename: 'Mutations__UpdatePaperlessPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__UpdateClientInstall
 */
export interface IMutationsUpdateClientInstallInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  parseInstallationId?: string | null;
  deviceToken?: string | null;
  allowPaymentReminder7DaysNotifications?: boolean | null;
  allowPaymentSuccessNotifications?: boolean | null;
  allowPaymentReminderNotifications?: boolean | null;
  allowPaymentStatusNotifications?: boolean | null;
  allowAccountStatusNotifications?: boolean | null;
}

/**
 * Autogenerated return type of Mutations__UpdateClientInstall
 */
export interface IMutationsUpdateClientInstallPayload {
  __typename: 'Mutations__UpdateClientInstallPayload';
  clientInstall: IClientInstall | null;

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
}

/**
 * Autogenerated input type of Mutations__UpdateEmail
 */
export interface IMutationsUpdateEmailInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  email: string;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__UpdateEmail
 */
export interface IMutationsUpdateEmailPayload {
  __typename: 'Mutations__UpdateEmailPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  customer: ICustomer;
  errors: Array<string | null> | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__UpdatePhoneNumber
 */
export interface IMutationsUpdatePhoneNumberInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  phoneNumber: string;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__UpdatePhoneNumber
 */
export interface IMutationsUpdatePhoneNumberPayload {
  __typename: 'Mutations__UpdatePhoneNumberPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  customer: ICustomer;
  errors: Array<string | null> | null;
  success: boolean;
}

/**
 * Verification Mutations
 */
export interface IMutationsVerification {
  __typename: 'Mutations__Verification';
  acceptCounterOffer: IMutationsAcceptCounterOfferPayload | null;
  acceptNewLoanTerms: IMutationsAcceptNewLoanTermsPayload | null;
  addAdditionalIncome: IMutationsAddAdditionalIncomePayload | null;
  answerKBA: IMutationsAnswerKbaPayload | null;
  confirmBankAccount: IMutationsConfirmBankAccountPayload | null;
  confirmDOB: IMutationsConfirmDOBPayload | null;
  confirmSSN: IMutationsConfirmSSNPayload | null;
  consentToCreditHardPull: IMutationsConsentToCreditHardPullPayload | null;
  consentToCreditScoreDisclosure: IMutationsConsentToCreditScoreDisclosurePayload | null;
  consentToYodleeEula: IMutationsConsentToYodleeEulaPayload | null;
  enterBankAccount: IMutationsEnterBankAccountPayload | null;
  mfa: IMutationsMFA | null;
  mitek: IMutationsMitek | null;
  plaid: IMutationsPlaid | null;
  refuseOvtConnection: IMutationsRefuseOvtConnectionPayload | null;
  rejectCounterOffer: IMutationsRejectCounterOfferPayload | null;
  sendEmailConfirmation: IMutationsSendEmailConfirmationPayload | null;
  startFastlink2Session: IMutationsStartFastlink2SessionPayload | null;
  startJumioSession: IMutationsStartJumioSessionPayload | null;
  startKBASession: IMutationsStartKbaSessionPayload | null;

  /**
   * Verification Self Service Unlock Credit Report
   */
  unlockCreditReport: IMutationsUnlockCreditReportPayload | null;
  updateAddress: IMutationsUpdateAddressPayload | null;
  updateIncomeType: IMutationsUpdateIncomeTypePayload | null;
  validateAddress: IMutationsValidateAddressPayload | null;
  verificationsAnalyticsEvent: IMutationsVerificationsAnalyticsEventPayload | null;
  yodlee: IMutationsYodlee | null;
}

export interface IAcceptCounterOfferOnMutationsVerificationArguments {
  input: IMutationsAcceptCounterOfferInput;
}

export interface IAcceptNewLoanTermsOnMutationsVerificationArguments {
  input: IMutationsAcceptNewLoanTermsInput;
}

export interface IAddAdditionalIncomeOnMutationsVerificationArguments {
  input: IMutationsAddAdditionalIncomeInput;
}

export interface IAnswerKBAOnMutationsVerificationArguments {
  input: IMutationsAnswerKbaInput;
}

export interface IConfirmBankAccountOnMutationsVerificationArguments {
  input: IMutationsConfirmBankAccountInput;
}

export interface IConfirmDOBOnMutationsVerificationArguments {
  input: IMutationsConfirmDOBInput;
}

export interface IConfirmSSNOnMutationsVerificationArguments {
  input: IMutationsConfirmSSNInput;
}

export interface IConsentToCreditHardPullOnMutationsVerificationArguments {
  input: IMutationsConsentToCreditHardPullInput;
}

export interface IConsentToCreditScoreDisclosureOnMutationsVerificationArguments {
  input: IMutationsConsentToCreditScoreDisclosureInput;
}

export interface IConsentToYodleeEulaOnMutationsVerificationArguments {
  input: IMutationsConsentToYodleeEulaInput;
}

export interface IEnterBankAccountOnMutationsVerificationArguments {
  input: IMutationsEnterBankAccountInput;
}

export interface IRefuseOvtConnectionOnMutationsVerificationArguments {
  input: IMutationsRefuseOvtConnectionInput;
}

export interface IRejectCounterOfferOnMutationsVerificationArguments {
  input: IMutationsRejectCounterOfferInput;
}

export interface ISendEmailConfirmationOnMutationsVerificationArguments {
  input: IMutationsSendEmailConfirmationInput;
}

export interface IStartFastlink2SessionOnMutationsVerificationArguments {
  input: IMutationsStartFastlink2SessionInput;
}

export interface IStartJumioSessionOnMutationsVerificationArguments {
  input: IMutationsStartJumioSessionInput;
}

export interface IStartKBASessionOnMutationsVerificationArguments {
  input: IMutationsStartKbaSessionInput;
}

export interface IUnlockCreditReportOnMutationsVerificationArguments {
  input: IMutationsUnlockCreditReportInput;
}

export interface IUpdateAddressOnMutationsVerificationArguments {
  input: IMutationsUpdateAddressInput;
}

export interface IUpdateIncomeTypeOnMutationsVerificationArguments {
  input: IMutationsUpdateIncomeTypeInput;
}

export interface IValidateAddressOnMutationsVerificationArguments {
  input: IMutationsValidateAddressInput;
}

export interface IVerificationsAnalyticsEventOnMutationsVerificationArguments {
  input: IMutationsVerificationsAnalyticsEventInput;
}

/**
 * Autogenerated input type of Mutations__AcceptCounterOffer
 */
export interface IMutationsAcceptCounterOfferInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID: string;
}

/**
 * Autogenerated return type of Mutations__AcceptCounterOffer
 */
export interface IMutationsAcceptCounterOfferPayload {
  __typename: 'Mutations__AcceptCounterOfferPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__AcceptNewLoanTerms
 */
export interface IMutationsAcceptNewLoanTermsInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID: string;
}

/**
 * Autogenerated return type of Mutations__AcceptNewLoanTerms
 */
export interface IMutationsAcceptNewLoanTermsPayload {
  __typename: 'Mutations__AcceptNewLoanTermsPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  customerApplication: ICustomerApplication | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__AddAdditionalIncome
 */
export interface IMutationsAddAdditionalIncomeInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID: string;
  willAddAdditionalIncome: boolean;
}

/**
 * Autogenerated return type of Mutations__AddAdditionalIncome
 */
export interface IMutationsAddAdditionalIncomePayload {
  __typename: 'Mutations__AddAdditionalIncomePayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__AnswerKba
 */
export interface IMutationsAnswerKbaInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  answers: Array<IMutationsAnswerKbaInputObject>;
  customerApplicationUUID?: string | null;
}

export interface IMutationsAnswerKbaInputObject {
  id: string;
  choice?: string | null;
  choices?: any | null;
}

/**
 * Autogenerated return type of Mutations__AnswerKba
 */
export interface IMutationsAnswerKbaPayload {
  __typename: 'Mutations__AnswerKbaPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  session: IKBASession | null;
  success: boolean;

  /**
   * @deprecated "use success or error fields instead."
   */
  task: IVerificationsTask | null;
}

export interface IKBASession {
  __typename: 'KBASession';
  createdAt: string | null;
  id: string;
  questions: Array<IKBAQuestion>;
  shown: string | null;
  updatedAt: string | null;
}

export interface IKBAQuestion {
  __typename: 'KBAQuestion';
  answerSeconds: number | null;
  choices: Array<IKBAQuestionChoice>;
  createdAt: string | null;
  help: string | null;
  id: string;
  questionID: string;
  single: boolean;
  text: string;
  updatedAt: string | null;
}

export interface IKBAQuestionChoice {
  __typename: 'KBAQuestionChoice';
  choiceID: string | null;
  chosen: boolean;
  createdAt: string | null;
  id: string;
  kbaQuestionID: string | null;
  text: string;
  updatedAt: string | null;
}

/**
 * Autogenerated input type of Mutations__ConfirmBankAccount
 */
export interface IMutationsConfirmBankAccountInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
  routingNumber: string;
  accountNumber?: string | null;
  accountType?: string | null;
}

/**
 * Autogenerated return type of Mutations__ConfirmBankAccount
 */
export interface IMutationsConfirmBankAccountPayload {
  __typename: 'Mutations__ConfirmBankAccountPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;

  /**
   * @deprecated "use success or error fields instead."
   */
  task: IVerificationsTask | null;
}

/**
 * Autogenerated input type of Mutations__ConfirmDOB
 */
export interface IMutationsConfirmDOBInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
  month: string;
  day: string;
  year: string;
}

/**
 * Autogenerated return type of Mutations__ConfirmDOB
 */
export interface IMutationsConfirmDOBPayload {
  __typename: 'Mutations__ConfirmDOBPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;

  /**
   * @deprecated "use success or error fields instead."
   */
  task: IVerificationsTask | null;
}

/**
 * Autogenerated input type of Mutations__ConfirmSSN
 */
export interface IMutationsConfirmSSNInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
  ssn: string;
}

/**
 * Autogenerated return type of Mutations__ConfirmSSN
 */
export interface IMutationsConfirmSSNPayload {
  __typename: 'Mutations__ConfirmSSNPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;

  /**
   * @deprecated "use success or error fields instead."
   */
  task: IVerificationsTask | null;
}

/**
 * Autogenerated input type of Mutations__ConsentToCreditHardPull
 */
export interface IMutationsConsentToCreditHardPullInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID: string;
}

/**
 * Autogenerated return type of Mutations__ConsentToCreditHardPull
 */
export interface IMutationsConsentToCreditHardPullPayload {
  __typename: 'Mutations__ConsentToCreditHardPullPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  customerApplication: ICustomerApplication | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__ConsentToCreditScoreDisclosure
 */
export interface IMutationsConsentToCreditScoreDisclosureInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
  consent: boolean;
}

/**
 * Autogenerated return type of Mutations__ConsentToCreditScoreDisclosure
 */
export interface IMutationsConsentToCreditScoreDisclosurePayload {
  __typename: 'Mutations__ConsentToCreditScoreDisclosurePayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__ConsentToYodleeEula
 */
export interface IMutationsConsentToYodleeEulaInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  agree: boolean;
}

/**
 * Autogenerated return type of Mutations__ConsentToYodleeEula
 */
export interface IMutationsConsentToYodleeEulaPayload {
  __typename: 'Mutations__ConsentToYodleeEulaPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  consents: ICustomerConsent | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__EnterBankAccount
 */
export interface IMutationsEnterBankAccountInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
  routingNumber: string;
  accountNumber?: string | null;
  accountType?: string | null;
}

/**
 * Autogenerated return type of Mutations__EnterBankAccount
 */
export interface IMutationsEnterBankAccountPayload {
  __typename: 'Mutations__EnterBankAccountPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Verification MFA
 */
export interface IMutationsMFA {
  __typename: 'Mutations__MFA';
  sendMFACode: IMutationsSendMFACodePayload | null;
  verifyMFACode: IMutationsVerifyMFAPayload | null;
}

export interface ISendMFACodeOnMutationsMFAArguments {
  input: IMutationsSendMFACodeInput;
}

export interface IVerifyMFACodeOnMutationsMFAArguments {
  input: IMutationsVerifyMFAInput;
}

/**
 * Autogenerated input type of Mutations__SendMFACode
 */
export interface IMutationsSendMFACodeInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
  method: MFAMethod;
  phoneNumber: string;
}

/**
 * Types of multi-factor authentication
 */
export const enum MFAMethod {
  SMS = 'SMS',
  CALL = 'CALL',
}

/**
 * Autogenerated return type of Mutations__SendMFACode
 */
export interface IMutationsSendMFACodePayload {
  __typename: 'Mutations__SendMFACodePayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  phoneVerificationUUID: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__VerifyMFA
 */
export interface IMutationsVerifyMFAInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  code: string;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__VerifyMFA
 */
export interface IMutationsVerifyMFAPayload {
  __typename: 'Mutations__VerifyMFAPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;

  /**
   * @deprecated "use success or error fields instead."
   */
  task: IVerificationsTask | null;
}

/**
 * Verification Mitek
 */
export interface IMutationsMitek {
  __typename: 'Mutations__Mitek';

  /**
   * Sends a Mitek document upload email
   */
  mitekDocumentUploadEmail: IMutationsMitekDocumentUploadEmailPayload | null;
  mitekUploadBackID: IMutationsMitekUploadBackIDPayload | null;
  mitekUploadFrontID: IMutationsMitekUploadFrontIDPayload | null;
  mitekUploadPassport: IMutationsMitekUploadPassportPayload | null;
  mitekUploadSelfie: IMutationsMitekUploadSelfiePayload | null;
}

export interface IMitekDocumentUploadEmailOnMutationsMitekArguments {
  input: IMutationsMitekDocumentUploadEmailInput;
}

export interface IMitekUploadBackIDOnMutationsMitekArguments {
  input: IMutationsMitekUploadBackIDInput;
}

export interface IMitekUploadFrontIDOnMutationsMitekArguments {
  input: IMutationsMitekUploadFrontIDInput;
}

export interface IMitekUploadPassportOnMutationsMitekArguments {
  input: IMutationsMitekUploadPassportInput;
}

export interface IMitekUploadSelfieOnMutationsMitekArguments {
  input: IMutationsMitekUploadSelfieInput;
}

/**
 * Autogenerated input type of Mutations__MitekDocumentUploadEmail
 */
export interface IMutationsMitekDocumentUploadEmailInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__MitekDocumentUploadEmail
 */
export interface IMutationsMitekDocumentUploadEmailPayload {
  __typename: 'Mutations__MitekDocumentUploadEmailPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__MitekUploadBackID
 */
export interface IMutationsMitekUploadBackIDInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  image?: IMutationsImageInputObject | null;

  /**
   * (Portable Data File) Decoded bar code information
   */
  pdf417?: string | null;
  customerApplicationUUID?: string | null;
}

/**
 * Represents a Image type.
 */
export interface IMutationsImageInputObject {
  base64ImageData: string;
}

/**
 * Autogenerated return type of Mutations__MitekUploadBackID
 */
export interface IMutationsMitekUploadBackIDPayload {
  __typename: 'Mutations__MitekUploadBackIDPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__MitekUploadFrontID
 */
export interface IMutationsMitekUploadFrontIDInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  image: IMutationsImageInputObject;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__MitekUploadFrontID
 */
export interface IMutationsMitekUploadFrontIDPayload {
  __typename: 'Mutations__MitekUploadFrontIDPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__MitekUploadPassport
 */
export interface IMutationsMitekUploadPassportInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  image: IMutationsImageInputObject;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__MitekUploadPassport
 */
export interface IMutationsMitekUploadPassportPayload {
  __typename: 'Mutations__MitekUploadPassportPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__MitekUploadSelfie
 */
export interface IMutationsMitekUploadSelfieInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  image: IMutationsImageInputObject;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__MitekUploadSelfie
 */
export interface IMutationsMitekUploadSelfiePayload {
  __typename: 'Mutations__MitekUploadSelfiePayload';
  blockers: Array<string> | null;

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  mitek_processing_success: boolean;
  out_of_attempts: boolean;
  success: boolean;
}

/**
 * Verification Plaid
 */
export interface IMutationsPlaid {
  __typename: 'Mutations__Plaid';
  capturePlaidEvent: IMutationsCapturePlaidEventPayload | null;
  exitPlaidConnection: IMutationsExitPlaidConnectionPayload | null;
  initializePlaidSession: IMutationsInitializePlaidSessionPayload | null;
  startPlaidSession: IMutationsStartPlaidSessionPayload | null;
}

export interface ICapturePlaidEventOnMutationsPlaidArguments {
  input: IMutationsCapturePlaidEventInput;
}

export interface IExitPlaidConnectionOnMutationsPlaidArguments {
  input: IMutationsExitPlaidConnectionInput;
}

export interface IInitializePlaidSessionOnMutationsPlaidArguments {
  input: IMutationsInitializePlaidSessionInput;
}

export interface IStartPlaidSessionOnMutationsPlaidArguments {
  input: IMutationsStartPlaidSessionInput;
}

/**
 * Autogenerated input type of Mutations__CapturePlaidEvent
 */
export interface IMutationsCapturePlaidEventInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  eventName?: string | null;
  metadata?: any | null;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__CapturePlaidEvent
 */
export interface IMutationsCapturePlaidEventPayload {
  __typename: 'Mutations__CapturePlaidEventPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__ExitPlaidConnection
 */
export interface IMutationsExitPlaidConnectionInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID: string;
}

/**
 * Autogenerated return type of Mutations__ExitPlaidConnection
 */
export interface IMutationsExitPlaidConnectionPayload {
  __typename: 'Mutations__ExitPlaidConnectionPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__InitializePlaidSession
 */
export interface IMutationsInitializePlaidSessionInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  metadata?: any | null;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__InitializePlaidSession
 */
export interface IMutationsInitializePlaidSessionPayload {
  __typename: 'Mutations__InitializePlaidSessionPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  link_token: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__StartPlaidSession
 */
export interface IMutationsStartPlaidSessionInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  publicToken?: string | null;
  metadata?: any | null;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__StartPlaidSession
 */
export interface IMutationsStartPlaidSessionPayload {
  __typename: 'Mutations__StartPlaidSessionPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__RefuseOvtConnection
 */
export interface IMutationsRefuseOvtConnectionInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID: string;
}

/**
 * Autogenerated return type of Mutations__RefuseOvtConnection
 */
export interface IMutationsRefuseOvtConnectionPayload {
  __typename: 'Mutations__RefuseOvtConnectionPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__RejectCounterOffer
 */
export interface IMutationsRejectCounterOfferInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID: string;
}

/**
 * Autogenerated return type of Mutations__RejectCounterOffer
 */
export interface IMutationsRejectCounterOfferPayload {
  __typename: 'Mutations__RejectCounterOfferPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__SendEmailConfirmation
 */
export interface IMutationsSendEmailConfirmationInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__SendEmailConfirmation
 */
export interface IMutationsSendEmailConfirmationPayload {
  __typename: 'Mutations__SendEmailConfirmationPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__StartFastlink2Session
 */
export interface IMutationsStartFastlink2SessionInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__StartFastlink2Session
 */
export interface IMutationsStartFastlink2SessionPayload {
  __typename: 'Mutations__StartFastlink2SessionPayload';
  app: string | null;

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  redirectReq: boolean | null;
  rsession: string | null;
  success: boolean;
  token: string | null;
  url: string | null;
}

/**
 * Autogenerated input type of Mutations__StartJumioSession
 */
export interface IMutationsStartJumioSessionInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__StartJumioSession
 */
export interface IMutationsStartJumioSessionPayload {
  __typename: 'Mutations__StartJumioSessionPayload';
  authToken: string | null;

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  redirectUrl: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__StartKbaSession
 */
export interface IMutationsStartKbaSessionInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__StartKbaSession
 */
export interface IMutationsStartKbaSessionPayload {
  __typename: 'Mutations__StartKbaSessionPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  session: IKBASession | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__UnlockCreditReport
 */
export interface IMutationsUnlockCreditReportInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__UnlockCreditReport
 */
export interface IMutationsUnlockCreditReportPayload {
  __typename: 'Mutations__UnlockCreditReportPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  stillLocked: boolean;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__UpdateAddress
 */
export interface IMutationsUpdateAddressInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipCode: string;
  entryType: VerificationsAddressEntryType;
  customerApplicationUUID?: string | null;
}

export const enum VerificationsAddressEntryType {
  NewAddress = 'NewAddress',
  ListSelection = 'ListSelection',
  ExistingAddress = 'ExistingAddress',
}

/**
 * Autogenerated return type of Mutations__UpdateAddress
 */
export interface IMutationsUpdateAddressPayload {
  __typename: 'Mutations__UpdateAddressPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  originalAddress: IVerificationsAddress;
  success: boolean;
  validAddresses: Array<IVerificationsAddress>;
}

/**
 * Autogenerated input type of Mutations__UpdateIncomeType
 */
export interface IMutationsUpdateIncomeTypeInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID: string;
  incomeType: string;
}

/**
 * Autogenerated return type of Mutations__UpdateIncomeType
 */
export interface IMutationsUpdateIncomeTypePayload {
  __typename: 'Mutations__UpdateIncomeTypePayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Autogenerated input type of Mutations__ValidateAddress
 */
export interface IMutationsValidateAddressInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipCode: string;
  entryType: VerificationsAddressEntryType;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__ValidateAddress
 */
export interface IMutationsValidateAddressPayload {
  __typename: 'Mutations__ValidateAddressPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  originalAddress: IVerificationsAddress;
  success: boolean;
  validAddresses: Array<IVerificationsAddress>;
}

/**
 * Autogenerated input type of Mutations__VerificationsAnalyticsEvent
 */
export interface IMutationsVerificationsAnalyticsEventInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID: string;
  verificationsPageName: string;
}

/**
 * Autogenerated return type of Mutations__VerificationsAnalyticsEvent
 */
export interface IMutationsVerificationsAnalyticsEventPayload {
  __typename: 'Mutations__VerificationsAnalyticsEventPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean;
}

/**
 * Verification Yodlee
 */
export interface IMutationsYodlee {
  __typename: 'Mutations__Yodlee';
  startFastlink2Session: IMutationsStartFastlink2SessionPayload | null;
  startRisSession: IMutationsYodleeStartRisSessionPayload | null;
  updateRisSession: IMutationsYodleeUpdateRisSessionPayload | null;
}

export interface IStartFastlink2SessionOnMutationsYodleeArguments {
  input: IMutationsStartFastlink2SessionInput;
}

export interface IStartRisSessionOnMutationsYodleeArguments {
  input: IMutationsYodleeStartRisSessionInput;
}

export interface IUpdateRisSessionOnMutationsYodleeArguments {
  input: IMutationsYodleeUpdateRisSessionInput;
}

/**
 * Autogenerated input type of Mutations__Yodlee__StartRisSession
 */
export interface IMutationsYodleeStartRisSessionInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
}

/**
 * Autogenerated return type of Mutations__Yodlee__StartRisSession
 */
export interface IMutationsYodleeStartRisSessionPayload {
  __typename: 'Mutations__Yodlee__StartRisSessionPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  productCode: string | null;
  success: boolean;
  token: string | null;
  username: string | null;
  webhook: string | null;
}

/**
 * Autogenerated input type of Mutations__Yodlee__UpdateRisSession
 */
export interface IMutationsYodleeUpdateRisSessionInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  customerApplicationUUID?: string | null;
  orderId: string;
}

/**
 * Autogenerated return type of Mutations__Yodlee__UpdateRisSession
 */
export interface IMutationsYodleeUpdateRisSessionPayload {
  __typename: 'Mutations__Yodlee__UpdateRisSessionPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  error: string | null;
  success: boolean | null;

  /**
   * @deprecated "use success or error fields instead."
   */
  task: IVerificationsTask | null;
}

/**
 * Workflow Related Mutations
 */
export interface IMutationsWorkflow {
  __typename: 'Mutations__Workflow';
  runWorkflow: IMutationsRunWorkflowPayload | null;
}

export interface IRunWorkflowOnMutationsWorkflowArguments {
  input: IMutationsRunWorkflowInput;
}

/**
 * Autogenerated input type of Mutations__RunWorkflow
 */
export interface IMutationsRunWorkflowInput {
  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId?: string | null;
  previousState: string;
  inputs: Array<IMutationsRunWorkflowInputObject>;
  action: string;
}

export interface IMutationsRunWorkflowInputObject {
  name: string;
  value?: string | null;
}

/**
 * Autogenerated return type of Mutations__RunWorkflow
 */
export interface IMutationsRunWorkflowPayload {
  __typename: 'Mutations__RunWorkflowPayload';

  /**
   * A unique identifier for the client performing the mutation.
   */
  clientMutationId: string | null;
  workflow: IWorkflow;
}

/**
 * An Application Contract
 */
export interface IApplicationContract {
  __typename: 'ApplicationContract';

  /**
   * @deprecated "use pdf endoint to download contract"
   */
  contract: any;
  createdAt: string;
  id: string;
  identifier: string;
  signed: boolean;
  signedAt: string | null;
  updatedAt: string;
  uuid: string;
}

export type Contract = IApplicationContract | ILoanContract;

export interface IContract {
  __typename: 'Contract';
  contract: any;
  createdAt: string;
  id: string;
  identifier: string | null;
  signed: boolean;
  signedAt: string | null;
  updatedAt: string;
  uuid: string;
}

export interface IChargeOffInformation {
  __typename: 'ChargeOffInformation';
  disableMakePayment: boolean | null;
  operationallyChargedOff: boolean;
  operationsChargeOffAmount: IMoney | null;
  operationsChargeOffBalances: IOperationsChargeOffBalances | null;
  operationsChargeOffEffectiveDate: string | null;
  operationsChargeOffReason: string | null;
  operationsChargeOffTransactionDate: string | null;
  operationsRecoveredAmount: IMoney | null;
}

export interface IOperationsChargeOffBalances {
  __typename: 'OperationsChargeOffBalances';
  activeChargedOffFees: IMoney | null;
  activeChargedOffInterest: IMoney | null;
  activeChargedOffPrincipal: IMoney | null;
  recoveredFees: IMoney | null;
  recoveredInterest: IMoney | null;
  recoveredPrincipal: IMoney | null;
}

/**
 * The connection type for Contract.
 */
export interface IContractConnection {
  __typename: 'ContractConnection';
  edges: Array<IContractEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IContractEdge {
  __typename: 'ContractEdge';

  /**
   * A cursor for use in pagination.
   */
  cursor: string;
  node: Contract;
}

export interface IDebtSale {
  __typename: 'DebtSale';
  createdAt: string;
  debtBuyer: string | null;
  debtBuyerPhone: string | null;
  id: string;
  uuid: string;
}

export interface ILoan {
  __typename: 'Loan';
  amount: IMoney | null;
  amountWithOtherOriginationFees: IMoney | null;
  appliedOrApproved: boolean;
  aprPercentage: number | null;

  /**
   * @deprecated "use 'calculatedApr' instead. this was a typo"
   */
  calcuatedApr: number | null;
  calculatedApr: number | null;
  chargeOffInformation: IChargeOffInformation | null;
  contract_url: string | null;
  contracts: IContractConnection;
  createdAt: string;
  customerApplication: ICustomerApplication;
  debtSale: IDebtSale | null;
  expectedChargeOffDate: string | null;
  financialSituationReviewEligible: boolean;

  /**
   * Loan is eligible for financial situation review v2 workflow
   */
  financialSituationReviewV2Eligible: boolean;
  fundingDate: string | null;
  futureAchOptedInInstallmentPayments: IPaymentConnection;
  futureAllPayments: IPaymentConnection;
  futureInstallmentPayments: IPaymentConnection;
  futureNonAchOptedInInstallmentPayments: IPaymentConnection;
  futureOneTimePayments: IPaymentConnection;
  gracePeriodEndDate: string | null;
  hasOutstandingBalance: boolean;
  id: string;
  interestRate: number | null;

  /**
   * Loan isTransferred or !cancelled?, !rejected? and isIssued or isClosed or authorized?
   */
  isActive: boolean;
  isAutopay: boolean;
  isBankruptcyDischarged: boolean;
  isChargedOff: boolean;
  isFunded: boolean;
  isIssued: boolean;
  isLate: boolean;
  isOptedIntoAch: boolean;
  isOptedIntoCpa: boolean;
  isOptedIntoRcc: boolean;
  isPaidOff: boolean;
  isRefinance: boolean;
  isRegions: boolean;
  isSoldOff: boolean | null;
  isTransferred: boolean;
  lateDate: string | null;

  /**
   * date when loss mitigation offer expires
   */
  lossMitigationOfferExpirationDate: string | null;

  /**
   * Loan is eligible for loss mitigation workflow
   */
  lossMitigationPlanAcceptanceEligible: boolean;
  missedFees: IMoney | null;
  missedInstallmentAmount: IMoney | null;
  nextInstallmentPayment: IPayment | null;
  nextPaymentDueAmount: IMoney | null;
  nextPaymentDueDate: string | null;
  onActiveOrLockedInSettlement: boolean;

  /**
   * @deprecated "Do not use this value! it will always return $0!"
   */
  outstandingBalance: IMoney | null;
  owner: string | null;
  paidOffDate: string | null;
  paidPrincipal: IMoney | null;
  pastDueAmount: IMoney | null;
  paymentMethod: string | null;
  paymentPlan: IPaymentPlan | null;
  payments: IPaymentConnection;
  payoffInformation: IPayoffInformation | null;
  pointOfSaleInformation: IPointOfSaleInformation | null;
  principalAmount: IMoney | null;
  principalProgress: number | null;
  principalRemaining: IMoney | null;
  servicingAccountUuid: string | null;

  /**
   * Loan is eligible for settlement acceptance workflow
   */
  settlementAcceptanceEligible: boolean;
  settlementInformation: ISettlementInformation | null;
  settlementOfferExpirationDate: string | null;
  shouldHaveBankAccount: boolean;
  status: string;
  term: number | null;
  typicalPayment: IMoney | null;
  uuid: string;

  /**
   * @deprecated "legacy code -- use verificationTasksV2 on CustomerApplication instead"
   */
  verificationTasks: IVerification;
  wasCanceled: boolean;
  wasRefinanced: boolean;
  wasRejected: boolean;
}

export interface IContractsOnLoanArguments {
  /**
   * Returns the first _n_ elements from the list.
   */
  first?: number | null;

  /**
   * Returns the elements in the list that come after the specified global ID.
   */
  after?: string | null;

  /**
   * Returns the last _n_ elements from the list.
   */
  last?: number | null;

  /**
   * Returns the elements in the list that come before the specified global ID.
   */
  before?: string | null;
}

export interface IFutureAchOptedInInstallmentPaymentsOnLoanArguments {
  /**
   * Returns the first _n_ elements from the list.
   */
  first?: number | null;

  /**
   * Returns the elements in the list that come after the specified global ID.
   */
  after?: string | null;

  /**
   * Returns the last _n_ elements from the list.
   */
  last?: number | null;

  /**
   * Returns the elements in the list that come before the specified global ID.
   */
  before?: string | null;
}

export interface IFutureAllPaymentsOnLoanArguments {
  /**
   * Returns the first _n_ elements from the list.
   */
  first?: number | null;

  /**
   * Returns the elements in the list that come after the specified global ID.
   */
  after?: string | null;

  /**
   * Returns the last _n_ elements from the list.
   */
  last?: number | null;

  /**
   * Returns the elements in the list that come before the specified global ID.
   */
  before?: string | null;
}

export interface IFutureInstallmentPaymentsOnLoanArguments {
  /**
   * Returns the first _n_ elements from the list.
   */
  first?: number | null;

  /**
   * Returns the elements in the list that come after the specified global ID.
   */
  after?: string | null;

  /**
   * Returns the last _n_ elements from the list.
   */
  last?: number | null;

  /**
   * Returns the elements in the list that come before the specified global ID.
   */
  before?: string | null;
}

export interface IFutureNonAchOptedInInstallmentPaymentsOnLoanArguments {
  /**
   * Returns the first _n_ elements from the list.
   */
  first?: number | null;

  /**
   * Returns the elements in the list that come after the specified global ID.
   */
  after?: string | null;

  /**
   * Returns the last _n_ elements from the list.
   */
  last?: number | null;

  /**
   * Returns the elements in the list that come before the specified global ID.
   */
  before?: string | null;
}

export interface IFutureOneTimePaymentsOnLoanArguments {
  /**
   * Returns the first _n_ elements from the list.
   */
  first?: number | null;

  /**
   * Returns the elements in the list that come after the specified global ID.
   */
  after?: string | null;

  /**
   * Returns the last _n_ elements from the list.
   */
  last?: number | null;

  /**
   * Returns the elements in the list that come before the specified global ID.
   */
  before?: string | null;
}

export interface IPaymentsOnLoanArguments {
  /**
   * Returns the first _n_ elements from the list.
   */
  first?: number | null;

  /**
   * Returns the elements in the list that come after the specified global ID.
   */
  after?: string | null;

  /**
   * Returns the last _n_ elements from the list.
   */
  last?: number | null;

  /**
   * Returns the elements in the list that come before the specified global ID.
   */
  before?: string | null;
  active?: boolean | null;
  includeZeroPayments?: boolean | null;
  includePromises?: boolean | null;
  sort?: Sort | null;
}

/**
 * The connection type for Payment.
 */
export interface IPaymentConnection {
  __typename: 'PaymentConnection';
  edges: Array<IPaymentEdge>;

  /**
   * Information to aid in pagination.
   */
  pageInfo: IPageInfo;
}

/**
 * An edge in a connection.
 */
export interface IPaymentEdge {
  __typename: 'PaymentEdge';

  /**
   * A cursor for use in pagination.
   */
  cursor: string;
  node: IPayment;
}

/**
 * A Loan or Credit Line Payment
 */
export interface IPayment {
  __typename: 'Payment';
  activePaymentAccount: PaymentAccount | null;
  amount: IMoney | null;
  canCancel: boolean;
  canUpdate: boolean;
  createdAt: string;
  effDate: string | null;
  feesAmount: IMoney | null;
  id: string;
  inactive: boolean;
  interestAmount: IMoney | null;
  isBlind: boolean;
  isComplete: boolean | null;
  isFuture: boolean;
  isInstallment: boolean;
  isLate: boolean | null;
  isNSF: boolean;
  isOneTime: boolean;
  isPaymentPlan: boolean;
  isScheduled: boolean | null;
  method: string | null;
  origin: string | null;
  originalAmount: IMoney | null;
  paymentDeferment: IPaymentDeferment | null;
  principalAmount: IMoney | null;
  status: string;
  uuid: string;
  wasCanceled: boolean | null;
  wasReturned: boolean | null;
}

/**
 * A Payment Deferment
 */
export interface IPaymentDeferment {
  __typename: 'PaymentDeferment';
  maxDate: string;
  minDate: string;
  paymentDefermentType: string;
}

export interface IPaymentPlan {
  __typename: 'PaymentPlan';
  createdAt: string;
  endDate: string | null;
  firstPaymentDate: string | null;
  frequency: string | null;
  id: string;
  isSCRA: boolean;
  paymentAmount: IMoney | null;
  paymentMethod: string | null;
  startDate: string | null;
  status: string | null;
  structure: string | null;
  uuid: string;
}

/**
 * Sort type
 */
export const enum Sort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IPayoffInformation {
  __typename: 'PayoffInformation';
  newWorkflowEnabled: boolean;
  payoffQuoteDisqualified: boolean;
  scheduledQuote: IPayoffQuote | null;
}

export interface IPayoffQuote {
  __typename: 'PayoffQuote';
  amount: IMoney;
  id: string;
  paymentId: string;
  paymentMethod: string;
  paymentScheduledDate: string;
}

export interface IPointOfSaleInformation {
  __typename: 'PointOfSaleInformation';
  adjustedLoanAmount: IMoney;
  dispute: IPointOfSaleDispute | null;

  /**
   * @deprecated "use dispute.refund.isFullRefund object instead"
   */
  isFullyRefunded: boolean;

  /**
   * @deprecated "use dispute.paymentProtected object instead"
   */
  isOnNonPaymentProtectedDispute: boolean;

  /**
   * @deprecated "use dispute.paymentProtected object instead"
   */
  isOnPaymentProtectedDispute: boolean;

  /**
   * @deprecated "use dispute.refund.isFullRefund object instead"
   */
  isPartiallyRefunded: boolean;
  lastDateLoanAmountChanged: string | null;
  merchantImageUrl: string | null;
  merchantName: string;
  refund: IPointOfSaleRefund | null;
}

export interface IPointOfSaleDispute {
  __typename: 'PointOfSaleDispute';
  paymentProtected: boolean;
  resolution: IPointOfSaleDisputeResolution | null;
}

export interface IPointOfSaleDisputeResolution {
  __typename: 'PointOfSaleDisputeResolution';
  refund: IPointOfSaleRefund | null;
}

export interface IPointOfSaleRefund {
  __typename: 'PointOfSaleRefund';
  amount: IMoney;
  isFullRefund: boolean;
}

export interface ISettlementInformation {
  __typename: 'SettlementInformation';
  isSettled: boolean;
}

/**
 * A LoanContract
 */
export interface ILoanContract {
  __typename: 'LoanContract';

  /**
   * @deprecated "use pdf endoint to download contract"
   */
  contract: any;
  createdAt: string;
  id: string;
  identifier: string | null;
  signed: boolean;
  signedAt: string | null;
  updatedAt: string;
  uuid: string;
}

export interface IVerificationsAcceptCounterOfferTaskV2 {
  __typename: 'Verifications__AcceptCounterOfferTask__V2';
  id: string;
  initialTerms: IVerificationsTermsV2;
  name: string;
  newTerms: IVerificationsTermsV2;
  sortIndex: number | null;
  status: VerificationsStatus;
}

/**
 * Fields used to encapsulate terms for ver tasks
 */
export interface IVerificationsTermsV2 {
  __typename: 'Verifications__Terms__V2';
  amount: IMoney;
  apr: number;
  interestRate: number;
  monthlyPayment: IMoney;
  term: number;
}

/**
 * Fields used to display additional document upload instructions sidebar
 */
export interface IVerificationsAdditionalInstructions {
  __typename: 'Verifications__AdditionalInstructions';
  header: string;
  instructions: Array<string>;
}

/**
 * Fields used to display singular or pluralized title
 */
export interface IVerificationsDisplayItem {
  __typename: 'Verifications__DisplayItem';
  plural: string;
  singular: string;
}

export type Verifications__DocumentUpload__Task__Interface__V2 =
  | IVerificationsDocumentUploadTaskV2
  | IVerificationsDocumentUploadTaskOneRequiredV2;

export interface IVerificationsDocumentUploadTaskInterfaceV2 {
  __typename: 'Verifications__DocumentUpload__Task__Interface__V2';
  additionalInstructions: IVerificationsAdditionalInstructions;
  blockers: Array<string>;
  categoryIdentifier: string;
  categoryInstructions: Array<string>;
  displayCategory: string;
  displayItem: IVerificationsDisplayItem;
  displayTask: string;
  documentUuid: string | null;
  headerInstructions: Array<string>;
  id: string;
  modalLinkText: string;
  name: string;
  sortIndex: number | null;
  status: VerificationsStatus;
  subTaskIndex: number | null;
  taskIdentifier: string;
  uploadedFiles: Array<IVerificationsUploadedFile>;
}

/**
 * Fields used to display uploaded files for a customer
 */
export interface IVerificationsUploadedFile {
  __typename: 'Verifications__UploadedFile';
  filename: string;
  secureUrl: string;
}

export interface IVerificationsDocumentUploadPartnerTaskV2 {
  __typename: 'Verifications__DocumentUploadPartnerTask__V2';
  documentsRequested: Array<string>;
  id: string;
  name: string;
  sortIndex: number | null;
  status: VerificationsStatus;
}

export interface IVerificationsDocumentUploadTaskV2 {
  __typename: 'Verifications__DocumentUploadTask__V2';
  additionalInstructions: IVerificationsAdditionalInstructions;
  blockers: Array<string>;
  categoryIdentifier: string;
  categoryInstructions: Array<string>;
  displayCategory: string;
  displayItem: IVerificationsDisplayItem;
  displayTask: string;
  documentUuid: string | null;
  headerInstructions: Array<string>;
  id: string;
  modalLinkText: string;
  name: string;
  sortIndex: number | null;
  status: VerificationsStatus;
  subTaskIndex: number | null;
  taskIdentifier: string;
  uploadedFiles: Array<IVerificationsUploadedFile>;
}

export interface IVerificationsDocumentUploadTaskOneRequiredV2 {
  __typename: 'Verifications__DocumentUploadTaskOneRequired__V2';
  additionalInstructions: IVerificationsAdditionalInstructions;
  blockers: Array<string>;
  categoryIdentifier: string;
  categoryInstructions: Array<string>;
  displayCategory: string;
  displayItem: IVerificationsDisplayItem;
  displayTask: string;
  documentUuid: string | null;
  headerInstructions: Array<string>;
  id: string;
  modalLinkText: string;
  name: string;
  sortIndex: number | null;
  status: VerificationsStatus;
  subTaskIndex: number | null;
  taskIdentifier: string;
  uploadedFiles: Array<IVerificationsUploadedFile>;
}

export interface IVerificationsTaskV2 {
  __typename: 'Verifications__Task__V2';
  id: string;
  name: string;
  sortIndex: number | null;
  status: VerificationsStatus;
}

export interface IVerificationsUploadTaskV2 {
  __typename: 'Verifications__UploadTask__V2';
  id: string;
  instructions: IVerificationsTaskInstruction;
  name: string;
  sortIndex: number | null;
  status: VerificationsStatus;
}

export interface IVerificationsVendorTaskV2 {
  __typename: 'Verifications__VendorTask__V2';
  id: string;
  name: string;
  sortIndex: number | null;
  status: VerificationsStatus;
  vendor: VendorType | null;
}

export interface IWorkflowCheckboxInput {
  __typename: 'WorkflowCheckboxInput';
  name: string;
  placeholder: string | null;
  required: boolean | null;
  type: WorkflowInputType;
  value: string | null;
}

export interface IWorkflowDateInput {
  __typename: 'WorkflowDateInput';
  blacklisted: Array<string> | null;
  dueDate: string | null;
  max: string | null;
  min: string | null;
  name: string;
  placeholder: string | null;
  readonly: boolean | null;
  required: boolean | null;
  type: WorkflowInputType;
  value: string | null;
}

export interface IWorkflowEmailInput {
  __typename: 'WorkflowEmailInput';
  name: string;
  placeholder: string | null;
  required: boolean | null;
  type: WorkflowInputType;
  value: string | null;
}

/**
 * Valid Input Formats for a Workflow
 */
export const enum WorkflowInputFormat {
  currency = 'currency',
  cardNumber = 'cardNumber',
  cardExpirationDate = 'cardExpirationDate',
}

/**
 * Valid Input keyboard type for a Workflow
 */
export const enum WorkflowInputKeyboard {
  numeric = 'numeric',
}

/**
 * A input value for a workflow
 */
export interface IWorkflowInputValue {
  __typename: 'WorkflowInputValue';
  displayValue: string;
  value: string;
}

/**
 * An input value for a workflow
 */
export type WorkflowInputValueInterface =
  | IWorkflowInputValue
  | IWorkflowInputValueRadio;

/**
 * An input value for a workflow
 */
export interface IWorkflowInputValueInterface {
  __typename: 'WorkflowInputValueInterface';
  displayValue: string;
  value: string;
}

/**
 * An input value details for a workflow input
 */
export type WorkflowInputValueDetailsInterface =
  | IWorkflowInputValueRadioCard
  | IWorkflowInputValueRadioCardWithInput;

/**
 * An input value details for a workflow input
 */
export interface IWorkflowInputValueDetailsInterface {
  __typename: 'WorkflowInputValueDetailsInterface';
  dataPoints: Array<IWorkflowInputValueRadioCardDataPoint> | null;
  footer: string | null;
  header: string | null;
  subheader: string | null;
}

/**
 * Radio input value details datapoint
 */
export interface IWorkflowInputValueRadioCardDataPoint {
  __typename: 'WorkflowInputValueRadioCardDataPoint';
  content: string | null;
  footer: string | null;
  header: string | null;
}

/**
 * A radio input value for a workflow
 */
export interface IWorkflowInputValueRadio {
  __typename: 'WorkflowInputValueRadio';
  details: WorkflowInputValueDetailsInterface | null;
  displayValue: string;
  value: string;
}

/**
 * A radio input value details for a workflow
 */
export interface IWorkflowInputValueRadioCard {
  __typename: 'WorkflowInputValueRadioCard';
  dataPoints: Array<IWorkflowInputValueRadioCardDataPoint> | null;
  footer: string | null;
  header: string | null;
  headerPill: string | null;
  subheader: string | null;
}

/**
 * A radio input value details for a radio with an input for a workflow
 */
export interface IWorkflowInputValueRadioCardWithInput {
  __typename: 'WorkflowInputValueRadioCardWithInput';
  dataPoints: Array<IWorkflowInputValueRadioCardDataPoint> | null;
  footer: string | null;
  header: string | null;
  input: WorkflowInput;
  subheader: string | null;
}

export interface IWorkflowNumberInput {
  __typename: 'WorkflowNumberInput';
  description: string | null;
  format: WorkflowInputFormat | null;
  max: string | null;
  min: string | null;
  name: string;
  placeholder: string | null;
  readonly: boolean | null;
  required: boolean | null;
  step: number | null;
  type: WorkflowInputType;
  value: string | null;
}

export interface IWorkflowRadioInput {
  __typename: 'WorkflowRadioInput';
  name: string;
  placeholder: string | null;
  required: boolean | null;
  type: WorkflowInputType;
  value: string | null;

  /**
   * @deprecated "use 'valuesAndDetails' instead. This does not include 'details' object. Could not update this type because it needed to be a passive change."
   */
  values: Array<IWorkflowInputValue>;
  valuesAndDetails: Array<IWorkflowInputValueRadio>;
}

export interface IWorkflowSelectInput {
  __typename: 'WorkflowSelectInput';
  name: string;
  placeholder: string | null;
  required: boolean | null;
  type: WorkflowInputType;
  value: string | null;
  values: Array<IWorkflowInputValue>;
}

export interface IWorkflowTextAreaInput {
  __typename: 'WorkflowTextAreaInput';
  extraInfo: string | null;
  maxLength: number | null;
  minLength: number | null;
  name: string;
  placeholder: string | null;
  required: boolean | null;
  title: string | null;
  type: WorkflowInputType;
  value: string | null;
}

export interface IWorkflowTextInput {
  __typename: 'WorkflowTextInput';
  disabled: boolean | null;
  format: WorkflowInputFormat | null;
  keyboard: WorkflowInputKeyboard | null;
  name: string;
  pattern: string | null;
  placeholder: string | null;
  required: boolean | null;
  title: string | null;
  type: WorkflowInputType;
  value: string | null;
}

export const enum FakeLocale {
  az = 'az',
  cz = 'cz',
  de = 'de',
  de_AT = 'de_AT',
  de_CH = 'de_CH',
  en = 'en',
  en_AU = 'en_AU',
  en_BORK = 'en_BORK',
  en_CA = 'en_CA',
  en_GB = 'en_GB',
  en_IE = 'en_IE',
  en_IND = 'en_IND',
  en_US = 'en_US',
  en_au_ocker = 'en_au_ocker',
  es = 'es',
  es_MX = 'es_MX',
  fa = 'fa',
  fr = 'fr',
  fr_CA = 'fr_CA',
  ge = 'ge',
  id_ID = 'id_ID',
  it = 'it',
  ja = 'ja',
  ko = 'ko',
  nb_NO = 'nb_NO',
  nep = 'nep',
  nl = 'nl',
  pl = 'pl',
  pt_BR = 'pt_BR',
  ru = 'ru',
  sk = 'sk',
  sv = 'sv',
  tr = 'tr',
  uk = 'uk',
  vi = 'vi',
  zh_CN = 'zh_CN',
  zh_TW = 'zh_TW',
}

export const enum FakeTypes {
  zipCode = 'zipCode',
  city = 'city',
  streetName = 'streetName',

  /**
   * Configure address with option `useFullAddress`
   */
  streetAddress = 'streetAddress',
  secondaryAddress = 'secondaryAddress',
  county = 'county',
  country = 'country',
  countryCode = 'countryCode',
  state = 'state',
  stateAbbr = 'stateAbbr',
  latitude = 'latitude',
  longitude = 'longitude',
  colorName = 'colorName',
  productCategory = 'productCategory',
  productName = 'productName',

  /**
   * Sum of money. Configure with options `minMoney`/`maxMoney` and 'decimalPlaces'.
   */
  money = 'money',
  productMaterial = 'productMaterial',
  product = 'product',
  companyName = 'companyName',
  companyCatchPhrase = 'companyCatchPhrase',
  companyBS = 'companyBS',
  dbColumn = 'dbColumn',
  dbType = 'dbType',
  dbCollation = 'dbCollation',
  dbEngine = 'dbEngine',

  /**
   * By default returns dates beetween 2000-01-01 and 2030-01-01.
   * Configure date format with options `dateFormat` `dateFrom` `dateTo`.
   */
  date = 'date',

  /**
   * Configure date format with option `dateFormat`
   */
  pastDate = 'pastDate',

  /**
   * Configure date format with option `dateFormat`
   */
  futureDate = 'futureDate',

  /**
   * Configure date format with option `dateFormat`
   */
  recentDate = 'recentDate',
  financeAccountName = 'financeAccountName',
  financeTransactionType = 'financeTransactionType',
  currencyCode = 'currencyCode',
  currencyName = 'currencyName',
  currencySymbol = 'currencySymbol',
  bitcoinAddress = 'bitcoinAddress',
  internationalBankAccountNumber = 'internationalBankAccountNumber',
  bankIdentifierCode = 'bankIdentifierCode',
  hackerAbbreviation = 'hackerAbbreviation',
  hackerPhrase = 'hackerPhrase',

  /**
   * An image url. Configure image with options: `imageCategory`, `imageWidth`, `imageHeight` and `randomizeImageUrl`
   */
  imageUrl = 'imageUrl',

  /**
   * An URL for an avatar
   */
  avatarUrl = 'avatarUrl',

  /**
   * Configure email provider with option: `emailProvider`
   */
  email = 'email',
  url = 'url',
  domainName = 'domainName',
  ipv4Address = 'ipv4Address',
  ipv6Address = 'ipv6Address',
  userAgent = 'userAgent',

  /**
   * Configure color with option: `baseColor`
   */
  colorHex = 'colorHex',
  macAddress = 'macAddress',

  /**
   * Configure password with option `passwordLength`
   */
  password = 'password',

  /**
   * Lorem Ipsum text. Configure size with option `loremSize`
   */
  lorem = 'lorem',
  firstName = 'firstName',
  lastName = 'lastName',
  fullName = 'fullName',
  jobTitle = 'jobTitle',
  phoneNumber = 'phoneNumber',
  number = 'number',
  uuid = 'uuid',
  word = 'word',
  words = 'words',
  locale = 'locale',
  filename = 'filename',
  mimeType = 'mimeType',
  fileExtension = 'fileExtension',
  semver = 'semver',
}

export interface IFakeImageSize {
  width: number;
  height: number;
}

export const enum FakeLoremSize {
  word = 'word',
  words = 'words',
  sentence = 'sentence',
  sentences = 'sentences',
  paragraph = 'paragraph',
  paragraphs = 'paragraphs',
}

export interface IFakeColor {
  /**
   * @default 0
   */
  red255?: number | null;

  /**
   * @default 0
   */
  green255?: number | null;

  /**
   * @default 0
   */
  blue255?: number | null;
}

export interface IFakeOptions {
  /**
   * Only for type `streetAddress`
   */
  useFullAddress?: boolean | null;

  /**
   * Only for type `money`
   */
  minMoney?: number | null;

  /**
   * Only for type `money`
   */
  maxMoney?: number | null;

  /**
   * Only for type `money`
   */
  decimalPlaces?: number | null;

  /**
   * Only for type `imageUrl`
   */
  imageSize?: IFakeImageSize | null;

  /**
   * Only for type `imageUrl`. Example value: `["nature", "water"]`.
   */
  imageKeywords?: Array<string> | null;

  /**
   * Only for type `imageUrl`
   */
  randomizeImageUrl?: boolean | null;

  /**
   * Only for type `email`
   */
  emailProvider?: string | null;

  /**
   * Only for type `password`
   */
  passwordLength?: number | null;

  /**
   * Only for type `lorem`
   */
  loremSize?: FakeLoremSize | null;

  /**
   * Only for types `*Date`. Example value: `YYYY MM DD`. [Full Specification](http://momentjs.com/docs/#/displaying/format/)
   * @default "YYYY-MM-DDTHH:mm:ss[Z]"
   */
  dateFormat?: string | null;

  /**
   * Only for types `betweenDate`. Example value: `1986-11-02`.
   * @default "2010-01-01"
   */
  dateFrom?: string | null;

  /**
   * Only for types `betweenDate`. Example value: `2038-01-19`.
   * @default "2030-01-01"
   */
  dateTo?: string | null;

  /**
   * Only for type `colorHex`. [Details here](https://stackoverflow.com/a/43235/4989887)
   * @default {"red255":0,"green255":0,"blue255":0}
   */
  baseColor?: IFakeColor | null;

  /**
   * Only for type `number`
   */
  minNumber?: number | null;

  /**
   * Only for type `number`
   */
  maxNumber?: number | null;

  /**
   * Only for type `number`
   */
  precisionNumber?: number | null;
}

// tslint:enable
