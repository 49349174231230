import React from 'react';

import { LoanBanner } from '../';

import { PastDueProps } from './common';
import { PastDue } from './PastDue';
import { OutstandingBalance } from './OutstandingBalance';

const pastDueContent: (props: PastDueProps) => JSX.Element | null = props => {
  const { pastDueAmount, hasOutstandingBalance } = props;

  if (!!pastDueAmount?.cents) {
    return <PastDue {...props} />;
  } else if (hasOutstandingBalance) {
    return <OutstandingBalance {...props} />;
  }

  return null;
};

export const PastDueBanner: React.FC<PastDueProps> = props => {
  const content = pastDueContent(props);

  if (!content) {
    return null;
  }

  return <LoanBanner style='alert'>{content}</LoanBanner>;
};
