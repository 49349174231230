import React from 'react';

import { FlexColumn } from '../../../CommonStyles';
import { ApplicationWell, ILoanProps } from '../common';
import { Application } from '../Application';

import { LoanDescription } from './LoanDescription';
import { LoanActions } from './LoanActions';

export const IssuedLoan: React.FC<ILoanProps> = ({ product, rowIndex }) =>
  !!product && (
    <>
      <FlexColumn>
        <LoanDescription product={product} rowIndex={rowIndex} />
        <ApplicationWell>
          <Application application={product.customerApplication} product={product} />
        </ApplicationWell>
      </FlexColumn>
      <LoanActions product={product} rowIndex={rowIndex} />
    </>
  );
