import React from 'react';
import { ModalCtaButton } from '@amount/frontend-components';
import Modal from '../Modal';
import { ModalHeadline } from '../CommonStyles';
import { FormError } from '../Form';
import Styled from 'styled-components';
import SessionManager from '../../../util/sessionManager';
import { SessionTimeoutProps } from './types'

const LoggedOutCta = Styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const voidFn = () => null;

const SessionTimeoutThemeOne: React.FunctionComponent<SessionTimeoutProps> = props => (
  <Modal
    show={props.showWarning}
    close={voidFn}
    aria-label='You will be logged out soon'
    aria-labelledby='loggedOutLabel'
    aria-describedby='loggedOutDesc'
  >
    <ModalHeadline
      scale='medium'
      as='h2'
      id='loggedOutLabel'
    >
      You will be logged out soon
    </ModalHeadline>
    <p id='loggedOutDesc'>
      If you do not choose to stay logged in <strong>
      within {props.millisecondsToMinutes(AvantConfig.TenantConfig.logoutGracePeriod)} minutes
      </strong>, you will be logged out for your security.
    </p>
    <LoggedOutCta>
      <ModalCtaButton disabled={props.requesting} onClick={props.refreshToken} inline={true}>
        Stay Logged In
      </ModalCtaButton>
      <a href={SessionManager.urlRouter.logoutUrl} onClick={props.logout}>
        Log Me Out
      </a>
    </LoggedOutCta>
    {props.isError && (
      <FormError>An error occurred. Please try again.</FormError>
    )}
  </Modal>
);

export default SessionTimeoutThemeOne;
