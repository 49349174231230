import { compareDateString } from './date';

export const mergeSortedArrays: (arr1: IInfo[], arr2: IInfo[]) => IInfo[] = (arr1, arr2) => {
  const mergedArray: IInfo[] = [];
  let i = 0;
  let j = 0;

  while (i < arr1.length && j < arr2.length) {
    // If arr1 item is before or equal to arr2 item push it, otherwise push arr2 item
    mergedArray.push(compareDateString(arr1[i].date, arr2[j].date) >= 0 ? arr1[i++] : arr2[j++]);
  }

  // Make sure to add remaining items
  while (i < arr1.length) {
    mergedArray.push(arr1[i++]);
  }

  while (j < arr2.length) {
    mergedArray.push(arr2[j++]);
  }

  return mergedArray;
};

export interface IInfo {
  date?: string;
  component: React.ReactNode;
}
