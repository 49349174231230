import { VALID_BANNER_TYPES } from "@amount/frontend-components";
import { UserContext } from "src/g2/servicing/modules/shared/types";

type BannerVariantType = VALID_BANNER_TYPES | null;

export enum ServicingPageContextAction {
  UPDATE_BANNER = 'updateBanner',
  CLEAR_BANNER_BY_TYPE = 'clearBannerByType',
  SERVER_ERROR = 'serverError',
  CLEAR_ALL_BANNERS = 'clearAllBanners',
  SET_SUCCESS_BANNER = 'setSuccessBanner',
  CLEAR_SUCCESS_BANNER = 'clearSuccessBanner',
  SET_DISQUALIFIED_BANNER = 'setDisqualifiedBanner',
  CLEAR_DISQUALIFIED_BANNER = 'clearDisqualifiedBanner',
  SET_USER_CONTEXT = 'setUserContext',
}

export enum BannerType {
  VALIDATION_ERROR = 'validationError',
}

export interface ServicingPageContextBannerProps {
  bannerType?: BannerType;
  bannerVariant: BannerVariantType;
  bannerMessage: React.ReactNode;
  fieldName?: string;
}

export interface ServicingPageContextStateProps {
  hasServerError?: boolean;
  availableBanners: ServicingPageContextBannerProps[];
  successBanner?: string | null;
  disqualifiedBanner?: string | null;
  userContext?: UserContext;
}

interface ServicingPageContextUpdateActionType extends ServicingPageContextBannerProps {
  type: ServicingPageContextAction.UPDATE_BANNER;
}

interface ServicingPageContextClearActionType {
  type: ServicingPageContextAction.CLEAR_BANNER_BY_TYPE;
  bannerTypesToClear: BannerType | BannerType[];
}

interface ServicingPageContextClearAllBannersActionType {
  type: ServicingPageContextAction.CLEAR_ALL_BANNERS;
}

interface ServicingPageContextServerErrorActionType {
  type: ServicingPageContextAction.SERVER_ERROR;
}

interface ServicingPageContextSetSuccessBanner {
  type: ServicingPageContextAction.SET_SUCCESS_BANNER;
  successBanner?: string | null;
}

interface ServicingPageContextClearSuccessBanner {
  type: ServicingPageContextAction.CLEAR_SUCCESS_BANNER;
}

interface ServicingPageContextSetDisqualifiedBanner {
  type: ServicingPageContextAction.SET_DISQUALIFIED_BANNER;
  disqualifiedBanner?: string | null;
}

interface ServicingPageContextClearDisqualifiedBanner {
  type: ServicingPageContextAction.CLEAR_DISQUALIFIED_BANNER;
}

interface ServicingPageContextSetUserContext extends ServicingPageContextStateProps {
  type: ServicingPageContextAction.SET_USER_CONTEXT;
}

export type ServicingPageContextActionProps = ServicingPageContextUpdateActionType
  | ServicingPageContextClearActionType
  | ServicingPageContextServerErrorActionType
  | ServicingPageContextClearAllBannersActionType
  | ServicingPageContextSetSuccessBanner
  | ServicingPageContextClearSuccessBanner
  | ServicingPageContextSetDisqualifiedBanner
  | ServicingPageContextClearDisqualifiedBanner
  | ServicingPageContextSetUserContext;

export const servicingTaskContextReducer = (
  state: ServicingPageContextStateProps,
  action: ServicingPageContextActionProps
) => {
  switch (action.type) {
    case ServicingPageContextAction.UPDATE_BANNER: {
      const isNewBanner = action.bannerMessage
        && !state.availableBanners?.some(banner => banner.bannerMessage === action.bannerMessage);

        const availableBanners = isNewBanner
        ? [
            ...state.availableBanners,
            {
              bannerMessage: action.bannerMessage,
              bannerVariant: action.bannerVariant,
              bannerType: action.bannerType,
              fieldName: action.fieldName,
            }
          ]
        : state.availableBanners;

      return { ...state, availableBanners };
    }

    case ServicingPageContextAction.CLEAR_BANNER_BY_TYPE: {
      return {
        ...state,
        availableBanners: state.availableBanners.filter(banner =>
          banner.bannerType ? !action.bannerTypesToClear.includes(banner.bannerType) : true
        )
      };
    }

    case ServicingPageContextAction.CLEAR_ALL_BANNERS: {
      return { ...state, availableBanners: [] };
    }

    case ServicingPageContextAction.SERVER_ERROR: {
      return {
        ...state,
        hasServerError: true,
        availableBanners: []
      }
    }

    case ServicingPageContextAction.SET_SUCCESS_BANNER: {
      return {
        ...state,
        successBanner: action.successBanner
      }
    }

    case ServicingPageContextAction.CLEAR_SUCCESS_BANNER: {
      const { successBanner = {}, ...resetState } = state;
      return resetState;
    }

    case ServicingPageContextAction.SET_DISQUALIFIED_BANNER: {
      return {
        ...state,
        disqualifiedBanner: action.disqualifiedBanner
      }
    }

    case ServicingPageContextAction.CLEAR_DISQUALIFIED_BANNER: {
      const { disqualifiedBanner = {}, ...resetState } = state;
      return resetState;
    }

    case ServicingPageContextAction.SET_USER_CONTEXT: {
      if (action.userContext) {
        return {
          ...state,
          userContext: action.userContext
        }
      } else {
        return state
      }
    }

    default: return state;
  }
}
