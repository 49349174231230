import React from 'react';
import { Box, Text, VALID_EDGE_STYLE_DATA } from '@amount/frontend-components';

export interface IRepaymentDataPointProps {
  title: string;
  content: string;
  containerMargin?: VALID_EDGE_STYLE_DATA;
}

const RepaymentDataDisplay: React.FC<IRepaymentDataPointProps> = ({ title, content, containerMargin }) => (
  <Box direction="column" margin={containerMargin}>
    <Text
      display="flex"
      margin={{
        base: { bottom: '0' },
        mediumLarge: { bottom: '0.625x' },
      }}
      size={{
        base: 'heroMedium',
        mediumLarge: 'heroLarge',
      }}
      family="optimist_light"
    >
      {content}
    </Text>
    <Text
      display="flex"
      family="optimist_semibold"
      margin={{ top: '1x' }}
      size="bodyMedium"
      forwardedAs='h3'
    >
      {title}
    </Text>
  </Box>
);

export default RepaymentDataDisplay;
