import React, { useContext } from "react";

import { ServicingBannerVariants } from "../common/constants";
import { ServicingPageContext } from "../ServicingPageContextProvider";
import {
  ServicingPageContextStateProps,
  ServicingPageContextAction,
  BannerType,
  ServicingPageContextBannerProps,
} from "./servicingPageReducer";

type BannerMessage = Pick<ServicingPageContextBannerProps, 'bannerMessage'>;

interface UseServicingPageContextProps {
  state: ServicingPageContextStateProps;
  setErrorBannerMessage(message: React.ReactNode, bannerType?: BannerType): void;
  setWarningBannerMessage(message: React.ReactNode): void;
  setInfoBannerMessage(message: React.ReactNode): void;
  setServerError(): void;
  setBanner(banner: ServicingPageContextBannerProps): void;
  clearAllBanners(): void;
  clearBannerByType(bannerTypesToClear: BannerType | BannerType[]): void;
  setSuccessBannerMessage(message: string): void;
  clearSuccessBannerMessage(): void;
  setDisqualifiedBannerMessage(message: string): void;
  clearDisqualifiedBannerMessage(): void;
}

export function useServicingPageContext (): UseServicingPageContextProps {

  const { state, dispatch } = useContext(ServicingPageContext);

  const setErrorBannerMessage = (bannerMessage: BannerMessage, bannerType?: BannerType) => {
    dispatch({
      type: ServicingPageContextAction.UPDATE_BANNER,
      bannerVariant: ServicingBannerVariants.ERROR,
      bannerType,
      bannerMessage,
    });
  }

  const setWarningBannerMessage = (bannerMessage: BannerMessage) => {
    dispatch({
      type: ServicingPageContextAction.UPDATE_BANNER,
      bannerVariant: ServicingBannerVariants.WARNING,
      bannerMessage,
    });
  }

  const setInfoBannerMessage = (bannerMessage: BannerMessage) => {
    dispatch({
      type: ServicingPageContextAction.UPDATE_BANNER,
      bannerVariant: ServicingBannerVariants.INFO,
      bannerMessage,
    });
  }

  const setSuccessBannerMessage = (bannerMessage: string) => {
    dispatch({
      type: ServicingPageContextAction.SET_SUCCESS_BANNER,
      successBanner: bannerMessage
    });
  }

  const clearSuccessBannerMessage = () => {
    dispatch({ type: ServicingPageContextAction.CLEAR_SUCCESS_BANNER });
  }

  const setDisqualifiedBannerMessage = (bannerMessage: string) => {
    dispatch({
      type: ServicingPageContextAction.SET_DISQUALIFIED_BANNER,
      disqualifiedBanner: bannerMessage
    });
  }

  const clearDisqualifiedBannerMessage = () => {
    dispatch({ type: ServicingPageContextAction.CLEAR_DISQUALIFIED_BANNER });
  }

  const clearBannerByType = (bannerTypesToClear: BannerType | BannerType[]) => {
    dispatch({ type: ServicingPageContextAction.CLEAR_BANNER_BY_TYPE, bannerTypesToClear });
  };

  const clearAllBanners = () => {
    dispatch({ type: ServicingPageContextAction.CLEAR_ALL_BANNERS });
  };

  const setServerError = () => {
    dispatch({ type: ServicingPageContextAction.SERVER_ERROR });
  };

  const setBanner: (banner: ServicingPageContextBannerProps) => void = ({ bannerMessage, bannerVariant, bannerType }) =>
    dispatch({
      type: ServicingPageContextAction.UPDATE_BANNER,
      bannerVariant,
      bannerMessage,
      bannerType,
    });

  return {
    state,
    setErrorBannerMessage,
    setWarningBannerMessage,
    setInfoBannerMessage,
    setServerError,
    setBanner,
    clearBannerByType,
    clearAllBanners,
    setSuccessBannerMessage,
    clearSuccessBannerMessage,
    setDisqualifiedBannerMessage,
    clearDisqualifiedBannerMessage,
  }
}
