import React from 'react';
import Styled from 'styled-components';
import {
  media,
  SmallType,
  SVGIcon,
} from '@amount/frontend-components';

import { FragmentSelectionOnLoan, SelectionOnPointOfSaleInformation } from '../queries/GetCustomerHomeInformation.graphql';
import { NA } from '../../../constants';
import { ConditionalRenderWrapper, FlexColumn, FlexRow } from '../../Common/CommonStyles';
import ProductIdentifierHeadline from '../../Common/ProductIdentifierHeadline';
import { getFormattedAmount } from '../../../util';
import { ProductDetailsBox } from '../../Common/Box';

import { LoanCta } from './LoanCta';

type LoanIdentifierProps = Pick<
  FragmentSelectionOnLoan,
  | 'amount'
  | 'amountWithOtherOriginationFees'
  | 'id'
  | 'isRegions'
  | 'uuid'
  | 'isPaidOff'
  | 'isFunded'
  | 'pointOfSaleInformation'
  | 'servicingAccountUuid'
> & {
  inactive: boolean;
};

const StyledColumn = Styled(FlexColumn)`
  display: none;

  ${media.medium`
    display: block;
  `}
`;

const StyledImg = Styled.img`
  padding-top: 0.25em;
  padding-right: 1.0em;
  height: 40px;
`;

const StyledSVGIcon = Styled(SVGIcon)`
  align-self: center;
  width: 1.375em;
  margin-top: 0.25em;
  margin-right: 0.5em;
  fill: ${props => props.theme.colorSlate};
  ${media.ie11`
    height: 1.35em;
  `}
`;

const CenteredRow = Styled(FlexRow)`
  align-items: center;
  padding: 0;
`;

const image: (imageUrl: string | null) => React.ReactNode = imageUrl => {
  const showLoanIcon = AvantConfig.TenantConfig.customerHome.showLoanIcon;

  if (!showLoanIcon) { return null }

  if (imageUrl) {
    return (
      <ConditionalRenderWrapper hiddenOnMobile={true}>
        <StyledColumn>
          <StyledImg src={imageUrl} alt='logo' />
        </StyledColumn>
      </ConditionalRenderWrapper>
    );
  }

  return (
    <ConditionalRenderWrapper hiddenOnMobile={true}>
      <StyledColumn>
        <StyledSVGIcon icon='personal-loan' />
      </StyledColumn>
    </ConditionalRenderWrapper>
  );
};

const title: (pointOfSaleInformation: SelectionOnPointOfSaleInformation | null, loanAmount?: string) => React.ReactNode
  = (pointOfSaleInformation, loanAmount) => {
  const pointOfSaleMerchantName = pointOfSaleInformation && pointOfSaleInformation.merchantName;
  const pointOfSaleTitle = `${loanAmount || ''} ${AvantConfig.TenantConfig.product.loan.name} with ${pointOfSaleMerchantName}`;
  const installmentTitle = `${AvantConfig.TenantConfig.product.loan.descriptiveName} of ${loanAmount || NA}`;
  const cardTitle = (!!pointOfSaleInformation && !AvantConfig.TenantConfig.product.loan.useInstallmentTitle) ?
    pointOfSaleTitle : installmentTitle;

  return (
    <CenteredRow>
      <ProductIdentifierHeadline>
        {cardTitle}
      </ProductIdentifierHeadline>
    </CenteredRow>
  );
};

const subtitle: (id: string) => React.ReactNode = id => {
  const showLoanId = AvantConfig.TenantConfig.customerHome.showLoanId;
  const smallLoanId = AvantConfig.TenantConfig.customerHome.smallLoanId;

  return showLoanId && (
    <CenteredRow>
      {smallLoanId ?
        (<SmallType>Loan ID #{id}</SmallType>) :
        (<p>Loan ID #{id}</p>)}
    </CenteredRow>
  );
};

const LoanIdentifier: React.FunctionComponent<LoanIdentifierProps> = data =>  {
  const {
    amount,
    id,
    uuid,
    inactive,
    isFunded,
    isRegions,
    amountWithOtherOriginationFees,
    pointOfSaleInformation,
    servicingAccountUuid,
  } = data;

  return (
    <ProductDetailsBox inactive={inactive}>
      <FlexRow>
        {image(pointOfSaleInformation && pointOfSaleInformation.merchantImageUrl)}
        <FlexColumn>
          {title(pointOfSaleInformation, getFormattedAmount(amountWithOtherOriginationFees, amount))}
          {subtitle(id)}
        </FlexColumn>
      </FlexRow>
      <LoanCta uuid={uuid} isRegions={isRegions} isFunded={isFunded} servicingAccountUuid={servicingAccountUuid} />
    </ProductDetailsBox>
  );
};

export default LoanIdentifier;
