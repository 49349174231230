import * as React from 'react';
import {
  Headline,
  TextInput,
} from '@amount/frontend-components';
import { FragmentSelectionOnWorkflowTextInput } from '@amount/workflow-js';
import camelCase from 'lodash-es/camelCase';
import InputMask from 'react-input-mask';

import { IWorkflowInputBaseProps, maskerFor, WorkflowInputMaxWidthWrapper } from './common';

interface IWorkflowTextInputProps extends IWorkflowInputBaseProps {
  input: FragmentSelectionOnWorkflowTextInput;
}

interface IMaskerControlledProps {
  pattern?: string;
  disabled?: boolean;
  onChange? (e: React.ChangeEvent<HTMLInputElement>): void;
}

export const renderTextInput: (
  props: IWorkflowTextInputProps,
  maskerControlledProps: IMaskerControlledProps
) => React.ReactElement = (props, maskerControlledProps) => {
  const { input, value, onClickCallback, innerInputRef } = props;

  return (
    <TextInput
      id={camelCase(input.title || input.placeholder || undefined)}
      ref={innerInputRef}
      placeholder={input.placeholder || undefined}
      defaultValue={value || ''}
      required={!!input.required}
      title={input.title || undefined}
      data-event={camelCase(`${input.placeholder || ''}TextInput`)}
      onClick={_e => onClickCallback && onClickCallback()}
      {...maskerControlledProps}
    />
  );
};

export const WorkflowTextInput: React.FC<IWorkflowTextInputProps> = props => {
  const { input, updateInput } = props;

  const masker = maskerFor(input.format);
  const isMasked = masker.mask !== '';
  /**
   * InputMask literally prevents us from putting specific props on it's children, like disabled.
   * see: https://github.com/sanniassin/react-input-mask/blob/v2/src/index.js#L568
   *
   * Instead, it insists we put those properties on the InputMask itself. However, it also has a bug where
   * it does not propagate these properly,
   * see https://github.com/sanniassin/react-input-mask/issues/158
   *
   * So, we conditionally add those props to unmasked fields, and do not add them for masked fields.
   * This will only cause a problem when we need to disable a masked field.
   */
  const maskerControlledProps: IMaskerControlledProps = isMasked ? {
    // for local (form.checkValidity) validation, InputMask forces the validate on the masked value,
    // not the unmasked value passed up to workflow.js. We replace the pattern for this local validation
    // to match to the masked value just for masked inputs.
    pattern: masker.pattern
  } : {
    pattern: input.pattern || undefined,
    disabled: !!input.disabled,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.currentTarget.value)
  };

  const headline = input.title || input.placeholder;

  return (
    <>
      {headline && (
        <Headline scale='small' as='label' htmlFor={camelCase(headline || undefined)}>
          {headline}
        </Headline>
      )}
      <WorkflowInputMaxWidthWrapper>
        {isMasked ? (
          <InputMask
            mask={masker.mask}
            alwaysShowMask={false}
            maskChar={null}
            onChange={e => updateInput(masker.unmask(e.currentTarget.value))}
            disabled={!!input.disabled}
          >
            {() => renderTextInput(props, maskerControlledProps)}
          </InputMask>
        ) : (
          renderTextInput(props, maskerControlledProps)
        )}
      </WorkflowInputMaxWidthWrapper>
    </>
  );
};
