import React from 'react';
import {
  Card,
} from '@amount/frontend-components';

import {
  FragmentSelectionOnLoan,
  SelectionOnCustomer,
} from '../queries/GetCustomerHomeInformation.graphql';
import { LoanBanners } from '../../Common/BannerNotifications/LoanBanner';
import { CustomerHomeBannerWrapper } from '../../Common/BannerNotifications/BannerTextWrapper';
import { CardContentPaddingContainer, ProductCardWrapper, ProductStatuses } from '../common';
import { TransferredLoanContent } from '../CustomerHomeCards/TransferredLoanContent';

import { LoanContent } from './LoanContent';
import LoanIdentifier from './LoanIdentifier';

interface ILoanProductCardProps {
  loan: FragmentSelectionOnLoan;
  bankAccount?: SelectionOnCustomer['bankAccount'];
  canUpdateBankAccount: SelectionOnCustomer['canUpdateBankAccount'];
}

const isServiceablePointOfSaleLoan: (loan: FragmentSelectionOnLoan) => boolean = loan => {
  const { pointOfSaleInformation, status, isFunded } = loan;

  if (!pointOfSaleInformation) { return true; }

  const isFullRefund = !!pointOfSaleInformation.refund?.isFullRefund;
  const isAuthorized = status === ProductStatuses.Authorization;
  const isCurrentNotFunded = status === ProductStatuses.Current && !isFunded;

  return !isFullRefund && !isAuthorized && !isCurrentNotFunded;
};

const LoanProductCard: React.FunctionComponent<ILoanProductCardProps> = data => {
  const { bankAccount, canUpdateBankAccount, loan } = data;
  if (!loan) { return null; }

  const { chargeOffInformation } = loan;

  const operationallyChargedOff: boolean = !!chargeOffInformation && chargeOffInformation.operationallyChargedOff;
  const inactive: boolean = loan.isPaidOff || loan.isChargedOff || loan.isSoldOff || loan.isBankruptcyDischarged || operationallyChargedOff;
  const isServiceableLoan: boolean = AvantConfig.TenantConfig.hasLoanServicing && !loan.isRegions;
  const isServicablePointOfSale: boolean = isServiceablePointOfSaleLoan(loan);

  return (
    <ProductCardWrapper key={loan.uuid} width={AvantConfig.TenantConfig.product.cardWidth}>
      <Card padding='0'>
        {isServiceableLoan && (
          <CustomerHomeBannerWrapper>
            <LoanBanners
              {...loan}
              isDismissable={false}
              badAccount={!bankAccount || !!bankAccount.badAccount}
              bankAccount={bankAccount || null}
              canUpdateBankAccount={!!canUpdateBankAccount}
            />
          </CustomerHomeBannerWrapper>
        )}
        <CardContentPaddingContainer>
          <LoanIdentifier {...loan} inactive={inactive} />
            {isServiceableLoan && isServicablePointOfSale ?
              <LoanContent loan={loan} inactive={inactive} /> : <TransferredLoanContent productUuid={loan.uuid} />
            }
        </CardContentPaddingContainer>
      </Card>
    </ProductCardWrapper>
  );
};

export default LoanProductCard;
