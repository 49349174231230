import {
  SelectionOnAccountManagement,
  SelectionOnCustomerApplication,
} from '../queries/GetAccountHistoryData.graphql';

export interface AccountHistoryListProps {
  applications: SelectionOnCustomerApplication[];
  accounts: SelectionOnAccountManagement[];
}

export enum TagTypes {
  Active = 'active',
  PaidOff = 'paid-off',
  Canceled = 'canceled',
}

export enum HistoryViewModes {
  Account = 'account',
  Declined = 'declined',
  Canceled = 'canceled',
  Closed = 'closed',
}

export interface HistoryViewModel {
  type: HistoryViewModes;
  purchaseAmount?: string;
  tag?: TagTypes;
  sortDate: string;
  displayDate: string;
  applicationDate: string;
  merchant: string;
  applicationID: string;
  productID?: string;
  isRefunded?: boolean;
  accountLink?: string;
  contractLink?: string;
  noaaLink?: string;
}
