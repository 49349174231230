import React from 'react';
import { Banner_t2_ as Banner, Box, SVGIcon } from '@amount/frontend-components';
import sanitizeHtml from 'sanitize-html';

interface GlobalWarningBannerProps {
  htmlContent?: string | null;
}

export const GlobalWarningBanner: React.FC<GlobalWarningBannerProps> = ({ htmlContent }) => {
  if (!htmlContent) {
    return null;
  }

  return (
    <Banner.info margin={{ bottom: '.4rem' }}>
      <Box padding={{ right: '1.5x', top: 'double' }}>
        <SVGIcon icon='circle-info' size='2.5x' />
      </Box>
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(htmlContent) }} />
    </Banner.info>
  );
};
