import React from 'react';
import { Link } from 'react-router-dom';
import { BannerLink } from '@amount/frontend-components';

import { ApplicationBaseRoutes, ServicingRoutes } from '../../../../../routes';
import { LinkAndTextWrapper } from '../../common';
import { BannerNotificationsProps } from '../types';

import { LoanBanner } from '.';

type RemotelyCreatedCheckProps = Pick<BannerNotificationsProps, 'uuid'>;
export const RemotelyCreatedCheckBanner: React.FC<RemotelyCreatedCheckProps> = ({ uuid }) => (
  <LoanBanner>
    <LinkAndTextWrapper>
      <span>{AvantConfig.TenantConfig.banners.rccDeprecationBanner}</span>
      <BannerLink bannerStyle='inactive'>
        <Link
          data-event='bannerLoanOptIntoAch'
          to={`${ApplicationBaseRoutes.loanServicing}/${uuid}${ServicingRoutes.achOptIn}`}
        >
          Opt-in to ACH
        </Link>
      </BannerLink>
    </LinkAndTextWrapper>
  </LoanBanner>
);
