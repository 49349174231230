import React, { useContext } from 'react';
import { AppContext } from '../../../util/AppContextWrapper/AppContext';
import { ThemeTwoNotFound } from '../../../_shared/ThemeTwo/Error/ThemeTwoNotFound';
import ThemeOneNotFound from './ThemeOneNotFound';

const NotFound: React.FunctionComponent = () => {
  const { enableG2Dashboards } = useContext(AppContext);

  return (
    enableG2Dashboards ?
      <ThemeTwoNotFound />
    :
      <ThemeOneNotFound />
  )
};

export default NotFound;
