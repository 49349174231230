import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Loading } from '../../components/Common/Spinner/LoadingHOC';
import GetWarningBannersQuery, { GetWarningBanners, SelectionOnWarningBanners } from '../queries/GetWarningBanners.graphql';
import GetWarningBannersG2Query,
{
  GetWarningBannersG2,
  SelectionOnCustomerManagementWarningBanners
} from '../queries/GetWarningBannersG2.graphql';
import { FeatureFlagHelper } from '../../util/featureFlag';
import { GlobalWarningBanner } from '../../components/Common/BannerNotifications/GlobalWarningBanner';
import { GlobalWarningBanner as GlobalWarningBannerT2 } from 'src/_shared/ThemeTwo/BannerNotifications/GlobalWarningBanner';
import { AppContext } from '@Util/AppContextWrapper/AppContext';

type WarningBannerType = Exclude<keyof SelectionOnWarningBanners, '__typename'>;
type WarningBannerG2Type = Exclude<keyof SelectionOnCustomerManagementWarningBanners, '__typename'>;

export const withWarningBanner:
<T>(Component: React.ComponentClass<T> | React.FC<T>, bannerKey: WarningBannerType | WarningBannerG2Type) => React.FC<T> = (
  Component,
  bannerKey
) =>
  props => {
    const isEnabled = FeatureFlagHelper.isWarningBannersEnabled;
    const { enableG2Dashboards } = useContext(AppContext);

    const getBanners = () => enableG2Dashboards ?
    useQuery<GetWarningBannersG2>(GetWarningBannersG2Query) : useQuery<GetWarningBanners>(GetWarningBannersQuery);

    const { data, loading } = isEnabled ? getBanners() : { data: undefined, loading: false };

    return (
      <Loading data={data} loading={loading}>
        {enableG2Dashboards ?
          <GlobalWarningBannerT2 htmlContent={data?.warningBanners[bannerKey]} /> :
          <GlobalWarningBanner htmlContent={data?.warningBanners[bannerKey]} />
        }
        <Component {...props} />
      </Loading>
    );
  };


export const WarningBannerWrapper: (props: {
  bannerKey: WarningBannerG2Type,
  children?: React.ReactNode
}) => JSX.Element = ({bannerKey, children}) => {
  const isEnabled = FeatureFlagHelper.isWarningBannersEnabled;

  const { data, loading } = useQuery<GetWarningBannersG2>(
    GetWarningBannersG2Query,
    { skip: !isEnabled }
  );

  return (
    <Loading data={data} loading={loading}>
      <GlobalWarningBannerT2 htmlContent={data?.warningBanners[bannerKey]} />
      {children}
    </Loading>
  )
};
