import React from 'react';

import { ApplicationBaseRoutes } from '../../../routes';

import {
  ApplicationCardContainer,
  CardLabelWrapper,
  FlexColumnIE,
  IApplicationCardProps,
  IApplicationLink,
} from './common';
import ApplicationCardCta from './ApplicationCardCta';

export class CreditFreezeCard extends React.PureComponent<IApplicationCardProps> {
  public render () {
    const cardCta: IApplicationLink = {
      text: 'Learn More',
      route: `${ApplicationBaseRoutes.creditFreeze}/${this.props.application.uuid}`,
    };

    return (
      <ApplicationCardContainer onDismiss={this.handleDismiss}>
        <FlexColumnIE>
          <CardLabelWrapper as='h3'>
            There's a freeze on your credit report.
          </CardLabelWrapper>
          <span>
            Unfreeze your report and restart your application.
          </span>
          <ApplicationCardCta cardLink={cardCta} dataEvent='creditFreeze' />
        </FlexColumnIE>
      </ApplicationCardContainer>
    );
  }

  private readonly handleDismiss: (event: React.MouseEvent<HTMLButtonElement>) => void = event => {
    event.preventDefault();

    this.props.dismissApplication(this.props.application);
  }
}
