import React from 'react';
import {
  Banner_t2_,
  Box,
  SVGIcon,
  VALID_BANNER_TYPES,
} from '@amount/frontend-components';
import { BannerTextWrapper } from '../../BannerTextWrapper';

export interface ILoanBannerProps {
  variant: VALID_BANNER_TYPES;
}

const { bannerIcons } = AvantConfig.TenantConfig.accountManagementDash;

export const LoanBanner: React.FC<ILoanBannerProps> = ({ variant, children }) => {
  const Banner = Banner_t2_[variant];

  return (
    <Banner>
      <BannerTextWrapper>
        <>
          <Box padding={{ right: '1.5x', top: 'double' }}>
            <SVGIcon icon={bannerIcons[variant]} size='2.5x' />
          </Box>
          {children}
        </>
      </BannerTextWrapper>
    </Banner>
  )
};
