import React from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import {
  HeaderDesktopOnlyLinkContainer,
  HeaderDrawer,
  HeaderDrawerLink,
  HeaderDrawerNavLink,
  HeaderInnerContainer,
  HeaderLinksContainer,
  HeaderMobileOnlyDrawerLinkContainer,
  HeaderMobileOnlyLinkContainer,
  NavButton,
  VALID_BREAKPOINTS,
  VALID_SVG_ICONS,
  media,
} from '@amount/frontend-components';

import SupportModal from '../../Support';
import SessionManager from '../../../../util/sessionManager';
import { AccountRoutes, ApplicationBaseRoutes, paymentMethodsPath, personalInformationPath } from '../../../../routes';
import { SkipToContentLink } from '../../SkipLink';
import NewWindowLink from '../../NewWindowLink';

import { ReturnToMerchantModal } from './ReturnToMerchant';
import { DrawerAnchor } from './DrawerAnchor';
import HeaderLogoContainer from './HeaderLogo';
import EqualHeaderSection from './EqualHeaderSection';
import ReturnToMerchantLink from './ReturnToMerchantLink';
import { HeaderOuterContainer } from '../../CommonStyles';
import { HeaderLinksConfig } from 'src/customTypings/avant-config/tenantConfig';

export const enum HeaderLinks {
  'Faq' = 'faq',
  'ReturnToMerchantLink' = 'ReturnToMerchantLink',
  'SupportLink' = 'supportLink',
  'Logout' = 'logout',
}

export const enum DrawerLinks {
  'AccountHistory' = 'accountHistory',
  'AccountSettings' = 'accountSettings',
  'Faq' = 'faq',
  'Home' = 'home',
  'Logout' = 'logout',
  'ReturnToMerchantLink' = 'ReturnToMerchantLink',
  'SupportLink' = 'supportLink',
  'PaymentMethods' = 'paymentMethods',
  'PersonalInformation' = 'personalInformation',
}

export interface IPointOfSaleInterface {
  merchantName?: string;
}

export interface IDrawerIcon {
  iconOpen?: VALID_SVG_ICONS;
  iconClosed?: VALID_SVG_ICONS;
}

export interface IDrawerAnchor extends IDrawerIcon {
  drawerOpen: boolean;
  breakPoint?: VALID_BREAKPOINTS;
  hideOnDesktop?: boolean;
}

export interface IHeaderProps {
  pointOfSaleInfo?: IPointOfSaleInterface | null;
  applicationId?: string;
  applicationUuid?: string;
  hideDrawerOnDesktop?: boolean;
  centeredLogo?: boolean;
  headerLinksConfig?: HeaderLinksConfig;
  isLogoClickable?: boolean;
  cardBrand?: string | null;
}

export interface ICustomHeaderProps extends IHeaderProps {
  rightLogo?: React.ReactNode;
  headerLinks?: HeaderLinks[];
  drawerLinks?: DrawerLinks[];
  drawerIcon?: IDrawerIcon;
  linksBreakPoint?: VALID_BREAKPOINTS;
  centeredLogo?: boolean;
  noDesktopDrawer?: boolean;
  hasDrawerDividers?: boolean;
  noMerchantLink?: boolean;
  sendClickEventCallback?(pageName: string): void;
  cardBrand?: string | null;
  noMerchantRedirectModal?: boolean;
}

interface IDefaultHeaderState {
  drawerOpen: boolean;
  supportOpen: boolean;
  returnMerchantOpen: boolean;
}

interface ILinkProps {
  closeDrawer?(): void;
  logout?(): void;
  toggleDrawer?(): void;
  toggleReturnToMerchant?(): void;
  toggleSupport?(): void;
  sendClickEventCallback?(pageName: string): void;
  hasDrawerDividers?: boolean;
}

const AccountHistory: React.FC<ILinkProps> = () => (
  <Link
    data-event="accountHistory"
    to={`${ApplicationBaseRoutes.account}${AccountRoutes.history}`}
    tabIndex={0}
  >
    Account History
  </Link>
);

const AccountSettings: React.FC<ILinkProps> = () => (
  <Link
    data-event="accountSettings"
    to={ApplicationBaseRoutes.account}
    tabIndex={0}
  >
    Account Settings
  </Link>
);

const DesktopReturnToMerchant: React.FC<IPointOfSaleInterface & ILinkProps> = ({
  merchantName,
  toggleReturnToMerchant,
  sendClickEventCallback,
}) => (
  <NavButton
    data-event="returnToMerchant"
    onClick={() => {
      if (sendClickEventCallback) { sendClickEventCallback('return_to_merchant') }
      if (toggleReturnToMerchant) { toggleReturnToMerchant() }
    }}
    data-back-to-merchant-link-desktop={true}
    tabIndex={0}
  >
    Back to {merchantName || 'Merchant'}
  </NavButton>
);

const MobileReturnToMerchant: React.FC<IPointOfSaleInterface & ILinkProps> = ({
  merchantName,
  toggleReturnToMerchant,
  sendClickEventCallback,
}) => (
  <NavButton
    data-ignore-click-listener={true}
    data-event="returnToMerchant"
    onClick={() => {
      if (sendClickEventCallback) { sendClickEventCallback('return_to_merchant') }
      if (toggleReturnToMerchant) { toggleReturnToMerchant() }
    }}
    data-back-to-merchant-link-mobile={true}
    tabIndex={0}
  >
    Back to {merchantName || 'Merchant'}
  </NavButton>
);

const supportCopy = AvantConfig.TenantConfig.header.support;

const supportLink = supportCopy.externalSupportUrl ?
  (
    <NewWindowLink href={supportCopy.externalSupportUrl}>
      {supportCopy.label}
    </NewWindowLink>
  ) : (
    supportCopy.label
  )

const DesktopSupportLink: React.FC<ILinkProps> = ({ toggleSupport, sendClickEventCallback, hasDrawerDividers }) => {
  const supportLinkPadding = hasDrawerDividers ? { padding: '0' } : undefined;

  return (
    <NavButton
      data-event="support"
      data-support-link-desktop={true}
      onClick={() => {
        if (sendClickEventCallback) { sendClickEventCallback('contact_us') }
        if (toggleSupport) { toggleSupport() }
      }}
      tabIndex={0}
      style={supportLinkPadding}
    >
      {supportLink}
    </NavButton>
  );
};

const MobileSupportLink: React.FC<ILinkProps> = ({ toggleSupport, sendClickEventCallback, hasDrawerDividers }) => {
  const supportLinkPadding = hasDrawerDividers ? { padding: '0' } : undefined;

  return (
    <NavButton
      data-event="supportDrawer"
      onClick={() => {
        if (sendClickEventCallback) { sendClickEventCallback('contact_us') }
        if (toggleSupport) { toggleSupport() }
      }}
      data-support-link-mobile={true}
      tabIndex={0}
      style={supportLinkPadding}
    >
      {supportLink}
    </NavButton>
  );
};

const Faq: React.FC = () => (
  // TODO_BASEURL - update?
  <NewWindowLink href={SessionManager.urlRouter.faqUrl} tabIndex={0}>
    FAQ
  </NewWindowLink>
);

const FaqMobile: React.FC<ILinkProps> = () => (
  <NewWindowLink
    href={SessionManager.urlRouter.faqUrl}
    data-event="faq"
    data-ignore-click-listener={true}
    tabIndex={0}
  >
    FAQ
  </NewWindowLink>
);

const HomeLink: React.FC<ILinkProps> = () => (
  <Link data-event="homeDrawer" to="/" tabIndex={0}>
    {AvantConfig.TenantConfig.header.homeLink}
  </Link>
);

const Logout: React.FC<ILinkProps> = ({ logout, sendClickEventCallback }) => (
  <a
    // TODO_BASEURL - update?
    href={SessionManager.urlRouter.logoutUrl}
    onClick={() => {
      if (sendClickEventCallback) { sendClickEventCallback('log_out') }
      if (logout) { logout() }
    }}
    data-event="logout"
    tabIndex={0}
  >
    {AvantConfig.TenantConfig.header.logOutLink}
  </a>
);

const PersonalInformationLink: React.FC<ILinkProps> = () => (
  <Link
    data-event="personalInformation"
    to={personalInformationPath}
    tabIndex={0}
  >
    Personal Information
  </Link>
);

const PaymentMethodsLink: React.FC<ILinkProps> = () => (
  <Link
    data-event="paymentMethods"
    to={paymentMethodsPath}
    tabIndex={0}
  >
    Payment Methods
  </Link>
);

const DrawerLinkWrapper: React.FC<{
  breakPoint?: VALID_BREAKPOINTS;
  wrapMobile: boolean;
  hasDrawerDividers?: boolean;
}> = ({ breakPoint, children, wrapMobile, hasDrawerDividers }) => {
  const drawerMargins = hasDrawerDividers ? { margin: '8px 0' } : undefined;

  return wrapMobile ? (
    <HeaderMobileOnlyDrawerLinkContainer breakPoint={breakPoint} style={drawerMargins}>
      {children}
    </HeaderMobileOnlyDrawerLinkContainer>
  ) : (
    <HeaderDrawerNavLink style={drawerMargins}>{children}</HeaderDrawerNavLink>
  );
};


const HeaderLinkMap: {
  [key in HeaderLinks]: React.FC<ILinkProps & IPointOfSaleInterface>;
} = {
  [HeaderLinks.Faq]: Faq,
  [HeaderLinks.ReturnToMerchantLink]: DesktopReturnToMerchant,
  [HeaderLinks.SupportLink]: DesktopSupportLink,
  [HeaderLinks.Logout]: Logout,
};

const DrawerLinkMap: {
  [key in DrawerLinks]: React.FC<ILinkProps & IPointOfSaleInterface>;
} = {
  [DrawerLinks.AccountHistory]: AccountHistory,
  [DrawerLinks.AccountSettings]: AccountSettings,
  [DrawerLinks.Faq]: FaqMobile,
  [DrawerLinks.Home]: HomeLink,
  [DrawerLinks.Logout]: Logout,
  [DrawerLinks.ReturnToMerchantLink]: MobileReturnToMerchant,
  [DrawerLinks.SupportLink]: MobileSupportLink,
  [DrawerLinks.PersonalInformation]: PersonalInformationLink,
  [DrawerLinks.PaymentMethods]: PaymentMethodsLink,
};

const wrapForMobile = new Map<DrawerLinks, boolean>([
  [DrawerLinks.AccountHistory, false],
  [DrawerLinks.AccountSettings, false],
  [DrawerLinks.Faq, true],
  [DrawerLinks.Home, false],
  [DrawerLinks.Logout, false],
  [DrawerLinks.ReturnToMerchantLink, true],
  [DrawerLinks.SupportLink, true],
]);

const StyledDrawerWrapper = Styled.div<{ hasDrawerDividers?: boolean }>`
  ${props => props.hasDrawerDividers && `li:last-child {
      padding: 0 16px;
      border: none;
    }`
  }
`;

const StyledLinkWrapper = Styled.div`
  a {
    color: inherit;
  }
`;

const DrawerLinkDivider = Styled.hr`
  margin: auto 16px;
  height: 1px;
  border: 0;
  background-color: ${props => props.theme.colorSlate10};
`;

const MobileOnlyDrawerLinkDivider = Styled(DrawerLinkDivider)<{ linksBreakPoint?: string }>`
  display: block;

  ${({ linksBreakPoint }) =>
    media[linksBreakPoint || 'small']` display: none;`
  }
`;

export default class Header extends React.PureComponent<
  ICustomHeaderProps,
  IDefaultHeaderState
> {
  public state = {
    drawerOpen: false,
    supportOpen: false,
    returnMerchantOpen: false,
  };

  public componentDidMount () {
    window.addEventListener('click', this.clickListener);
  }

  public componentWillUnmount () {
    window.removeEventListener('click', this.clickListener);
  }

  public render () {
    const drawerContent = (
      <HeaderDrawerLink
        data-event="myAccount"
        data-header-drawer-link={true}
        open={this.state.drawerOpen}
        breakPoint={this.props?.linksBreakPoint}
      >
        <NavButton
          onClick={this.toggleDrawer}
          aria-controls="header-drawer"
          data-header-anchor={true}
          aria-expanded={this.state.drawerOpen}
        >
          <DrawerAnchor
            iconOpen={this.props.drawerIcon?.iconOpen}
            iconClosed={this.props.drawerIcon?.iconClosed}
            drawerOpen={this.state.drawerOpen}
            breakPoint={this.props?.linksBreakPoint}
          />
        </NavButton>
        <StyledDrawerWrapper hasDrawerDividers={this.props.hasDrawerDividers}>
          <HeaderDrawer
            id="header-drawer"
            open={this.state.drawerOpen}
            data-header-drawer={true}
            breakPoint={this.props?.linksBreakPoint}
            closeMenu={() => null}
          >
            {this.props.drawerLinks?.map((linkType, index, arr) => {
              const DrawerLink = DrawerLinkMap[linkType];
              const isLastLink = index === arr.length - 1;
              const showDividers = !isLastLink && this.props.hasDrawerDividers;

              return (
                <React.Fragment key={linkType}>
                  <DrawerLinkWrapper
                    wrapMobile={!!wrapForMobile.get(linkType)}

                    breakPoint={this.props?.linksBreakPoint}
                    hasDrawerDividers={this.props.hasDrawerDividers}
                  >
                    <StyledLinkWrapper>
                      <DrawerLink
                        logout={
                          linkType === DrawerLinks.Logout ? this.logout : undefined
                        }
                        toggleSupport={this.toggleSupport}
                        merchantName={this.props.pointOfSaleInfo?.merchantName}
                        toggleReturnToMerchant={this.toggleReturnToMerchant}
                        sendClickEventCallback={this.props?.sendClickEventCallback}
                        hasDrawerDividers={this.props.hasDrawerDividers}
                      />
                    </StyledLinkWrapper>
                  </DrawerLinkWrapper>
                  {showDividers && (
                    linkType === DrawerLinks.SupportLink ?
                      <MobileOnlyDrawerLinkDivider linksBreakPoint={this.props?.linksBreakPoint} />
                    :
                      <DrawerLinkDivider />
                  )}
                </React.Fragment>
              );
            })}
          </HeaderDrawer>
        </StyledDrawerWrapper>
      </HeaderDrawerLink>
    );

    return (
      <>
        <HeaderOuterContainer>
          <HeaderInnerContainer>
            <SkipToContentLink />
            {this.props?.centeredLogo ? (
              <>
                <EqualHeaderSection contentAlignment="flex-start">
                  {!this.props.noMerchantLink && this.props.pointOfSaleInfo?.merchantName && (
                    <ReturnToMerchantLink
                      linksBreakPoint={this.props?.linksBreakPoint}
                      merchantName={this.props.pointOfSaleInfo?.merchantName}
                      sendClickEventCallback={this.props?.sendClickEventCallback}
                    />
                  )}
                </EqualHeaderSection>
                <EqualHeaderSection
                  contentAlignment="center"
                  alignSelf="center"
                >
                  <HeaderLogoContainer />
                </EqualHeaderSection>
              </>
            ) : (
              <HeaderLogoContainer cardBrand={this.props.cardBrand} />
            )}
            <HeaderLinksContainer>
              {this.props.headerLinks?.map(linkType => {
                const HeaderLink = HeaderLinkMap[linkType];

                return (
                  <HeaderDesktopOnlyLinkContainer
                    key={linkType}
                    breakPoint={this.props?.linksBreakPoint}
                  >
                    <HeaderLink
                      toggleReturnToMerchant={this.toggleReturnToMerchant}
                      toggleSupport={this.toggleSupport}
                      merchantName={this.props.pointOfSaleInfo?.merchantName}
                      sendClickEventCallback={this.props?.sendClickEventCallback}
                      hasDrawerDividers={this.props?.hasDrawerDividers}
                    />
                  </HeaderDesktopOnlyLinkContainer>
                );
              })}

              {this.props?.noDesktopDrawer ? (
                <HeaderMobileOnlyLinkContainer as="div">
                  {drawerContent}
                </HeaderMobileOnlyLinkContainer>
              ) : (
                <>{drawerContent}</>
              )}
            </HeaderLinksContainer>
            {!!this.props.rightLogo && this.props.rightLogo}
          </HeaderInnerContainer>
        </HeaderOuterContainer>
        {!supportCopy.externalSupportUrl && (
            <SupportModal
              show={this.state.supportOpen}
              close={this.toggleSupport}
            />
        )}
        {this.props.pointOfSaleInfo && (
          <ReturnToMerchantModal
            show={this.state.returnMerchantOpen}
            close={this.toggleReturnToMerchant}
            merchantName={this.props.pointOfSaleInfo?.merchantName}
            applicationUuid={this.props.applicationUuid}
            applicationId={this.props.applicationId}
          />
        )}
      </>
    );
  }

  private readonly toggleSupport = () => {
    this.setState(({ supportOpen }) => ({ supportOpen: !supportOpen }));
  };

  private readonly toggleReturnToMerchant = () => {
    this.setState(({ returnMerchantOpen }) => ({
      returnMerchantOpen: !returnMerchantOpen,
    }));
  };

  private readonly toggleDrawer: () => void = () => {
    this.setState(({ drawerOpen }) => ({ drawerOpen: !drawerOpen }));
  };

  private readonly clickListener: (e: MouseEvent) => void = e => {
    if (!this.state.drawerOpen) {
      return;
    }

    const headerDrawerLink: HTMLElement | null = document.querySelector(
      '[data-header-drawer-link]'
    );
    const headerOpenElement: HTMLButtonElement | null = document.querySelector(
      '[data-header-anchor]'
    );
    const targetElement = e.target as Element;
    const ignoreClickListener: boolean = targetElement.hasAttribute(
      'data-ignore-click-listener'
    );

    if (
      !headerDrawerLink ||
      !headerOpenElement ||
      headerOpenElement.contains(targetElement) ||
      ignoreClickListener
    ) {
      return;
    }

    this.setState({ drawerOpen: false });
    e.preventDefault();
  };

  private readonly logout = () => {
    SessionManager.logout();

    window.location.assign(SessionManager.urlRouter.logoutUrl);
  };
}
