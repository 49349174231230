import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Query } from 'react-apollo';

import { Loading } from '../components/Common/Spinner/LoadingHOC';
import PageTitle from '../components/Common/PageTitle';

import GetCustomerHomeInformationQuery, {
  GetCustomerHomeInformation,
} from './queries/GetCustomerHomeInformation.graphql';
import { CustomerHomeDash } from './Presentational/CustomerHomeDash';

const LiftedApp: React.FunctionComponent<RouteComponentProps> = props => (
  <Query<GetCustomerHomeInformation> query={GetCustomerHomeInformationQuery}>
    {({ data, loading }) => (
      <Loading loading={loading}>
        <PageTitle pageTitle={AvantConfig.TenantConfig.pageTitles.customerHome} />
        <CustomerHomeDash {...props} data={data} />
      </Loading>
    )}
  </Query>
);

export default LiftedApp;
