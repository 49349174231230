import React from 'react';

import { BannerNotificationsProps } from '../../types';
import { LoanBanner } from '..';

import { ActiveChargeOff } from './ActiveChargeOff';
import { ChargedOff } from './ChargedOff';
import { chargeOff } from '../../bannerStyles';

type ChargeOffProps = Pick<BannerNotificationsProps, 'chargeOffInformation' | 'uuid' | 'payoffInformation' | 'isChargedOff'>;
export const ChargeOffBanner: React.FC<ChargeOffProps> = props => (
  <LoanBanner style={chargeOff}>
    { !props.chargeOffInformation?.disableMakePayment && !!props.chargeOffInformation?.operationallyChargedOff
      ? <ActiveChargeOff {...props} />
      : <ChargedOff />
    }
  </LoanBanner>
);
