/**
 * This class contains common URLs needed by IP Dash
 */
class UrlRouter {
  private readonly _apiPath: string = '/api/v2/customer';
  private readonly _loggedOut: string = '/loggedOut';
  private _apiBaseUrl: string;

  constructor (apiBaseUrl: string) {
    this._apiBaseUrl = apiBaseUrl;
  }

  public set apiBaseUrl (apiBaseUrl: string) {
    this._apiBaseUrl = apiBaseUrl;
  }

  public get apiBaseUrl (): string {
    return this._apiBaseUrl;
  }

  public get apiBaseUrlWithApiPath (): string {
    return `${this._apiBaseUrl}${this._apiPath}`;
  }

  public get loggedOut (): string {
    return this._loggedOut;
  }

  public get faqUrl (): string {
    return `${this._apiBaseUrl}/faq`;
  }

  public get fileUploadUrl (): string {
    return `${this.apiBaseUrlWithApiPath}/upload`;
  }

  public get fileUploadFraudDocumentsPartnerUrlG2 (): string {
    return `${this._apiBaseUrl}/api/account_opening/upload_fraud_documents_partner`;
  }

  public get contractFileDownload (): string {
    return `${this.apiBaseUrlWithApiPath}/contract`;
  }

  public get riskBasedPricingNoticeFileDownload (): string {
    return `${this.apiBaseUrlWithApiPath}/riskBasedPricingNotice`;
  }

  public get noaaFileDownload (): string {
    return `${this.apiBaseUrlWithApiPath}/noaa`;
  }

  public get creditScoreDisclosureDownload (): string {
    return `${this.apiBaseUrlWithApiPath}/creditScoreDisclosure`;
  }

  public get securityFreezeDownload (): string {
    return `${this.apiBaseUrlWithApiPath}/securityFreeze`;
  }

  public get graphQLUrl (): string {
    return `${this.apiBaseUrlWithApiPath}/graphql`;
  }

  public get devToolsGraphQLUrl (): string {
    return `${this.apiBaseUrlWithApiPath}/graphql_dev_tools`;
  }

  public get refreshTokenUrl (): string {
    return `${this.apiBaseUrlWithApiPath}/refreshToken`;
  }

  public get loginUrl (): string {
    return `${this._apiBaseUrl}/login`;
  }

  public get logoutUrl (): string {
    return `${this._apiBaseUrl}/logout`;
  }

  public get applicationNoticeUrlG2 (): string {
    return `${this._apiBaseUrl}/account_opening/apply/notice_content`;
  }

  public get applyUrl (): string {
    return `${this._apiBaseUrl}/apply`;
  }

  public get bootstrapSession (): string {
    return `${this._apiBaseUrl}/bootstrap_session`;
  }

  public get beginNewAppUrl (): string {
    return `${this.applyUrl}?start=1`;
  }

  public get cardUrl (): string {
    return `${this._apiBaseUrl}/card`;
  }

  public get accountUrl (): string {
    return `${this._apiBaseUrl}/account`;
  }

  public get pointOfSaleUrl (): string {
    return `${this._apiBaseUrl}/point_of_sale`;
  }

  public getCardDashboardUrl: (uuid: string) => string = uuid => `${this.cardUrl}/${uuid}/dashboard`;

  public getCardPaymentsUrl: (uuid: string) => string = uuid => `${this.cardUrl}/${uuid}/payments`;

  public getCardMakePaymentUrl: (uuid: string) => string = uuid => `${this.getCardPaymentsUrl(uuid)}/make-payment`;

  public getCardAutopayUrl: (uuid: string) => string = uuid => `${this.cardUrl}/${uuid}/onboard/autopay`;

  public getCardActivationUrl: (uuid: string) => string = uuid => `${this.cardUrl}/${uuid}/activate`;

  public getUpdateFinancialInformationUrl: (uuid: string) => string = uuid => `${this.accountUrl}/${uuid}/incomeAndHousing`;

  public getApplicationUrl: (id: string, page?: string) => string = (id, page) => {
    const base: string = `${this.applyUrl}/${id}`;
    if (!page) { return base; }

    return `${base}?navigate=${page}`;
  }

  public getBasicUrl: (slug: string) => string = slug => `${this._apiBaseUrl}/${slug}`.replace(/([^:]\/)\/+/g, '$1');

  public getMerchantDeclineUrl: (id: string) => string = id => `${this.pointOfSaleUrl}/declined/${id}`;
}

export default UrlRouter;
