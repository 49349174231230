import React from 'react';

import { NA } from '../../../../../constants';
import { BannerNotificationsProps } from '../types';

import { LoanBanner } from '.';

type SoldOffProps = Pick<BannerNotificationsProps, 'debtSale'>;
export const SoldOffBanner: React.FC<SoldOffProps> = ({ debtSale }) => (
  <LoanBanner>
    <span>
      Your account has been sold to <strong>{debtSale?.debtBuyer || NA}</strong>.
      Please contact them at <strong>{debtSale?.debtBuyerPhone || NA}</strong>.
      In case you have already sent payment to {AvantConfig.TenantConfig.companyName}, the payment will be forwarded to{' '}
      <strong>{debtSale?.debtBuyer || NA}</strong>.
    </span>
  </LoanBanner>
);
