import { NavButton } from '@amount/frontend-components';
import { useMutation } from '@apollo/react-hooks';
import * as React from 'react';

import { devToolsEnabled, IProductUuid } from '../../common';
import { devToolsClient } from '../../devToolsApolloClient';
import { TakeoverLoader } from '../TakeoverLoader';
import CompleteCurrentVerActionMutation, {
  CompleteCurrentVerAction as CompleteCurrentVerActionData,
  CompleteCurrentVerActionInput,
} from './queries/CompleteCurrentVerAction.graphql';

type CurrentActionProps = IProductUuid & { outcome: CurrentActionOutcomes }

export enum CurrentActionOutcomes {
  Fail = 'fail',
  Pass = 'pass',
}

export const CurrentAction: React.FC<CurrentActionProps> = ({ uuid, outcome }) => {
  const buttonText = `${outcome === CurrentActionOutcomes.Pass ? 'Pass' : 'Fail'} Current Action`

  if (!devToolsEnabled()) {
    return null;
  }

  const [completeCurrentVerAction, { loading }] = useMutation<CompleteCurrentVerActionData, CompleteCurrentVerActionInput>(
    CompleteCurrentVerActionMutation,
    {
      variables: { input: { customerApplicationUUID: uuid, outcome } },
      client: devToolsClient,
    },
  );

  const onClick = async () => {
    const response = await completeCurrentVerAction();

    if (response.data?.completeCurrentVerAction?.success) {
      window.location.reload();
    } else {
      alert('Failed to complete current ver action, see backend error log');
    }
  };

  return (
    <>
      {
        loading && (
          <TakeoverLoader />
        )
      }
      <NavButton onClick={onClick}>
        {buttonText}
      </NavButton>
    </>
  );
};
