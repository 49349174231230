interface IBankAccount {
  __typename: 'BankAccount';
}

interface IPointOfSaleInformation {
  __typename: 'PointOfSaleInformation';
}

interface IPaymentPreferences {
  pointOfSaleInformation: IPointOfSaleInformation | null;
  bankAccount: IBankAccount | null;
}

export const showsPaymentPreferences: (data: IPaymentPreferences) => boolean = ({ pointOfSaleInformation, bankAccount }) =>
  [!!pointOfSaleInformation, !bankAccount]
  .every((conditionNeeded: boolean) => conditionNeeded);
