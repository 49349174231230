import * as React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  NavButton,
  FormRow,
  Legend,
  FormGroup,
  HeaderIcon,
  Spinner,
  SelectOption,
  SelectInput,
  Input,
 } from '@amount/frontend-components';

import { devToolsClient } from '../../devToolsApolloClient';
import { TakeoverLoader } from '../TakeoverLoader';
import { devToolsEnabled, IProductUuid } from '../../common';
import CustomerReliantMitigationsQuery, {
  CustomerReliantMitigations,
  CustomerReliantMitigationsInput } from './queries/CustomerReliantMitigations.graphql'
import TriggerVerificationMitigationMutation,
{ TriggerVerificationMitigation,
  TriggerVerificationMitigationInput } from './queries/TriggerVerificationMitigation.graphql';
import GetVerificationInformationQuery, {
  GetVerificationInformation,
  GetVerificationInformationInput
} from 'src/modules/queries/GetVerificationInformation.graphql';


export const TriggerMitigation: React.FC<IProductUuid> = ({ uuid }) => {
  if (!devToolsEnabled()) {
    return null;
  }

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [mitigation, setMitigation] = React.useState("")

  const {data: verData} = useQuery<GetVerificationInformation,GetVerificationInformationInput>(
    GetVerificationInformationQuery,
    {
      variables: { uuid },
      fetchPolicy: 'cache-first',
    }
  )

  const {loading: mitigationsLoading,
    data: customerReliantMitigations,
  } = useQuery<CustomerReliantMitigations, CustomerReliantMitigationsInput>(
      CustomerReliantMitigationsQuery,
      {
        variables: {uuid},
        fetchPolicy: 'network-only',
        client: devToolsClient,
      }
    )

   const [triggerMitigation, { loading }] = useMutation<TriggerVerificationMitigation, TriggerVerificationMitigationInput>(
     TriggerVerificationMitigationMutation,
     {
       variables: { input: { customerApplicationUUID: uuid, mitigation }},
       client: devToolsClient,
        onCompleted: () => { window.location.reload(true) }
     },
  );


  if (!verData?.me?.getApplication?.isG2) {
    return null;
  }

  const toggleMenu = () => { setIsMenuOpen(!isMenuOpen) }

  const selectMitigation: React.ChangeEventHandler<HTMLSelectElement> = ( { currentTarget: { value } }) => {
    setMitigation(value)
  }
  const onSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    void triggerMitigation()
  }

  const mitigations = customerReliantMitigations?.customerReliantMitigations || []

  return (
    <>
      {
        loading && <TakeoverLoader />
      }

      <NavButton onClick={toggleMenu}>
        Trigger Mitigation
        <HeaderIcon open={isMenuOpen} />
      </NavButton>

      {isMenuOpen && (
        <form onSubmit={onSubmit}>
          <FormRow >
            <FormGroup>
               <Legend>Select a mitigation to fire</Legend>
               <p>
                 Note: This works best if triggered before completing any
                 actions
               </p>
                {
                 mitigationsLoading ? (<Spinner small={true} />) : (
                     <SelectInput value={mitigation} onChange={selectMitigation} placeholder="customer">
                       {
                         mitigations.map( item => (
                           <SelectOption key={item}>{item}</SelectOption>
                         ))
                       }
                      </SelectInput>
                    )
                }
            </FormGroup>
          </FormRow>
          <Input type="submit" value="Fire" />
        </form>
      )}
    </>
  );
};
