import React from "react";

import { ApplicationBaseRoutes } from "src/routes";
import { HeaderLinkProps } from "../constants";
import { RouterLink } from "src/_shared/ThemeTwo/Link/router-link.component";

export const Home: React.FC<HeaderLinkProps> = ({ variant, linkLabel, onClickCallback }) => (
  <RouterLink.siteHeader
    variant={variant}
    to={ApplicationBaseRoutes.baseRoute}
    onClick={onClickCallback}
  >
    {linkLabel}
  </RouterLink.siteHeader>
);
