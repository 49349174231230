import React from 'react';
import { SessionTimeoutProps } from '../../../components/Common/Session/types'
import SessionManager from '../../../util/sessionManager';
import { FormError } from '../../../components/Common/Form';
import {
  Modal,
  Button_t2_,
  Text,
  Box
} from '@amount/frontend-components';

const SessionTimeoutThemeTwo: React.FunctionComponent<SessionTimeoutProps> = props => {
  const sessionTimeoutConfig = AvantConfig.TenantConfig.session.timeout;

  return (
    <Modal
      isShown={props.showWarning}
      ariaModal={true}
      ariaLabelledby='session-expire-modal'
      cardWidth={{ max: 'narrow' }}
      heading={{
        text: sessionTimeoutConfig.headingText,
        styles: {
          align: 'center',
          family: 'optimist_light',
          lineHeight: '1.2',
          margin: { horizontal: 'auto' },
          size: 'heroLarge',
        }
      }}
      handleModalClose={props.refreshToken}
    >
      <Text lineHeight='1.5' align='center' margin={{top: '3x', bottom: '4x'}}>
        {sessionTimeoutConfig.bodyText}
      </Text>
      <Box justifyContent='center' width='100%'>
        <Button_t2_
          variant='primary'
          onClick={props.refreshToken}
          disabled={props.requesting}
          flexBasis={{ base: '100%', medium: '55%' }}
        >
          {sessionTimeoutConfig.stayLoggedInButtonText}
        </Button_t2_>
      </Box>
      <Box justifyContent='center' margin={{top: '3x'}}>
        <a href={SessionManager.urlRouter.logoutUrl} onClick={props.logout}>
          {sessionTimeoutConfig.logOutNowText}
        </a>
      </Box>
      {props.isError && (
        <Box justifyContent='center'>
          <FormError>{sessionTimeoutConfig.errorOccuredText}</FormError>
        </Box>
      )}
    </Modal>
  )
}

export default SessionTimeoutThemeTwo;
