import React from 'react';
import { Link } from 'react-router-dom';
import { BannerLink } from '@amount/frontend-components';

import {
  AccountRoutes,
  ApplicationBaseRoutes
} from '../../../../../routes';
import { LinkAndTextWrapper } from '../../common';

import { LoanBanner } from '.';

export const BadBankBanner: React.FC = () => (
  <LoanBanner style='alert'>
    <LinkAndTextWrapper>
      <span>
        Your bank account information appears invalid. Update your information and schedule a payment to get current on your loan.
      </span>
      <BannerLink bannerStyle='alert'>
        <Link
          data-event='bannerLoanUpdateBankAccountInformation'
          to={`${ApplicationBaseRoutes.account}${AccountRoutes.bank}`}
        >
          Update your information
        </Link>
      </BannerLink>
    </LinkAndTextWrapper>
  </LoanBanner>
);
