import { useLocation } from 'react-router-dom';
import { paymentMethodsPath } from '../../../../../../../routes';

interface UsePaymentMethodsLinkProps {
  isPaymentMethodsPage: boolean;
  paymentMethodsPath: string;
}

export const usePaymentMethodsLink = (): UsePaymentMethodsLinkProps => {
  const location = useLocation();
  const { pathname } = location;

  const isPaymentMethodsPage = pathname === paymentMethodsPath;

  return({ isPaymentMethodsPage, paymentMethodsPath })
}
