import loadableWrapper from 'src/Loadable';

export const LoadableLoanDash = loadableWrapper(
  async () => {
    const { default: component } = await import(/* webpackChunkName: "LoanDash" */ 'src/modules/LoanDash');

    return component;
  }
);

export const LoadableCardDash = loadableWrapper(
  async () => {
    const { default: component } = await import(/* webpackChunkName: "LiftedCreditCard" */ './CreditCard/index');

    return component;
  }
);

export const LoadableAccountDash = loadableWrapper(
  async () => {
    const { LiftedAccountDash: component } = await import(/* webpackChunkName: "AccountDash" */ 'src/modules/AccountDash');

    return component;
  }
);
