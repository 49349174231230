import React from 'react';
import {
  DecorativeElement,
  InnerContainer,
  SplashHeadline,
  SplashIcon,
  VerContainer,
} from '@amount/frontend-components';

import { ErrorLayout } from './ErrorLayout';

const ThemeOneNotFound: React.FunctionComponent = () => (
  <VerContainer>
    <InnerContainer>
      <ErrorLayout>
        {AvantConfig.TenantConfig.errorMessages.notFound.showImage && (
          <SplashIcon>
            <DecorativeElement image='404' alt='Error Image' />
          </SplashIcon>
        )}
        <SplashHeadline icon='circle-alert' scale='large' as='h1'>
          {AvantConfig.TenantConfig.errorMessages.notFound.headline}
        </SplashHeadline>
        {/* XXX: FIX! */}
        {Object.values(AvantConfig.TenantConfig.errorMessages.notFound.text)
          .map((text, i) => (
            <p key={i}>
              {text}
            </p>
          )
        )}
      </ErrorLayout>
    </InnerContainer>
  </VerContainer>
);

export default ThemeOneNotFound;
