import React from 'react';
import { RouteComponentProps } from 'react-router';

import { GetCustomerHomeInformation } from '../queries/GetCustomerHomeInformation.graphql';
import Session from '../../components/Common/Session';
import ServerError from '../../components/Common/Error/ServerError';
import SessionManager from '../../util/sessionManager';
import { LoadableVerDash } from '../PreloadableDashboards';
import { LoadableAccountDash, LoadableCardDash, LoadableLoanDash } from 'src/g1/servicing/modules/PreloadableModules';
import CustomerHome from '../../components/CustomerHomeDash';
import { Content } from '../../components/Common/SkipLink';
import { analyticsHelper } from '../../analytics';
import { withWarningBanner } from '../helpers/withWarningBanner';
import { DashWrapper } from '../../components/Common/DashWrapper';
import { ApplyShutdownBanner } from 'src/components/Common/BannerNotifications/ApplyShutdownBanner';

interface ICustomerHomeProps extends RouteComponentProps {
  data?: GetCustomerHomeInformation;
}

class PresentationalCustomerHomeDash extends React.Component<ICustomerHomeProps> {
  public componentDidMount () {
    analyticsHelper.view(AvantConfig.TenantConfig.analytics?.verification?.customerHome?.pageView);
    this.checkEligibility(this.props);
    // Pre-loads Account, Servicing, and Verifications once the page has finished loading
    window.addEventListener('load', this.preloadDashboards);
  }

  public componentWillUnmount () {
    window.removeEventListener('load', this.preloadDashboards);
  }

  public componentWillUpdate (nextProps: ICustomerHomeProps) {
    this.checkEligibility(nextProps);
  }

  public render () {
    const { data } = this.props;

    if (!data || !data.me) {
      return <ServerError />;
    }

    return (
      <Session {...this.props} me={data.me}>
        <DashWrapper>
          {
            AvantConfig.TenantConfig.customerHomeBannerMessage &&
            <ApplyShutdownBanner message={AvantConfig.TenantConfig.customerHomeBannerMessage} />
          }
          <Content>
            <CustomerHome />
          </Content>
        </DashWrapper>
      </Session>
    );
  }

  private readonly checkEligibility: (props: ICustomerHomeProps) => void = props => {
    this.ensureLoggedIn(props);
  }

  private readonly ensureLoggedIn: (props: ICustomerHomeProps) => void = ({ data }) => {
    if (data && !data.me) {
      this.props.history.replace(SessionManager.urlRouter.loggedOut);
    }
  }

  private readonly preloadDashboards: () => void = () => {
    LoadableAccountDash.preload();
    LoadableVerDash.preload();
    LoadableLoanDash.preload();
    LoadableCardDash.preload();
  }
}

export const CustomerHomeDash = withWarningBanner(PresentationalCustomerHomeDash, 'home');
