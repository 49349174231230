import React from 'react';
import Styled from 'styled-components';
import {
  Logo,
  media,
  RegionsLogo,
} from '@amount/frontend-components';
import { Link } from 'react-router-dom';

import { FragmentSelectionOnLoan } from '../queries/GetCustomerHomeInformation.graphql';
import { ApplicationBaseRoutes } from '../../../routes';
import { StyledViewArrowSVG, ViewButtonWrapper } from '../common';
import { ConditionalRenderWrapper } from '../../Common/CommonStyles';

type LoanCtaProps = Pick<FragmentSelectionOnLoan, 'isRegions' | 'uuid' | 'isFunded' | 'servicingAccountUuid'>;

const LogoWrapper = Styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  order: -1;
  height: 1.875em;
  width: 7.625em;

  svg {
    height: auto;
    width: 100%;
  }

  ${media.small`
    margin: 0;
    order: 2;
  `}
`;

export const LoanCta: React.FunctionComponent<LoanCtaProps> = ({ isRegions, isFunded, uuid }) =>  (
  <>
    {(AvantConfig.TenantConfig.hasLoanServicing && !isRegions && isFunded) && (
      <ConditionalRenderWrapper hiddenOnMobile={true}>
        <Link data-event='viewLoan' tabIndex={-1} to={`${ApplicationBaseRoutes.loanServicing}/${uuid}`}>
          <ViewButtonWrapper buttonStyle='secondary' inline={true} size='tiny'>
            <strong>View Loan</strong>
            <StyledViewArrowSVG icon='forward-arrow' iconStyle='link' />
          </ViewButtonWrapper>
        </Link>
      </ConditionalRenderWrapper>
    )}
    {isRegions && (
      <LogoWrapper>
        <RegionsLogo />
      </LogoWrapper>
    )}
    {!AvantConfig.TenantConfig.hasLoanServicing && !isRegions && (
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
    )}
  </>
);
