export default {
  __schema: {
    types: [
      { kind: 'INTERFACE', name: 'ProductInterface', possibleTypes: [] },
      { kind: 'INTERFACE', name: 'WorkflowInput', possibleTypes: [] },
      {
        kind: 'INTERFACE',
        name: 'ApplicationCommunicationInterface',
        possibleTypes: [],
      },
    ],
  },
};
