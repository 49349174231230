import React from 'react';

import { LoanBanner } from '../';
import { refunded } from '../../bannerStyles';

export const RefundedBanner: React.FC = () => (
  <LoanBanner style={refunded}>
    <span>This loan has been refunded in full.</span>
  </LoanBanner>
);
