import { format } from 'date-fns';
import { CustomDate, formatDateWithFullMonthName } from '@Util/date';

export const formatDateWithHourMinuteTOD: (date: CustomDate) => string = date => format(date, 'h:mma');

export const timeWithTimeZoneShortName = (expiration_time: string) => {
  const formatUseBy = expiration_time.replace(/\b[-]{1}/g, '/');
  const useBy = new Date(formatUseBy);

  const timeZone = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
  }).format(useBy);

  const time = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(useBy)
    .toLowerCase();

  const abbreviatedTimeZone = timeZone.substr(timeZone.indexOf(',') +2)
  // We need the timezone in CT/PT format without Daylight or Standard
  // except for Hawaii as they do not observe daylight savings
  const formatTimeZone = ['HST'].includes(abbreviatedTimeZone) ? abbreviatedTimeZone : abbreviatedTimeZone.replace(/(S|D)T$/, 'T');

  return `${time} ${formatTimeZone}`;
};

export const formatDateWithTimeZone: (date: string) => string = date => {
  const monthDayYear = formatDateWithFullMonthName(date);

  return `${monthDayYear} at ${timeWithTimeZoneShortName(date)}`;
};
