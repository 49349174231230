import React from 'react';

import { useLocation } from 'react-router-dom';

import { personalInformationPath } from '../../../../../../../routes';
import { LoanBanner } from '.';
import { BannerBaseProps } from '../index';
import { BannerLinkAndTextWrapper } from '../../common';
import { BannerLink } from '../../../../../../../_shared/ThemeTwo/Link/BannerLink';
import { formatString } from '@Util/string';

const { bannerMessages: { badPersonalInformation }, bannerLinks: { personalInformation: linkCopy } }
  = AvantConfig.TenantConfig.accountManagementDash;

export const BadPersonalInformationBanner: React.FC<BannerBaseProps> = ({variant}) => {
  const location = useLocation();
  const { pathname } = location;

  const isPersonalInformationPage = pathname === personalInformationPath;

  const personalInfoText = isPersonalInformationPage ? linkCopy.toLowerCase()
  : <BannerLink event="personalInformation" to={personalInformationPath}>{linkCopy}</BannerLink>;

  return (
    <LoanBanner variant={variant}>
      <BannerLinkAndTextWrapper>
        {formatString(badPersonalInformation, undefined, personalInfoText)}
      </BannerLinkAndTextWrapper>
    </LoanBanner>
  );
};
