import React from 'react';
import Styled from 'styled-components';
import { Headline } from '@amount/frontend-components';
import { creditCardsFromProducts, isCreditCardCustomer, loansFromProducts } from '@amount/frontend-product-utilities';

import { NA } from '../../../../constants';
import { AccountRoutes, ApplicationBaseRoutes, createPathWithUUID } from '../../../../routes';
import ServerError from '../../Error/ServerError';
import SettingsTable, { IHTableRow } from '../..//SettingsTable';
import { ContentPaddingContainer, SettingsTableLayout } from '../../CommonStyles';
import { IAccountSettingsProps } from '../common';
import { analyticsHelper } from '../../../../analytics';
import {
  FragmentSelectionOnCreditCardAccount,
  FragmentSelectionOnLoan,
  SelectionOnBankAccount,
  SelectionOnDebitCard,
  SelectionOnDebitCardConnection,
  SelectionOnProductConnection
} from '../queries/GetAccountSettings.graphql';

const InfoContainer = Styled.div`
  flex-direction: column;
`;

const userEmail: (email: string | null, obfuscatedEmail: string | null) => string | null
 = (email, obfuscatedEmail) =>
  AvantConfig.TenantConfig.tasks.confirmEmail.obfuscateEmail ? obfuscatedEmail : email;

const supportPhoneNumber: (isCreditCard: boolean, isPointOfSale: boolean) => string
  = (isCreditCard, isPointOfSale) => {
    const supportPhone = AvantConfig.TenantConfig.support?.phoneNumber?.readable ?? '';
    if (isCreditCard) {
      const creditCardPhone = AvantConfig.TenantConfig.support.servicingContactInfo?.creditCard?.phoneNumber?.readable;

      return creditCardPhone || supportPhone;
    }
    if (isPointOfSale) {
      const pointOfSalePhone = AvantConfig.TenantConfig.support.servicingContactInfo?.pointOfSale?.phoneNumber?.readable;

      return pointOfSalePhone || supportPhone;
    }
    const loanPhone = AvantConfig.TenantConfig.support.servicingContactInfo?.loan?.phoneNumber?.readable;

    return loanPhone || supportPhone;
};

const supportEmailAddress: (isCreditCard: boolean, isPointOfSale: boolean) => string
  = (isCreditCard, isPointOfSale) => {
    const supportEmail = AvantConfig.TenantConfig.support.email;
    if (isCreditCard) {
      const creditCardEmail = AvantConfig.TenantConfig.support.servicingContactInfo?.creditCard?.email;

      return creditCardEmail || supportEmail;
    }
    if (isPointOfSale) {
      const pointOfSaleEmail = AvantConfig.TenantConfig.support.servicingContactInfo?.pointOfSale?.email;

      return pointOfSaleEmail || supportEmail;
    }
    const loanEmail = AvantConfig.TenantConfig.support.servicingContactInfo?.loan?.email;

    return loanEmail || supportEmail;
};

const isPointOfSaleOnlyCustomer: (products: SelectionOnProductConnection) => boolean = products => {
  const loans = loansFromProducts<FragmentSelectionOnLoan>(products);
  const installmentLoans = loans.filter(loan => loan.isActive && !loan.pointOfSaleInformation);
  const pointOfSaleLoans = loans.filter(loan => loan.isActive && !!loan.pointOfSaleInformation);
  const creditCards = creditCardsFromProducts<FragmentSelectionOnCreditCardAccount>(products)
  .filter(card => card.isActive);

  return !!pointOfSaleLoans.length && !installmentLoans.length && !creditCards.length;
};

interface IPersonalInformationProps {
  name: string | null;
  email: string | null;
  address: string | null;
  eligibleToUpdateIncomeAndHousing: boolean;
  supportEmail: string;
  supportPhone: string;
  products: SelectionOnProductConnection;
}

const PersonalInformationSection: React.FC<IPersonalInformationProps> = data => {
  const { name, address, email, supportEmail, supportPhone, products, eligibleToUpdateIncomeAndHousing } = data;
  const supportInfo = { supportPhone, supportEmail };

  const rows: IHTableRow[] = [
    {
      key: 'Name',
      value: name
    },
    {
      key: 'Address',
      value: address || NA,
      isHtml: !!address
    },
    {
      key: 'Email',
      value: email
    }
  ];

  if (!isPointOfSaleOnlyCustomer(products) && eligibleToUpdateIncomeAndHousing) {
    const linkUrl = `${ApplicationBaseRoutes.account}${AccountRoutes.incomeAndHousing}`;
    const incomeAndHousingLink = {
      text: 'Change',
      id: 'income_and_housing_link',
      labelledBy: 'income_and_housing_link income_and_housing',
      dataEvent: 'incomeAndHousing',
      linkUrl
    };
    rows.push({
      key: 'Income Information',
      value: null,
      link: incomeAndHousingLink
    });
  }

  return (
    <SettingsTable
      title='Personal Information'
      titleId='personal-information'
      rows={rows}
      supportInfo={supportInfo}
    />
  );
};

interface IPaymentPreferenceSectionProps {
  pathname: string;
  bankAccount: SelectionOnBankAccount | null;
  canManageDebitCard: boolean;
  canUpdateBankAccount: boolean;
  debitCards: SelectionOnDebitCardConnection | null;
  productUUID?: string;
}

const DebitCardRows: (
  debitCards: SelectionOnDebitCardConnection | null,
  canManageDebitCard: boolean,
  pathname: string
) => IHTableRow[] = (debitCards, canManageDebitCard, pathname) => {
  const hasDebitCards = debitCards && debitCards?.edges.length > 0;
  if (!debitCards || debitCards.edges.length === 0 && !canManageDebitCard) { return [] }

  const manageDebitCardLink = {
    linkUrl: `${pathname}${AccountRoutes.manageDebitCard}`,
    id: 'manage-debit-card-link',
    labelledBy: 'manage-debit-card-link manage-debit-card',
    text: hasDebitCards ? 'Change' : 'Add',
    dataEvent: 'cardManageDebitCard',
  };

  const debitCardLink = canManageDebitCard ? manageDebitCardLink : undefined;

  if (!hasDebitCards && canManageDebitCard) {
    return [{
      key: 'add new debit card',
      label: 'Debit Card',
      value: null,
      link: debitCardLink,
    }]
  }

  const cards = debitCards.edges.reduce<SelectionOnDebitCard[]>(
    (acc, curr) => {
      const { node: { cardBrand, cardNumberLast4, id, __typename} } = curr;
      acc.push({ __typename, cardBrand, cardNumberLast4, id });

      return acc;
    },
    []
  );

  return cards.map((debitCard, index) => (
    {
      key: `Debit Card ${index}`,
      label: 'Debit Card',
      value: `${debitCard.cardBrand || ''} Debit *${debitCard.cardNumberLast4}`,
      link: debitCardLink,
    }
  ));

};

const PaymentPreferenceSection: React.FC<IPaymentPreferenceSectionProps> = data => {
  const { pathname, canUpdateBankAccount, bankAccount, debitCards, productUUID: uuid } = data;
  const canManageDebitCard = data.canManageDebitCard || false;

  const updateBankAccountUrl = uuid ?
    createPathWithUUID({ base: pathname, uuid, route: AccountRoutes.paymentPreference }) :
    `${pathname}${AccountRoutes.bank}`;

  const updateBankAccountLink = {
    linkUrl: updateBankAccountUrl,
    id: 'change-bank-account-link',
    labelledBy: 'change-bank-account-link change-bank-account',
    text: 'Change',
    dataEvent: 'cardUpdateBankAccount',
  };

  const bankAccountLink = AvantConfig.TenantConfig.hasLoanServicing && canUpdateBankAccount
    ? updateBankAccountLink
    : undefined;

  const bankAccountRow = {
    key: 'Bank Account',
    value: bankAccount ? `${bankAccount.bankName || ''} *${bankAccount.accountNumberLast4}` : NA,
    link: bankAccountLink,
  };

  const debitCardRows = DebitCardRows(debitCards, canManageDebitCard, pathname);

  const rows = [bankAccountRow, ...debitCardRows];

  return (
    <SettingsTable
      title='Payment Methods'
      titleId='payment-methods'
      rows={rows}
    />
  );
};

const firstActivePointOfSaleUUID: (products: SelectionOnProductConnection) => string | undefined = products => {
  const loans = loansFromProducts<FragmentSelectionOnLoan>(products);
  const pointOfSaleLoans = loans.filter(loan => loan.isActive && !!loan.pointOfSaleInformation);

  return pointOfSaleLoans[0]?.uuid;
};

export class AccountSettings extends React.Component<IAccountSettingsProps> {
  public componentDidMount: () => void = () =>
    analyticsHelper.view(AvantConfig.TenantConfig.analytics?.verification?.accountSettings?.pageView)

  public render (): JSX.Element {
    const { data, location: { pathname } } = this.props;

    if (!data || !data.me) {
      return <ServerError />;
    }
    const { name, address, obfuscatedEmail, email: emailAddress, bankAccount, debitCards } = data.me;
    const { canUpdateBankAccount, products, eligibleToUpdateIncomeAndHousing, canManageDebitCard } = data.me;
    const isPointOfSale = isPointOfSaleOnlyCustomer(products);
    const isCreditCard = isCreditCardCustomer(products);
    const productUUID = isPointOfSale ? firstActivePointOfSaleUUID(products) : undefined;
    const email = userEmail(emailAddress, obfuscatedEmail);
    const supportEmail = supportEmailAddress(isCreditCard, isPointOfSale);
    const supportPhone = supportPhoneNumber(isCreditCard, isPointOfSale);

    return (
      <InfoContainer>
        <SettingsTableLayout as='div'>
          <ContentPaddingContainer>
            <Headline scale='large' as='h1'>Account Settings</Headline>
            {PersonalInformationSection({
              name,
              email,
              supportEmail,
              supportPhone,
              address: address && address.formattedAddress,
              eligibleToUpdateIncomeAndHousing,
              products
            })}
            {PaymentPreferenceSection(
              { pathname, bankAccount, canUpdateBankAccount, canManageDebitCard, debitCards, productUUID }
            )}
          </ContentPaddingContainer>
        </SettingsTableLayout>
      </InfoContainer>
    );
  }
}
