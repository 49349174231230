import { FragmentSelectionOnLoan, SelectionOnBankAccount, SelectionOnCustomer, SelectionOnPointOfSaleDispute,
  SelectionOnPointOfSaleInformation } from '../../../../modules/queries/GetLoanServicingInformation.graphql';

type LoanProps = Pick<
  FragmentSelectionOnLoan,
  | 'isBankruptcyDischarged'
  | 'wasRefinanced'
  | 'isSoldOff'
  | 'pastDueAmount'
  | 'debtSale'
  | 'paymentPlan'
  | 'gracePeriodEndDate'
  | 'lossMitigationPlanAcceptanceEligible'
  | 'settlementAcceptanceEligible'
  | 'lossMitigationOfferExpirationDate'
  | 'settlementOfferExpirationDate'
  | 'financialSituationReviewEligible'
  | 'financialSituationReviewV2Eligible'
  | 'uuid'
  | 'isPaidOff'
  | 'isChargedOff'
  | 'isLate'
  | 'chargeOffInformation'
  | 'payoffInformation'
  | 'hasOutstandingBalance'
  | 'isOptedIntoRcc'
  | 'pointOfSaleInformation'
  | 'isFunded'
  | 'paymentMethod'
  | 'amount'
  | 'status'
  | 'settlementInformation'
  | 'onActiveOrLockedInSettlement'
>;

type CustomerProps = Pick<SelectionOnCustomer, 'canUpdateBankAccount' | 'bankAccount'>;

export type BankAccount = SelectionOnBankAccount;
type BankAccountProps = Pick<BankAccount, 'badAccount'>;

export type PointOfSaleProps = SelectionOnPointOfSaleInformation;
export type DisputeProps = SelectionOnPointOfSaleDispute;

interface IBannersProps {
  isDismissable?: boolean;
}

export type BannerNotificationsProps = LoanProps & CustomerProps & BankAccountProps & IBannersProps;

export enum LoanBannerTypes {
  soldOff = 'soldOff',
  settled = 'settled',
  bankruptcyDischarged = 'bankruptcyDischarged',
  refinanced = 'refinanced',
  paidOff = 'paidOff',
  incompleteSettlement = 'incompleteSettlement',
  chargeOff = 'chargeOff',
  refunded = 'refunded',
  dispute = 'dispute',
  payoffQuote = 'payoffQuote',
  hasPaymentPlanAndBadBank = 'hasPaymentPlanAndBadBank',
  paymentPlan = 'paymentPlan',
  accountCredit = 'accountCredit',
  financialSituationReview = 'financialSituationReview',
  paymentPreferences = 'paymentPreferences',
  hasNoPaymentPlanAndBadBank = 'hasNoPaymentPlanAndBadBank',
  remotelyCreatedCheck = 'remotelyCreatedCheck',
  pastDute = 'pastDute',
  dashboardSetup = 'dashboardSetup'
}
