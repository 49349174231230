import { createContext, useContext, useEffect } from "react";

interface IPageContext {
  pageName: string;
  setPageName(pageName: string): void;
}

export const PageContext = createContext<IPageContext>({
  pageName: '',
  setPageName: () => null,
});

export const usePageContext = (pageName: string): void => {
  const { setPageName } = useContext(PageContext);
  useEffect(() => {
    setPageName(pageName);

    return () => undefined;
  }, []);
}
