/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { createAccountManagementPath } from 'src/routes';
import { css } from 'styled-components';
import { media, Box } from '@amount/frontend-components';

import {
  SelectionOnConsumerProduct,
  SelectionOnVirtualCardToken,
} from '../../queries/GetCustomerHomeG2.graphql';
import { ProductDetailsBox } from 'src/components/Common/Box';
import { RouterLink } from "src/_shared/ThemeTwo/Link/router-link.component";
import { ProductSummary } from 'src/g2/servicing/components/AccountManagementDash/_shared/ProductSummary';

const loanProductCardCopy = AvantConfig.TenantConfig.customerHome.loanProductCard;

export const LoanIdentifier: React.FC<{
  product: SelectionOnConsumerProduct;
  servicingAccountUUID: string;
  virtualCard: SelectionOnVirtualCardToken | null;
  isDashboardAccessible: boolean;
}> = ({ product, servicingAccountUUID, virtualCard, isDashboardAccessible }) => (
  <ProductDetailsBox inactive={true}>
    <ProductSummary
      merchantName={product.merchant_name}
      formattedAmount={product.amount.formatted}
      productId={product.id}
      accountUuid={servicingAccountUUID}
      contractSignedDate={product.contract_signed_date}
      virtual_card={virtualCard}
      headingLevel={'h2'}
    />
    {isDashboardAccessible &&
      <Box
        display='block'
        margin={{ base: { top: '3x', bottom: '3x'}, medium: { top: 'none', bottom: 'none'}}}
        childrenStyles={css`
        ${media.medium`
          && > a {
            min-width: 7em;
            width: 7em;
          }`}
        `}
      >
        <RouterLink.button
          variant="primary"
          tabIndex={-1}
          to={createAccountManagementPath(servicingAccountUUID)}
          data-event='viewLoan'
          data-testid='loan_product_view_button'
        >
          {loanProductCardCopy.buttonLabel}
        </RouterLink.button>
      </Box>
    }
  </ProductDetailsBox>
);
