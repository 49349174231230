import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import ServerError from 'src/components/Common/Error/ThemeOneServerError';
import { Loading } from 'src/components/Common/Spinner/LoadingHOC';

import { CustomerHome } from './Presentational';
import GetCustomerHomeG2Query, { GetCustomerHomeG2 } from './queries/GetCustomerHomeG2.graphql';

export const LiftedCustomerHomeDashG2: React.FC = () => {
  const { data, loading } = useQuery<GetCustomerHomeG2>(GetCustomerHomeG2Query);

  if (loading) { return <Loading loading={true} /> }
  if (!data?.identity) { return <ServerError /> }

  return (
    <CustomerHome
      servicingAccounts={data.identity.servicing_accounts}
      identity={data.identity}
    />
  );
};
