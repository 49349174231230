
import { useEffect, useState } from 'react';
import { TenantTheme } from '../../../../config/tenantTheme';
import { isMobile } from '../../../../util/browserCompatibility';
import { addDays } from 'date-fns';

export const useMarqetaJs = (
  clientAccessToken: string | undefined,
  successCopyPanCallback: Function,
  errorCopyPanCallback: Function,
  hasCardStyles?: boolean,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (clientAccessToken === undefined) { return undefined; }

    // Append Marqeta JS libary to DOM
    const script = document.createElement('script');
    script.src = 'https://widgets.marqeta.com/marqetajs/1.1.0/marqeta.min.js';
    script.async = true;
    document.body.appendChild(script);

    const dataStyles = hasCardStyles ? {
      cardNumber: {
        span: {
          color: TenantTheme.allSetPage.fontColor,
          "font-size": isMobile()
            ? TenantTheme.allSetPage.marqetaJs.card.fontSize.mobile.cardNumber
            : TenantTheme.allSetPage.marqetaJs.card.fontSize.desktop.cardNumber,
          "font-family": TenantTheme.allSetPage.marqetaJs.card.fontFamily
        }
      },
      expCvvNumber: {
        span: {
          color: TenantTheme.allSetPage.fontColor,
          "font-size": isMobile()
            ? TenantTheme.allSetPage.marqetaJs.card.fontSize.mobile.expCvvNumber
            : TenantTheme.allSetPage.marqetaJs.card.fontSize.desktop.expCvvNumber,
          "font-family": TenantTheme.allSetPage.marqetaJs.card.fontFamily
        }
      }
    } : {
      cardNumber: {
        span: {
          color: TenantTheme.allSetPage.fontColor,
          "font-size": isMobile()
            ? TenantTheme.allSetPage.marqetaJs.modal.fontSize.mobile.cardNumber
            : TenantTheme.allSetPage.marqetaJs.modal.fontSize.desktop.cardNumber,
          "font-family": TenantTheme.allSetPage.marqetaJs.modal.fontFamily,
          "font-weight": TenantTheme.allSetPage.marqetaJs.modal.fontWeight
        }
      },
      expCvvNumber: {
        span: {
          color: TenantTheme.allSetPage.fontColor,
          "font-size": isMobile()
            ? TenantTheme.allSetPage.marqetaJs.modal.fontSize.mobile.expCvvNumber
            : TenantTheme.allSetPage.marqetaJs.modal.fontSize.desktop.expCvvNumber,
          "font-family": TenantTheme.allSetPage.marqetaJs.modal.fontFamily,
          "font-weight": TenantTheme.allSetPage.marqetaJs.modal.fontWeight
        }
      }
    };

    const successCallback = () => {
      setIsLoading(false);
    };

    const errorCallback = () => {
      setHasError(true);
      setIsLoading(false);
    };

    // Proactively set timestamp as workaround to prevent copy number Marqeta toast notification
    localStorage.setItem('clipboardWarning', addDays(new Date(), 90).toString());

    script.onload = () => {
      // @ts-ignore
      marqeta.bootstrap({
        clientAccessToken,
        showPan: {
          cardPan: {
            domId: "mq-card-pan-container",
            format: true,
            styles: dataStyles.cardNumber
          },
          cardExp: {
            domId: "mq-card-exp-container",
            format: true,
            styles: dataStyles.expCvvNumber
          },
          cardCvv: {
            domId: "mq-card-cvv-container",
            styles: dataStyles.expCvvNumber
          },
          copyCardPan: {
            domId: "mq-copy-pan-container",
            mode: "transparent",
            onCopySuccess: successCopyPanCallback,
            onCopyFailure: errorCopyPanCallback
          },
        },
        callbackEvents: {
          onSuccess: successCallback,
          onFailure: errorCallback
        }
      });
    }

    return () => {
      document.body.removeChild(script);
    }
  }, [clientAccessToken]);

  return { isLoading, hasError };
};
