import { VALID_HEADER_LINKS } from "@amount/frontend-components";

import { AccountHistory, Home, LogOut, PaymentMethods, PersonalInformation, Support } from ".";

export interface HeaderLinkProps {
  externalUrl?: string;
  linkLabel: string;
  variant: VALID_HEADER_LINKS;
  onClickCallback?(): void;
}

export const enum HeaderLinkTypes {
  'Support' = 'support',
}

export const HeaderLinkMap: {
  [key in HeaderLinkTypes]: React.FC<HeaderLinkProps>;
} = {
  [HeaderLinkTypes.Support]: Support,
};

export const enum DrawerLinkTypes {
  'AccountHistory' = 'accountHistory',
  'Home' = 'home',
  'LogOut' = 'logOut',
  'PaymentMethods' = 'paymentMethods',
  'PersonalInformation' = 'personalInformation',
  'Support' = 'support',
}

export const DrawerLinkMap: {
  [key in DrawerLinkTypes]: React.FC<HeaderLinkProps>;
} = {
  [DrawerLinkTypes.AccountHistory]: AccountHistory,
  [DrawerLinkTypes.Home]: Home,
  [DrawerLinkTypes.LogOut]: LogOut,
  [DrawerLinkTypes.PaymentMethods]: PaymentMethods,
  [DrawerLinkTypes.PersonalInformation]: PersonalInformation,
  [DrawerLinkTypes.Support]: Support,
};
