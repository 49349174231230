import React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Sentry from '@sentry/browser';

import SessionManager, {
  EXPIRED_EVENT,
  UPDATE_STATUS_EVENT,
  WARNING_EVENT,
} from '../../../util/sessionManager';
import { IAdditionalUserInfo, IUserInfo } from '../../../util/error';
import {
  SelectionOnCustomer as VerMe,
  SelectionOnCustomerApplication as VerApplication
} from '../../../modules/queries/GetVerificationInformation.graphql';
import {
  SelectionOnCustomer as LoanServicingMe
} from '../../../modules/queries/GetLoanServicingInformation.graphql';
import {
  SelectionOnCustomer as CardServicingMe
} from 'src/g1/servicing/modules/CreditCard/queries/GetCardServicingInformationFallback.graphql';
import {
  SelectionOnCustomer as CustomerHomeMe
} from '../../../modules/queries/GetCustomerHomeInformation.graphql';
import {
  SelectionOnCustomer as CreditFreezeMe
} from '../../../components/CustomerHomeDash/CreditFreeze/queries/GetCreditFreezeStatus.graphql';
import SessionTimeoutThemeOne from './SessionTimeoutThemeOne';

const { TENANT } = process.env;
interface ISessionState {
  showWarning: boolean;
  error: boolean;
  requesting: boolean;
}
interface ISessionProps extends RouteComponentProps {
  theme2?: boolean;
  me?: VerMe | LoanServicingMe | CustomerHomeMe | CardServicingMe | CreditFreezeMe;
  application?: VerApplication;
  product?: LoanServicingMe['getProduct'] | CardServicingMe['getProduct'];
}

class Session extends React.Component<ISessionProps, ISessionState> {
  public state: ISessionState = {
    showWarning: false,
    error: false,
    requesting: false,
  };

  public componentWillMount () {
    SessionManager.subscribe(WARNING_EVENT, () => this.warn());
    SessionManager.subscribe(EXPIRED_EVENT, () => this.loggedOut());
    SessionManager.subscribe(UPDATE_STATUS_EVENT, () => this.refreshToken());

    this.setUser();
  }

  public componentDidUpdate (prevProps: ISessionProps) {
    if (
      prevProps.me &&
      prevProps.me.email &&
      prevProps.me.id &&
      (
        this.props.me && (
          this.props.me.email === prevProps.me.email &&
          this.props.me.id === prevProps.me.id
        )
      )
    ) {
      return;
    }

    this.setUser();
  }

  public render () {
    return (
      <>
      {
        this.props.theme2 ? <>Session Not Implemented For Theme Two</> :
        <SessionTimeoutThemeOne
          refreshToken={this.refreshToken}
          millisecondsToMinutes={this.millisecondsToMinutes}
          requesting={this.state.requesting}
          showWarning={this.state.showWarning}
          logout={this.logout}
          isError={this.state.error}
        />
      }

        {this.props.children}
      </>
    );
  }

  private readonly millisecondsToMinutes: (milliseconds: number) => number = milliseconds => {
    const MILLISECONDS_IN_SECOND: number = 1000;
    const SECONDS_IN_MINUTE: number = 60;

    return milliseconds / MILLISECONDS_IN_SECOND / SECONDS_IN_MINUTE;
  }

  private readonly refreshToken = async () => {
    if (this.state.requesting) { return; }
    this.setState({ error: false });
    const success = await SessionManager.refreshToken();
    this.setState({ showWarning: !success, error: !success });

    const sessionRefreshUrl = AvantConfig.TenantConfig.sessionRefreshUrl;
    if (sessionRefreshUrl) {
      const url = `${sessionRefreshUrl}?return_to=${encodeURIComponent(window.location.href)}`
      window.location.assign(url);
    }
  }

  private readonly warn = () => {
    this.setState({ showWarning: true });
  }

  private readonly logout = () => {
    SessionManager.logout();
  }

  private readonly loggedOut = () => {
    this.logout();

    window.location.assign(SessionManager.urlRouter.logoutUrl);
  }

  private readonly setUser = () => {
    if (!this.props.me) {
      return;
    }

    const { id, uuid } = this.props.me;

    let userInfo: IUserInfo = {
      id,
      uuid,
      partner: TENANT
    };

    const custApp = this.props.application || (this.props.product && this.props.product.customerApplication);
    const product = (this.props.application && this.props.application.product) || this.props.product;

    if (!!custApp) {
      let mergeInfo: Partial<IAdditionalUserInfo> = {
        appId: custApp.id,
        appUUID: custApp.uuid
      };

      if (product) {
        const { id: productId, __typename: productType, uuid: productUUID } = product;

        mergeInfo = {
          ...mergeInfo,
          productId,
          productType,
          productUUID
        };
      }

      userInfo = {
        ...userInfo,
        ...mergeInfo,
      };
    }

    Sentry.setUser(userInfo);
  }
}

export default Session;
