import React, { useEffect, useState } from 'react';
import { PromptProps } from 'react-router';
import { SelectionOnWorkflowOnExit } from '@amount/workflow-js';
import { Prompt } from 'react-router-dom';
import * as History from 'history';

import { ExitModal } from './ExitModal';

type CustomPromptProps = Pick<PromptProps, 'when'> & IOnExit & { navigate (path: string): void };
interface IOnExit {
  onExit: SelectionOnWorkflowOnExit | null;
}

const defaultMessage: string = 'You have unsaved changes, are you sure you want to leave?';

interface ICustomPromptState {
  showModal: boolean;
  shouldNavigate: boolean;
  confirmedNavigation: boolean;
  lastLocation: History.Location | null;
}

export const CustomPrompt: React.FC<CustomPromptProps> = ({when, onExit, navigate}) => {
  const [state, setState] = useState<ICustomPromptState>({
    showModal: false,
    shouldNavigate: false,
    confirmedNavigation: false,
    lastLocation: null
  });

  const closeModal: (navigateAfter: boolean) => void = navigateAfter =>
    setState({ showModal: false, shouldNavigate: navigateAfter, confirmedNavigation: false, lastLocation: state.lastLocation });

  const onCancel = () => closeModal(false);
  const onConfirm = () => closeModal(true);

  const checkIfDisplayPrompt = (location: History.Location): boolean => {
    // This function uses a side effect of setting state to display our custom prompt, and returns
    // false to prevent the user from navigating until that prompt is dismissed
    // returning true here allows the user to navigate as expected
    if (when && !state.confirmedNavigation) {
      setState({ showModal: true, lastLocation: location, confirmedNavigation: false, shouldNavigate: false });

      return false;
    }

    return true;
  };

  useEffect(() => {
    const {shouldNavigate, lastLocation, confirmedNavigation} = state;
    if (shouldNavigate && lastLocation) {
      setState({showModal: false, shouldNavigate: false, confirmedNavigation: true, lastLocation});
    }
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  });

  type promptMessageResult = string | ((location: History.Location) => boolean);

  const generateMessage = (onExitProvided: SelectionOnWorkflowOnExit | null): promptMessageResult => {
    // Only need a custom Prompt if we have custom data for one of the buttons, otherwise message is sufficient
    // checkIfDisplayPrompt is responsible for blocking default Prompt and showing the custom modal
    if (onExitProvided?.cancelText || onExitProvided?.confirmationText) {
      return checkIfDisplayPrompt;
    }

    // Otherwise just return either the onExit message or the default message to the prompt.
    return onExitProvided?.text || defaultMessage;
  };

  return (
    <>
      { onExit && (
        <ExitModal
          message={onExit.text}
          confirmationText={onExit.confirmationText}
          cancellationText={onExit.cancelText}
          visible={state.showModal}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
      <Prompt message={generateMessage(onExit)} when={when} />
    </>
  );
};

export default CustomPrompt;
