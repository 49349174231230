import Styled from 'styled-components';
import { media, VALID_COLOR } from '@amount/frontend-components';

export const LinkAndTextWrapper = Styled.div`
  flex-flow: wrap column;

  a {
    display: flex;
    white-space: nowrap;
    justify-content: center;

    ${media.small`
      display: inline-block;
      float: initial;
    `}
  }

  ${media.small`
    flex-flow: nowrap row;
  `}
`;

export interface IBannerStyles {
  style: VALID_COLOR;
}

export const AlertStyles: IBannerStyles = {
  style: 'alert',
};

export const PrimaryStyles: IBannerStyles = {
  style: 'primary',
};

export const DefaultStyles: IBannerStyles = {
  style: 'inactive'
};
