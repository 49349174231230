import React from 'react';

import { LoanBanner } from '../..';
import { BannerBaseProps } from '../../../index';

const { expiredVCardSplitPay } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages;

export const ExpiredSplitPayVCBanner: React.FC<BannerBaseProps> = ({ variant }) => (
  <LoanBanner variant={variant}>
    <span>{expiredVCardSplitPay}</span>
  </LoanBanner>
);
