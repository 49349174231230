import Styled from 'styled-components';
import { Box, media, VALID_COLOR } from '@amount/frontend-components';

export const BannerTextWrapper = Styled(Box)`
  align-content: center;
  display: flex;
  justify-content: start;
  text-align: left;
  line-height: 1.5;

  ${media.small`
    flex-wrap: nowrap;
  `}

  div, span {
    text-align: left;
  }
`;

export const InlineAnchor = Styled.a<{ bannerStyle: VALID_COLOR }>`
  color: ${({ theme: { banner }, bannerStyle }) => banner[bannerStyle].colorLink};

  &:hover {
    color: ${({ theme: { banner }, bannerStyle }) => banner[bannerStyle].colorLink};
  }
`;
