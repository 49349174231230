import React from 'react';
import Styled from 'styled-components';
import { Card, CardLabel } from '@amount/frontend-components';

import { SelectionOnCustomerApplication1 } from '../queries/GetCustomerHomeInformation.graphql';
import { ApplicationProductType, APPLICATION_STAGE } from '../../../constants';
import { FlexColumn, FlexRow } from '../../Common/CommonStyles';
import { CardContainer } from '../common';
import { isPointOfSaleProduct } from '../../../util';

const PRODUCT: string = 'product';
const FormattedProductTypeMap: Map<string, string> = new Map([
  [ApplicationProductType.Loan, 'loan'],
  [ApplicationProductType.CreditCard, 'credit card'],
  [ApplicationProductType.Refinance, 'refinance'],
  [ApplicationProductType.PointOfSale, AvantConfig.TenantConfig.product.loan.name],
]);

export interface IApplicationCardProps {
  application: SelectionOnCustomerApplication1;
  dismissApplication (application: SelectionOnCustomerApplication1): void;
}

export const getFormattedProductName: (
  productType: SelectionOnCustomerApplication1['productType'],
  merchantName: SelectionOnCustomerApplication1['merchantName'],
) => string = (productType, merchantName) => {
  if (!productType) { return PRODUCT; }

  const formattedProductName = FormattedProductTypeMap.get(productType);
  if (isPointOfSaleProduct(productType) && merchantName) {
    return `${formattedProductName} with ${merchantName}`;
  }

  return formattedProductName || PRODUCT;
};

export const FlexColumnIE = Styled(FlexColumn)`
  width: 100%;
`;

export const CardLabelWrapper = Styled(CardLabel)`
  line-height: normal;
  margin-bottom: 0.25em;
`;

const ColumnContainer = Styled(FlexRow)`
  align-items: flex-start;
`;

export interface ICardProps {
  onDismiss?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
}

export const ApplicationCardContainer: React.FunctionComponent<ICardProps> = ({ onDismiss, children }) => (
  <CardContainer>
    <Card padding='1.25em 1.5em' onDismiss={onDismiss}>
      <ColumnContainer>
        {children}
      </ColumnContainer>
    </Card>
  </CardContainer>
);

export interface IApplicationLink {
  text: string;
  route?: string;
  redirect?: boolean;
  onClick? (): void;
}

export interface IApplicationCardContentProps {
  cardLink: IApplicationLink | null;
  dataEvent: string;
  cardHeadline: string | null;
  currentStage: string | null;
}

export interface IBaseStageProps {
  stage: SelectionOnCustomerApplication1['stage'];
}

export const ApplicationCardText: React.FunctionComponent<IBaseStageProps> = ({
  stage,
}) => {
  switch (stage) {
    case APPLICATION_STAGE.Completed:
      return <span>There is nothing more for you to do at this time. We will contact you soon about the result of your application</span>;
    default:
      return null;
  }
};

export type ApplicationCardCtaProps = Pick<IApplicationCardContentProps, 'cardLink' | 'dataEvent'> & { inline?: boolean };
