
import * as React from 'react';
import Styled from 'styled-components';
import { Spinner } from '@amount/frontend-components';
import { transparentize } from 'polished';

const transparency: number = 0.15;
const TransparentOverlay = Styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => transparentize(transparency, theme.colorWhite)};
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TakeoverLoader: React.FC = () => (
  <TransparentOverlay>
    <Spinner />
  </TransparentOverlay>
);
