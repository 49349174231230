import React, { useContext, useEffect, useReducer, Reducer } from 'react';
import * as Sentry from '@sentry/browser';
import SessionManager, {
  EXPIRED_EVENT,
  UPDATE_STATUS_EVENT,
  WARNING_EVENT,
} from '../../../util/sessionManager';
import { IAdditionalUserInfo, IUserInfo } from '../../../util/error';
import SessionTimeoutThemeTwo from '../../../_shared/ThemeTwo/Session/SessionTimeoutThemeTwo';
import { IIdentityCustomer, IConsumerProduct } from 'src/gql';
import { AppContext } from '../../../util/AppContextWrapper/AppContext';

const { TENANT } = process.env;
type IdentitySummary = Pick<IIdentityCustomer, 'id' | 'customer_uuid' | 'email_addresses' >
type ProductSummary = Pick<IConsumerProduct, 'id' | 'product_uuid' | 'product_type'>

export interface ISessionG2Props {
  identity: IdentitySummary;
  product?: ProductSummary;
}

interface SessionState {
  requesting: boolean;
  showWarning: boolean;
  error: boolean;
}

const millisecondsToMinutes: (milliseconds: number) => number = milliseconds => {
  const MILLISECONDS_IN_SECOND: number = 1000;
  const SECONDS_IN_MINUTE: number = 60;

  return milliseconds / MILLISECONDS_IN_SECOND / SECONDS_IN_MINUTE;
}

const SessionG2: React.FC<ISessionG2Props> = React.memo(({identity, product, children}) => {
  const [state, setState] = useReducer<Reducer<SessionState, Partial<SessionState>>>(
    (state, newState) => ({...state, ...newState}),
    {requesting: false, showWarning: false, error: false}
   );

  const { sessionRefreshUrl = '' } = useContext(AppContext);

  const refreshToken = async () => {
    if (state.requesting) { return; }
    setState({ error: false });
    const success = await SessionManager.refreshToken();

    setState({
      showWarning: !success,
      error: !success
    });

    if (sessionRefreshUrl) {
      let url = sessionRefreshUrl + '?return_to=' + encodeURIComponent(window.location.href)
      window.location.href = url;
    };
  }

  const warn = () => {
    setState({ showWarning: true });
  }

  const logout = () => {
    SessionManager.logout();
  }

  const loggedOut = () => {
    logout();
    window.location.assign(SessionManager.urlRouter.logoutUrl);
  }

  const setUser = () => {
    if (!identity) {
      return;
    }

    let userInfo: IUserInfo = {
      id: identity.id,
      uuid: identity.customer_uuid,
      partner: TENANT
    };

    if (product) {
      const productInfo: Partial<IAdditionalUserInfo> = {
        productId: product.id,
        productType: product.product_type,
        productUUID: product.product_uuid
      }

      userInfo = {
        ...userInfo,
        ...productInfo,
      };
    }

    Sentry.setUser(userInfo);
  }

  useEffect(() => {
    SessionManager.subscribe(WARNING_EVENT, warn);
    SessionManager.subscribe(EXPIRED_EVENT, loggedOut);
    SessionManager.subscribe(UPDATE_STATUS_EVENT, refreshToken);
  }, []);

  useEffect(() => {
    setUser();
  }, [identity?.id, identity?.email_addresses[0]]);

  const { requesting, showWarning, error } = state;

  return (
    <>
      <SessionTimeoutThemeTwo
        refreshToken={refreshToken}
        millisecondsToMinutes={millisecondsToMinutes}
        requesting={requesting}
        showWarning={showWarning}
        logout={logout}
        isError={error}
      />
      {children}
    </>
  );
});

export { SessionG2 };
