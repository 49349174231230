import * as React from 'react';
import Styled from 'styled-components';
import { VALID_SVG_ICONS } from '@amount/frontend-components';

interface IStandaloneLinkChildrenProps {
  icon: VALID_SVG_ICONS;
}

interface IStandaloneLinkWrapperProps {
  children (props: IStandaloneLinkChildrenProps): React.ReactNode;
}

export const StandaloneLinkWrapperBase: React.FunctionComponent<IStandaloneLinkWrapperProps> = ({ children, ...props }) => (
  <div {...props}>
    {children({ icon: 'forward-arrow' })}
  </div>
);

export const StandaloneLinkWrapper = Styled(StandaloneLinkWrapperBase)`
  a {
    display: flex;
  }

  div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 0.375em;
    height: 0.75em;
    width: 0.75em;
  }
`;

export const PaddedStandaloneLinkWrapper = Styled(StandaloneLinkWrapper)`
  padding-top: 1em;
`;
