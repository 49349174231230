import * as Sentry from '@sentry/browser';

import heapHelper from './util/heapHelper';
import { AbstractAnalyticsLogger } from './util/analyticsLogger';
import { AdobeLaunch } from './util/adobeLaunch';
import { NoAnalytics } from '@Util/noAnalytics';

const initializeSentry = () => {
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

  if (!SENTRY_DSN) { return; }

  try {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: process.env.REACT_APP_VERSION || 'development',
      environment: process.env.TENANT,
    });
  } catch (error) {
    console.log('error initializing sentry');
    console.log(error);
  }
};

initializeSentry();
heapHelper.initializeHeap();

export const analyticsHelper: AbstractAnalyticsLogger =
  AvantConfig.TenantConfig.analytics?.provider === 'adobeLaunch'
    ? new AdobeLaunch()
    : new NoAnalytics();
