import React from 'react';
import {
  CollapsibleSubNavBreadcrumbs,
  CollapsibleSubNavInnerContainer,
  HeaderBreadcrumb,
  HeaderBreadcrumbIcon,
  HeaderBreadcrumbIconMobile,
  HeaderSubNav,
  SVGIcon,
  VALID_SVG_ICONS
} from '@amount/frontend-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { LocationDescriptorObject } from 'history';
import { IServicingRoute } from './types';
import { SubNavTabs } from './SubNavTabs';


export interface IBreadcrumb {
  title: string;
  href: string;
  icon?: VALID_SVG_ICONS;
}

export interface IBreadcrumbProps {
  breadcrumbs?: IBreadcrumb[];
}

export interface ISubHeaderProps extends IBreadcrumbProps {
  exitLinkPathFromProps?: string;
  exitLinkTextFromProps?: string;
  tabsConfig?: IServicingRoute[];
}

const renderExitRoute = (exitLinkPath: string | LocationDescriptorObject, exitLinkText: string) => (
  <CollapsibleSubNavBreadcrumbs>
    <Link to={exitLinkPath} data-event='subheaderExit'>
      <HeaderBreadcrumbIcon>
        <SVGIcon icon='return-arrow' />
      </HeaderBreadcrumbIcon>
      {exitLinkText}
    </Link>
  </CollapsibleSubNavBreadcrumbs>
);

const renderBreadcrumbs = (breadcrumbs: IBreadcrumb[]) => (
  <CollapsibleSubNavBreadcrumbs>
    {breadcrumbs
      .slice(0, -1)
      .map(({ href, title, icon }) => (
        <HeaderBreadcrumb key={`${title}__-__${href}`}>
          <Link to={href} data-event={`subheader${title}`}>
            {!!icon && (
              <HeaderBreadcrumbIconMobile>
                <SVGIcon icon={icon} />
              </HeaderBreadcrumbIconMobile>
            )}
            {title}
          </Link>
        </HeaderBreadcrumb>
      ))
    }
    <HeaderBreadcrumb key='__current'>
      <span>{breadcrumbs[breadcrumbs.length - 1].title}</span>
    </HeaderBreadcrumb>
  </CollapsibleSubNavBreadcrumbs>
);

const ServicingDashSubHeader: React.FC<ISubHeaderProps> = ({
  breadcrumbs, exitLinkPathFromProps, exitLinkTextFromProps, tabsConfig
}) => {
  const history = useHistory();
  const { pathname } = useLocation<{ pathname: string }>();

  const showExitLinks = !!(exitLinkTextFromProps && exitLinkPathFromProps);
  const showBreadcrumbs = !!breadcrumbs?.length;

  const showRedirectLinks = (showExitLinks || showBreadcrumbs);
  const showTabs = !showExitLinks && tabsConfig;

  const handleRouteClick: React.MouseEventHandler<HTMLButtonElement> = ({ currentTarget: { dataset: { route } } }) => {
    history.replace(route || '');
  };

  if (!showRedirectLinks && !showTabs) { return null }

  return (
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    <HeaderSubNav>
      {showRedirectLinks && (
        <CollapsibleSubNavInnerContainer>
          {showExitLinks && renderExitRoute(exitLinkPathFromProps!, exitLinkTextFromProps!)}
          {showBreadcrumbs && renderBreadcrumbs(breadcrumbs!)}
        </CollapsibleSubNavInnerContainer>
      )}
      {showTabs && (
        <SubNavTabs
          onClick={handleRouteClick}
          pathname={pathname}
          tabsConfig={tabsConfig!}
        />
      )}
    </HeaderSubNav>
    /* eslint-enabled @typescript-eslint/no-non-null-assertion */
  );
}

export default ServicingDashSubHeader;
