import React from 'react';

import { PointOfSaleProps } from '../../../types';

import { ActiveDisputeBanner } from './Active';
import { ResolvedDisputeBanner } from './Resolved';

type DisputeBannerProps = Pick<PointOfSaleProps, 'dispute' | 'adjustedLoanAmount'>;

export const DisputeBanner: React.FC<DisputeBannerProps> = props =>
  !!props.dispute?.resolution
    ? <ResolvedDisputeBanner {...props} />
    : <ActiveDisputeBanner {...props} />;
