import React from 'react';
import { Link } from 'react-router-dom';
import { BannerLink } from '@amount/frontend-components';

import { ApplicationBaseRoutes } from '../../../../../../routes';
import { formatDate } from '../../../../../../util/date';
import { formatString } from '../../../../../../util/string';
import { LinkAndTextWrapper } from '../../../common';
import { BannerNotificationsProps } from '../../types';

interface IActiveFinancialSituationReviewProps extends Pick<BannerNotificationsProps, 'uuid'> {
  expirationDate: string;
  workflowRoute: string;
  bannerText: string;
  bannerLink: string;
}

export const ActiveFinancialSituationReview: React.FC<IActiveFinancialSituationReviewProps> =
({ uuid, expirationDate, workflowRoute, bannerText, bannerLink }) => (
  <LinkAndTextWrapper>
    <span>
      {formatString(bannerText, value => <strong key={value}>{value}</strong>, expirationDate && formatDate(expirationDate))}
    </span>
    <BannerLink bannerStyle='alert'>
      <Link to={`${ApplicationBaseRoutes.loanServicing}/${uuid}${workflowRoute}`} data-event={`bannerLoanFsrActive`}>
        {bannerLink}
      </Link>
    </BannerLink>
  </LinkAndTextWrapper>
);
