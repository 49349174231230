import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { NavButton } from '@amount/frontend-components';

import { TakeoverLoader } from '../TakeoverLoader';
import { devToolsEnabled, IProductUuid } from '../../common';
import { devToolsClient } from '../../devToolsApolloClient';

import ConfirmDobMutation, {
  ConfirmDob as ConfirmDobData,
  ConfirmDobInput
} from './queries/ConfirmDob.graphql';

export const ConfirmDob: React.FC<IProductUuid> = ({ uuid }) => {
  if (!devToolsEnabled()) {
    return null;
  }
  const [confirmDob, { loading }] = useMutation<ConfirmDobData, ConfirmDobInput>(
    ConfirmDobMutation,
    {
      variables: { input: { customerApplicationUUID: uuid }},
      client: devToolsClient,
    },
  );

  const onClick = async () => {
    const response = await confirmDob();

    if (response.data?.confirmDob?.success) {
      window.location.reload();
    } else {
      alert('Failed, see backend error log');
    }
  };

  return (
    <>
      {
        loading && (
          <TakeoverLoader />
        )
      }
      <NavButton onClick={onClick}>
        Confirm Date of Birth
      </NavButton>
    </>
  );
};
