import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Logo as LogoFEC,
} from '@amount/frontend-components';

import { ApplicationBaseRoutes } from 'src/routes';
import { containerStyles } from './styled';

interface LogoProps {
  isClickable?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ isClickable }: LogoProps) => {
  return (
    <Box {...containerStyles}>
      {isClickable === undefined || isClickable ? (
        <Link
          to={ApplicationBaseRoutes.baseRoute}
          aria-label={AvantConfig.TenantConfig.logo.linkAriaLabel}
        >
          <LogoFEC data-test-id="header-logo" />
        </Link>
      ) : (
        <Box {...containerStyles} margin={{ bottom: '0.75x' }}>
          <LogoFEC data-test-id="header-logo" />
        </Box>
      )}
    </Box>
  );
};
