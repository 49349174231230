import { Headline } from '@amount/frontend-components';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IUUIDRouteProps } from '../../../../constants';
import { devToolsEnabled } from '../../common';
import { SectionWrapper } from '../common';
import { ApproveProduct } from './ApproveProduct';
import { CompleteCurrentVerStage } from './CompleteCurrentVerStage';
import { ConfirmBankAccount } from './ConfirmBankAccount';
import { ConfirmDob } from './ConfirmDob';
import { ConfirmEmail } from './ConfirmEmail';
import { ConfirmSsn } from './ConfirmSsn';
import { CurrentAction, CurrentActionOutcomes } from './CurrentAction';
import { EmailConfirmationLink } from './EmailConfirmationLink';
import { TriggerMitigation } from './TriggerMitigation';

export const VerTools: React.FC<RouteComponentProps<IUUIDRouteProps>> = ({ match }) => {
  if (!devToolsEnabled()) {
    return null;
  }

  return (
    <SectionWrapper>
      <Headline scale='small'>Verifications</Headline>
      <ApproveProduct uuid={match.params.uuid} />
      <CompleteCurrentVerStage uuid={match.params.uuid} />
      <ConfirmSsn uuid={match.params.uuid} />
      <ConfirmDob uuid={match.params.uuid} />
      <ConfirmBankAccount uuid={match.params.uuid} />
      <ConfirmEmail uuid={match.params.uuid} />
      <EmailConfirmationLink uuid={match.params.uuid} />
      <CurrentAction uuid={match.params.uuid} outcome={CurrentActionOutcomes.Fail} />
      <CurrentAction uuid={match.params.uuid} outcome={CurrentActionOutcomes.Pass} />
      <TriggerMitigation uuid={match.params.uuid} />
    </SectionWrapper>
  );
};
