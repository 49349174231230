import { AppContext } from "@Util/AppContextWrapper/AppContext";
import { LocationDescriptorObject } from "history";
import React, { useContext } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router";
import { createAccountManagementPath } from "src/routes";

type ServicingTaskLocationState<TaskStateContext> = ServicingTaskStateStack & TaskStateContext;
type ServicingTaskLocationObject<TaskStateContext> = LocationDescriptorObject<ServicingTaskLocationState<TaskStateContext>>;

export interface ServicingTaskStateStack {
  servicingTaskStack: LocationDescriptorObject[];
  servicingTaskState?: {
    paymentUUID?: string;
  }
}

export function useServicingTaskRedirect <TaskStateContext = {}> () {
  const { uuid } = useParams<{ uuid: string }>();
  const location = useLocation<ServicingTaskLocationState<TaskStateContext>>();
  const { lastNavigation } = useContext(AppContext);
  const history = useHistory<ServicingTaskLocationState<TaskStateContext>>();

  const { state: { servicingTaskStack = [] } = ({} as ServicingTaskStateStack) } = location;

  const getTaskLocation = (
    taskPath: string | LocationDescriptorObject,
    options?: {
      paymentUUID?: string;
      context?: TaskStateContext;
      resetTaskRedirectStack?: boolean;
    }
  ) => {
    const { paymentUUID, context, resetTaskRedirectStack } = options || {};
    const taskLocation = typeof taskPath === 'string' ? { pathname: taskPath } : taskPath;

    if (resetTaskRedirectStack) {
      const resetTaskState: ServicingTaskStateStack = {
        servicingTaskStack: [],
        servicingTaskState: { paymentUUID }
      }

      return { ...taskLocation, state: resetTaskState } as ServicingTaskLocationObject<TaskStateContext>;
    }

    const currentLocation = {
      ...history.location,
      state: {
        ...history.location.state,
        servicingTaskState: {
          ...history.location.state?.servicingTaskState,
          ...context
        }
      }
    }

    return {
      ...taskLocation,
      state: {
        ...taskLocation.state,
        servicingTaskState: {
          ...paymentUUID ? { paymentUUID } : null
        },
        servicingTaskStack: [...servicingTaskStack, currentLocation],
      },
    } as ServicingTaskLocationObject<TaskStateContext>;
  };

  // start a stack with fallback url if navigated to by improper means
  const initializeTaskStack = () => {
    if (lastNavigation.to === location && !location.state?.servicingTaskStack) {
      let servicingTaskStack: LocationDescriptorObject[] = [
        lastNavigation.from || { pathname: uuid ? createAccountManagementPath(uuid) : '/' }
      ];
      // we need to construct the stack if redirecting from a page with an existing servicing
      // task state stack
      if (lastNavigation.from?.state?.servicingTaskStack) {
        servicingTaskStack = [
          ...lastNavigation.from.state.servicingTaskStack,
          lastNavigation.from
        ]
      }
      return (<Redirect to={{
        ...location,
        state: {
          ...location.state,
          servicingTaskStack,
        }
      }} />);
    }

    return null;
  };

  const navigateToServicingTask = (
    taskPath: string,
    options?: {
      paymentUUID?: string;
      context?: TaskStateContext;
      resetTaskRedirectStack?: boolean;
    },
  ) => {
    const { paymentUUID, context, resetTaskRedirectStack } = options || {};

    const to = getTaskLocation(taskPath, { paymentUUID, context, resetTaskRedirectStack });
    history.push(to);
  };

  const getPreviousTaskLocation = () => (servicingTaskStack.length
    ? servicingTaskStack[servicingTaskStack.length - 1]
    : { pathname: uuid ? createAccountManagementPath(uuid) : '/' }
  ) as ServicingTaskLocationObject<TaskStateContext>;

  const getInitialReferralLocation = () => (servicingTaskStack.length
    ? servicingTaskStack[0]
    : {
        pathname: uuid ? createAccountManagementPath(uuid) : '/'
      }
  ) as ServicingTaskLocationObject<TaskStateContext>;

  return {
    navigateToServicingTask,
    getTaskLocation,
    getPreviousTaskLocation,
    initializeTaskStack,
    getInitialReferralLocation,
  };
}
