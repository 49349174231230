import React, { useEffect } from 'react';
import Styled, { css } from 'styled-components';
import { Headline, media, SVGIcon } from '@amount/frontend-components';

import { devToolsEnabled } from '../../common';

import { ToolsSection } from './ToolsSection';

const MenuContain = Styled.div<{ showMenu: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  transform: ${({ showMenu }) => showMenu ? `translateX(0)` : 'translateX(100%)'};
  min-height: 100vh;
  max-height: 100%;
  width: 100%;
  z-index: 10001;
  background: ${({ theme }) => theme.colorBackground};
  padding: 2em 3.25em;
  transition: transform 100ms ease, box-shadow 100ms ease-out;
  pointer-events: ${({ showMenu }) => showMenu ? 'initial' : 'none'};
  overflow-y: scroll;
  box-shadow: ${({ showMenu }) => showMenu ? '0 16px 48px 0 rgba(0, 0, 0, 0.3)' : 'none'};

  ${({ showMenu }) => css`
    ${media.small`
      transform: ${showMenu ? `translateX(0)` : 'translateX(22.5em)'};
      width: 22.5em;
    `}
  `}
`;

const MenuClose = Styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 1em 1.375em;
  top: 0.75rem;
  right: 1.75rem;
  color: #fff;

  ${media.small`
    top: 1.5rem;
  `}

  &:hover {
    color: ${({ theme }) => theme.colorSlate20};
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 1em;
    height: 1em;
  }
`;

const clickListener: (e: MouseEvent, showMenu: boolean, toggleMenu: () => void) => void = (e, showMenu, toggleMenu) => {
    if (!showMenu) { return; }

    const Wrap: HTMLElement | null = document.querySelector(`[data-menu='true']`);
    const Button: HTMLElement | null = document.querySelector(`[data-menubutton='true']`);

    if (Wrap?.contains(e.target as Element)) { return; }
    if (Button?.contains(e.target as Element)) { return; }

    e.preventDefault();
    toggleMenu();
  };

interface IDevToolsMenuProps {
  showMenu: boolean;
  toggleMenu (): void;
}

export const DevToolsMenu: React.FC<IDevToolsMenuProps> = ({ showMenu, toggleMenu }) => {
  if (!devToolsEnabled()) { return null; }

  useEffect(() => {
    const cb: (e: MouseEvent) => void = event => clickListener(event, showMenu, toggleMenu);
    window.addEventListener('click', cb);

    return () => {
      window.removeEventListener('click', cb);
    };
  });

  return (
    <MenuContain
      showMenu={showMenu}
      data-menu={true}
    >
      <MenuClose tabIndex={showMenu ? 0 : -1} onClick={toggleMenu} data-event='MenuClose'>
        <SVGIcon icon='close' />
      </MenuClose>
      <Headline scale='medium'>Dev Tools:</Headline>
      <ToolsSection />
    </MenuContain>
  );
};
