import React from 'react'
import {
  Box, SVGIcon, Text
} from '@amount/frontend-components';

const {
  cvvLabel,
  expirationLabel
} = AvantConfig.TenantConfig.allSetVcnCard;

interface IVCNCard {
  merchantName?: string | null;
}

const VCNCard: React.FC<IVCNCard> = ({
  merchantName
}) => (
  <Box
    margin={{
      base: { bottom: '3x' },
      medium: { bottom: '5x' }
    }}
    position={{ type: 'relative', top: '0' }}
    zIndex='low'
    display='block'
    width={{
      base: 'full',
      medium: 'full'
    }}
  >
    <SVGIcon icon='shape' color='accentBlue' />
    <Box
      display='block'
      width={{
        base: 'full',
        medium: 'full'
      }}
      height='90%'
      position={{ type: 'absolute', top: '50%', left: '50%' }} transform='translate(-50%, -50%)' zIndex='middle'>
      <Box justifyContent='center' height='inherit' margin={{ medium: { horizontal: '1x' } }}>
        <Box
          margin={{ base: { horizontal: '2x' } }}
          padding={{
            base: { vertical: '2x', horizontal: '2x' },
            medium: { top: '3x', horizontal: '3x', bottom: '4x' }
          }}
          borderRadius='1.5x'
          backgroundColor='gray50'
          boxShadow='0 2px 7px 1px rgba(0,0,0,0.3)'
          flexWrap='wrap'
          width={{ base: 'full', medium: 'full' }}
        >
          <Box width='full' justifyContent='space-between' margin={{ bottom: '1.25x' }}>
            <Text
              data-testid='merchant'
              family='optimist_semibold'
              margin={{ base: { top: '1x' }, medium: { top: '1x' } }}
              overflowWrap='break-word'
              size={{ medium: 'heroMedium' }}>{merchantName}
            </Text>
            <SVGIcon icon='master-card' />
          </Box>
          <Box
            id='mq-card-pan-container'
            margin={{ medium: { bottom: '1x' } }}
            height='1.75em'
            width='full'
          />
          <Box width='full'>
            <Box alignItems='center' height={{ base: '3.5x', medium: '5x' }}>
              <Text
                family='optimist_semibold'
                margin={{ base: { right: 'quadruple' }, medium: { right: '1x' } }}
                size={{ base: 'bodyMedium', medium: 'heroMedium' }}
                display='inline-block'
              >
                {`${expirationLabel}: `}
              </Text>
              <Box
                id='mq-card-exp-container'
                margin={{ base: { top: 'single' }, medium: { top: '0.625x' } }}
                display='inline-block'
                height='1.75em'
                width='3.75em'
              />
            </Box>
            <Box alignItems='center'>
              <Text
                data-testid='cvv'
                family='optimist_semibold'
                margin={{ base: { left: 'quadruple', right: 'quadruple' }, medium: { right: '1x', left: '4x', } }}
                size={{ base: 'bodyMedium', medium: 'heroMedium' }}
                display='inline-block'
              >
                {`${cvvLabel}: `}
              </Text>
              <Box
                id='mq-card-cvv-container'
                margin={{ base: { top: 'single' }, medium: { top: 'quadruple' } }}
                display='inline-block'
                height='1.75em'
                width='2.8125em'
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default React.memo(VCNCard);
