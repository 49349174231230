import React from 'react';

import { formatDate, isFuture } from '../../../../../../util/date';
import { ApplicationBaseRoutes, PaymentRoutes, ServicingRoutes } from '../../../../../../routes';
import { formatString } from '../../../../../../util/string';
import { LinkAndTextWrapper } from '../../../common';

import { PastDuePaymentLink, PastDueProps } from './common';

type GracePeriodProps = Pick<PastDueProps, 'gracePeriodEndDate' | 'pastDueAmount'>;
const GracePeriodMessage: React.FC<GracePeriodProps> = ({ gracePeriodEndDate, pastDueAmount }) => (
  <span>
    {
      formatString(
        AvantConfig.TenantConfig.banners.withinGracePeriodText,
        value => <strong key={value}>{value}</strong>,
        pastDueAmount?.formatted || '',
        formatDate(gracePeriodEndDate || '')
      )
    }
  </span>
);

type PastDueMessageProps = Pick<PastDueProps, 'pastDueAmount'>;
const PastDueMessage: React.FC<PastDueMessageProps> = ({ pastDueAmount }) => (
  <span>
    Your loan is past due by <strong>{pastDueAmount?.formatted || ''}</strong>. Schedule a payment to get current on your loan.
  </span>
);

export const PastDue: React.FC<PastDueProps> = ({ gracePeriodEndDate, pastDueAmount, uuid }) => (
  <LinkAndTextWrapper>
    { !!gracePeriodEndDate && isFuture(gracePeriodEndDate)
        ? <GracePeriodMessage gracePeriodEndDate={gracePeriodEndDate} pastDueAmount={pastDueAmount} />
        : <PastDueMessage pastDueAmount={pastDueAmount} />
    }
    <PastDuePaymentLink
      text='Schedule a Payment'
      dataEvent='bannerLoanSchedulePayment'
      destination={`${ApplicationBaseRoutes.loanServicing}/${uuid}${ServicingRoutes.payments}${PaymentRoutes.schedule}`}
    />
  </LinkAndTextWrapper>
);
