/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { LoanBanner } from '../..';
import { formatString } from '../../../../../../../../../util/string';
import { BannerNotificationsProps } from '../../../index';
import { FullBannerProps } from '../../../types';
import { useByString } from '../../../utils/virtualCardUseByString';

const { vCardNotUsedNotSplitPay } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages;

export const NotUsedNotSplitPayVCBanner: React.FC<BannerNotificationsProps> = ({ variant, bannerProps }) => {
  const { servicingAccount: { virtual_card } } = bannerProps as FullBannerProps
  if (!virtual_card) { return null }
  const useBy = useByString(virtual_card.use_by);

  return (
    <LoanBanner variant={variant}>
      <span>
        {formatString(vCardNotUsedNotSplitPay, undefined, useBy)}
      </span>
    </LoanBanner>
  );
};
