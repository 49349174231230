import React from 'react';

import { Loading } from '../Spinner/LoadingHOC';
import PageTitle from '../PageTitle';

import GetAccountSettingsQuery, { GetAccountSettings } from './queries/GetAccountSettings.graphql';
import { AccountSettings } from './Presentational';
import { IAccountSettingsProps } from './common';
import { useQuery } from '@apollo/react-hooks';

const LiftedAccountSettings: React.FunctionComponent<IAccountSettingsProps> = props => {
  const { data, loading } = useQuery<GetAccountSettings>(
    GetAccountSettingsQuery,
    { fetchPolicy: 'cache-and-network' },
  );

  return(
    <Loading loading={loading}>
      <PageTitle pageTitle={AvantConfig.TenantConfig.pageTitles.accountSettings} />
      <AccountSettings {...props} data={data} />
    </Loading>
  );
};

export default LiftedAccountSettings;
