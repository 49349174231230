import React from 'react';

import { LoanBanner } from '.';
import { formatString } from '@Util/string';
import { BannerNotificationsProps } from '../index';
import { FullBannerProps } from '../types';
import { formatDateWithFullMonthName } from '@Util/date';

const { autoPay } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages

export const AutoPayOnBanner: React.FC<BannerNotificationsProps> = ({ variant, bannerProps }) => {
  const {servicingAccount: { product }} = bannerProps as FullBannerProps

  return (
    <LoanBanner variant={variant}>
      <span>
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          formatString(autoPay, undefined, formatDateWithFullMonthName(product.next_installment_date!))
        }
      </span>
    </LoanBanner>
  );
};
