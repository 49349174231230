import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { NavButton } from '@amount/frontend-components';

import { TakeoverLoader } from '../TakeoverLoader';
import { devToolsEnabled, IProductUuid } from '../../common';
import { devToolsClient } from '../../devToolsApolloClient';

import EmailConfirmationLinkMutation, {
  EmailConfirmationLink as EmailConfirmationLinkData,
  EmailConfirmationLinkInput
} from './queries/EmailConfirmationLink.graphql';

export const EmailConfirmationLink: React.FC<IProductUuid> = ({ uuid }) => {
  if (!devToolsEnabled()) {
    return null;
  }
  const [emailConfirmationLink, { loading }] = useMutation<EmailConfirmationLinkData, EmailConfirmationLinkInput>(
    EmailConfirmationLinkMutation,
    {
      variables: { input: { customerApplicationUUID: uuid }},
      client: devToolsClient,
    },
  );

  const onClick = async () => {
    const response = await emailConfirmationLink();
    const base_url = process.env.REACT_APP_API_URL || ''
    const link = response.data?.emailConfirmationLink?.confirmation_url || ''

    if (response.data?.emailConfirmationLink?.success) {
      window.location.replace(base_url + link);
    } else {
      alert('Failed to confirm email, see backend error log');
    }
  };

  return (
    <>
      {
        loading && (
          <TakeoverLoader />
        )
      }
      <NavButton onClick={onClick}>
        Email Confirmation Link
      </NavButton>
    </>
  );
};
