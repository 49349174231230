import * as Sentry from '@sentry/browser';

export const hasMatchedReferrer = (): boolean => {
  try {
    const referrer = (new URL(document.referrer)).origin;
    const vcn_whitelist_urls = process.env.REACT_APP_VCN_WHITELIST_URLS;

    if (vcn_whitelist_urls) {
        return vcn_whitelist_urls.split(",").map(u => u.replaceAll(" ", "")).includes(referrer);
    } else {
        return false;
    }
  }
  catch (e) {
    Sentry.captureException(e);
    return false
  }
};
