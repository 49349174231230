import { ILoanProps } from '../common';

export const loanTitle = (
  { product: { pointOfSaleInformation, amount } }: ILoanProps
) => {
  const tenant = AvantConfig.TenantConfig.product.loan.name;

  return !!pointOfSaleInformation
    ? `${amount && amount.formatted} ${tenant} Loan with ${pointOfSaleInformation.merchantName}`
    : `${amount && amount.formatted} Personal Loan`;
};
