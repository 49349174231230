import React from 'react';
import Styled from 'styled-components';
import { ModalCtaButton, NavButton } from '@amount/frontend-components';

import Modal from '../../Common/Modal';
import { FlexColumn } from '../../Common/CommonStyles';

interface IExitModalProps {
  message: string;
  confirmationText: string;
  cancellationText: string;
  visible: boolean;
  onCancel (): void;
  onConfirm (): void;
}

const StyledCTA = Styled(FlexColumn)`
  align-items: center;
`;

export const ExitModal: React.FC<IExitModalProps> = props => {
  const { message, confirmationText, cancellationText, onCancel, onConfirm, visible } = props;

  return (
    <Modal
      show={visible}
      close={onCancel}
      aria-label={message}
      aria-labelledby='workflowOnExitMessage'
      aria-describedby='workflowOnExitMessageDesc'
      modalContent={{ leftAlign: true }}
    >
      <p id='workflowOnExitMessageDesc'>
        {message}
      </p>
      <StyledCTA>
        <ModalCtaButton onClick={onConfirm} inline={true}>
          {confirmationText}
        </ModalCtaButton>
        <NavButton onClick={onCancel} >
          {cancellationText}
        </NavButton>
      </StyledCTA>
    </Modal>
  );
};
