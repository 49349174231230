import Styled from 'styled-components';

import { GetAccountHistoryData } from './queries/GetAccountHistoryData.graphql';

export const InfoText = Styled.p`
  line-height: 2.4rem;
  margin-top: 0.25em;
  margin-bottom: 0;
`;

export interface IAccountHistoryProps {
  data?: GetAccountHistoryData;
}
