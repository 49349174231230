import React from 'react';
import {
  Datapoint,
  DatapointLabel,
  Headline,
  RadioCard,
  RadioCardDataPoint,
} from '@amount/frontend-components';
import camelCase from 'lodash-es/camelCase';
import { IInnerInputProps, SelectionOnWorkflowInputValueRadio } from '@amount/workflow-js';

import { WorkflowTextInput } from '../TextInput';
import { WorkflowNumberInput } from '../NumberInput';

import { HeaderContainer, IWorkflowRadioInputProps, RadioCardDetails, RadioContentContainer } from './common';

export interface IRadioValueProps extends IWorkflowRadioInputProps {
  opt: SelectionOnWorkflowInputValueRadio;
}

interface IState {
  value: string;
}

export class WorkflowRadioCardWithInput extends React.PureComponent<IRadioValueProps, IState> {
  public state: IState = {
    // eslint-disable-next-line react/no-unused-state
    value: ''
  };
  private readonly innerInputRef: React.RefObject<HTMLInputElement> = React.createRef();

  public render () {
    const {
      opt,
      input,
      value,
      updateInput,
      innerInputs
    } = this.props;

    if (!opt.details || opt.details.__typename !== 'WorkflowInputValueRadioCardWithInput' || !innerInputs) { return null; }

    const innerInput = innerInputs.get(opt.details.input.name);
    if (!innerInput) { return null; }
    const checked: boolean = value === opt.value;

    return (
      <RadioCard
        key={`${input.name}-${opt.value}`}
        id={opt.value}
        name={input.name}
        defaultValue={opt.value}
        placeholder={input.placeholder || undefined}
        required={!!input.required}
        onChange={e => updateInput(e.currentTarget.value)}
        checked={checked}
        data-event={camelCase(`${opt.displayValue}RadioCardInput`)}
        onClick={this.radioValueOnClick}
      >
        <RadioContentContainer>
          <HeaderContainer>
            <Headline as='h4' scale='small'>{opt.details.header}</Headline>
          </HeaderContainer>
          {opt.details.subheader && <p>{opt.details.subheader}</p>}
          <RadioCardDetails>
            {opt.details.dataPoints && opt.details.dataPoints.map((info, i) => info && (
              <RadioCardDataPoint key={`details-${i}`}>
                {info.header}
                <Datapoint size='small'>{info.content}</Datapoint>
                <DatapointLabel>{info.footer}</DatapointLabel>
              </RadioCardDataPoint>
            ))}
          </RadioCardDetails>
          {opt.details.footer && <DatapointLabel>{opt.details.footer}</DatapointLabel>}
          {this.renderInnerInput(innerInput, checked)}
          {opt.details.footer && <DatapointLabel>{opt.details.footer}</DatapointLabel>}
        </RadioContentContainer>
      </RadioCard>
    );
  }

  private readonly radioValueOnClick: () => void = () => {
    if (this.innerInputRef && this.innerInputRef.current) {
      this.innerInputRef.current.focus();
    }
  }

  private readonly innerInputOnClick: () => void = () => {
    this.props.updateInput(this.props.opt.value);
  }

  // Currently only supports WorkflowTextInput, WorkflowNumberInput
  private readonly renderInnerInput: (input: IInnerInputProps, isSelected: boolean) => React.ReactNode =
  ({ input, updateInput, value, }, isSelected) => {
    // inner inputs are only required if the outer input is selected as well
    const required: boolean = (input.required || false) && isSelected;

    switch (input.__typename) {
      case 'WorkflowTextInput':
        return (
          <WorkflowTextInput
            innerInputRef={this.innerInputRef}
            input={{...input, required}}
            value={value}
            updateInput={updateInput}
            onClickCallback={this.innerInputOnClick}
          />
        );
      case 'WorkflowNumberInput':
        return (
          <WorkflowNumberInput
            innerInputRef={this.innerInputRef}
            input={{...input, required}}
            value={value}
            updateInput={updateInput}
            onClickCallback={this.innerInputOnClick}
          />
        );
      default:
        return null;
    }
  }
}
