import React from "react";

import { paymentMethodsPath } from "src/routes";
import { HeaderLinkProps } from "../constants";
import { RouterLink } from "src/_shared/ThemeTwo/Link/router-link.component";

export const PaymentMethods: React.FC<HeaderLinkProps> = ({ variant, linkLabel, onClickCallback }) => (
  <RouterLink.siteHeader
    variant={variant}
    to={paymentMethodsPath}
    onClick={onClickCallback}
  >
    {linkLabel}
  </RouterLink.siteHeader>
);
