import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { NavButton } from '@amount/frontend-components';

import { TakeoverLoader } from '../TakeoverLoader';
import { devToolsEnabled, IProductUuid } from '../../common';
import { devToolsClient } from '../../devToolsApolloClient';

import ConfirmEmailMutation, {
  ConfirmEmail as ConfirmEmailData,
  ConfirmEmailInput
} from './queries/ConfirmEmail.graphql';

export const ConfirmEmail: React.FC<IProductUuid> = ({ uuid }) => {
  if (!devToolsEnabled()) {
    return null;
  }
  const [confirmEmail, { loading }] = useMutation<ConfirmEmailData, ConfirmEmailInput>(
    ConfirmEmailMutation,
    {
      variables: { input: { customerApplicationUUID: uuid }},
      client: devToolsClient,
    },
  );

  const onClick = async () => {
    const response = await confirmEmail();

    if (response.data?.confirmEmail?.success) {
      window.location.reload();
    } else {
      alert('Failed to confirm email, see backend error log');
    }
  };

  return (
    <>
      {
        loading && (
          <TakeoverLoader />
        )
      }
      <NavButton onClick={onClick}>
        Confirm Email
      </NavButton>
    </>
  );
};
