import React from 'react';
import { BannerLink } from '@amount/frontend-components';
import { Link } from 'react-router-dom';

import { PaymentMethods } from '../../../../../../constants';
import { LoanBanner } from '..';
import { ApplicationBaseRoutes, ServicingRoutes } from '../../../../../../routes';
import { LinkAndTextWrapper } from '../../../common';

import { PayoffQuoteProps } from './common';
import { PaperCheckPayoffQuote } from './PaperCheckPayoffQuote';
import { ScheduledPayoffQuote } from './ScheduledPayoffQuote';
import { payOff } from '../../bannerStyles';

export const PayoffQuote: React.FC<PayoffQuoteProps> = ({ payoffInformation, uuid }) => (
  <LoanBanner style={payOff}>
    <LinkAndTextWrapper>
      {payoffInformation?.scheduledQuote?.paymentMethod === PaymentMethods.PaperCheck
        ? <PaperCheckPayoffQuote payoffInformation={payoffInformation} uuid={uuid} />
        : <ScheduledPayoffQuote payoffInformation={payoffInformation} uuid={uuid} />
      }
      <BannerLink bannerStyle='inactive'>
        <Link
          data-event='bannerLoanCancelPayoff'
          to={`${ApplicationBaseRoutes.loanServicing}/${uuid}${ServicingRoutes.cancelQuote}`}
        >
          Cancel Payoff
        </Link>
      </BannerLink>
    </LinkAndTextWrapper>
  </LoanBanner>
);
