import React from 'react';

import {
  ContactButtons,
  ISupportContentProps,
  ModalColumn,
  SpecialistText
} from './common';

export const SupportContent: React.FunctionComponent<ISupportContentProps> = ({
  contactInfo,
  showContactInfoFirst
}) => {
  const buttons = (
    <ModalColumn>
      <ContactButtons
        contactInfo={contactInfo}
        showContactInfoFirst={showContactInfoFirst}
      />
    </ModalColumn>
  );

  const contactConfig = contactInfo.contactHoursText || AvantConfig.TenantConfig.support.specialistText;

  const specialistText = (
    <ModalColumn as='dl'>
      {Object.values(contactConfig)
        .map((text, i) => (
          <SpecialistText key={i}>
            {text}
          </SpecialistText>
        )
      )}
    </ModalColumn>
  );

  return showContactInfoFirst ?
    (
      <>
        {buttons}
        {specialistText}
      </>
    )
    :
    (
      <>
        {specialistText}
        {buttons}
      </>
    );
};
