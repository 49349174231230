import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import GetAppContextQuery, { GetAppContext } from './queries/GetAppContext.graphql';
import { Loading } from '../../components/Common/Spinner/LoadingHOC';
import ServerError from '../../components/Common/Error/ServerError';
import { AppContext } from './AppContext';
import { useLocation } from 'react-router';
import { LocationDescriptorObject } from 'history';
import { ServicingTaskStateStack } from 'src/g2/servicing/components/ServicingTasks/utils/useServicingTaskRedirect';
import SessionManager from '../../util/sessionManager';
import { WorkflowSessionData } from 'src/g2/servicing/components/ServicingTasks/utils/Api/UseWorkflowLogger/types';

export const AppContextWrapper: React.FC = ({ children }) => {
  const location = useLocation<ServicingTaskStateStack>();
  const [lastNavigation, setLastNavigation] = useState<{
    from: LocationDescriptorObject<ServicingTaskStateStack> | null;
    to: LocationDescriptorObject<ServicingTaskStateStack> | null;
  }>({ from: null, to: null});
  const { loading, data, error } = useQuery<GetAppContext>(
    GetAppContextQuery
  );
  const workflowSessionRef = React.useRef<WorkflowSessionData>({ rank: 1, pastActions: [], activeWorkflowSessionUuids: [] });

  useEffect(() => {
    if (location.pathname !== lastNavigation.to?.pathname) {
      setLastNavigation(prev => {
        return {
          from: prev.to,
          to: location
        }
      });
    } else {
      setLastNavigation(prev => {
        return {
          from: prev.from,
          to: location
        }
      });
    }
  }, [location])
  if (loading) { return <Loading loading={loading} /> }

  // relying on the existence of identity is a short term solution, and will be updated before MVP
  //   https://amount.atlassian.net/browse/CF-5988
  const isG2User = !!data?.identity;
  const enableG2Dashboards = isG2User && AvantConfig.TenantConfig.g2DashboardsEnabled;
  const sessionRefreshUrl = AvantConfig.TenantConfig.sessionRefreshUrl;

  /* FIXME: This is a hack to fix an issue that was causing the service outage page
   *        to always be shown when the user was not logged in. We are checking for the existence
   *        of an error here to allow this to be skipped when the above query has cached data,
   *        meaning the user previously had a session but no longer does. This allows the SessionTimeout
   *        modal to still function on the /loggedOut route which needs to be reachable after session expiration.
   *        This can be removed if/once we make it possible to reach certain routes, ie /loggedOut,
   *        without going through this wrapper. */
  if (!!error && !SessionManager.hasSession) {
    window.location.assign(SessionManager.urlRouter.loginUrl);
    return null;
  }

  return (
    !!error
      ? <ServerError />
      : (
        <AppContext.Provider value={{
          isG2User,
          enableG2Dashboards,
          lastNavigation,
          workflowSessionRef,
          sessionRefreshUrl }}>
          {children}
        </AppContext.Provider>
      )
  );
};
