import React from 'react';
import { Radio } from '@amount/frontend-components';
import camelCase from 'lodash-es/camelCase';

import { IRadioValueProps } from './common';

export const WorkflowRadioButton: React.FunctionComponent<IRadioValueProps> = ({
  opt,
  input,
  value,
  updateInput,
}) => (
  <Radio
    id={opt.value}
    name={input.name}
    defaultValue={opt.value}
    placeholder={input.placeholder || undefined}
    required={!!input.required}
    onChange={e => updateInput(e.currentTarget.value)}
    checked={value === opt.value}
    data-event={camelCase(`${opt.displayValue}RadioInput`)}
  >
    {opt.displayValue}
  </Radio>
);
