import { css } from 'styled-components';
import {
  IBoxProps,
  ITextProps,
  media
} from '@amount/frontend-components';

export const historyBoxTheme = {
  backgroundColor: 'white',
  boxShadow: '1x',
  borderRadius: 'quadruple',
  padding: {
    base: { horizontal: '3x' },
    medium: { horizontal: '5x', vertical: '1.5x' },
  },
  direction: 'column',
};

export const historyItemBoxTheme = {
  width: '100%',
  padding: { vertical: '3x' },
  justifyContent: 'space-between',
  direction: {
    base: 'column',
    medium: 'row',
  }
};

export const historyItemBorderTheme: IBoxProps['border'] = { side: 'bottom', color: 'gray300' };

export const historyActionsTheme = {
  direction: 'column',
  alignItems: {
    base: 'flex-start',
    medium: 'flex-end',
  },
  padding: {
    base: { top: '1x' },
    medium: { top: '0' },
  },
  childrenStyles: css`
    a {
      display: inline-flex;
      align-items: center;
      line-height: 1.5;
      margin-top: ${({ theme: { themeTwo: { global } } }) => global.edgeSize['0.75x']};
      ${media.medium`
        margin-top: 0;
      `}
    }
    div { margin-left: ${({ theme: { themeTwo: { global } } }) => global.edgeSize['1x']}; }
  `,
}

export const historyTagTheme: ITextProps = {
  family: 'optimist_semibold',
  lineHeight: '1.5',
  margin: {
    medium: { left: '0.625x' },
  },
}
