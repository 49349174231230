import * as React from 'react';
import { Box, Button_t2_, Text } from '@amount/frontend-components';

export interface ICopyVcnButtonProps {
  buttonState: 'default' | 'success' | 'failed';
  buttonText: string,
  failureMessage: string,
  successMessage: string,
  variant: 'secondary' | 'link';
}

export const CopyVcnButton: React.FC<ICopyVcnButtonProps> = ({
  buttonState,
  variant,
  buttonText,
  successMessage,
  failureMessage,
}) => {
  let role;
  let ariaLive: 'polite' | undefined;
  let ariaLabel;

  switch (buttonState) {
    case 'success':
      role = 'alert';
      ariaLive = 'polite';
      ariaLabel = successMessage;
      break;
    case 'failed':
      role = 'alert';
      ariaLive = 'polite';
      ariaLabel = failureMessage;
      break;
    default:
      role = undefined;
      ariaLive = undefined;
      ariaLabel = undefined;
  }

  return (
    <>
      <Button_t2_
        data-testid='copy_vcn_button'
        variant={variant}
        color='success'
        role={role}
        aria-live={ariaLive}
        aria-label={ariaLabel}
        width="100%"
      >
        {buttonText}
      </Button_t2_>
      <Box justifyContent="center" margin={{ top: '1x' }}>
      { buttonState === 'success' &&
        <Text color='accentBlue' data-testid="copyVcnButtonSuccess" display='block'>{successMessage}</Text>
      }
      {
        buttonState === 'failed' &&
        <Text color='accentBlue' data-testid="copyVcnButtonFailed" display='block'>{failureMessage}</Text>
      }
    </Box>
  </>
  );
}
