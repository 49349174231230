import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { defaultDataIdFromObject, IdGetterObj, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';
import Cookies from 'universal-cookie';

import { UNAUTHORIZED_STATUS } from './constants';
import FragmentTypes from './config/fragmentTypes';
import httpLink from './httpLink';
import SessionManager from './util/sessionManager';
import { enableApolloDevTools, JWT_TOKEN_KEY } from './util';

const fragmentMatcher: IntrospectionFragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: FragmentTypes
});

interface ICustomGetterObj extends IdGetterObj {
  statementEndDate?: string;
  date?: string;
  amount?: {
    cents: number;
  }
}

const cookies = new Cookies();

export const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (result: ICustomGetterObj) => {
    switch (result.__typename) {
      case 'CreditCardStatement': {
        // Adding typename to custom key to emulate default apollo cache key implementation
        return result.statementEndDate ? `${result.__typename}:${result.statementEndDate}` : null;
      }
      case 'ConsumerPaymentAllocationInformation': {
        // Adding typename to custom key to emulate default apollo cache key implementation
        return (result.date && result.amount) ? `${result.__typename}:${result.id}:${result.date}:${result.amount.cents}` : null;
      }
      default: {
        return defaultDataIdFromObject(result);
      }
    }
  }
});

const stateLink: ApolloLink = withClientState({
  cache,
  resolvers: {
    Mutation: {

    }
  },
  typeDefs: ``
});

const setTokenLink: ApolloLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'X-Avant-Token': SessionManager.token || ''
    }
  });

  if (!forward) { throw new Error('Forward function missing!'); }

  return forward(operation);
});

const updateTokenLink: ApolloLink = new ApolloLink((operation, forward) => {
  if (!forward) { throw new Error('Forward function missing!'); }

  return forward(operation)
    .map(response => {
      const { refreshToken, ...rest } = response as typeof response & { refreshToken: string };
      if (refreshToken) {
        localStorage.setItem(JWT_TOKEN_KEY, refreshToken);
      }

      return rest;
    });
});

const errorLink: ApolloLink = onError(({ networkError }) => {
  if (
    networkError &&
    ((networkError as any).statusCode === UNAUTHORIZED_STATUS) && // eslint-disable-line  @typescript-eslint/no-explicit-any
    !window.location.href.endsWith(SessionManager.urlRouter.loggedOut) // If we are already on loggoutOut dont reassign location
  ) {
    cookies.set('redirectAfterAuth', window.location.href, { path: '/', maxAge: 600000 });
    SessionManager.logout();
    window.location.assign(`${process.env.PUBLIC_URL}${SessionManager.urlRouter.loggedOut}`);
  }
});

export const baseLinks = ApolloLink.from([
  errorLink,
  setTokenLink,
  updateTokenLink,
  stateLink,
]);

const link: ApolloLink = ApolloLink.from([
  baseLinks,
  httpLink
]);

export const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: enableApolloDevTools(),
});
