import React from 'react';
import {
  Datapoint,
  DatapointLabel,
  ModalClose
} from '@amount/frontend-components';

import {
  GetCustomerAndAppID,
  SelectionOnCustomer,
} from '../queries/GetCustomerAndAppId.graphql';
import Modal, { IModalProps } from '../../Modal';
import SessionManager from '../../../../util/sessionManager';
import NewWindowLink from '../../NewWindowLink';
import { isInstallmentLoanProduct } from '../../../../util';

import {
  SupportContent
} from './SupportContent';
import { getSupportContactInfo, ModalColumn } from './common';
import { ModalHeadline } from '../../CommonStyles';

export interface ISupportModalProps extends IModalProps {
  data?: GetCustomerAndAppID;
}

interface IContactInstructions {
  customer?: SelectionOnCustomer;
}

const ContactInstructions: React.FunctionComponent<IContactInstructions> = ({ customer }) => (
  <p>
    {!!AvantConfig.TenantConfig.support.faq ? (
      <>
        You can find commonly asked questions on our{' '}
        <NewWindowLink
          href={(!!customer?.getApplication && AvantConfig.TenantConfig.support.verification?.faq) || AvantConfig.TenantConfig.support.faq}
          data-event='supportFaq'
        >
          F.A.Q. page
        </NewWindowLink>{' '}
        {!AvantConfig.TenantConfig.support.hideSupportName && (
          <>
            or contact {AvantConfig.TenantConfig.support.supportName}
          </>
        )}
      </>
    ) : (
      <>
        {!AvantConfig.TenantConfig.support.hideSupportName && (
          <>
            Contact {AvantConfig.TenantConfig.support.supportName}
          </>
        )}
      </>
    )}
    {!AvantConfig.TenantConfig.support.hideSupportName && (
      AvantConfig.TenantConfig.support.hideCustomerId && customer ? (
        !AvantConfig.TenantConfig.support.hideApplicationId && customer.getApplication && (
          <> and reference your Application ID.</>
        )
      ) : (
        <> and reference your Customer ID.</>
      )
    )}
  </p>
);


const SupportModal: React.FunctionComponent<ISupportModalProps> = ({
  close,
  show,
  data,
}) => {
  const customer = data?.me;
  const app = data?.me.getApplication;
  const contactInfo = getSupportContactInfo({ app, product: customer?.getProduct });

  return (
    <Modal
      show={show}
      close={close}
      aria-labelledby='support-modal-header'
      modalContent={{
        padding: {
          medium: '3em 4em'
        }
      }}
      >
      <ModalClose data-event='closeSupportModal' close={close} />
      <ModalHeadline
        id='support-modal-header'
        scale='medium'
        as='h2'
      >
        {AvantConfig.TenantConfig.support.headline}
      </ModalHeadline>
      <ContactInstructions customer={customer} />
      {!AvantConfig.TenantConfig.support.hideCustomerId && customer && (
        <ModalColumn>
          <DatapointLabel>Customer ID</DatapointLabel>
          <Datapoint as='div'>{customer.id}</Datapoint>
        </ModalColumn>
      )}
      {!AvantConfig.TenantConfig.support.hideApplicationId && app && (
        <ModalColumn>
          <DatapointLabel>Application ID</DatapointLabel>
          <Datapoint as='div'>{app.id}</Datapoint>
        </ModalColumn>
      )}
      <SupportContent
        contactInfo={contactInfo}
        showContactInfoFirst={AvantConfig.TenantConfig.support.showEmailBeforeSpecialistText}
      />
      {!AvantConfig.TenantConfig.support.hideChangeLoan && app && isInstallmentLoanProduct(app.productType) && (
        <p>
          If you need to change your loan amount or length,{' '}
          <a
            data-event='supportChangeTerms'
            href={SessionManager.urlRouter.getApplicationUrl(
              app.id,
              'rates_terms'
            )}
          >
            click here
          </a>.
        </p>
      )}
    </Modal>
  );
};

export default SupportModal;
