import React, { useState } from 'react';
import Styled from 'styled-components';
import { Button } from '@amount/frontend-components';

import { devToolsEnabled } from './common';
import { DevToolsMenu } from './components/Menu';

const DevToolsButton = Styled(Button)`
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 500;
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.3);
`;

const Tools: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <>
      <DevToolsMenu toggleMenu={toggleMenu} showMenu={showMenu} />
      <DevToolsButton inline={true} onClick={toggleMenu}>Dev Tools</DevToolsButton>
    </>
  );
};

export const DevTools: React.FC = () =>
  devToolsEnabled()
    ? <Tools />
    : null;
