import * as React from 'react';
import Styled from 'styled-components';
import { Datapoint } from '@amount/frontend-components';

import { FragmentSelectionOnCreditCardAccount } from '../queries/GetCardServicingDashInfo.graphql';

interface IAvailableCreditText {
  overlimit: boolean;
  wasClosed: boolean;
  isLate: boolean;
}

export const AvailableCreditTextWrapper = Styled.div<IAvailableCreditText>`
  color: ${props => {
    if (props.overlimit) {
      return props.theme.brand.colorAlert;
    } else if (props.wasClosed || props.isLate) {
      return props.theme.colorSlate50;
    }

    return props.theme.brand.colorSecondary;
  }};
`;

interface ICurrentBalanceText {
  wasClosed: boolean;
  currentBalance: FragmentSelectionOnCreditCardAccount['currentBalance'];
}

export const CurrentBalanceTextWrapper = Styled.div<ICurrentBalanceText>`
  ${props =>
    props.wasClosed &&
    !!props.currentBalance &&
    props.currentBalance.cents === 0 &&
    `color: ${props.theme.colorSlate50}`}
`;

interface ICreditLimit {
  wasClosed: boolean;
}

export const CreditLimitWrapper: React.FC<ICreditLimit> = ({
  wasClosed,
  children
}) => {
  if (wasClosed) {
    return null;
  }

  return <>{children}</>;
};

export const AlertDatapoint = Styled(Datapoint)`
  color: ${({ theme: { brand } }) => brand.colorAlert};
`;
