import { SelectionOnAccountManagement } from '../../queries/GetAccountHistoryData.graphql';
import { createAccountManagementPath } from '../../../../../../routes';
import { ProductStatuses } from '../../../../../../components/CustomerHomeDash/common';
import { HistoryViewModel, HistoryViewModes, TagTypes } from '../types';
import { canAccessServicingDashboard } from 'src/g2/_shared/utils/canAccessServicingDashboard';

const activeStatuses: string[] = [
  ProductStatuses.Authorization,
  ProductStatuses.Current,
  ProductStatuses.Late,
  ProductStatuses.ChargedOff
];

const accountToHistoryViewModel: (account: SelectionOnAccountManagement) => HistoryViewModel | null = account => {
  const { product, point_of_sale_information: posInfo } = account;
  const isDashboardActive = canAccessServicingDashboard({
    isVCUsed: !!account.virtual_card?.is_used,
    productStatus: product.status,
  });
  let displayDate = null;
  let isRefunded = false;
  let tag;
  if (activeStatuses.includes(product.status)) {
    tag = TagTypes.Active;
    displayDate = product.contract_signed_date;
  } else if (product.status === ProductStatuses.PaidOff) {
    tag = TagTypes.PaidOff;
    displayDate = posInfo.latest_product_return_date || product.paid_off_date;
    isRefunded = !!posInfo.latest_product_return_date;
  } else if (product.status === ProductStatuses.Cancelled) {
    tag = TagTypes.Canceled;
    displayDate = product.application.closedAt || '';
  }

  if (displayDate === null || !product.application.merchantName || !product.contract_signed_date) {
    return null;
  }

  return ({
    type: HistoryViewModes.Account,
    purchaseAmount: product.amount.formatted,
    tag,
    sortDate: product.contract_signed_date,
    displayDate,
    applicationDate: product.application.createdAt || '',
    merchant: product.application.merchantName,
    applicationID: product.application.id,
    productID: product.id,
    isRefunded,
    accountLink: isDashboardActive ?
      createAccountManagementPath(product.servicing_account_uuid) :
      undefined,
    contractLink: product.contract_url,
  });
};

export default accountToHistoryViewModel;
