import React from 'react';

import { FlexColumn } from '../../../CommonStyles';
import { ApplicationWell, ICreditCardProps } from '../common';
import { Application } from '../Application';

import { CreditCardActions } from './CreditCardActions';
import { CreditCardDescription } from './CreditCardDescription';

export const IssuedCreditCard: React.FC<ICreditCardProps> = ({ product, rowIndex }) =>
  !!product && (
    <>
      <FlexColumn>
        <CreditCardDescription product={product} rowIndex={rowIndex} />
        <ApplicationWell>
          <Application application={product.customerApplication} product={product} />
        </ApplicationWell>
      </FlexColumn>
      <CreditCardActions product={product} rowIndex={rowIndex}  />
    </>
  );
