import { IEventInfo } from '../customTypings/avant-config/tenantConfig';

import { TagsSupported } from './tealium/common';

export interface IEventDisabled {
  tagDisabled: TagsSupported;
}

export abstract class AbstractAnalyticsLogger {

  private _applicationId: string | undefined;
  private _applicationUuid: string | undefined;
  public constructor (applicationId?: string) {
    this.applicationId = applicationId;
  }
  public get applicationId () { return this._applicationId; }
  public set applicationId (applicationId: string | undefined) {
    this._applicationId = applicationId;
  }

  public get applicationUuid () { return this._applicationUuid; }
  public set applicationUuid (applicationUuid: string | undefined) {
    this._applicationUuid = applicationUuid;
  }

  // The IEventDisabled parameter is only used by HSBC. After HSBC is no longer
  // in production, we can remove it
  public abstract view (tags?: IEventInfo, eventDisabled?: IEventDisabled): void;

  public abstract sendEvent (
    tags?: IEventInfo, eventName?: string, eventDisabled?: IEventDisabled
  ): void;

  public abstract sendClickEvent (
    linkLabel: string, siteSection: string, pageName: string,
  ): void;
}
