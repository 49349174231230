import React from 'react';
import { Box, Button_t2_, Link, Modal, Text } from '@amount/frontend-components';
import { GetAllSetVcnCard } from '../../../../../modules/queries/GetAllSetVcnCard.graphql';
import { formatUrl } from '../utils/formatUrl';
import { Loading } from '../../../../../components/Common/Spinner/LoadingHOC';
import ServerError from '../../../../../components/Common/Error/ServerError';
import { CopyVcnButton } from './CopyVcnButton';
import { StandardHorizontalDivider } from 'src/components/Common/Box/Divider';
import { formatDateWithTimeZone } from '../utils/formatDateWithTimeZone';

const {
  cardLimitLabel,
  cardNumberLabel,
  cvvLabel,
  expirationDateLabel,
  merchantLabel,
  modalClose,
  modalHeader,
  timeStampLabel,
} = AvantConfig.TenantConfig.allSetVcnCard;

const {
  buttonText,
  successMessage,
  failureMessage,
} = AvantConfig.TenantConfig.allSetVcnCard.copyVcnButton.link;

export interface IVCNModal {
  accountUUID: string;
  data?: GetAllSetVcnCard;
  handleModalClose(): void;
  isShown: boolean;
  displayError: boolean;
  displayLoader: boolean;
  copyVcnButtonState: 'default' | 'success' | 'failed';
}

export const VCNModal: React.FC<IVCNModal> = ({
  data,
  handleModalClose,
  isShown,
  displayError,
  displayLoader,
  copyVcnButtonState,
}) => {
  const amount = data?.account_management?.virtual_card?.credit_amount.formatted;
  const useBy = data?.account_management?.virtual_card?.use_by;
  // TODO_BASEURL assuming this is an external link?
  const mDomain = data?.account_management?.merchant_information?.merchant_domain;

  return (
    <Modal
      ariaModal={isShown}
      isShown={isShown}
      handleModalClose={handleModalClose}
      heading={{
        text: modalHeader,
        styles: {
          align: 'center',
          family: 'optimist_light',
          size: {
            base: 'heroSmall', medium: 'heroLarge'
          },
          lineHeight: '1.5',
        }
      }}
      ariaLabelledby={modalHeader}
      cardWidth={{
        base: 'full',
        medium: '32em'
      }}
    >
      {!displayLoader && displayError &&
        <Box margin={{ top: '4x' }}>
          <ServerError />
        </Box>
      }
      {displayLoader &&
        <Loading loading={displayLoader} />
      }
      <Box
        backgroundColor='gray50'
        border={{ color: 'gray400', style: 'solid', side: 'all' }}
        display='block'
        margin={{ base: { top: '2.5x' }, medium: { top: '5x' } }}
        borderRadius='quadruple'
        width={{ base: 'full', medium: '28em' }}
        hidden={displayError || displayLoader}
      >
        <Box
          direction={{ base: 'column', medium: 'row' }}
          padding={{ top: '2x', medium: { horizontal: '3x' }, base: { horizontal: '2x' } }}
        >
          <Box direction={{ base: 'column', medium: 'row' }} display={{ base: 'flex', medium: 'block' }}>
            <Box
              direction={{ medium: 'row' }}
              margin={{ base: { top: '1x' }, medium: { top: '0' } }}
              flexWrap='wrap'
            >
              <Text
                display='block'
                family='optimist_semibold'
                margin={{ base: { bottom: '0', right: '8px' }, medium: { bottom: 'quadruple' } }}
                size='bodyMedium'
              >
                {cardNumberLabel}
              </Text>
              <Box
                id='mq-copy-pan-container'
                position='relative'
                width='120px'
              >
                <CopyVcnButton
                  buttonState={copyVcnButtonState}
                  variant='link'
                  buttonText={buttonText}
                  successMessage={successMessage}
                  failureMessage={failureMessage}
                />
              </Box>
            </Box>
            <Box id="mq-card-pan-container" height='1.75em' width='13.5em' />
          </Box>
          <Box
            direction={{ medium: 'row' }}
            margin={{ base: { top: '1x' }, medium: { top: '0' } }}
            flexWrap='wrap'
          >
            <Box direction='column'>
              <Text
                display='block'
                family='optimist_semibold'
                margin={{ base: '0', medium: { left: '5x', bottom: 'quadruple' } }}
                size='bodyMedium'
              >
                {expirationDateLabel}
              </Text>
              <Box
                id="mq-card-exp-container"
                margin={{ medium: { left: '5x' } }}
                height="1.75em"
                width="3.125em"
              />
            </Box>
            <Box direction='column'>
              <Text
                display='block'
                family='optimist_semibold'
                margin={{ left: '4x', medium: { bottom: 'quadruple' } }}
                size='bodyMedium'
              >
                {cvvLabel}
              </Text>
              <Box
                id="mq-card-cvv-container"
                margin={{ left: '4x' }}
                height="1.75em"
                width="2.8125em"
              />
            </Box>
          </Box>
        </Box>
        <Box margin={{ base: { top: '1.25x' }, medium: { top: '2.5x' } }} border={{ color: 'gray400', style: 'solid', side: 'top' }}>
        <StandardHorizontalDivider margin='0' />
        </Box>
        <Box
          padding={{
            top: '2x', bottom: '0.75x', medium: { horizontal: '3x' }, base: { horizontal: '2x' }
          }}
          flexWrap='wrap'
          justifyContent={{ base: 'flex-start', medium: 'space-evenly' }}
        >
          <Box direction={{ base: 'column', medium: 'row' }} width='full'>
            <Text family='optimist_semibold' margin={{ base: { bottom: '0' }, medium: { bottom: '1.75x' } }} size='bodyMedium'>
              {timeStampLabel}
            </Text>
            <Text
              margin={{ bottom: '1.75x', base: { left: '0' }, medium: { left: '4x' } }}
            >
              {
                useBy &&
                formatDateWithTimeZone(useBy)
              }
            </Text>
          </Box>
          <Box direction={{ base: 'column', medium: 'row' }} width='full'>
            <Text family='optimist_semibold' margin={{ base: { bottom: '0' }, medium: { bottom: '1.75x' } }} size='bodyMedium'>
              {merchantLabel}
            </Text>
            <Text
              margin={{ bottom: '1.75x', base: { left: '0' }, medium: { left: '4x' } }}
            >
              {
                mDomain &&
                <Link.inline href={mDomain} target='_blank'>{formatUrl(mDomain)}</Link.inline>
              }
            </Text>
          </Box>
          <Box direction={{ base: 'column', medium: 'row' }} width='full'>
            <Text family='optimist_semibold' margin={{ base: { bottom: '0' }, medium: { bottom: '1.75x' } }} size='bodyMedium'>
              {cardLimitLabel}
            </Text>
            <Text
              margin={{ bottom: '1.75x', base: { left: '0' }, medium: { left: '4x' } }}
            >
              {amount}
            </Text>
          </Box>
        </Box>
      </Box>
      {
        !displayLoader &&
        <Box justifyContent='center' margin={{ top: '5x' }}>
          <Button_t2_
            onClick={handleModalClose}
            variant='primary'
            width={{ min: '8.125em', base: 'full', medium: '8.125em' }}
          >
            {modalClose}
          </Button_t2_>
        </Box>
      }
    </Modal>
  );
};
