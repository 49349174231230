import { ProductStatuses } from '@Constants';
import { compareDateString } from '@Util/date';
import { SelectionOnAccountManagement } from '../queries/GetCustomerHomeG2.graphql';

const byProductIDComparator = (a: SelectionOnAccountManagement, b: SelectionOnAccountManagement) =>
  b.product.product_id - a.product.product_id

const byPaidOffDateComparator = (a: SelectionOnAccountManagement, b: SelectionOnAccountManagement) =>
  compareDateString(a.product.paid_off_date, b.product.paid_off_date);

const byExpirationDateComparator = (a: SelectionOnAccountManagement, b: SelectionOnAccountManagement) =>
  compareDateString(a.virtual_card.expiration_time, b.virtual_card.expiration_time);

const byEffectiveDateComparator = (a: SelectionOnAccountManagement, b: SelectionOnAccountManagement) =>
  compareDateString(a.charge_off_information?.effective_date, b.charge_off_information?.effective_date);

const sortChargedOffWithOutstandingBalanceServicingAccounts = (accounts: SelectionOnAccountManagement[]) => {
  const filteredChargedOffOutstandingBalance = accounts.filter(account =>
    account.charge_off_information?.is_operationally_charged_off && account.product.outstanding_balance.cents > 0
  )

  return filteredChargedOffOutstandingBalance.sort(byEffectiveDateComparator)
}

const sortChargedOffNoOutstandingBalanceServicingAccounts = (accounts: SelectionOnAccountManagement[]) => {
  const filteredChargedOff = accounts.filter(account =>
    account.charge_off_information?.is_operationally_charged_off && account.product.outstanding_balance.cents === 0
  )

  return filteredChargedOff.sort(byEffectiveDateComparator)
}

const sortPastDueServicingAccounts = (accounts: SelectionOnAccountManagement[]) => {
  const filteredPastDue = accounts.filter(account =>
    account.product.is_past_due &&
    account.product.status !== ProductStatuses.PaidOff &&
    account.product.status !== ProductStatuses.Cancelled &&
    !account.charge_off_information?.is_operationally_charged_off
  )

  return filteredPastDue.sort(byProductIDComparator)
}

const sortNotPastDueServicingAccounts = (accounts: SelectionOnAccountManagement[]) => {
  const filteredNotPastDue = accounts.filter(account =>
    !account.product.is_past_due &&
    account.virtual_card.is_used &&
    !account.charge_off_information?.is_operationally_charged_off &&
    account.product.status !== ProductStatuses.PaidOff &&
    account.product.status !== ProductStatuses.Cancelled
  )

  return filteredNotPastDue.sort(byProductIDComparator)
}

const sortPaidOffServicingAccounts = (accounts: SelectionOnAccountManagement[]) => {
  const filteredPaidOff = accounts.filter(account => account.product.status === ProductStatuses.PaidOff)

  return filteredPaidOff.sort(byPaidOffDateComparator)
}

const sortExpiredVirtualCardServicingAccounts = (accounts: SelectionOnAccountManagement[]) => {
  const filteredExpiredVirtualCard = accounts.filter(account =>
    account.virtual_card.is_expired &&
    !account.virtual_card.is_used &&
    !account.product.is_past_due &&
    account.product.status !== ProductStatuses.Cancelled &&
    account.product.status !== ProductStatuses.PaidOff
  )

  return filteredExpiredVirtualCard.sort(byExpirationDateComparator)
}

const sortCancelledServicingAccounts = (accounts: SelectionOnAccountManagement[]) => {
  const filteredCancelledAccounts = accounts.filter(account => account.product.status === ProductStatuses.Cancelled)

  return filteredCancelledAccounts.sort(byProductIDComparator)
}

export const getSortedCustomerHomeProductCards = (servicingAccounts: SelectionOnAccountManagement[]) => {
  const sortedPastDueAccounts = sortPastDueServicingAccounts(servicingAccounts)
  const sortedNotPastDueAccounts = sortNotPastDueServicingAccounts(servicingAccounts)
  const sortedPaidOffAccounts = sortPaidOffServicingAccounts(servicingAccounts)
  const sortedExpiredVirtualCardAccounts = sortExpiredVirtualCardServicingAccounts(servicingAccounts)
  const sortedChargedOffWithOutstandingBalanceAccounts = sortChargedOffWithOutstandingBalanceServicingAccounts(servicingAccounts)
  const sortedChargedOffNoOutstandingBalanceAccounts = sortChargedOffNoOutstandingBalanceServicingAccounts(servicingAccounts)
  const sortedCancelledServicingAccounts = sortCancelledServicingAccounts(servicingAccounts)

  const sortedServicingAccounts = [
    ...sortedChargedOffWithOutstandingBalanceAccounts,
    ...sortedPastDueAccounts,
    ...sortedNotPastDueAccounts,
    ...sortedPaidOffAccounts,
    ...sortedExpiredVirtualCardAccounts,
    ...sortedChargedOffNoOutstandingBalanceAccounts,
    ...sortedCancelledServicingAccounts
  ]

  const missingServicingAccounts = servicingAccounts.filter(account => !sortedServicingAccounts.includes(account));

  sortedServicingAccounts.push(...missingServicingAccounts);

  // ensure there are no duplicates
  return [...(new Set(sortedServicingAccounts))];
}
