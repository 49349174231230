import React from 'react';

import { BannerLinkAndTextWrapper } from '../../../common';
import { PastDuePaymentLink } from './common';
import { LoanBanner } from '..';
import { BannerNotificationsProps } from '../../index';
import { formatString } from '../../../../../../../../util/string';
import { FullBannerProps } from '../../types';
import { ServicingTasks } from '@Constants';
import { createAccountManagementPath } from 'src/routes';
import { useServicingTaskRedirect } from 'src/g2/servicing/components/ServicingTasks/utils/useServicingTaskRedirect';

const { bannerMessages: { pastDue }, bannerLinks: { schedulePayment } } = AvantConfig.TenantConfig.accountManagementDash;

export const PastDueBanner: React.FC<BannerNotificationsProps> =
({ bannerProps , servicingAccountUUID, variant}) => {
  const { getTaskLocation } = useServicingTaskRedirect();
  // non-null-assertion handled by checks on respective parent queries
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const makePaymentPath = createAccountManagementPath(servicingAccountUUID!, ServicingTasks.MakePayment.route);
  const {servicingAccount: { product }} = bannerProps as FullBannerProps

  return (
    <LoanBanner variant={variant}>
      <BannerLinkAndTextWrapper>
        <span>
          {formatString(pastDue, undefined,
            product.past_due_amount?.formatted)}
        </span>
        {!product.is_make_payment_disqualified && (
          <PastDuePaymentLink
            text={schedulePayment}
            dataEvent='bannerLoanSchedulePayment'
            destination={getTaskLocation(makePaymentPath)}
          />
        )}
      </BannerLinkAndTextWrapper>
    </LoanBanner>
  );
};
