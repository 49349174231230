import React, { useState } from 'react';

import sessionManager from '../../../../../util/sessionManager';
import { FileDownloader } from '../../../FileDownloader';
import { FormError } from '../../../Form';
import { ILoanProps, LinkContainer } from '../common';

import { LoanDashLink } from './LoanDashLink';

const creditScoreExceptionDisclosureDownload = ({ product, rowIndex }: ILoanProps) => ({
  endpoint: sessionManager.urlRouter.riskBasedPricingNoticeFileDownload,
  params: { applicationUuid: product.customerApplication.uuid },
  downloadName: 'CreditScoreExceptionDisclosure.pdf',
  text: 'Credit Score Exception Disclosure',
  'data-event': `creditScoreExceptionDisclosure-${rowIndex}`
});

const loanContractDownload = ({ product, rowIndex }: ILoanProps) => ({
  endpoint: sessionManager.urlRouter.contractFileDownload,
  params: { productUuid: product.uuid },
  downloadName: 'LoanContract.pdf',
  text: 'Loan Contract',
  'data-event': `loanContract-${rowIndex}`
});

export const LoanActions: React.FC<ILoanProps> = ({ product, rowIndex }) => {
  const { customerApplication, contracts, wasCanceled } = product;

  const [isError, setIsError] = useState(false);

  return (
    <LinkContainer>
      <LoanDashLink product={product} rowIndex={rowIndex} />
      {!!customerApplication.riskDisclosureConsent &&
        (
          <FileDownloader
            icon={'download'}
            updateError={setIsError}
            {...creditScoreExceptionDisclosureDownload({ product, rowIndex })}
          />
        )
      }
      {!!contracts.edges.length && !wasCanceled &&
        (
          <FileDownloader
            icon={'download'}
            updateError={setIsError}
            {...loanContractDownload({ product, rowIndex })}
          />
        )
      }
      {isError && <FormError>Error downloading file</FormError>}
    </LinkContainer>
  );
};
