import React from 'react';

import { BannerNotificationsProps } from '../../types';
import { isEligibleForFinancialSituationReview } from '../../loanBannerConditions';
import { LoanBanner } from '..';

import { EligibleFinancialSituationReview } from './EligibleFinancialSituationReview';
import { SettlementOfferAcceptance } from './SettlementOfferAcceptance';
import { LossMitigationAcceptance } from './LossMitigationAcceptance';

type FinancialSituationReviewProps = Pick<BannerNotificationsProps, 'settlementAcceptanceEligible' | 'uuid' | 'status'
  | 'lossMitigationPlanAcceptanceEligible' | 'financialSituationReviewEligible' | 'financialSituationReviewV2Eligible'
  | 'lossMitigationOfferExpirationDate' | 'settlementOfferExpirationDate'>;

const financialSituationReviewContent: (props: FinancialSituationReviewProps) => JSX.Element | null = props => {
  if (isEligibleForFinancialSituationReview(props)) {
    return <EligibleFinancialSituationReview {...props} />;
  } else if (props.lossMitigationPlanAcceptanceEligible) {
    return <LossMitigationAcceptance {...props} />;
  } else if (props.settlementAcceptanceEligible) {
    return <SettlementOfferAcceptance {...props} />;
  }

  return null;
};

export const FinancialSituationReview: React.FC<FinancialSituationReviewProps> = props => {
  const content = financialSituationReviewContent(props);

  if (!content) {
    return null;
  }

  return <LoanBanner style={props.status === 'current' ? 'success' : 'alert'}>{content}</LoanBanner>;
};
