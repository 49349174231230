import React from 'react';
import Styled from 'styled-components';
import {
  media,
  SVGIcon,
} from '@amount/frontend-components';

import {
  FragmentSelectionOnCreditCardAccount,
  SelectionOnCreditCard
} from '../../../../../components/CustomerHomeDash/queries/GetCustomerHomeInformation.graphql';
import { StyledProductDetails, StyledViewArrowSVG, ViewButtonWrapper, ViewCardLink } from 'src/components/CustomerHomeDash/common';
import { ConditionalRenderWrapper, FlexColumn, FlexRow } from '../../../../../components/Common/CommonStyles';
import ProductIdentifierHeadline from '../../../../../components/Common/ProductIdentifierHeadline';

interface ICreditCardIdentifierProps {
  lastFour: SelectionOnCreditCard['lastFour'];
  uuid: FragmentSelectionOnCreditCardAccount['uuid'];
  productName: FragmentSelectionOnCreditCardAccount['productName'];
}

const CreditCardIdentifierContainer = Styled(FlexColumn)`
  align-content: center;
`;

const SVGWrapper = Styled(SVGIcon)`
  svg {
    width: 1.375em;
    margin-top: 0.25em;
    margin-right: 0.5em;
    fill: ${props => props.theme.colorSlate};
    ${media.ie11`
      height: 1em;
    `}
  }
`;

const CreditCardIdentifier: React.FunctionComponent<ICreditCardIdentifierProps> = ({ lastFour, uuid, productName }) =>  (
  <StyledProductDetails marginBottom='0'>
    <FlexRow>
      <ConditionalRenderWrapper hiddenOnMobile={true}>
        <SVGWrapper icon='mini-card' />
      </ConditionalRenderWrapper>
      <CreditCardIdentifierContainer>
        <ProductIdentifierHeadline>
          {productName || 'Credit Card'} *{lastFour}
        </ProductIdentifierHeadline>
      </CreditCardIdentifierContainer>
    </FlexRow>
    <ConditionalRenderWrapper hiddenOnMobile={true}>
      <ViewCardLink uuid={uuid}>
        <ViewButtonWrapper buttonStyle='secondary' inline={true} size='tiny'>
          <strong>View Card</strong>
          <StyledViewArrowSVG icon='forward-arrow' iconStyle='brand' />
        </ViewButtonWrapper>
      </ViewCardLink>
    </ConditionalRenderWrapper>
  </StyledProductDetails>
);

export default CreditCardIdentifier;
