import React, { useState } from 'react';

import { Footer } from '../../../Footer';
import { PageContext } from '../../../util/adobeLaunch/PageContext';
import Header from '../Header';
import { IHeaderProps } from '../Header/common';
import { Header as HeaderThemeTwo, HeaderProps } from '../../../_shared/ThemeTwo/Header';

const { enabled: themeTwoHeaderEnabled, links: { default: defaultThemeTwoHeaderLinks } = {} } = AvantConfig.TenantConfig.header.themeTwo;
const themeTwoFooterEnabled = AvantConfig.TenantConfig.footer.themeTwo.enabled;

export const DashWrapper: React.FC<IHeaderProps> = props => {
  const [pageName, setPageName] = useState<string>('');

  const themeTwoHeaderProps: HeaderProps = {
    centeredLogo: props.centeredLogo,
    hideDropdownOnDesktop: props.hideDrawerOnDesktop,
    headerLinksConfig: props.headerLinksConfig || defaultThemeTwoHeaderLinks,
    isLogoClickable: props.isLogoClickable
  }

  return (
    <PageContext.Provider value={{ pageName, setPageName }}>
      {themeTwoHeaderEnabled ? <HeaderThemeTwo {...themeTwoHeaderProps} /> : <Header {...props} />}
      {props.children}
      {themeTwoFooterEnabled ? <>Theme 2 Footer Not Implemented</> : <Footer />}
    </PageContext.Provider>
  );
};
