import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { NavButton } from '@amount/frontend-components';

import { TakeoverLoader } from '../TakeoverLoader';
import { devToolsEnabled, IProductUuid } from '../../common';
import { devToolsClient } from '../../devToolsApolloClient';

import CompleteCurrentVerStageMutation, {
  CompleteCurrentVerStage as CompleteCurrentVerStageData,
  CompleteCurrentVerStageInput
} from './queries/CompleteCurrentVerStage.graphql';

export const CompleteCurrentVerStage: React.FC<IProductUuid> = ({ uuid }) => {
  if (!devToolsEnabled()) {
    return null;
  }

  const [completeCurrentVerStage, { loading }] = useMutation<CompleteCurrentVerStageData, CompleteCurrentVerStageInput>(
    CompleteCurrentVerStageMutation,
    {
      variables: { input: { customerApplicationUUID: uuid }},
      client: devToolsClient,
    },
  );

  const onClick = async () => {
    const response = await completeCurrentVerStage();

    if (response.data?.completeCurrentVerStage?.success) {
      window.location.reload();
    } else {
      alert('Failed to complete current ver stage, see backend error log');
    }
  };

  return (
    <>
      {
        loading && (
          <TakeoverLoader />
        )
      }
      <NavButton onClick={onClick}>
        Complete Current Ver Stage
      </NavButton>
    </>
  );
};
