import React from 'react';
import { Box, Header_t2_ } from '@amount/frontend-components';

import { SkipToContentLink } from '../../../components/Common/SkipLink';
import { Logo } from './components/Logo';
import { HeaderLinksList } from './components/HeaderLinksList';
import { HeaderLinksConfig } from 'src/customTypings/avant-config/tenantConfig';

const isLogoAlwaysCentered =  AvantConfig.TenantConfig.header.themeTwo.centeredLogo?.default;

export interface HeaderProps {
  centeredLogo?: boolean;
  hideDropdownOnDesktop?: boolean;
  headerLinksConfig?: HeaderLinksConfig;
  isLogoClickable?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ centeredLogo, hideDropdownOnDesktop, headerLinksConfig, isLogoClickable }) => {
  const shouldCenterLogo = isLogoAlwaysCentered || centeredLogo;

  return (
    <Box display='block'>
      <SkipToContentLink />

      <Header_t2_
        sections={
          // linter doesn't know that the Header from FEC handles the key prop
          /* eslint-disable react/jsx-key */
          (shouldCenterLogo ? [
            // We must include an extra element for the logic in Header_t2_ to calculate the correct flex-basis values
            //  when the logo is centered.
            //
            // If a link needs to be added to the top left side of the header, it can be nested inside of this Box.
            <Box />
          ] : []).concat([
          (
            <Box alignItems='center' flexGrow='1' justifyContent={shouldCenterLogo ? 'center' : 'flex-start'}>
              <Logo isClickable={isLogoClickable} />
            </Box>
          ),
          (
            <Box alignItems='center' flexGrow='1' justifyContent='flex-end' forwardedAs='nav'>
              <HeaderLinksList hideDropdownOnDesktop={hideDropdownOnDesktop} headerLinksConfig={headerLinksConfig} />
            </Box>
          ),
          /* eslint-enable react/jsx-key */
        ])}
      />
    </Box>
  );
}
