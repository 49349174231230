import React, { useContext } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import { LiftedAccountDash as MyAccount } from './modules/AccountDash';
import CustomerHomeDash from './modules/CustomerHomeDash';
import { LoggedOut } from './components/Common/Session/LoggedOut';
import { LoggedOut as LoggedOutThemeTwo } from './_shared/ThemeTwo/Session/LoggedOut';
import SessionManager from './util/sessionManager';
import { LoadableCardDash, LoadableLoanDash } from 'src/g1/servicing/modules/PreloadableModules';
import { LoadableVerDash } from './modules/PreloadableDashboards';
import { ApplicationBaseRoutes, ServicingRoutes } from './routes';
import NotFound from './components/Common/Error/NotFound';
import RegionsRedirectBanner from './components/Common/BannerNotifications/RegionsRedirectBanner';
import ServerError from './components/Common/Error/ServerError';
import { useNewCardDash } from './util';
import { ScrollToTop } from '@Util/scrollToTop';
import { LiftedCreditFreeze } from './components/CustomerHomeDash/CreditFreeze';
import { MakeItRain } from './components/Common/Confetti/MakeItRain';
import { GlobalWarningBanner } from './components/Common/BannerNotifications/GlobalWarningBanner';
import { devToolsEnabled } from './util/devTools/common';
import { DevTools } from './util/devTools';
import { RootContainer } from './components/Common/Box';
import { LiftedAllSetVCNCard } from './g2/servicing/components/AllSetVCNCard';
import { GuardedRoute } from './components/__helpers__/GuardedRoute';
import { hasMatchedReferrer } from './g2/servicing/components/AllSetVCNCard/utils/referrer';
import { AppContext } from './util/AppContextWrapper/AppContext';
import { AppContextWrapper } from '@Util/AppContextWrapper';

import { LiftedCustomerHomeModuleG2 } from './g2/_shared/modules/CustomerHome';
import { LiftedAccountHistoryModule as AccountHistoryG2 } from './g2/servicing/modules/AccountHistory';
import { LoadableAccountManagementModule, LoadableCustomerManagementModule } from './g2/servicing/modules/PreloadableModules';


export const AppWithoutRouter: React.FunctionComponent = () => {
  const { enableG2Dashboards } = useContext(AppContext);

  // Disable all routes if in MAINTENANCE_MODE
  if (process.env.REACT_APP_MAINTENANCE_MODE === '1') {
    return <ServerError />;
  }

  return (
    <RootContainer>
      {
        process.env.REACT_APP_REGIONS_SINGLE_TENANT_BANNER === '1' &&
        <RegionsRedirectBanner />
      }
      {
        !!process.env.REACT_APP_GLOBAL_BANNER_MESSAGE &&
        <GlobalWarningBanner htmlContent={process.env.REACT_APP_GLOBAL_BANNER_MESSAGE} />
      }
      <Switch>
        {
          enableG2Dashboards &&
          <GuardedRoute
            component={LiftedAllSetVCNCard}
            isGuarded={hasMatchedReferrer()}
            path={`${ApplicationBaseRoutes.vcnCard}/:uuid`}
            redirectPath={ServicingRoutes.dashboard}
          />
        }
        {
          AvantConfig.TenantConfig.customerHome.creditFreezePageEnabled &&
          <Route path={`${ApplicationBaseRoutes.creditFreeze}/:uuid`} component={LiftedCreditFreeze} />
        }
        {enableG2Dashboards &&
          <Route path={`${ApplicationBaseRoutes.customerManagement}`} component={LoadableCustomerManagementModule} />
        }
        {enableG2Dashboards &&
          <Route path={`${ApplicationBaseRoutes.accountManagement}/:uuid`} component={LoadableAccountManagementModule} />
        }
        {!enableG2Dashboards &&
          <Route path={`${ApplicationBaseRoutes.loanServicing}/:uuid`} component={LoadableLoanDash} />
        }
        <Route path={SessionManager.urlRouter.loggedOut} component={enableG2Dashboards ? LoggedOutThemeTwo : LoggedOut} />
        <Route path={`${ApplicationBaseRoutes.verify}/:uuid`} component={LoadableVerDash} />
        {// Disable all credit card routes until we enable card dash to be seen by customers
          useNewCardDash() &&
          <Route path={`${ApplicationBaseRoutes.cardServicing}/:uuid`} component={LoadableCardDash} />
        }
        <Route
          path={`${ApplicationBaseRoutes.account}`}
          component={enableG2Dashboards ? AccountHistoryG2 : MyAccount}
        />
        <Route
          path={ApplicationBaseRoutes.baseRoute}
          exact={true}
          component={enableG2Dashboards ? LiftedCustomerHomeModuleG2 : CustomerHomeDash}
        />
        <Route component={NotFound} />
      </Switch>
      {
        process.env.NODE_ENV === 'development' &&
        <MakeItRain />
      }
      {devToolsEnabled() &&
        <DevTools />
      }
    </RootContainer>
  );
};


const baseUrl = process.env.PUBLIC_URL;
const baseBrowserRouterProps = !!baseUrl ? { basename: baseUrl } : {};

const App: React.FunctionComponent = () => (
  <BrowserRouter {...baseBrowserRouterProps}>
    <AppContextWrapper>
      <ScrollToTop />
      <AppWithoutRouter />
    </AppContextWrapper>
  </BrowserRouter>
);

export default App;
