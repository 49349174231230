import React from 'react';
import { Text } from '@amount/frontend-components';

export interface INextPaymentLoanCardDisplayProps {
  title: string;
  content: string;
  color: string;
}

export const NextPaymentLoanCardDisplay: React.FC<INextPaymentLoanCardDisplayProps> = (props: INextPaymentLoanCardDisplayProps) => {
  const { title, content, color } = props;

  return (
    <>
      <Text
        display="flex"
        margin={{
          base: { bottom: '0' },
          mediumLarge: { bottom: '0.625x' },
        }}
        size={{
          base: 'heroMedium',
          mediumLarge: 'heroLarge',
        }}
        family="optimist_light"
        color={color}
      >
        {content}
      </Text>
      <Text
        display="flex"
        family="optimist_semibold"
        margin={{ top: '1x' }}
        size="bodyMedium"
        color={color}
      >
        {title}
      </Text>
    </>
  );
};
