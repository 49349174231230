import React from 'react';

import PageTitle from '../../../../components/Common/PageTitle';

import GetAccountHistoryDataQuery, {
  GetAccountHistoryData,
} from './queries/GetAccountHistoryData.graphql';
import { AccountHistory } from './Presentational';
import { AccountHistoryProps } from './common';
import { LiftedQuery } from '../ServicingTasks/utils/LiftedQuery';

const LiftedAccountHistory: React.FunctionComponent<AccountHistoryProps> = props => (
  <LiftedQuery<GetAccountHistoryData>
    query={GetAccountHistoryDataQuery}
    dataIsLoaded={data => !!data?.identity?.applications && !!data?.identity?.servicing_accounts}
    renderOnSuccess={data => (
      <>
        <PageTitle pageTitle={AvantConfig.TenantConfig.pageTitles.accountHistory} />
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <AccountHistory {...props} identity={data.identity!} />
      </>
    )}
  />
);

export default LiftedAccountHistory;
