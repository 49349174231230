import React from 'react';
import Styled from 'styled-components';
import { CircularProgressWithLabel, Datapoint, media } from '@amount/frontend-components';
import isNil from 'lodash-es/isNil';

import { FragmentSelectionOnLoan } from '../../CustomerHomeDash/queries/GetCustomerHomeInformation.graphql';
import { NA } from '../../../constants';
import { isUnresolvedPaymentProtectPointOfSale } from '../../../util';

import RepaymentContent from './RepaymentContent';

const RowWrapper = Styled.div`
  display: flex;
  justify-content: flex-start;

  svg {
    width: 100%;
  }

  > * {
    white-space: nowrap;
    padding: 0.125em;
    flex: 0 0 50%;
    max-width: 12em;

    ${media.small`
      min-width: 9em;
    `}
  }
`;

type RepaymentCircularProgress = Pick<
  FragmentSelectionOnLoan,
  'paidPrincipal' |
  'principalAmount' |
  'principalProgress' |
  'chargeOffInformation' |
  'pointOfSaleInformation' |
  'principalRemaining' |
  'aprPercentage'>;

const RepaymentCircularProgress: React.FunctionComponent<RepaymentCircularProgress> = data => {
  const {
    paidPrincipal,
    principalAmount,
    principalProgress,
    chargeOffInformation,
    pointOfSaleInformation,
    principalRemaining,
  } = data;

  if (!paidPrincipal || !principalAmount || isNil(principalProgress) || (!!pointOfSaleInformation && !principalRemaining)) {
    return <Datapoint size='large'>{NA}</Datapoint>;
  }

  const isActiveDispute = isUnresolvedPaymentProtectPointOfSale(pointOfSaleInformation);
  // floating point precision..
  const percentage: number = isActiveDispute ? 0 : Math.floor(principalProgress * 1000 / 10);
  const isOperationallyChargedOff: boolean = !!chargeOffInformation && chargeOffInformation.operationallyChargedOff;

  return (
    <RowWrapper>
      {!isOperationallyChargedOff && (
        <CircularProgressWithLabel
          label={!!pointOfSaleInformation ? 'Loan Paid' : 'Principal Paid'}
          percentage={percentage}
          animate={true}
        />
      )}
      <RepaymentContent {...data} paidPrincipal={paidPrincipal.formatted} principalAmount={principalAmount.formatted} />
    </RowWrapper>
  );
};

export default RepaymentCircularProgress;
