import React, { useEffect } from 'react';
import { isAfter } from 'date-fns';
import { ResolutionDecisions } from '@Constants';

import { LoanBanner } from '../..';
import { BannerNotificationsProps } from '../../../index';
import { ConsumerProductDispute } from '../../../types';
import { disputeIdsKey } from '../../../../common';
import { formatString } from '../../../../../../../../../util/string';
import { IMoney } from 'src/gql';

const ResolvedPaymentProtectedDisputeBannerTypes: string[] = [
  ResolutionDecisions.CustomerFavorBankChargeBack,
  ResolutionDecisions.CustomerFavorMerchantChargeBack
];
const { resolvedDisputeFullAmount, resolvedDisputeMerchantFavor } = AvantConfig.TenantConfig.accountManagementDash.bannerMessages

const useDisputeIdLocalStorageSet = (dispute: ConsumerProductDispute ) => {
  useEffect(() => () => {
    const previouslyShownDisputeIds = JSON.parse(localStorage.getItem(disputeIdsKey) || '[]') as string[];
    if (!previouslyShownDisputeIds.includes(dispute.dispute_id)) {
      previouslyShownDisputeIds.push(dispute.dispute_id);
    }
    localStorage.setItem(disputeIdsKey, JSON.stringify(previouslyShownDisputeIds));
  }, []);
}

export const getLatestDisputeByResolutionDate =
  (disputes: Array<ConsumerProductDispute | null>) => {

  if (!disputes.length) { return null }

  return disputes.reduce((acc, curr) => {
    if (curr?.resolution_date) {
      if (!acc?.resolution_date || isAfter(new Date(curr.resolution_date), new Date(acc.resolution_date)) ) {
        acc = curr;
      }
    }

    return acc;
  });
}

export const filterPaymentProtectedMerchantFavorDisputes =
  (disputes: Array<ConsumerProductDispute | null>) => {

  return disputes.filter(
    dispute => (
      dispute?.resolution_decision === ResolutionDecisions.MerchantFavor &&
      dispute.is_payment_protection_applied &&
      dispute.resolution_date &&
      dispute.resolution_amount?.cents
  ))
}

export const filterPaymentProtectedDisputes =
 (disputes: Array<ConsumerProductDispute | null>) => {
  return disputes.filter(
    dispute => (
      dispute?.resolution_decision &&
      ResolvedPaymentProtectedDisputeBannerTypes.includes(dispute.resolution_decision) &&
      dispute?.is_payment_protection_applied &&
      dispute?.resolution_date &&
      dispute?.resolution_amount?.cents
  ))
}

export const isResolvedPaymentProtectedMerchantFavorDispute:
 (disputes: Array<ConsumerProductDispute | null>) => boolean = disputes => {
  if (!disputes.length) { return false }
  const filteredDisputes = filterPaymentProtectedMerchantFavorDisputes(disputes);

  return !!filteredDisputes.length && !!getLatestDisputeByResolutionDate(filteredDisputes);
};

export const isResolvedPaymentProtectedDispute:
  (disputes: Array<ConsumerProductDispute | null>) => boolean = disputes => {
  if (!disputes.length) { return false }
  const filteredDisputes = filterPaymentProtectedDisputes(disputes);

  return !!filteredDisputes.length && !!getLatestDisputeByResolutionDate(filteredDisputes)
};


type ResolvedPaymentProtectedDisputeMerchantFavorProps =
  Pick<BannerNotificationsProps, 'variant'> &
  { dispute: ConsumerProductDispute };

type ResolvedPaymentProtectedDisputeProps =
  ResolvedPaymentProtectedDisputeMerchantFavorProps &
  { refundAmount?: Partial<IMoney> };

export const ResolvedPaymentProtectedDisputeBanner: React.FC<ResolvedPaymentProtectedDisputeProps> =
  ({ variant, dispute, refundAmount }) => {
    useDisputeIdLocalStorageSet(dispute);

    return (
      <LoanBanner variant={variant}>
        <span>
          {formatString(resolvedDisputeFullAmount, undefined, dispute.dispute_id, refundAmount?.formatted)}
        </span>
      </LoanBanner>
    );
  };

export const ResolvedPaymentProtectedDisputeMerchantFavorBanner: React.FC<ResolvedPaymentProtectedDisputeMerchantFavorProps> =
  ({ variant, dispute }) => {
    useDisputeIdLocalStorageSet(dispute);

    return (
      <LoanBanner variant={variant}>
        <span>
          {formatString(resolvedDisputeMerchantFavor, undefined, dispute.dispute_id)}
        </span>
      </LoanBanner>
    );
  };
