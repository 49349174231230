import * as React from 'react';
import Styled from 'styled-components';
import { BreakPointsMap, media, SVGIcon, VALID_SVG_ICONS } from '@amount/frontend-components';

interface ICollapsibleSubNavLinkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: VALID_SVG_ICONS;
  theme?: 'brand' | 'grey';
  active?: boolean;
}

export class CollapsibleSubNavLink extends React.PureComponent<ICollapsibleSubNavLinkProps> {
  public render () {
    const { icon, theme, name, ...rest } = this.props;

    return (
      <CollapsibleSubNavButtonContain>
        <CollapsibleSubNavButton {...rest}>
          <SVGIcon
            icon={icon}
            iconStyle={theme}
            aria-hidden={false}
            // this comes from an internal config
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            iconTitle={name!}
          />
          <SubNavSpan aria-hidden={true}>{name}</SubNavSpan>
        </CollapsibleSubNavButton>
      </CollapsibleSubNavButtonContain>
    );
  }
}

interface ISubNavButtonProps {
  active?: boolean;
}

const SubNavButton =
 Styled.button<ISubNavButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme: { header }, active }) => active ? header.colorSubNavLinkHover : header.colorSubNavLink};
  border-bottom: 2px solid ${({ theme: { header }, active }) => active ? header.colorSubNavLinkHover : 'transparent'};
  border-top: 2px solid transparent;
  white-space: nowrap;
  height: 100%;
  width: 100%;

  svg {
    fill: ${({ theme: { header }, active }) => active ? header.colorSubNavLinkHover : header.colorSubNavLink};
  }

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme: { header } }) => header.colorSubNavLinkHover};
    border-bottom: 2px solid ${({ theme: { header } }) => header.colorSubNavLinkHover};

    svg {
      fill: ${({ theme: { header } }) => header.colorSubNavLinkHover};
    }
  }
`;

const SubNavButtonContain = Styled.li`
  flex: 1 0 auto;
  padding-right: 0;

  svg {
    display: flex;
    height: 1.5em;
    width: 1.5em;
    margin: 0 0.5em 0 0;
  }

  span {
    display: flex;
  }
`;

const CollapsibleSubNavButtonContain = Styled(SubNavButtonContain)`
  ${media.small`
    flex: 0;
    padding-right: 1em;

    &:last-child {
      padding-right: 0;
    }
  `}

  ${media.build({ minWidth: BreakPointsMap.small, ie11: true })`
    flex: 0 0 auto;
  `}
`;

const CollapsibleSubNavButton = Styled(SubNavButton)`
  ${media.small`
    justify-content: normal;
    width: unset;
  `}
`;

const SubNavSpan = Styled.span`
  text-align: right;
  height: 1.5em;
`;
