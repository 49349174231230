import React from 'react';
import Styled from 'styled-components';
import {
  Card,
  CardLabel,
  media,
  SVGIcon
} from '@amount/frontend-components';
import { isCreditCard, sortedDisplayableProducts } from '@amount/frontend-product-utilities';
import { isBefore, subMonths } from 'date-fns';

import {
  SelectionOnCustomer,
  SelectionOnCustomerApplication,
  SelectionOnCustomerApplicationEdge,
} from '../queries/GetCustomerHomeInformation.graphql';
import LoanProductCard from '../LoanProductCard';
import CreditCardProductCard from '../../../g1/servicing/components/CreditCard/CreditCardProductCard';
import { ApplicationCard } from '../ApplicationCard';
import {
  generateLocalStorageKeyWithId,
  getLocalStorageInfo,
  ILocalStorageInfo,
} from '../../../util';
import { ConditionalRenderWrapper, FlexColumn, FlexRow, NoDataWrapper } from '../../Common/CommonStyles';
import sessionManager from '../../../util/sessionManager';
import { CardContainer } from '../common';
import { IApplicationLink } from '../ApplicationCard/common';
import ApplicationCardCta from '../ApplicationCard/ApplicationCardCta';

import { CategoryHeader } from './CategoryHeader';

const CardLabelWrapper = Styled(CardLabel)`
  line-height: normal;
  padding-bottom: 0.25em;
`;

const NewLoanWrapper = Styled.div`
  svg {
    padding-top: 0.375em;
    width: 3.75em;
    padding-right: 1.5em;

    ${media.ie11`
      // IE11 applies a default 150px height to SVGs unless otherwise specified
      // see https://css-tricks.com/scale-svg/
      height: 3.75em;
    `}
  }
`;

/*
 * fix for IE11; flex items will overflow their bounds if not given either
 * an explicitly defined flex spec, or a explicitly defined width.
 * see https://stackoverflow.com/a/35113633
 */
const FlexColumnAsFlexItem = Styled(FlexColumn)`
  flex: 1;
`;

const showAppKey: string = 'showApp';

interface ICustomerHomeCardsState {
  hiddenApplications: Set<string>;
}

type CustomerHomeCardsProps = Pick<
  SelectionOnCustomer,
  | 'customerApplications'
  | 'products'
  | 'bankAccount'
  | 'canUpdateBankAccount'
  | 'canReapply'
  | 'eligibleToUpdateIncomeAndHousing'>;

const ReapplyCard:  React.FunctionComponent = () => {
  const cardLink: IApplicationLink = {
    text: 'Begin New Application',
    route: sessionManager.urlRouter.beginNewAppUrl,
    redirect: true,
  };

  return (
    <>
      {AvantConfig.TenantConfig.hasReapplyLink &&
        <CardContainer key='no_applications'>
          <Card padding='1.25em 1.5em'>
            <FlexRow>
              <ConditionalRenderWrapper hiddenOnMobile={true}>
                {AvantConfig.TenantConfig.customerHome.showApplicationImage && (
                  <NewLoanWrapper>
                    <SVGIcon icon='new-loan' iconStyle='secondary' />
                  </NewLoanWrapper>
                )}
              </ConditionalRenderWrapper>
              <FlexColumnAsFlexItem>
                <CardLabelWrapper id='card-headline'>
                  {!!AvantConfig.TenantConfig.applyMessage ? AvantConfig.TenantConfig.applyMessage : 'Apply for a personal loan today!'}
                </CardLabelWrapper>
                <span>
                  Checking your loan options does not affect your credit score.
                </span>
                <ApplicationCardCta
                  cardLink={cardLink}
                  dataEvent='beginNewApplication'
                  inline={false}
                />
              </FlexColumnAsFlexItem>
            </FlexRow>
          </Card>
        </CardContainer>
      }
    </>
  );
};

class CustomerHomeCards extends React.Component<CustomerHomeCardsProps, ICustomerHomeCardsState> {
  public constructor (props: CustomerHomeCardsProps, state: ICustomerHomeCardsState) {
    super(props, state);

    let hiddenApplications: Set<string> = new Set<string>();

    if (props.customerApplications.edges.length) {
      hiddenApplications = props.customerApplications.edges.reduce<Set<string>>(
        (acc, { node }) => {
          const LOCAL_STORAGE_KEY = generateLocalStorageKeyWithId(showAppKey, node.uuid);
          const hideApp: ILocalStorageInfo = getLocalStorageInfo(LOCAL_STORAGE_KEY);

          return hideApp.isTrue() ? acc.add(node.uuid) : acc;
        },
        new Set()
      );
    }
    this.state = { hiddenApplications };
  }

  public render () {
    const { canReapply } = this.props;

    const apps = this.getOpenAppsOrDeclinedApp();
    const productCards = this.getProductCards();

    if ((!apps || !apps.length) && !productCards.length && !canReapply) {
      return (
        <NoDataWrapper>You have no products or applications at this time.</NoDataWrapper>
      );
    }

    return (
      <>
        {(!!apps && !!apps.length || canReapply) && (
          <>
            <CategoryHeader as='h2'>Applications</CategoryHeader>
            {canReapply &&
              <ReapplyCard />
            }
            {
              !!apps &&
              !!apps.length &&
              apps.map(app => <ApplicationCard application={app.node} key={app.node.id} dismissApplication={this.dismissApplication} />)
            }
          </>
        )}
        {!!productCards.length && (
          <>
            <CategoryHeader as='h2'>Products</CategoryHeader>
            {productCards}
          </>
        )}
      </>
    );
  }

  // show all open apps
  // OR the most recent declined app
  private readonly getOpenAppsOrDeclinedApp: () => SelectionOnCustomerApplicationEdge[] | null = () => {
    if (!this.props.customerApplications.edges.length) { return null; }

    // get all open apps
    const openApps: SelectionOnCustomerApplicationEdge[] =
      this.props.customerApplications.edges.filter(app => this.isAppOpen(app.node));

    if (openApps.length) { return openApps; }

    const mostRecentDeclinedApp: SelectionOnCustomerApplicationEdge | null  =
      this.props.customerApplications.edges.reduce<SelectionOnCustomerApplicationEdge | null>(
        (mostRecent, curr) => {
          if (curr.node.declined) {
            return !mostRecent ? curr : this.compareMostRecentApps(mostRecent, curr);
          }

          return mostRecent;
        },
        null
      );

    // TODO: remove once avant-basic only returns applications from the last month
    const lastMonthDate = subMonths(new Date(), 1);
    if (
      !mostRecentDeclinedApp ||
      !mostRecentDeclinedApp.node.createdAt ||
      isBefore(mostRecentDeclinedApp.node.createdAt, lastMonthDate)
    ) { return null; }
    const { node } = mostRecentDeclinedApp;

    if (this.state.hiddenApplications.has(node.uuid)) { return null; }

    return [mostRecentDeclinedApp];
  }

  private readonly getProductCards: () => React.ReactNode[] = () => {
    const { products, canUpdateBankAccount, bankAccount, eligibleToUpdateIncomeAndHousing } = this.props;
    const sortedProducts = sortedDisplayableProducts(products);

    return sortedProducts.reduce<React.ReactNode[]>(
      (productCards, product) => {
        if (isCreditCard(product)) {
          productCards.push(
            <CreditCardProductCard
              eligibleToUpdateIncomeAndHousing={eligibleToUpdateIncomeAndHousing}
              key={product.uuid}
              product={product}
            />
          );
        } else {
          productCards.push(
            <LoanProductCard
              loan={product}
              bankAccount={bankAccount}
              canUpdateBankAccount={canUpdateBankAccount}
              key={product.uuid}
            />
          );
        }

        return productCards;
      },
      []
    );
  }

  private readonly compareMostRecentApps: (
    app1: SelectionOnCustomerApplicationEdge,
    app2: SelectionOnCustomerApplicationEdge
  ) => SelectionOnCustomerApplicationEdge = (
    app1,
    app2,
  ) => {
    if (!app1.node.createdAt) { return app2; }
    if (!app2.node.createdAt) { return app1; }

    return app1.node.createdAt < app2.node.createdAt ? app2 : app1;
  }

  private readonly isAppOpen: (application: SelectionOnCustomerApplication) => boolean = app =>
  app.isOpen && !!app.status

  private readonly dismissApplication: (application: SelectionOnCustomerApplication) => void = application => {
    const LOCAL_STORAGE_KEY = generateLocalStorageKeyWithId(showAppKey, application.uuid);
    const hideApp: ILocalStorageInfo = getLocalStorageInfo(LOCAL_STORAGE_KEY);
    hideApp.setTrue();

    this.setState(({ hiddenApplications }) => {
      const hiddenApplicationsCopy = new Set(hiddenApplications);
      hiddenApplicationsCopy.add(application.uuid);

      return { hiddenApplications: hiddenApplicationsCopy };
    });
  }
}

export default CustomerHomeCards;
