import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import devToolsHttpLink from './devToolsHttpLink';

import { baseLinks } from '../../apolloClient';
import FragmentTypes from '../../config/devToolsFragmentTypes';

const fragmentMatcher: IntrospectionFragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: FragmentTypes
});

const cache = new InMemoryCache({
  fragmentMatcher
});


const link: ApolloLink = ApolloLink.from([
  baseLinks,
  devToolsHttpLink
]);

export const devToolsClient = new ApolloClient({
  link,
  cache
});
