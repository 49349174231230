import React from 'react';
import Styled from 'styled-components';
import { CardLabel, media } from '@amount/frontend-components';

import {
  SelectionOnCustomer
} from '../queries/GetCustomerHomeInformation.graphql';

import { DepositAccount } from './Cards/DepositAccount';

const CategoryHeaderRightColumn = Styled(CardLabel)`
  margin-top: 0;

  ${media.medium`
    margin-top: 1em;
  `}
`;

interface ICrossSellOfferProps {
  crossSellInformation: SelectionOnCustomer['crossSellInformation'];
}

const CrossSellOffers: React.FunctionComponent<ICrossSellOfferProps> = ({
  crossSellInformation
}) => {

  if (!crossSellInformation.length || !AvantConfig.TenantConfig.customerHome.crossSellOffers) {
    return null;
  }

  return (
    <>
      <CategoryHeaderRightColumn as='h2'>Offers</CategoryHeaderRightColumn>
      {crossSellInformation.map(({ crossSellType, data }, i) => {
        switch (crossSellType) {
          case 'deposit_account':
            return <DepositAccount data={data} key={i} />;
          default:
            return null;
        }
      })}
  </>
  );
};

export default CrossSellOffers;
