import React from 'react';
import { ISVGIconProps, Spinner, SVGIcon, VALID_SVG_ICONS } from '@amount/frontend-components';
import * as Sentry from '@sentry/browser';

import sessionManager from '../../../util/sessionManager';
import { StandaloneButton } from '../StandaloneLink';

interface IProductParams {
  productUuid: string;
}

interface IApplicationParams {
  applicationUuid: string;
}

interface INoaaParams {
  adverseActionNoticeId: string;
}

interface NoaaG2Params {
  application_uuid: string;
  notice_identifier: string;
}

interface IFileDownloaderProps {
  endpoint: string;
  params?: IProductParams | IApplicationParams | INoaaParams | NoaaG2Params;
  downloadName: string;
  text: string;
  icon?: VALID_SVG_ICONS;
  iconProps?: Partial<ISVGIconProps>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<any>;
  'data-event'?: string;
  'aria-describedby'?: string;
  updateError (error: boolean): void;
}

interface IFileDownloaderState {
  loading: boolean;
}

const revokeObjectURLTimeout: number = 100;

export class FileDownloader extends React.PureComponent<IFileDownloaderProps, IFileDownloaderState> {

  public state: IFileDownloaderState = {
    loading: false
  };

  public render () {
    const { icon, iconProps = {}, component: Component = StandaloneButton } = this.props;

    return (
      <Component
        onClick={this.downloadPdf}
        disabled={this.state.loading}
        data-event={this.props['data-event']}
        aria-describedby={this.props['aria-describedby']}
      >
        {this.props.text}
        {!this.state.loading && !!icon && <SVGIcon aria-hidden={false} iconTitle={'download'} icon={icon} {...iconProps} />}
        {this.state.loading && <Spinner small={true} />}
      </Component>
    );
  }

  private readonly downloadPdf: React.MouseEventHandler<HTMLButtonElement> = async () => {
    this.setState({ loading: true });
    this.props.updateError(false);

    const url = new URL(this.props.endpoint);
    if (this.props.params) {
      Object.entries(this.props.params)
        .forEach(([key, value]) => {
          url.searchParams.append(key, value);
        });
    }

    try {
      const response = await fetch(url.href, {
        headers: {
          'Content-Type': 'application/json',
          'X-Avant-Token': sessionManager.token || ''
        }
      });

      if (!response.ok) {
        Sentry.addBreadcrumb({
          message: response.statusText,
          level: Sentry.Severity.Error,
        });
        Sentry.captureMessage(
          response.statusText || 'Failed to download file',
          Sentry.Severity.Error
        );
        throw new Error(response.statusText);
      }

      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const blob = new Blob([await response.blob()], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);

        return;
      }

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = this.props.downloadName;
      // Use dispatchEvent instead of '.click()' to be more browser compatible
      // Doesn't work on IE11
      try {
        const mouseEvent: MouseEvent = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });
        link.dispatchEvent(mouseEvent);
      } catch (e) {
        // catch in IE
        link.click();
      }

      // For Firefox it is necessary to delay revoking the ObjectURL
      setTimeout(
        () => {
          window.URL.revokeObjectURL(downloadUrl);
        },
        revokeObjectURLTimeout
      );
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      this.props.updateError(true);
    }
  }
}
