import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Styled from 'styled-components';
import { Button, SelectInput, SelectOption } from '@amount/frontend-components';
import { TakeoverLoader } from '../TakeoverLoader';
import { devToolsEnabled } from '../../common';
import { devToolsClient } from '../../devToolsApolloClient';
// eslint-disable-next-line max-len
import GetCustomerHomeInformationQuery, { GetCustomerHomeInformation } from '../../../../components/CustomerHomeDash/queries/GetCustomerHomeInformation.graphql';
import { Loading } from '../../../../components/Common/Spinner/LoadingHOC';
// eslint-disable-next-line max-len
import IssueAndFundProductMutation, { IssueAndFundProduct as IssueAndFundProductData, IssueAndFundProductInput } from './queries/IssueAndFundProduct.graphql';

const ButtonWithMargin = Styled(Button)`
  margin-top: 1em;
`;

interface IIssueAndFund {
  data: GetCustomerHomeInformation;
}

const IssueAndFund: React.FC<IIssueAndFund> = ({ data }) => {
  if (!devToolsEnabled()) {
    return null;
  }

  const productEdges = data.me.products.edges;

  if (!productEdges.length) {
    return <p>No products</p>;
  }

  const [currentProductUuid, setCurrentProductUuid] = useState<string>(productEdges[0].node.uuid);

  const [issueAndFundProduct, { loading: mutationLoading }] = useMutation<IssueAndFundProductData, IssueAndFundProductInput>(
    IssueAndFundProductMutation,
    {
      variables: { input: { productUUID: currentProductUuid} },
      client: devToolsClient,
    },
  );
  const onClick = async () => {
    const response = await issueAndFundProduct();
    if (response.data?.issueAndFundProduct?.success) {
      window.location.reload();
    } else {
      alert('Failed to issue and fund product, see backend error log');
    }
  };
  const handleChange: (event: React.FormEvent<HTMLSelectElement>) => void = event => {
    if (!event.currentTarget) {
      return;
    }

    const newProduct = productEdges.find(({ node }) => node.uuid === event.currentTarget.value);

    setCurrentProductUuid(newProduct?.node.uuid || '');
  };

  return (
    <>
      {
        mutationLoading && (
          <TakeoverLoader />
        )
      }
      <label>
        Product
      </label>
      <SelectInput onChange={handleChange}>
        {
          data.me.products.edges.map<React.ReactNode>(edge => (
            <SelectOption key={edge.node.uuid} value={edge.node.uuid}>{`${edge.node.__typename} - ${edge.node.id}`}</SelectOption>
          ))
        }
      </SelectInput>
      <ButtonWithMargin onClick={onClick} size='tiny'>
        Fund and Issue Loan
      </ButtonWithMargin>
    </>
  );
};

export const IssueAndFundProductSection: React.FC = () => {
  const { loading, data } = useQuery<GetCustomerHomeInformation>(
    GetCustomerHomeInformationQuery,
  );

  if (loading) {
    return <Loading loading={true} />;
  }
  if (!data) {
    return <p>Error loading IssueAndFundProduct</p>;
  }
  return <IssueAndFund data={data} />;
};
