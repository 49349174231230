import { createPortal } from 'react-dom';
import { ApplicationProductType } from '../constants';
import { ICustomerApplication, VerificationsStatus } from '../gql';

export const isPointOfSaleProduct: (productType?: string | null) => boolean = productType => (
  productType === ApplicationProductType.PointOfSale
);

export const isInstallmentLoanProduct: (productType: string | null) => boolean = productType => (
  productType === ApplicationProductType.Loan
);

export const isCreditCardProduct: (productType: string | null) => boolean = productType => (
  productType === ApplicationProductType.CreditCard
);

interface IDisputePointOfSaleInformation {
  dispute: {
    paymentProtected: boolean;
    resolution: {} | null;
  } | null;
}

export const isUnresolvedPaymentProtectPointOfSale: (pointOfSaleInformation: IDisputePointOfSaleInformation | null) => boolean
= information =>
  !!information?.dispute?.paymentProtected && !information?.dispute.resolution;

export const formatMoney = (amount?: number | null) => amount ? amount.toLocaleString(undefined, {
  currency: 'USD',
  style: 'currency',
  maximumFractionDigits: 2,
  // should not show decimals for whole number values
  minimumFractionDigits: Number.isInteger(amount) ? 0 : 2,
}) : '';

export const formatPercent = (percent: number) => percent.toLocaleString(undefined, {
  style: 'percent',
  maximumFractionDigits: 2
});

export interface GetQueryStringReturnType { [key: string]: string }

export const getQueryStringParams: (query: string) => GetQueryStringReturnType = query => {
  if (!query) {
    return {};
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce<{ [key: string]: string }>(
      (params, param) => {
        const [ key, value ] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';

        return params;
      },
      {}
    );
};

const LS_AFFIRMATIVE_KEY: string = 'true';
const LS_NEGATIVE_KEY: string = 'false';

const tenant = process.env.TENANT;

export const generateLocalStorageKey: (key: string) => string = key => `${tenant}:dash:${key.replace(' ', ':')}`;

export const generateLocalStorageKeyWithId: (key: string, uuid: string) => string = (key, uuid) =>
  `${tenant}:dash:${key.replace(' ', ':')}-${uuid}`;

export interface ILocalStorageInfo {
  /**
   * If the value is true
   */
  isTrue (): boolean;
  /**
   * Whether the key has previously been set
   */
  isSet (): boolean;
  /**
   * Set the value to true
   */
  setTrue (): void;
  /**
   * Set the value to false
   */
  setFalse (): void;
  /**
   * Clear the value
   */
  clear (): void;
}

export const getLocalStorageInfo: (key: string) => ILocalStorageInfo = key => (
  {
    isTrue: () => localStorage.getItem(key) === LS_AFFIRMATIVE_KEY,
    isSet: () => localStorage.getItem(key) !== null,
    setTrue: () => localStorage.setItem(key, LS_AFFIRMATIVE_KEY),
    setFalse: () => localStorage.setItem(key, LS_NEGATIVE_KEY),
    clear: () => localStorage.removeItem(key),
  }
);

export const sortOnTruthiness: <T>(arr: T[], predicate: (value: T) => boolean) => T[] = (arr, predicate) => {
  if (!arr.length) { return []; }

  return (
    arr
    .reduce(
      (acc, curr) => {
        acc[predicate(curr) ? 1 : 0].push(curr);

        return acc;
      },
      [[], []] as Array<typeof arr>
    )
    .reduce((acc, curr) => [...acc, ...curr])
  );
};

export const VER_PREREQ_STAGE: string = 'gather_prerequisites';

export const isInGatheringPrereqs: (stage: string | null) => boolean = stage => !!stage && stage === VER_PREREQ_STAGE;

export const VER_CREDIT_SCORE_DISCLOSURE: string = 'credit_score_disclosure';

export const isInCreditScoreDisclosure: (stage: string | null) => boolean = stage => !!stage && stage === VER_CREDIT_SCORE_DISCLOSURE;

const VER_DECLINE_REVIEW_STAGE: string = 'decline_review';

export const isInDeclineReview: (stage?: string | null) => boolean = stage => !!stage && stage === VER_DECLINE_REVIEW_STAGE;

const COMPLETED_VER_STAGES: Set<string> = new Set([
  'tech_review',
  'initial_partner_api',
  'review',
  'pre_contract_api',
  'post_contract'
]);

export const isInCompletedVerStage: (stage?: string | null) => boolean = stage => !!stage && COMPLETED_VER_STAGES.has(stage);

interface IVerTaskInfo {
  completed: boolean;
  failed: boolean;
  reopened: boolean;
  required: boolean;
  pending: boolean;
}

type GetTaskInformation = <T extends IVerTaskInfo>(task: T | null | undefined) => boolean;

// Task is "Completed" when task status is completed or if task has been failed.
export const isTaskComplete: GetTaskInformation = task => !task || task.completed || task.failed;
export const isTaskRequired: GetTaskInformation = task => !task || task.required;
export const isTaskPending: GetTaskInformation = task => !task || task.pending;
export const isTaskReopened: GetTaskInformation = task => !task || task.reopened;
export const isTaskAccessible: GetTaskInformation = task => isTaskPending(task) || isTaskComplete(task);

type UnlockCreditReportRequired = (tasks?: ICustomerApplication['verificationTasksV2']) => boolean;

export const unlockCreditReportRequired: UnlockCreditReportRequired = tasks =>
  !!tasks?.some(task =>
    task.__typename === 'Verifications__UnlockCreditReportTask__V2' &&
      task.status === VerificationsStatus.opened
  );

interface IMoney {
  formatted: string;
}

export const getFormattedAmount: <T extends IMoney>(amountWithOtherOriginationFees?: T | null, amount?: T | null) => string | undefined = (
  amountWithOtherOriginationFees, amount
) => (
 amountWithOtherOriginationFees?.formatted || amount?.formatted || undefined
);

export const isProduction: () => boolean = () => process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENVIRONMENT === 'production';

export const enableApolloDevTools: () => boolean = () =>
  !isProduction() && !!process.env.REACT_APP_ENABLE_APOLLO_DEV_TOOLS;

export const isReactIntegrationEnvironment: () => boolean = () => process.env.REACT_APP_ENVIRONMENT === 'integration';

export const isNewCardDashQueryParamSet: () => boolean = () => {
  const qsParams: { [key: string]: string | undefined } = getQueryStringParams(window.location.search);

  return !!qsParams.useNewCardDash && (qsParams.useNewCardDash.toLowerCase() === 'true' || qsParams.useNewCardDash === '1');
};

export const JWT_TOKEN_KEY: string = generateLocalStorageKey('jwt-token');
export const LS_ENABLED_CARD_DASH: string = generateLocalStorageKey('enable-card-dash');
export const LS_DISMISSED_INCOME_AND_HOUSING: string = generateLocalStorageKey('dismissed-income-and-housing');
export const TOKEN_COOKIE = 'customer_dash_token';

export const incomeAndHousingWasDismissed: () => boolean = () =>
  getLocalStorageInfo(LS_DISMISSED_INCOME_AND_HOUSING)
    .isTrue();

export const dismissIncomeAndHousing: () => void = () =>
  getLocalStorageInfo(LS_DISMISSED_INCOME_AND_HOUSING)
    .setTrue();

const newCardDashExperimentEnabled: () => boolean = () =>
  getLocalStorageInfo(LS_ENABLED_CARD_DASH)
    .isTrue();

export const useNewCardDash: () => boolean = () =>
  // production uses experiment value
  isProduction()
    ? newCardDashExperimentEnabled()
    // all other envs check experiment, query param, env var, and node env
    : newCardDashExperimentEnabled()
    || isNewCardDashQueryParamSet()
    || process.env.REACT_APP_ENABLE_CARD_DASH === '1'
    || process.env.NODE_ENV === 'development';

export function renderModalInModalRoot(modalContent: React.ReactNode) {
  const modalRoot = document.getElementById('modal-root');
  return modalRoot ? createPortal(modalContent, modalRoot) : null;
}
