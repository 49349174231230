import React from 'react';
import Styled from 'styled-components';

import { FragmentSelectionOnLoan } from '../../CustomerHomeDash/queries/GetCustomerHomeInformation.graphql';
import { FlexColumn } from '../CommonStyles';
import { isUnresolvedPaymentProtectPointOfSale } from '../../../util';
import { ZeroAmountFormatted } from '../../../constants';

import RepaymentDataDisplay, { IRepaymentDataPointProps } from './RepaymentDataDisplay';

const ColumnWrapper = Styled(FlexColumn)`
  align-items: flex-start;
  justify-content: space-between;
  a {
    padding-top: 1em;
  }
`;

export type RepaymentContentProps = { paidPrincipal: string; principalAmount: string }
  & Pick< FragmentSelectionOnLoan, 'chargeOffInformation' | 'pointOfSaleInformation' | 'principalRemaining' | 'aprPercentage'>;

const paidDisplay: (showPoSCopy: boolean, isActiveDispute: boolean, isChargedOff: boolean, paidPrincipal: string, recoveredPrincipal: string)
=> IRepaymentDataPointProps = (showPoSCopy, isActiveDispute, isChargedOff, paidPrincipal, recoveredPrincipal) => {
  const title: string = showPoSCopy ? 'Amount Paid' : isChargedOff ? 'Payments Since Charge-Off' : 'Principal Paid';
  const paidAmount: string =  isChargedOff ? recoveredPrincipal : paidPrincipal;
  const content: string = isActiveDispute ? ZeroAmountFormatted : paidAmount;

  return { title, content, contentHighlight: true, extraPadding: isChargedOff, applyPaddingTop: true };
};

const remainingDisplay: (showPoSCopy: boolean, isChargedOff: boolean, remaining: string, chargedOff: string, principal: string)
=> IRepaymentDataPointProps = (showPoSCopy, isChargedOff, remaining, chargedOff, principal) => {
  const totalAmount: string = isChargedOff ? chargedOff : principal;
  const title: string = showPoSCopy ? 'Remaining' : isChargedOff ? 'Charge-off balance' : 'Total Principal';
  const content: string = showPoSCopy ? remaining : totalAmount;

  return { title, content, extraPadding: isChargedOff };
};

const showPoSCopy: (isPoS: boolean, aprPercentage: number | null) => boolean = (isPoS, aprPercentage) => {
  const zeroApr = !aprPercentage;

  return isPoS &&
    (!AvantConfig.TenantConfig.customerHome.canShowAprCopyForPoS || zeroApr);
}

const RepaymentContent: React.FC<RepaymentContentProps> = data => {
  const {
    pointOfSaleInformation,
    paidPrincipal,
    principalAmount,
    chargeOffInformation,
    principalRemaining,
    aprPercentage,
  } = data;

  const isChargedOff: boolean = !!chargeOffInformation && chargeOffInformation.operationallyChargedOff;
  const isPoS: boolean = !!pointOfSaleInformation;
  const isActiveDispute: boolean = isUnresolvedPaymentProtectPointOfSale(pointOfSaleInformation);
  const shouldShowPoSCopy = showPoSCopy(isPoS, aprPercentage);

  const recoveredAmount: string = chargeOffInformation?.operationsRecoveredAmount?.formatted || '';
  const chargeOffAmount: string = chargeOffInformation?.operationsChargeOffAmount?.formatted || '';
  const remainingAmount: string = principalRemaining?.formatted || '';

  return (
    <ColumnWrapper as='dl'>
      <RepaymentDataDisplay {...paidDisplay(shouldShowPoSCopy, isActiveDispute, isChargedOff, paidPrincipal, recoveredAmount)} />
      <RepaymentDataDisplay {...remainingDisplay(shouldShowPoSCopy, isChargedOff, remainingAmount, chargeOffAmount, principalAmount)} />
    </ColumnWrapper>
  );
};

export default RepaymentContent;
