import React from 'react';
import Styled from 'styled-components';
import { media } from '@amount/frontend-components';
import { FragmentSelectionOnWorkflowTextInput, IInputProps } from '@amount/workflow-js';

export const WorkflowInputWrapper = Styled.div`
  padding: 1em 0;
`;

export const WorkflowInputMaxWidthWrapper = Styled(WorkflowInputWrapper)`
  ${media.small`
    max-width: 300px;
  `}
`;

export interface IWorkflowInputBaseProps extends IInputProps {
  innerInputRef?: React.RefObject<HTMLInputElement>;
  onClickCallback? (): void;
}

export interface IMasker {
  // for mask format and behavior
  // see: https://github.com/sanniassin/react-input-mask/tree/v2#mask--string
  mask: string | Array<string | RegExp>;
  pattern: string | undefined;
  unmask (maskedValue: string): string;
}

export const maskerFor: (format: FragmentSelectionOnWorkflowTextInput['format']) => IMasker = format => {
  switch (format) {
    case 'cardExpirationDate':
      return {
        mask: '99/9999',
        unmask: masked => masked, // backend takes MM/YYYY format
        pattern: '^(0[1-9]|10|11|12)/20[0-9]{2}$'
      };
    case 'cardNumber':
      return {
        mask: '9999 9999 9999 9999',
        unmask: masked => masked.replace(/[^\d]/g, ''), // extract only numerals
        pattern: '^(\\d{4}\\s?){3}\\d{0,4}$' // validate against space-masked value
      };
    default:
      return {
        mask: '',
        unmask: masked => masked,
        pattern: undefined
      };
  }
};
