import Styled from 'styled-components';
import {
  Card,
  media,
} from '@amount/frontend-components';

// eslint-disable-next-line
const bgCrossSellImageVertical: string = require('@amount/frontend-components/src/images/bg/bg-cross-sell-vertical.td.jpg');
// eslint-disable-next-line
const bgCrossSellImageHorizontal: string = require('@amount/frontend-components/src/images/bg/bg-cross-sell-horizontal.td.jpg');

export const StyledCrossSellCard = Styled(Card)`
  background-image: url(${bgCrossSellImageVertical});
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

 ${media.small`
    background-image: url(${bgCrossSellImageHorizontal});
    height: 100%;
  `}

  ${media.mediumLarge`
    background-image: url(${bgCrossSellImageVertical});
    height: 450px;
    justify-content: flex-end;
    flex-direction: column;
    background-position-y: -4em;
  `}
`;
